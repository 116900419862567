import LanguageProvider from "~/shared/components/language-provider";
import "../scss/product-wd.scss";
import { useDropzone } from 'react-dropzone';
import { Button } from "primereact/button";

const XmlFiles = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <>
      <div className="return-main-container">
        <div className="return-header-wrapper">
          <div className="container">
            <div className="header">
              <div className="title-wrapper">
                <span className="header-message">
                  <span className="title">
                    <LanguageProvider
                      id={"dcp.side.menu.product.wd.actions.xml.files"}
                    />
                  </span>
                </span>
                <Button
                  appearance="primary"
                  className="dcp-button-md primary"
                  autoFocus >
                  Importar
                </Button>
              </div>
            </div>
            <div className="drop-zone-container-duett">

              <section className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Arraste e solte um arquivo aqui</p>
                </div>
                <aside>
                  <h4>Arquivos</h4>
                  <ul>{files}</ul>
                </aside>
              </section>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default XmlFiles;
