import { Menu } from "primereact/menu";
import { classNames } from "primereact/utils";
import { memo, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import Icon from "~/shared/components/icons";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import LanguageProvider from "../../../../shared/components/language-provider";
import { ThemeContext } from "~/app";

const ReportCard = ({ model }) => {
  const { idDataSource, name, reportQtdRows, type } = model;
  const { currentTheme } = useContext(ThemeContext);
  const ref = useRef(null);
  const navigate = useNavigate();
  const dcpAxiosService = useDcpAxiosService();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { loadMenuItems } = useMenuItemsContext();

  const deleteReport = async () => {
    setSubmitting(true);
    try {
      const { status } = await dcpAxiosService.delete(
        `${settings.Urls.Rest.Datalake}/delete`,
        "Platform",
        {
          params: {
            idDataSource: idDataSource,
            name: name,
            typeDataSource: type,
          },
        }
      );

      if (status === 200) {
        setDeleteConfirmationVisible(false);
      }
      loadMenuItems();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  const menuItemTemplate = (
    options,
    color,
    fontWeight,
    viewName,
    onClick = () => {}
  ) => {
    return (
      <div
        className={classNames(options.className)}
        style={{ color, fontWeight: fontWeight, fontSize: "14px" }}
        onClick={onClick}
      >
        {LanguageProvider({ id: viewName })}
      </div>
    );
  };

  const menuItems = [
    {
      template: (item, options) =>
        menuItemTemplate(
          options,
          currentTheme.texPrimary,
          "400",
          "gen.edit.button",
          () => navigate(`/reports/edit/${idDataSource}`)
        ),
    },
    {
      template: (item, options) =>
        menuItemTemplate(options, "#F9485B", "400", "gen.exclude.button", () =>
          setDeleteConfirmationVisible(true)
        ),
    },
  ];

  return (
    <div
      onClick={() => {
        if (!deleteConfirmationVisible) {
          navigate(`reports/${idDataSource}`);
        }
      }}
    >
      <div className="card database-card fade-in">
        <div className="database-card-header">
          <div className="database-icon-container">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="40"
                height="40"
                rx="10"
                fill={currentTheme.databaseCardBackgroundColor}
              />
              <path
                d="M29.2 30C29.48 30 29.62 30 29.727 29.9455C29.8211 29.8976 29.8976 29.8211 29.9455 29.727C30 29.62 30 29.48 30 29.2V18.8C30 18.52 30 18.38 29.9455 18.273C29.8976 18.1789 29.8211 18.1024 29.727 18.0545C29.62 18 29.48 18 29.2 18L26.8 18C26.52 18 26.38 18 26.273 18.0545C26.1789 18.1024 26.1024 18.1789 26.0545 18.273C26 18.38 26 18.52 26 18.8V21.2C26 21.48 26 21.62 25.9455 21.727C25.8976 21.8211 25.8211 21.8976 25.727 21.9455C25.62 22 25.48 22 25.2 22H22.8C22.52 22 22.38 22 22.273 22.0545C22.1789 22.1024 22.1024 22.1789 22.0545 22.273C22 22.38 22 22.52 22 22.8V25.2C22 25.48 22 25.62 21.9455 25.727C21.8976 25.8211 21.8211 25.8976 21.727 25.9455C21.62 26 21.48 26 21.2 26H18.8C18.52 26 18.38 26 18.273 26.0545C18.1789 26.1024 18.1024 26.1789 18.0545 26.273C18 26.38 18 26.52 18 26.8V29.2C18 29.48 18 29.62 18.0545 29.727C18.1024 29.8211 18.1789 29.8976 18.273 29.9455C18.38 30 18.52 30 18.8 30L29.2 30Z"
                stroke="#ff8b62"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 14.8C18 14.52 18 14.38 18.0545 14.273C18.1024 14.1789 18.1789 14.1024 18.273 14.0545C18.38 14 18.52 14 18.8 14H21.2C21.48 14 21.62 14 21.727 14.0545C21.8211 14.1024 21.8976 14.1789 21.9455 14.273C22 14.38 22 14.52 22 14.8V17.2C22 17.48 22 17.62 21.9455 17.727C21.8976 17.8211 21.8211 17.8976 21.727 17.9455C21.62 18 21.48 18 21.2 18H18.8C18.52 18 18.38 18 18.273 17.9455C18.1789 17.8976 18.1024 17.8211 18.0545 17.727C18 17.62 18 17.48 18 17.2V14.8Z"
                stroke="#ff8b62"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 20.8C11 20.52 11 20.38 11.0545 20.273C11.1024 20.1789 11.1789 20.1024 11.273 20.0545C11.38 20 11.52 20 11.8 20H14.2C14.48 20 14.62 20 14.727 20.0545C14.8211 20.1024 14.8976 20.1789 14.9455 20.273C15 20.38 15 20.52 15 20.8V23.2C15 23.48 15 23.62 14.9455 23.727C14.8976 23.8211 14.8211 23.8976 14.727 23.9455C14.62 24 14.48 24 14.2 24H11.8C11.52 24 11.38 24 11.273 23.9455C11.1789 23.8976 11.1024 23.8211 11.0545 23.727C11 23.62 11 23.48 11 23.2V20.8Z"
                stroke="#ff8b62"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10.8C10 10.52 10 10.38 10.0545 10.273C10.1024 10.1789 10.1789 10.1024 10.273 10.0545C10.38 10 10.52 10 10.8 10H13.2C13.48 10 13.62 10 13.727 10.0545C13.8211 10.1024 13.8976 10.1789 13.9455 10.273C14 10.38 14 10.52 14 10.8V13.2C14 13.48 14 13.62 13.9455 13.727C13.8976 13.8211 13.8211 13.8976 13.727 13.9455C13.62 14 13.48 14 13.2 14H10.8C10.52 14 10.38 14 10.273 13.9455C10.1789 13.8976 10.1024 13.8211 10.0545 13.727C10 13.62 10 13.48 10 13.2V10.8Z"
                stroke="#ff8b62"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="popup_menu_right settings-button"
            onClick={(e) => {
              ref.current.toggle(e);
              e.stopPropagation();
            }}
          >
            <Icon
              icon={"dots-vertical"}
              size={30}
              color={"#8189A3"}
              className={"settings-field-button"}
            />
            <Menu popup model={menuItems} ref={ref}></Menu>
          </div>
        </div>
        <div className="database-card-content">
          <span className="database-name">{name}</span>
          <span className="database-entries">
            {/* {`${Intl.NumberFormat().format(
            model.length
          )} ${LanguageProvider({
            id: "dashboard.database.registers",
          })}`}  */}
          </span>
        </div>
      </div>

      {/* Delete field modal */}
      <ModalConfirmation
        isOpen={deleteConfirmationVisible}
        modalTitle={<LanguageProvider id="reports.delete.title" />}
        bodyMessage={<LanguageProvider id="reports.delete.message" />}
        isDelete={true}
        onConfirm={deleteReport}
        onCancel={() => {
          setDeleteConfirmationVisible(false);
        }}
        loading={submitting}
      />
    </div>
  );
};

export default memo(ReportCard);
