import "./scss/layout.scss";

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "~/shared/components/dcp-button";
import { ColorPicker } from "primereact/colorpicker";
import HomeProduct from "~/shared/components/home-product";
import Icon from "~/shared/components/icons";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "~shared/components/language-provider";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import RackExample from "./components/rack-example";

import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import { RackLayoutModel } from "~/shared/components/rack-layout-model";
import { ThemeContext } from "~/app";
import { classNames } from "primereact/utils";
import imgArea from "~/theme/media/assets/addProductImg.svg";
import settings from "~/services/settings.json";
import { useFormik } from "formik";
import { useToastContext } from "~/context/ToastContext";
import { SeeExampleButton } from "~/shared/components/buttons/see-example-button";
import { deleteRack } from "~/services/api/platform/layout";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import PageHeader from "~/shared/components/page-header/page-header";

const Layout = () => {
  const { showToast } = useToastContext();
  const { currentLanguage } = useLanguageContext();
  const { currentTheme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();
  const [rackNames, setRackNames] = useState([]);
  const [filteredRackName, setFilteredRackName] = useState([]);
  const [visibleButtons, setVisibleButtons] = useState(false);

  const [displayRackExampleDialog, setDisplayRackExampleDialog] =
    useState(false);
  const [modalConfirmationIsOpen, setModalConfirmationIsOpen] = useState(false);
  const [modalIsDelete, setModalIsDelete] = useState(false);
  const [confirmDeleteRackId, setConfirmDeleteRackId] = useState("");
  const [modalConfirmationTitle, setModalConfirmationTitle] = useState("");
  const [modalConfirmationBodyMessage, setModalConfirmationBodyMessage] =
    useState("");

  const [searchValue, setSearchValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submittingDuplicate, setSubmittingDuplicate] = useState(false);

  const dcpAxiosService = useDcpAxiosService();

  const { rackId } = useParams();

  const rackFormik = useFormik({
    initialValues: {
      name: "",
      color: "#04AA77",
      height: 0,
      width: 0,
      firstShelfDistance: 0,
      qtyShelfs: 1,
      defaultShelfHeight: 0,
      defaultShelfLocation: 0,
      defaultShelfSize: true,
      topShelf: false,
      shelves: [{ id: 0, height: 0, locationsAmount: 0 }],
    },
    validateOnChange: false,
    validate: (data) => {
      let errors = {};
      if (!data.name) {
        errors.name = (
          <LanguageProvider id={"rack.create.name.validation.error"} />
        );
      }
      if (data.height <= 0) {
        errors.height = (
          <LanguageProvider id={"rack.create.height.validation.error"} />
        );
      }
      if (!data.width) {
        errors.width = (
          <LanguageProvider id={"rack.create.width.validation.error"} />
        );
      }
      if (!data.firstShelfDistance) {
        errors.firstShelfDistance = (
          <LanguageProvider
            id={"rack.create.floor.distance.validation.error"}
          />
        );
      }
      if (data.qtyShelfs <= 0) {
        errors.qtyShelfs = (
          <LanguageProvider id={"rack.create.total.shelf.validation.error"} />
        );
      }

      if (data.defaultShelfSize) {
        if (data.defaultShelfHeight <= 0) {
          errors.defaultShelfHeight = (
            <LanguageProvider
              id={"rack.create.default.shelf.height.validation.error"}
            />
          );
        }
        if (data.defaultShelfLocation <= 0) {
          errors.defaultShelfLocation = (
            <LanguageProvider
              id={"rack.create.total.locations.shelf.validation.error"}
            />
          );
        }
      }
      if (Array.isArray(data.shelves) && data.shelves.length > 0) {
        data.shelves.forEach((element, index) => {
          if (!element.height || element.height <= 0) {
            errors[`shelves[${index}].height`] = (
              <LanguageProvider
                id={"rack.create.default.shelf.height.validation.error"}
              />
            );
          }
          if (!element.locationsAmount || element.locationsAmount <= 0) {
            errors[`shelves[${index}].locationsAmount`] = (
              <LanguageProvider
                id={"rack.create.total.locations.shelf.validation.error"}
              />
            );
          }
        });
      }
      return errors;
    },
    onSubmit: async (data) => {
      setSubmitting(true);
      try {
        const rackModel = {
          id: data.id,
          name: data.name,
          color: "#".concat(data.color),
          height: data.height,
          width: data.width,
          firstShelfDistance: data.firstShelfDistance,
          topShelf: data.topShelf,
          qtyShelfs: data.qtyShelfs,
          defaultShelfSize: data.defaultShelfSize,
          defaultShelfHeight: data.defaultShelfHeight,
          defaultShelfLocation: data.defaultShelfLocation,
          shelves: [...data.shelves],
        };

        const { data: responseData, status } = await dcpAxiosService.post(
          `${settings.Urls.Rest.Layout}/create/`,
          rackModel,
          "Platform"
        );

        if (status === 200) {
          showToast({
            severity: "success",
            message: LanguageProviderWithoutContext({
              id: "gen.message.rack.saved",
              currentLanguage,
            }),
          });

          LoadRacks();
          LoadSelectedRack(data.id);
        } else {
          showToast({
            severity: "error",
            message: <LanguageProvider id={"data.message"} />,
          });
          rackFormik.resetForm();
        }
        if (responseData.responseCode === "SE-00011-400" && status === 200) {
          showToast({
            severity: "error",
            message: (
              <LanguageProvider id={"inventory.rack.validation.same.name"} />
            ),
            life: 3000,
          });
          rackFormik.resetForm();
        }
        setSubmitting(false);
      } catch (error) {
        rackFormik.resetForm();
      }
    },
  });

  const IsRackFormValid = (name) => {
    return !!rackFormik.errors[name];
  };
  const GetRackFormErrorMessage = ({ name }) => {
    return IsRackFormValid(name) ? (
      <small className="p-error">{rackFormik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  // load from params
  const LoadSelectedRack = async (rackId) => {
    const { data } = await dcpAxiosService.get(
      `${settings.Urls.Rest.Layout}/get-rack?id=${rackId}`,
      "Platform"
    );
    if (data.status) {
      rackFormik.setValues({
        ...data.data,
        defaultShelfHeight:
          Array.isArray(data.data.shelves) && data.data.shelves.length > 0
            ? data.data.shelves[0].height
            : 0,
        defaultShelfLocation:
          Array.isArray(data.data.shelves) && data.data.shelves.length > 0
            ? data.data.shelves[0].locationsAmount
            : 0,
      });
      setVisibleButtons(true);
    } else {
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: data.message,
          currentLanguage,
        }),
      });
    }
  };

  // load from params
  const LoadRack = async () => {
    try {
      if (rackId === "0") {
        rackFormik.resetForm();
        setVisibleButtons(false);
        return;
      }

      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Layout}/get-rack?id=${rackId}`,
        "Platform"
      );

      if (data.status) {
        rackFormik.setValues({
          ...data.data,
          defaultShelfHeight:
            Array.isArray(data.data.shelves) && data.data.shelves.length > 0
              ? data.data.shelves[0].height
              : 0,
          defaultShelfLocation:
            Array.isArray(data.data.shelves) && data.data.shelves.length > 0
              ? data.data.shelves[0].locationsAmount
              : 0,
        });
        setVisibleButtons(true);
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: data.message,
            currentLanguage,
          }),
        });
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    LoadRack();
  }, []);

  const LoadRacks = async () => {
    const { data } = await dcpAxiosService.get(
      `${settings.Urls.Rest.Layout}/list-racks`,
      "Platform"
    );
    if (data.status) {
      setRackNames(data.data);
      if (data.data.length > 0) {
        setShowForm(true);
      }
    } else {
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: data.message,
          currentLanguage,
        }),
      });
    }
  };

  const handleUpdateRack = async (model) => {
    setSubmitting(true);
    let updatedModel = {
      ...model,
      color: "#".concat(rackFormik.values.color),
    };
    const { data: responseData, status } = await dcpAxiosService.post(
      `${settings.Urls.Rest.Layout}/update-rack/`,
      updatedModel,
      "Platform"
    );
    if (status === 200) {
      showToast({
        severity: "success",
        message: LanguageProviderWithoutContext({
          id: "gen.message.rack.saved",
          currentLanguage,
        }),
      });
      LoadRacks();
      LoadSelectedRack(responseData.data.id);
    } else {
      showToast({
        severity: "error",
        message: <LanguageProvider id={"data.message"} />,
      });
    }
    setSubmitting(false);
  };
  const handleDuplicateRack = async (model) => {
    setSubmittingDuplicate(true);
    // const copyOfRackName = "Copia ";
    // const duplicatedRackName = copyOfRackName.concat(model.name);

    let duplicatedModel = {
      ...model,
      color: rackFormik.values.color,
    };
    const { data: responseData, status } = await dcpAxiosService.post(
      `${settings.Urls.Rest.Layout}/create/`,
      duplicatedModel,
      "Platform"
    );
    if (status === 200 && status !== false) {
      showToast({
        severity: "success",
        message: LanguageProviderWithoutContext({
          id: "gen.message.rack.duplicated",
          currentLanguage,
        }),
      });
      LoadRacks();
      LoadSelectedRack(responseData.data.id);
    } else {
      showToast({
        severity: "error",
        message: <LanguageProvider id={"data.message"} />,
      });
    }
    setSubmittingDuplicate(false);
  };
  const HandleNameChange = (e) => {
    rackFormik.setFieldValue("name", e.target.value);
  };
  const HandleColorChange = (e) => {
    rackFormik.setFieldValue("color", e.value);
  };
  const HandleHeightChange = (e) => {
    rackFormik.setFieldValue("height", e.value);
  };
  const HandleWidthChange = (e) => {
    rackFormik.setFieldValue("width", e.value);
  };
  const HandleFirstShelfDistanceChange = (e) => {
    rackFormik.setFieldValue("firstShelfDistance", e.value);
  };
  const HandleToggleTopShelf = (e) => {
    rackFormik.setFieldValue("topShelf", e.value);
  };
  const HandleShelfAmountChange = (e) => {
    rackFormik.setFieldValue("qtyShelfs", e.value);
  };
  const HandleShelfAmountBlur = (e) => {
    const value = parseInt(e.target.value);
    const currentLength = rackFormik.values.shelves.length;
    let shelves = [...rackFormik.values.shelves];

    // Return in case of no changes.
    if (!value || value === currentLength) return;

    if (value < currentLength) {
      shelves = [...rackFormik.values.shelves].slice(0, value);
    } else {
      for (let i = shelves.length; i < value; i++) {
        shelves.push({
          id: 0,
          height: 0,
          locationsAmount: 0,
        });
      }
    }
    rackFormik.setFieldValue("shelves", shelves);
  };
  const HandleToggleDefaultShelfSize = (e) => {
    rackFormik.setFieldValue("defaultShelfSize", e.value);
    if (!e.value) {
      rackFormik.setFieldValue("qtyShelfs", 2);
      HandleShelfAmountBlur({
        target: {
          value: 2,
        },
      });
    } else {
      rackFormik.setFieldValue("qtyShelfs", 1);
      HandleShelfAmountBlur({
        target: {
          value: 1,
        },
      });
    }
    if (!e.value) {
      rackFormik.setFieldValue("qtyShelfs", 2);
      HandleShelfAmountBlur({
        target: { value: 2 },
      });
    } else {
      rackFormik.setFieldValue("qtyShelfs", 1);
      HandleShelfAmountBlur({
        target: {
          value: 1,
        },
      });
    }
  };
  const HandleDefaultShelfHeightChange = (e) => {
    rackFormik.setFieldValue("defaultShelfHeight", e.value);
    let shelves = rackFormik.values.shelves;
    shelves = shelves.map((element) => {
      element.height = e.value;
      return element;
    });
    rackFormik.setFieldValue("shelves", shelves);
  };
  const HandleDefaultShelfLocationsChange = (e) => {
    rackFormik.setFieldValue("defaultShelfLocation", e.value);
    let shelves = rackFormik.values.shelves;
    shelves.forEach((element) => {
      element.locationsAmount = e.value;
      return element;
    });
    rackFormik.setFieldValue("shelves", shelves);
  };
  const OpenModalConfirmationDelete = (id) => {
    setModalIsDelete(true);
    setConfirmDeleteRackId(id);
    setModalConfirmationTitle(
      <LanguageProvider id="gen.confirm.rack.delete" />
    );
    setModalConfirmationBodyMessage(
      <LanguageProvider id="gen.message.rack.delete" />
    );
    setModalConfirmationIsOpen(true);
  };

  const OpenModalConfirmationDuplicate = (id) => {
    setModalIsDelete(false);
    setConfirmDeleteRackId(id);
    setModalConfirmationTitle(
      <LanguageProvider id="gen.confirm.rack.duplicate" />
    );
    setModalConfirmationBodyMessage(
      <LanguageProvider id="gen.message.rack.duplicate" />
    );
    setModalConfirmationIsOpen(true);
  };
  const CloseModalConfirmationUser = () => {
    setModalConfirmationIsOpen(false);
    LoadRacks();
  };
  const confirmResponse = async (confirmed) => {
    if (confirmed) {
      if (modalIsDelete) {
        const { status } = await deleteRack(confirmDeleteRackId);
        if (status) {
          showToast({
            severity: "success",
            message: LanguageProviderWithoutContext({
              id: "gen.message.rack.deleted",
              currentLanguage,
            }),
          });
        } else {
          showToast({
            severity: "error",
          });
        }
      } else {
        handleDuplicateRack(rackFormik.values);
      }
      CloseModalConfirmationUser();
      rackFormik.resetForm();
      setVisibleButtons(false);
      LoadRacks();
    }
  };

  function headerActions() {
    return (
      <SeeExampleButton
        onClick={() => setDisplayRackExampleDialog(true)}
        disabled={false}
      ></SeeExampleButton>
    );
  }

  useEffect(() => {
    async function load() {
      setLoading(true);
      await LoadRacks();
      setLoading(false);
    }

    load();
  }, []);

  if (loading)
    return <LoadingIndicator size="small" color={currentTheme.primary} />;

  if (!showForm) {
    return (
      <div className="home-layout">
        <HomeProduct
          title={<LanguageProvider id={"product.drone.layout"} />}
          bodyMessage={
            <LanguageProvider id={"inventory.message.empty.layout"} />
          }
          image={imgArea}
          isVisible={(e) => setShowForm(e)}
        />
      </div>
    );
  } else {
    return (
      <>
        <div className="inventory-layout">
          <div className="layout-content">
            <PageHeader
              titleTemplate={undefined}
              recordsCount={undefined}
              onReturn={() => navigate(-1)}
              actions={headerActions}
              title={
                rackId === "0" ? (
                  <LanguageProvider id={"gen.configure.rack.type"} />
                ) : (
                  <LanguageProvider id={"gen.edit.rack.type"} />
                )
              }
            />
            <div className="botton-container">
              {/* <div className="search-rack">
                <span className="rack-types-title">
                  <LanguageProvider id={"gen.rack.types"} />
                </span>
                <span className="description">
                  <LanguageProvider id={"rack.type.description"} />
                </span>
                <span className="search-input p-input-icon-left">
                  <div className="icon-search">
                    <Icon icon={"search-md"} color="#98A2B3" size={20} />
                  </div>
                  <InputText
                    className="p-inputtext-md"
                    value={searchValue}
                    onChange={HandleSearchValueChange}
                    placeholder={LanguageProvider({
                      id: "search.field.placeholder",
                    })}
                  />
                </span>
                <Button
                  className="new-rack-button p-button-sm p-button-outlined"
                  icon={<Icon icon={"plus"} color="#4146ff" />}
                  iconPos="left"
                  disabled={!visibleButtons}
                  onClick={() => {
                    rackFormik.resetForm();
                    setVisibleButtons(false);
                  }}
                >
                  <LanguageProvider id={"gen.add.new.type"} />
                </Button>
                <div className="racks-wrapper">
                  {filteredRackName.map((rack, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames({
                          rack: true,
                          "active-rack": rackFormik.values.id === rack.id,
                        })}
                        style={{
                          "--rackColor": rack.color,
                        }}
                        onClick={() => LoadSelectedRack(rack.id)}
                      >
                        <div className="rack-tag" />
                        <span className="rack-name" title={rack.name}>
                          {rack.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div> */}
              <div className="rack-configure-wrapper">
                <form onSubmit={rackFormik.handleSubmit} className="rack-form ">
                  <div className="rack-form-content">
                    <div className="dcp-form">
                      <div className="form-content">
                        <span className="form-content-title">
                          <LanguageProvider id={"gen.rack"} />
                        </span>
                        <div className="form-row">
                          <div className="input-container rack-name">
                            <label className="identifier p-identifier-sm">
                              <LanguageProvider id={"gen.name"} />
                            </label>
                            <div className="input">
                              <InputText
                                name="name"
                                value={rackFormik.values.name}
                                onChange={HandleNameChange}
                                className={[
                                  "p-inputtext-md",
                                  classNames({
                                    "p-invalid": IsRackFormValid("name"),
                                  }),
                                ]}
                                placeholder={LanguageProviderWithoutContext({
                                  id: "gen.rack.name",
                                  currentLanguage,
                                })}
                              />
                            </div>
                            <GetRackFormErrorMessage name="name" />
                          </div>
                          <div className="input-container rack-color">
                            <label className="identifier">
                              <LanguageProvider id={"gen.color"} />
                            </label>
                            <div className="input">
                              <ColorPicker
                                type="text"
                                className="p-colorpicker-preview "
                                value={rackFormik.values.color}
                                onChange={HandleColorChange}
                              />
                            </div>
                            <GetRackFormErrorMessage name="color" />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-column">
                            <div className="input-container rack-height">
                              <label className="identifier">
                                <LanguageProvider id={"gen.rack.height"} />
                              </label>
                              <div className="input">
                                <InputNumber
                                  name="height"
                                  value={rackFormik.values.height}
                                  onChange={HandleHeightChange}
                                  placeholder={LanguageProviderWithoutContext({
                                    id: "gen.cm",
                                    currentLanguage,
                                  })}
                                  className={classNames({
                                    "p-invalid": IsRackFormValid("height"),
                                    "p-inputtext-md": true,
                                  })}
                                  min={1}
                                  useGrouping={false}
                                  maxFractionDigits={2}
                                />
                              </div>
                              <GetRackFormErrorMessage name="height" />
                            </div>
                            <div className="input-container rack-first-shelf-distance">
                              <label className="identifier">
                                <LanguageProvider
                                  id={"gen.distance.first.shelf"}
                                />
                              </label>
                              <div className="input">
                                <InputNumber
                                  name="firstShelfDistance"
                                  value={rackFormik.values.firstShelfDistance}
                                  onChange={HandleFirstShelfDistanceChange}
                                  className={classNames({
                                    "p-invalid":
                                      IsRackFormValid("firstShelfDistance"),
                                    "p-inputtext-md": true,
                                  })}
                                  placeholder={LanguageProviderWithoutContext({
                                    id: "gen.cm",
                                    currentLanguage,
                                  })}
                                  min={1}
                                  useGrouping={false}
                                  maxFractionDigits={2}
                                />
                              </div>
                              <GetRackFormErrorMessage name="firstShelfDistance" />
                            </div>
                            <div className="input-container rack-amount-of-shelves">
                              <label className="identifier">
                                <LanguageProvider id={"gen.total.shelf"} />
                              </label>
                              <div className="input">
                                <InputNumber
                                  name="qtyShelfs"
                                  className={classNames({
                                    "p-invalid": IsRackFormValid("qtyShelfs"),
                                    "p-inputtext-md": true,
                                  })}
                                  value={rackFormik.values.qtyShelfs}
                                  onChange={HandleShelfAmountChange}
                                  onBlur={HandleShelfAmountBlur}
                                  min={1}
                                />
                              </div>
                              <GetRackFormErrorMessage name="qtyShelfs" />
                            </div>
                          </div>
                          <div className="form-column">
                            <div className="input-container rack-width">
                              <label className="identifier">
                                <LanguageProvider id={"gen.rack.width"} />
                              </label>
                              <div className="input">
                                <InputNumber
                                  name="width"
                                  value={rackFormik.values.width}
                                  onChange={HandleWidthChange}
                                  className={classNames({
                                    "p-invalid": IsRackFormValid("width"),
                                    "p-inputtext-md": true,
                                  })}
                                  placeholder={LanguageProviderWithoutContext({
                                    id: "gen.cm",
                                    currentLanguage,
                                  })}
                                  min={1}
                                  useGrouping={false}
                                  maxFractionDigits={2}
                                />
                              </div>
                              <GetRackFormErrorMessage name="width" />
                            </div>
                            <div className="input-container rack-top-shelf-available">
                              <label className="identifier">
                                <LanguageProvider
                                  id={"gen.top.shelf.available"}
                                />
                              </label>
                              <div className="input">
                                <InputSwitch
                                  className="p-inputswitch"
                                  checked={rackFormik.values.topShelf}
                                  onChange={HandleToggleTopShelf}
                                />
                              </div>
                            </div>
                            <div className="input-container rack-default-size">
                              <label className="identifier">
                                <LanguageProvider id={"gen.default.height"} />
                              </label>
                              <div className="input">
                                <InputSwitch
                                  className="p-inputswitch"
                                  checked={rackFormik.values.defaultShelfSize}
                                  onChange={HandleToggleDefaultShelfSize}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {!rackFormik.values.defaultShelfSize ? (
                          <>
                            {Array.isArray(rackFormik.values.shelves) &&
                              rackFormik.values.shelves.map((shelf, index) => {
                                const reversedIndex =
                                  rackFormik.values.shelves.length - index - 1;

                                return (
                                  <div
                                    className="mixed-shelves-container"
                                    key={reversedIndex}
                                  >
                                    <div className="form-row">
                                      <span className="shelf-index">
                                        {`${
                                          reversedIndex + 1
                                        } º ${LanguageProviderWithoutContext({
                                          id: "gen.shelf",
                                          currentLanguage,
                                        })}`}
                                      </span>
                                      <div className="input-container rack-shelf-height">
                                        <label className="identifier">
                                          <LanguageProvider id={"gen.height"} />
                                        </label>
                                        <div className="input">
                                          <InputText
                                            // name={`shelves[${shelfIndex}].height`}
                                            value={
                                              rackFormik.values.shelves[
                                                reversedIndex
                                              ].height
                                            }
                                            onChange={(e) => {
                                              rackFormik.setFieldValue(
                                                `shelves[${reversedIndex}].height`,
                                                e.target.value
                                              );
                                            }}
                                            className={classNames({
                                              "p-invalid": IsRackFormValid(
                                                `shelves[${reversedIndex}].height`
                                              ),
                                              "p-inputtext-md": true,
                                            })}
                                            placeholder={LanguageProviderWithoutContext(
                                              {
                                                id: "gen.cm",
                                                currentLanguage,
                                              }
                                            )}
                                            min={1}
                                            useGrouping={false}
                                            maxFractionDigits={2}
                                            type="number"
                                          />
                                        </div>
                                        <GetRackFormErrorMessage
                                          name={`shelves[${reversedIndex}].height`}
                                        />
                                      </div>
                                      <div className="input-container rack-shelf-locations-amount">
                                        <label className="identifier">
                                          <LanguageProvider
                                            id={"gen.total.shelf.locations"}
                                          />
                                        </label>
                                        <div className="input">
                                          <InputText
                                            // name={`shelves[${shelfIndex}].locationsAmount`}
                                            value={
                                              rackFormik.values.shelves[
                                                reversedIndex
                                              ].locationsAmount
                                            }
                                            onChange={(e) => {
                                              rackFormik.setFieldValue(
                                                `shelves[${reversedIndex}].locationsAmount`,
                                                e.target.value
                                              );
                                            }}
                                            className={classNames({
                                              "p-invalid": IsRackFormValid(
                                                `shelves[${reversedIndex}].locationsAmount`
                                              ),
                                              "p-inputtext-md": true,
                                            })}
                                            placeholder={LanguageProviderWithoutContext(
                                              {
                                                id: "gen.un",
                                                currentLanguage,
                                              }
                                            )}
                                            min={1}
                                            type="number"
                                            useGrouping={false}
                                          />
                                        </div>
                                        <GetRackFormErrorMessage
                                          name={`shelves[${reversedIndex}].locationsAmount`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : (
                          <div className="form-row">
                            <div className="input-container rack-default-shelf-height">
                              <label className="identifier">
                                <LanguageProvider id={"gen.shelf.height"} />
                              </label>
                              <div className="input">
                                <InputNumber
                                  name="defaultShelfHeight"
                                  value={rackFormik.values.defaultShelfHeight}
                                  onChange={HandleDefaultShelfHeightChange}
                                  className={classNames({
                                    "p-invalid":
                                      IsRackFormValid("defaultShelfHeight"),
                                    "p-inputtext-md": true,
                                  })}
                                  placeholder={LanguageProviderWithoutContext({
                                    id: "gen.cm",
                                    currentLanguage,
                                  })}
                                />
                              </div>
                              <GetRackFormErrorMessage name="defaultShelfHeight" />
                            </div>
                            <div className="input-container rack-default-locations-amount">
                              <label className="identifier">
                                <LanguageProvider
                                  id={"gen.total.shelf.locations"}
                                />
                              </label>
                              <div className="input">
                                <InputNumber
                                  name="defaultShelfLocation"
                                  value={rackFormik.values.defaultShelfLocation}
                                  onChange={HandleDefaultShelfLocationsChange}
                                  className={classNames({
                                    "p-invalid": IsRackFormValid(
                                      "defaultShelfLocation"
                                    ),
                                    "p-inputtext-md": true,
                                  })}
                                />
                              </div>
                              <GetRackFormErrorMessage name="defaultShelfLocation" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="demo-rack-content">
                      <div className="header">
                        <span>
                          <LanguageProvider id={"rack.front.side"} />
                        </span>
                        <span>
                          <LanguageProvider id={"rack.size"} />:{" "}
                          {`X: ${rackFormik.values.width}cm Y: ${rackFormik.values.height}cm`}
                        </span>
                      </div>
                      <div className="generated-rack">
                        <RackLayoutModel rack={{ ...rackFormik.values }} />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="rack-form-footer">
                      <div className="dcp-btn-wrapper-delete-rack">
                        <Button
                          appearance="danger"
                          className="p-button-danger"
                          visible={visibleButtons}
                          type="button"
                          onClick={() => {
                            OpenModalConfirmationDelete(rackFormik.values.id);
                          }}
                        >
                          <LanguageProvider id={"gen.exclude.button"} />
                        </Button>
                      </div>
                      <div className="dcp-btn-wrapper-manage-racks">
                        <Button
                          label={
                            <LanguageProvider id={"gen.message.duplicate"} />
                          }
                          appearance="subtle"
                          className="p-button-secondary"
                          disabled={!visibleButtons}
                          type="button"
                          loading={submittingDuplicate}
                          onClick={() => {
                            OpenModalConfirmationDuplicate(
                              rackFormik.values.id
                            );
                          }}
                        />
                        <Button
                          className="p-secondary"
                          loading={submitting}
                          visible={visibleButtons}
                          type="button"
                          onClick={() => {
                            handleUpdateRack(rackFormik.values);
                          }}
                        >
                          <LanguageProvider id={"gen.save.button"} />
                        </Button>
                        <Button
                          size="medium"
                          loading={submitting}
                          visible={!visibleButtons}
                          type="submit"
                        >
                          <LanguageProvider id={"gen.save.button"} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <RackExample
          visible={displayRackExampleDialog}
          onClose={() => setDisplayRackExampleDialog(false)}
        />
        <ModalConfirmation
          isOpen={modalConfirmationIsOpen}
          modalTitle={modalConfirmationTitle}
          bodyMessage={modalConfirmationBodyMessage}
          model={confirmDeleteRackId}
          isDelete={modalIsDelete}
          onConfirm={confirmResponse}
          onCancel={CloseModalConfirmationUser}
        />
      </>
    );
  }
};

export default Layout;
