import { InputText } from "primereact/inputtext";

export function TextConfig({ config, updateValue = function () {} }) {
  return (
    <InputText
      value={config.value}
      onChange={(e) => updateValue(e.target.value)}
    />
  );
}
