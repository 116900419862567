import { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import fakeData from "../../fake-data/amc/movement-by-address.json";
import { ThemeContext } from "~/app";

export default function MovmentByAddress(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState([]);

  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: currentTheme.DashboardDataColor,
        },
        grid: {
          color: currentTheme.DashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.DashboardDataColor,
        },
        grid: {
          color: currentTheme.DashboardStrokesColor,
        },
      },
    },
    dataLabels: {
      enabled: false,
      colors: ["#000"],
    },
    colors: ["#008FFB"],
    parentHeight: "100%",
    xaxis: {
      labels: {
        rotate: -90,
        rotateAlways: true,
      },
    },
  };

  useEffect(() => {
    if (useMockData) {
      setData(fakeData);
    } else setData([]);
  }, [useMockData]);

  return (
    <div
      className="inbound-heat-map"
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <Chart
        options={options}
        series={data}
        type="heatmap"
        width="100%"
        height="100%"
      />
    </div>
  );
}
