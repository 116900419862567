import "../../scss/entry-dialog.scss";

import { useEffect } from "react";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "~/shared/components/dcp-button";
import LanguageProvider from "~/shared/components/language-provider";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useToastContext } from "~/context/ToastContext";
import settings from "~/services/settings.json";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";

const AddItemDialog = ({
  visible,
  onHide,
  itemModel,
  skuOptions,
  addressOptions,
  tableData,
  isEdit,
  isOutput,
}) => {
  const { currentLanguage } = useLanguageContext();
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();

  const ValidateForm = async (data) => {
    let erros = {};
    try {
      if (data) {
        if (!data.skuItem || data.skuItem.name.length <= 0)
          erros.skuItem = (
            <LanguageProvider id={"warehouse.table.item.validation.error"} />
          );
        if (!data.address || data.address.name.length <= 0)
          erros.address = (
            <LanguageProvider id={"warehouse.table.address.validation.error"} />
          );
        if (!data.qty || data.qty <= 0)
          erros.qty = (
            <LanguageProvider
              id={"warehouse.table.quantity.validation.error"}
            />
          );
        if (isOutput) {
          if (
            data.skuItem?.name?.length > 0 &&
            data.address?.name?.length > 0 &&
            data?.qty > 0
          ) {
            const checkQuantityToOutput = await verifyQuantityToOutput(data);
            if (!checkQuantityToOutput) {
              erros.qty = (
                <LanguageProvider
                  id={"warehouse.table.quantity.exceeded.error"}
                />
              );
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return erros;
  };
  const IsFormValid = (name) => {
    return !!(tableItemFormik.touched[name] && tableItemFormik.errors[name]);
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">{tableItemFormik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };
  const verifyQuantityToOutput = async (item) => {
    const { data, status } = await dcpAxiosService.get(
      `${settings.Urls.Rest.Movements}/list-total-entries`,
      "Warehouse",
      {
        params: { itemId: item.skuItem.id, addressItemId: item.address.id },
      }
    );

    if (status) {
      if (data.data.qty > item.qty) {
        return true;
      } else {
        return false;
      }
    } else {
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: "gen.error",
          currentLanguage,
        }),
      });
      return false;
    }
  };

  const saveForm = async (data) => {
    if (!itemModel) {
      const newTableData = { ...data, id: generateUniqueId() };
      tableData(newTableData);
    } else {
      const updatedTableData = { ...data, id: itemModel.id };
      tableData(updatedTableData);
    }
    CloseModal();
  };

  const tableItemFormik = useFormik({
    initialValues: {
      skuItem: "",
      address: "",
      qty: "",
    },
    validate: ValidateForm,
    onSubmit: saveForm,
  });

  const CloseModal = () => {
    if (onHide) {
      tableItemFormik.resetForm();
      onHide();
    }
  };

  useEffect(() => {
    if (visible && itemModel) {
      tableItemFormik.setFieldValue("skuItem", itemModel.skuItem);
      tableItemFormik.setFieldValue("address", itemModel.address);
      tableItemFormik.setFieldValue("qty", itemModel.qty);
    }
  }, [visible, itemModel]);

  return (
    <Dialog
      className="entry-dialog"
      visible={visible}
      onHide={CloseModal}
      header={() => {
        return (
          <div className="entry-dialog-header">
            <span className="entry-dialog-header-title">
              {isEdit ? (
                <LanguageProvider
                  id={"dcp.platform.warehouse.entry.dialog.edit.title"}
                />
              ) : (
                <LanguageProvider
                  id={"dcp.platform.warehouse.entry.dialog.add.title"}
                />
              )}
            </span>
          </div>
        );
      }}
      footer={() => {
        return (
          <div className="entry-dialog-footer">
            <div className="actions">
              <Button
                onClick={CloseModal}
                label={<LanguageProvider id={"gen.cancel"} />}
                className="p-button p-button-text p-button-plain"
              />
              <Button
                type="submit"
                size="medium"
                appearance="primary"
                onClick={() => tableItemFormik.handleSubmit()}
                label={
                  isEdit ? (
                    <LanguageProvider id={"gen.save.button"} />
                  ) : (
                    <LanguageProvider id={"gen.add.register"} />
                  )
                }
              />
            </div>
          </div>
        );
      }}
    >
      <Form className="entry-dialog-content">
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider id={"dcp.platform.warehouse.entry.item"} />
            }
          >
            <Dropdown
              id="skuItem"
              name="skuItem"
              value={tableItemFormik.values.skuItem}
              onChange={(e) => {
                tableItemFormik.setFieldValue("skuItem", e.value);
              }}
              options={skuOptions}
              optionLabel="name"
              filter
              filterBy="name"
              placeholder={<LanguageProvider id={"gen.placeholder.select"} />}
              emptyMessage={
                <LanguageProvider
                  id={"dcp.platform.warehouse.dropdown.empty.message"}
                />
              }
            />
            <GetFormError name={"skuItem"} />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider id={"dcp.platform.warehouse.entry.address"} />
            }
          >
            <Dropdown
              inputId="address"
              name="address"
              value={tableItemFormik.values.address}
              onChange={(e) => {
                tableItemFormik.setFieldValue("address", e.value);
              }}
              options={addressOptions}
              optionLabel="name"
              filter
              filterBy="name"
              placeholder={<LanguageProvider id={"gen.placeholder.select"} />}
              emptyMessage={
                <LanguageProvider
                  id={"dcp.platform.warehouse.dropdown.empty.message"}
                />
              }
            />
            <GetFormError name={"address"} />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider id={"dcp.platform.warehouse.entry.quantity"} />
            }
          >
            <InputNumber
              id="qty"
              name="qty"
              value={tableItemFormik.values.qty}
              onChange={(e) => {
                tableItemFormik.setFieldValue("qty", e.value);
              }}
              min={0}
            />
            <GetFormError name={"qty"} />
          </InputContainer>
        </FormRow>
      </Form>
    </Dialog>
  );
};

export default AddItemDialog;
