import React from "react";
import { useEffect } from "react";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";

const PrintersFromInventory = () => {
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(settings.DatabaseProcessType.Inventory_Printers);
  }, [loadDatabaseProductProcess]);

  return (
    databaseProductProcessData && (
      <DatabaseList
        id={databaseProductProcessData.idDatabase}
        canEdit={false}
        hasDetails={databaseProductProcessData.uriDetails.length > 0 ? true : false}
        uriDetails={databaseProductProcessData.uriDetails}
        showIcon={false}
      />
    )
  );
};

export default PrintersFromInventory;
