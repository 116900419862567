import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";

export function FontSizeConfig({ config, updateValue }) {
  return (
    <>
      <InputText
        value={config.value}
        style={{ marginBottom: "10px" }}
        onChange={(e) => updateValue(e.target.value)}
      />
      <Slider
        value={Number(config.value)}
        onChange={(e) => updateValue(e.value.toString())}
        step={2}
        min={12}
        max={72}
      />
    </>
  );
}
