import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { GenericCounter } from "../../custom-charts-components/generic-counter";
import { getConfigValue } from "../../grid-item-helpers";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

export default function InventoryCountLocation(configs) {
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const statusLocation = getConfigValue(configs, "status-location") || 0;

    const handleCountPendingItems = async () => {
      try {
        var statusLocationLabel = "";

        if (statusLocation == 1)
          statusLocationLabel = "Trust";
        else if (statusLocation == 2)
          statusLocationLabel = "LittleTrust";
        else if (statusLocation == 3)
          statusLocationLabel = "NonTrust";

        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.Dashboard + "/location_type_count?trustType=" + statusLocationLabel,
          "Inventory"
        );
        if (status === 200) {
          setCurrentValue(data.data);
        }

      } catch (error) {
        console.error(error);
      }
    };

    if (useMockData) {

      if (statusLocation == 1)
        setCurrentValue(25);
      else if (statusLocation == 2)
        setCurrentValue(30);
      else if (statusLocation == 3)
        setCurrentValue(35);

      setTitle(getConfigValue(configs, "count-title") ?? "");
    } else {
      handleCountPendingItems();
      setTitle(getConfigValue(configs, "count-title") ?? "");
    }
  }, [useMockData, configs]);

  return <GenericCounter title={title} value={currentValue} />;
}
