import { useEffect, useRef, useState } from "react";

import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";

import { InputContainer } from "~/shared/components/dcp-form";
import Icon from "~/shared/components/icons";
import locationImg2 from "~/theme/media/assets/Location.png";
import locationImg1 from "~/theme/media/assets/Location1.png";
import LanguageProvider from "~shared/components/language-provider";

import DeleteConfirmationOverlay from "~/shared/components/confirmation-popup";
import "./scss/barcode-read.scss";
import "./scss/create-sku.scss";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";

const BarcodeRead = ({ isOpen, onClose }) => {
  const barcodeFormik = useFormik({});
  const [submitting, setSubmitting] = useState(false);
  const [selectedLpnStatus, setSelectedLpnStatus] = useState(1);
  const [first, setFirst] = useState(0);
  const [skuList, setSkuList] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  // # Overlay refs
  const toast = useRef(null);
  const tooltipRef = useRef(null);

  // Status dropdown
  const lpnStatus = [
    {
      name: "Exceção",
      code: 1,
      color: "#F9485B",
    },
    {
      name: "Corresponde",
      code: 2,
      color: "#6172F3",
    },
    {
      name: "Solucionado",
      code: 3,
      color: "#03CEB7",
    },
  ];

  const onPageChange = (event) => {
    setFirst(event.first);
  };

  const onCreateSKU = async (values) => {
    setSubmitting(true);
    const status = 200;
    if (status === 200) {
      toast.current.show({
        severity: "success",
      });
      skuFormik.resetForm();
      setIsCreateModalVisible(false);
    } else {
      toast.current.show({
        severity: "error",
      });
      skuFormik.resetForm();
    }
    setSubmitting(false);
  };

  const skuFormik = useFormik({
    initialValues: {
      skuNumber: "",
    },
    validate: (data) => {
      const errors = {};
      if (!data.skuNumber) {
        errors.skuNumber = (
          <LanguageProvider id="sku.create.name.validation.error" />
        );
      }
      return errors;
    },
    onSubmit: onCreateSKU,
  });

  const isCreateSkuFormValid = (skuNumber) => !!skuFormik.errors[skuNumber];

  const IsBarcodeFormValid = (name) => !!barcodeFormik.errors[name];

  function GetSkuFormError({ skuNumber }) {
    return isCreateSkuFormValid(skuNumber) ? (
      <small className="p-error">{skuFormik.errors[skuNumber]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  }

  const selectImage = (index) => {
    setSelectedImage(index);
  };

  // Images URL's
  const images = [locationImg1, locationImg2];

  useEffect(() => {
    setSkuList([
      {
        id: "01",
        name: "GS30327582",
        color: "#EE46BC",
      },
      {
        id: "02",
        name: "GS30327582",
        color: "#03CEB7",
      },
      {
        id: "03",
        name: "GS30327582",
        color: "#FFCF0F",
      },
    ]);
  }, []);

  const getColorStyle = (rowData) => {
    const color = rowData.color.slice(1); // Removes char '#' from the hex
    return `color-${color}`;
  };

  const dropdownItemTemplate = (option) => {
    const isSelected =
      selectedLpnStatus && selectedLpnStatus.code === option.code;

    return (
      <div
        className={`lpn-status ${isSelected ? "selected" : ""}`}
        style={{ color: option.color, fontWeight: 600 }}
      >
        {option.name}
      </div>
    );
  };

  function generateColor(code) {
    let itemColor;
    switch (code) {
      case 1:
        itemColor = "#F9485B";
        break;
      case 2:
        itemColor = "#6172F3";
        break;
      case 3:
        itemColor = "#03CEB7";
        break;
      default:
        break;
    }

    const backgroundLightColor = adjustBackgroundColor(itemColor /* , 90 */);
    document.documentElement.style.setProperty(
      "--dropdown-selected-background-color",
      backgroundLightColor
    );
    document.documentElement.style.setProperty(
      "--dropdown-selected-text-color",
      itemColor
    );

    return itemColor;
  }

  function adjustBackgroundColor(textColor) {
    const transparency = 0.3;
    // Convert the text color to RGB changing the transparency
    const rgbaColor = hexToRGBA(textColor, transparency);

    return rgbaColor;
  }

  function hexToRGBA(hexColor, transparency) {
    const cleanHexColor = hexColor.replace("#", "");

    const red = parseInt(cleanHexColor.substr(0, 2), 16);
    const green = parseInt(cleanHexColor.substr(2, 2), 16);
    const blue = parseInt(cleanHexColor.substr(4, 2), 16);

    return `rgba(${red}, ${green}, ${blue}, ${transparency})`;
  }

  const CloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        visible={isOpen}
        onHide={CloseModal}
        className="dialog-location-details"
        maximized
      >
        <div className="barcode-read">
          <div className="barcode-read-content">
            <div className="barcode-details">
              <div className="return-option-wrapper">
                <div className="icon-return">
                  <Icon
                    icon="arrow-narrow-left"
                    color="#4146FF"
                    onClick={CloseModal}
                  />
                </div>
                <span className="return-text-title">
                  <LanguageProvider id="gen.location" />
                </span>
              </div>
              <div className="barcode-form-wrapper">
                <Toast ref={toast} />
                <div className="dcp-form">
                  <div className="form-row">
                    <div className="input-container street-name">
                      <label className="identifier p-identifier-sm">
                        <LanguageProvider id="gen.street" />
                      </label>
                      <div className="input">
                        <Dropdown
                          editable
                          name="street"
                          className={[
                            "p-dropdown",
                            classNames({
                              "p-invalid": IsBarcodeFormValid("name"),
                            }),
                          ]}
                          showClear
                          optionValue="id"
                          optionLabel="code"
                          value={9}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-container location-name">
                      <label className="identifier p-identifier-sm">
                        <LanguageProvider id="gen.location" />
                      </label>
                      <div className="input">
                        <Dropdown
                          editable
                          name="location"
                          value={"09-23-C-02"}
                          className={[
                            "p-dropdown",
                            classNames({
                              "p-invalid": IsBarcodeFormValid("name"),
                            }),
                          ]}
                        />
                      </div>
                    </div>
                  </div>

                  <Tooltip
                    content="Adicionar SKU"
                    target=".icon-add"
                    position="top"
                  />

                  <div className="form-row">
                    <div className="input-container sku-wrapper">
                      <label className="identifier p-identifier-sm">
                        <LanguageProvider id="gen.sku" />
                      </label>
                      <div
                        className="icon-add"
                        ref={tooltipRef}
                        onClick={() => setIsCreateModalVisible(true)}
                      >
                        <Icon
                          icon="plus"
                          color="#4146FF"
                          className="target-plus-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sku-list">
                    <div className="dcp-sku-table">
                      <ItensTable />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="input-container scan-date">
                      <label className="identifier p-identifier-sm">
                        <LanguageProvider id="gen.scanned" />
                      </label>
                      <span className="scan-datetime">
                        {`${new Date(
                          Date.now()
                        ).toLocaleDateString()} ${new Date(
                          Date.now()
                        ).toLocaleTimeString()}`}
                      </span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="input-container issue-details-wrapper">
                      <label className="identifier p-identifier-sm">
                        <LanguageProvider id="gen.problem.type" />
                      </label>
                      <div className="issue-details-text">
                        Lörem ipsum ding nes inte antropotris. Tell seras
                        kromanat i abplastisk. Speväliga trihin en ehur. Iskap
                        sessa, i nebelt i roliga.
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="input-container lpn-status">
                        <label className="identifier p-identifier-sm">
                          <LanguageProvider id="gen.status" />
                        </label>
                        <div className="input">
                          <Dropdown
                            name="lpnStatus"
                            value={selectedLpnStatus}
                            options={lpnStatus}
                            optionValue="code"
                            optionLabel="name"
                            onChange={(e) => {
                              setSelectedLpnStatus(e.value);
                              generateColor(e.value);
                            }}
                            className={[
                              "p-dropdown lpn-status",
                              classNames({
                                "p-invalid": IsBarcodeFormValid("name"),
                              }),
                            ]}
                            itemTemplate={dropdownItemTemplate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="other-locations-wrapper">
                <label className="identifier p-identifier-sm">
                  <LanguageProvider id="inventory.rack.exception.list" />
                </label>
                <br />
                <div className="location-street">
                  <div className="locations-tag" />
                  <span className="locations-name">09-23-C-07</span>
                </div>
              </div>
            </div>
            <div className="barcode-image">
              <span className="barcode-title">09-23-C-02</span>
              <img
                src={images[selectedImage]}
                alt="Location Image"
                className="location-image"
              />
              <div className="paginator">
                <Button
                  className="paginator-icon"
                  link
                  icon={<Icon icon="chevron-left" />}
                  onClick={() =>
                    setSelectedImage((prevIndex) =>
                      prevIndex > 0 ? prevIndex - 1 : images.length - 1
                    )
                  }
                />
                <span>
                  <LanguageProvider id="gen.image" /> {selectedImage + 1}{" "}
                  <LanguageProvider id="gen.message.of" /> {images.length}
                </span>
                <Button
                  className="paginator-icon"
                  link
                  icon={<Icon icon="chevron-right" />}
                  onClick={() =>
                    setSelectedImage((prevIndex) =>
                      prevIndex < images.length - 1 ? prevIndex + 1 : 0
                    )
                  }
                />
              </div>
            </div>
            <div className="barcode-thumbnail-session">
              <label className="barcode-image-thumbnail-title">
                <LanguageProvider id="gen.images" />
              </label>
              <div className="thumbnail-gallery">
                {images.map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl}
                    alt={`Thumbnail ${index + 1}`}
                    className={
                      selectedImage === index
                        ? "thumbnail selected"
                        : "thumbnail"
                    }
                    onClick={() => selectImage(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Create Modal */}
      <Dialog
        visible={isCreateModalVisible}
        header={<LanguageProvider id="gen.create.sku" />}
        onHide={() => setIsCreateModalVisible(false)}
        className="sku-create-dialog"
      >
        <form
          onSubmit={skuFormik.handleSubmit}
          className="dcp-form"
          style={{ paddingBottom: 0 }}
        >
          <div className="form-row content">
            <InputContainer label={<LanguageProvider id="sku.number" />}>
              <Dropdown
                value={skuFormik.values.skuNumber}
                onChange={(e) => {
                  skuFormik.setFieldValue("skuNumber", e.target.value);
                }}
                className={classNames({
                  "p-invalid": isCreateSkuFormValid("skuNumber"),
                  "p-inputtext-sm": true,
                })}
                placeholder="Pesquisar"
              />
              <GetSkuFormError name="skuNumber" />
            </InputContainer>
          </div>
          <div className="form-row">
            <div className="button-container">
              <Button
                size="medium"
                appearance="primary"
                className="p-button-text p-button-plain"
                type="button"
                text
                onClick={() => setIsCreateModalVisible(false)}
              >
                <LanguageProvider id="gen.cancel.button" />
              </Button>
              <Button
                size="medium"
                appearance="primary"
                className="p-button"
                type="submit"
                loading={submitting}
              >
                <LanguageProvider id="gen.add.register" />
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

function ItensTable() {
  const toast = useRef(null);
  const op = useRef(null);
  const buttonEl = useRef(null);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [itens, setItens] = useState([
    { id: 1, name: "GS30327581", color: "#EE46BC" },
    { id: 2, name: "GS30327582", color: "#03CEB7" },
    { id: 3, name: "GS30327583", color: "#FFCF0F" },
  ]);

  const enableEdit = (itemId) => {
    setItens((prevItens) =>
      prevItens.map((item) => {
        if (item.id === itemId) {
          return { ...item, editing: true };
        }
        return item;
      })
    );
  };

  const cancelEdit = (itemId) => {
    setItens((prevItens) =>
      prevItens.map((item) => {
        if (item.id === itemId) {
          return { ...item, editing: false };
        }
        return item;
      })
    );
  };

  const confirmEdit = (itemId) => {
    setItens((prevItens) =>
      prevItens.map((item) => {
        if (item.id === itemId) {
          return { ...item, editing: false };
        }
        return item;
      })
    );
  };

  const accept = () => {
    deleteItem(deleteItemId);
    toast.current.show({
      severity: "success",
      summary: <LanguageProvider id="gen.success" />,
      details: <LanguageProvider id="gen.message.sku.deleted" />,
      life: 3000,
    });
    setDeleteItemId();
    op.current.hide();
  };

  const deleteItem = (itemId) => {
    setItens((prevItens) => prevItens.filter((item) => item.id !== itemId));
  };

  const updateItemName = (itemId, newName) => {
    setItens((prevItens) =>
      prevItens.map((item) => {
        if (item.id === itemId) {
          return { ...item, name: newName };
        }
        return item;
      })
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="itens-table">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {itens.map((item) => (
              <tr key={item.id}>
                <td>
                  {item.editing ? (
                    <div className="list-content">
                      <div className="item-name">
                        <Dropdown
                          className="sku-dropdown-label"
                          filter
                          value={item.name}
                          placeholder={item.name}
                          style={{
                            "--itemColor": item.color,
                          }}
                          onChange={(e) => {
                            updateItemName(item.id, e.target.value);
                            setSelectedItem(e.value);
                          }}
                        />
                      </div>
                      <div className="action-list">
                        <Button
                          link
                          className="list-button"
                          onClick={() => cancelEdit(item.id)}
                        >
                          <Icon
                            icon="x-close"
                            className="icon-list"
                            color="#667085"
                          />
                        </Button>
                        <Button
                          className="list-button"
                          link
                          onClick={() => confirmEdit(item.id)}
                        >
                          <Icon
                            icon="check"
                            className="icon-list"
                            color="#667085"
                          />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="list-content">
                      <div className="item-name">
                        <span
                          className="sku-name-label"
                          style={{
                            "--itemColor": item.color,
                          }}
                        >
                          {item.name}
                        </span>
                      </div>
                      <div className="action-list">
                        <Button
                          className="list-button"
                          link
                          onClick={() => enableEdit(item.id)}
                        >
                          <Icon
                            icon="edit-01"
                            className="icon-list"
                            color="#667085"
                            size={16}
                          />
                        </Button>
                        <Button
                          link
                          className="list-button"
                          ref={buttonEl}
                          onClick={(e) => {
                            op.current.toggle(e);
                            setDeleteItemId(item.id);
                          }}
                        >
                          <Icon
                            icon="trash-02"
                            className="icon-list"
                            color="#667085"
                            size={16}
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DeleteConfirmationOverlay
        refProp={op}
        onConfirm={(op) => {
          accept(op);
        }}
        onCancel={(e) => op.current.hide(e)}
      />
    </>
  );
}

export default BarcodeRead;
