import Chart from "react-apexcharts";
import fakeData from "../../fake-data/wms/inbound-heat-map.json";
import "./InboundHeatMap.scss";

import React, { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { ThemeContext } from "~/app";

export default function InboundHeatMap(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [series, setSeries] = useState([]);

  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008FFB"],
    parentHeight: "100%", // Set parentHeight to "100%"
  };

  useEffect(() => {
    if (useMockData) {
      setSeries(fakeData);
    } else setSeries([]);
  }, [useMockData]);

  return (
    <div className="inbound-heat-map">
      <Chart
        options={options}
        series={series}
        type="heatmap"
        width="100%"
        height="100%"
      />
    </div>
  );
}
