import React, { useContext } from "react";
import { useEffect } from "react";
import { ThemeContext, UserContext } from "~/app";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";
import DcpDeniedAccess from "~/shared/components/dcp-denied-access";

const PrintInterface = () => {
  const { userRoles } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(
      settings.DatabaseProcessType.PrintDocument_InterfaceImpressao
    );
  }, [loadDatabaseProductProcess]);

  return (
    <>
      {userRoles.administrator ? (
        databaseProductProcessData && (
          <DatabaseList
            id={databaseProductProcessData.idDatabase}
            hasDetails={databaseProductProcessData.hasDetails}
            uriDetails={databaseProductProcessData.uriDetails}
            showIcon={false}
          />
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: currentTheme.systemBackground,
            color: currentTheme.textPrimary,
            fontSize: "24px",
          }}
        >
          <DcpDeniedAccess />
        </div>
      )}
      ;
    </>
  );
};

export default PrintInterface;
