import { ThemeContext } from "~/app";
import "./scss/page-header.scss";

import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { useContext, useState } from "react";
import { InputText } from "primereact/inputtext";

export default function PageHeader({
  title,
  titleTemplate,
  recordsCount,
  onReturn,
  actions,
  showGlobalFilterSearch = false,
  globalFilterContent = (content) => void 0,
}) {
  const { currentTheme } = useContext(ThemeContext);
  const [globalFilter, setGlobalFilter] = useState("");

  return (
    <div className="PageHeader">
      <div className="left-side">
        {onReturn && (
          <div className="return">
            <div className="return-icon">
              <Icon
                icon={"arrow-narrow-left"}
                size="25px"
                color={currentTheme.reportBackIconColor}
                onClick={onReturn}
              />
            </div>
          </div>
        )}
        <div className="title-wrapper">
          {titleTemplate ? (
            titleTemplate()
          ) : (
            <div className="title">{title}</div>
          )}
          {recordsCount >= 0 && (
            <div className="records-count">
              {recordsCount}{" "}
              <LanguageProvider
                id={
                  recordsCount < 2 && recordsCount !== 0
                    ? "gen.register"
                    : "gen.registers"
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="right-side">
        {showGlobalFilterSearch && (
          <div className="search-input-wrapper">
            <span className="search-input">
              <Icon
                className="search-icon"
                icon={"search-md"}
                size={20}
                color={"#667085"}
              />
              <InputText
                className="p-inputtext-md"
                placeholder={LanguageProvider({
                  id: "search.field.placeholder",
                })}
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    globalFilterContent(e.target.value);
                  }
                }}
              />
            </span>
          </div>
        )}
        {actions != null && actions()}
      </div>
    </div>
  );
}
