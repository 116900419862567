import { Dropdown } from "primereact/dropdown";

export function InventoryLocationStatus({ config, updateValue = function () {} }) {
  let options = [];

  const loadInventories = () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  if (true) {
    options = [
      {
        id: 0,
        name: "Selecionar",
      },
      {
        id: 1,
        name: "Confiavel",
      },
      {
        id: 2,
        name: "Pouco Confiavel",
      },
      {
        id: 3,
        name: "Não Confiavel",
      },
    ];
  }

  return (
    <Dropdown
      value={parseInt(config.value)}
      options={options}
      optionLabel="name"
      optionValue="id"
      onChange={(e) => updateValue(e.value.toString())}
    ></Dropdown>
  );
}
