import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { useToastContext } from "~/context/ToastContext";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import { SettingGroup } from "~/shared/components/settings-groups/setting-group";
import { SettingSubgroup } from "~/shared/components/settings-groups/setting-subgroup";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { writeStorage } from "~/services/storage/storage-access";

const SettingsGeneric = (parameters) => {
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [settingsGroups, setSettingsGroups] = useState([]);

  // Loading indicators
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const loadSettings = async () => {
    try {
      setLoading(true);
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Settings}/list-settings-group?specificAppId=${parameters.specificAppId}&groupId=${parameters.groupId}`,
        "Platform"
      );
      if (status === 200) {
        if (data.data[0].subgroups[0].name === "fields-qty-file-export") {
          var fieldsQtyFileExport =
            data.data[0].subgroups[0].settings[0].value * 1;
          writeStorage("defaultQtyToExportFile", fieldsQtyFileExport);
        }
        setSettingsGroups(data.data);
        setSelectedGroup(0);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const updateSettingValue = (value, setting) => {
    try {
      let groups = [...settingsGroups];
      groups.forEach((group) => {
        group.subgroups.forEach((subgroup) => {
          subgroup.settings.forEach((_setting) => {
            if (_setting.defaultValues.id === setting.defaultValues.id) {
              _setting["modified"] = true;
              _setting.value = value;
            }
          });
        });
      });

      setSettingsGroups(groups);
    } catch (error) {
      console.error(error);
    }
  };
  const onSave = async () => {
    try {
      setSaving(true);

      let modifiedSettings = [];
      settingsGroups.forEach((groups) => {
        groups.subgroups.forEach((subgroup) => {
          subgroup.settings.forEach((setting) => {
            if (setting.modified) {
              modifiedSettings.push({
                id: setting.id,
                propertyName: setting.propertyName,
                value: setting.value,
                defaultValues: setting.defaultValues,
              });
            }
          });
        });
      });

      if (modifiedSettings.length > 0) {
        const { data, status } = await dcpAxiosService.post(
          `${settings.Urls.Rest.Settings}/update-settings`,
          modifiedSettings,
          "Platform"
        );

        if (status === 200) {
          showToast({
            severity: "success",
            message: LanguageProvider({
              id: "setting.subgroup.items.generic.settings.success.message",
            }),
          });
        }
        loadSettings();
      }
    } catch (error) {
      console.error(error);
    }
    setSaving(false);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <div className="settings-container">
      <div className="container-area">
        {!loading && (
          <>
            {settingsGroups[selectedGroup].name && (
              <SettingGroup name={settingsGroups[selectedGroup].name}>
                {/* Render dynamic subgroups */}
                {settingsGroups[selectedGroup].subgroups &&
                  settingsGroups[selectedGroup].subgroups.map(
                    (subgroup, index) => {
                      return (
                        <SettingSubgroup
                          key={index}
                          subgroup={subgroup}
                          onChange={updateSettingValue}
                        ></SettingSubgroup>
                      );
                    }
                  )}
              </SettingGroup>
            )}
          </>
        )}
      </div>
      <div className="footer">
        <Button loading={saving} onClick={onSave}>
          <LanguageProvider id="gen.save.button" />
        </Button>
      </div>
    </div>
  );
};

export default SettingsGeneric;
