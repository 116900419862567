import "./scss/examples.scss";

import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";

import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import selection from "~/theme/fonts/selection.json";
import { useState } from "react";

const Examples = () => {
  const [example, setExample] = useState("");
  return (
    <div className="components-example">
      <Form>
        <FormRow>
          <InputContainer>
            <InputText
              value={example}
              onChange={(e) => {
                setExample(e.target.value);
              }}
              placeholder="Small Input"
              size="small"
            />
          </InputContainer>
          <InputContainer>
            <InputText
              value={example}
              onChange={(e) => {
                setExample(e.target.value);
              }}
              placeholder="Medium Input"
              size="medium"
            />
          </InputContainer>
          <InputContainer>
            <InputText
              value={example}
              onChange={(e) => {
                setExample(e.target.value);
              }}
              placeholder="Large Input"
              size="large"
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <InputText placeholder="Small Input" size="small" />
          </InputContainer>
          <InputContainer>
            <InputText placeholder="Medium Input" size="medium" />
          </InputContainer>
          <InputContainer>
            <InputText
              className="dcp-input-borderless"
              placeholder="Large Input"
              size="large"
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <Button size="small" label="Small Button" />
          </InputContainer>
          <InputContainer>
            <Button size="medium" label="Medium Button" />
          </InputContainer>
          <InputContainer>
            <Button size="large" label="Large Button" />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <Button
              appearance={"primary"}
              size="small"
              label="Primary Button"
            />
          </InputContainer>
          <InputContainer>
            <Button
              appearance="secondary"
              size="medium"
              label="Secondary Button"
            />
          </InputContainer>
          <InputContainer>
            <Button
              appearance="terciary"
              size="large"
              label="Terciary Button"
            />
          </InputContainer>
        </FormRow>
      </Form>

      <div className="icons">
        {selection.icons.map((_selection) => {
          const iconName = _selection.properties.name;
          return (
            <div className="icon">
              <Icon icon={_selection.properties.name}></Icon>
              <p>{iconName}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Examples;
