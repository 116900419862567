import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function listDatalakeDatabases(applicationId?: string) {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.Datalake + "/list-databases",
      "Platform",
      { params: { applicationId: applicationId } }
    );

    if (data && data.data && Array.isArray(data.data)) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}
