import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { Button } from "~/shared/components/dcp-button";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import "../scss/datalake-dialog-create.scss";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";

const DatalakeDialogCreate = ({ onClose }) => {
  // Utils
  const { currentLanguage } = useLanguageContext();
  const { showToast } = useToastContext();
  const { databases } = useMenuItemsContext();
  const dcpAxiosService = useDcpAxiosService();
  const navigate = useNavigate();

  // Loading
  const [submitting, setSubmitting] = useState(false);

  const mainDialogVisible = useState(true);
  const [headerTitle, setHeaderTitle] = useState("gen.datalakes");
  const [datalakeType, setDatalakeType] = useState();
  const [dataSourceList, setDataSourceList] = useState();
  const [newDatalakeOptionVisible, setNewDatalakeOptionVisible] =
    useState(true);
  const [
    newDatabaseSelectedOptionVisible,
    setNewDatabaseSelectedOptionVisible,
  ] = useState(false);
  const [newReportSelectedOptionVisible, setNewReportSelectedOptionVisible] =
    useState(false);
  const [formikKey, setFormikKey] = useState(0);

  const productListObject = propertyFrommStorage(
    "authentication",
    "applicationIds"
  );
  const productListArray = Object.entries(productListObject)
    .filter(([key]) => key !== "platform")
    .map(([key, value]) => ({
      key: LanguageProviderWithoutContext({ id: key, currentLanguage }),
      domain: key,
      value,
    }));

  const filterApplicationDomain = (applicationId) => {
    const filteredObject = productListArray.filter(
      (object) => object.value === applicationId
    );
    const firstProductLabel = filteredObject.map((object) => object.domain);

    switch (firstProductLabel[0]) {
      case "drone-inventory":
        return "inventory-dev";
      case "wms":
        return "wms-dev";
      case "mes":
        return "mes-dev";
      default:
        return firstProductLabel;
    }
  };

  function filterDataSourceObject(array) {
    const options = array.map((object) => ({
      id: object.dataSource.id,
      labelCode: LanguageProviderWithoutContext({
        id: object.dataSource.labelCode,
        currentLanguage,
      }),
    }));

    return options;
  }

  const loadDataSource = async (product) => {
    try {
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Report + "/data-sources",
        "Platform",
        {
          params: {
            applicationId: product,
          },
        }
      );

      if (status === 200) {
        const filteredData = filterDataSourceObject(data.data);
        setDataSourceList(filteredData);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      productOriginId: null,
      idDataSource: 0,
      type: 0,
      applications: [],
      dataSourceId: 0,
      dataSourceName: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.name || values.name.length < 1) {
        errors.name = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }
      if (!values.applications || values.applications.length < 1) {
        errors.applications = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }

      if (newReportSelectedOptionVisible) {
        if (!values.productOriginId || values.productOriginId.length < 1) {
          errors.product = LanguageProviderWithoutContext({
            id: "gen.message.field.required",
            currentLanguage,
          });
        }
        if (!values.dataSourceId || !values.productOriginId) {
          errors.dataSourceId = LanguageProviderWithoutContext({
            id: "gen.message.previous.product.field.required",
            currentLanguage,
          });
        }
      }

      if (databases.find((database) => database.name === values.name)) {
        errors.name = LanguageProviderWithoutContext({
          id: "database.duplicated.name.error",
          currentLanguage,
        });
      }
      return errors;
    },
    onSubmit: (values) => onDatalakeCreate(values),
  });

  const onDatalakeCreate = async () => {
    setSubmitting(true);
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Datalake + "/create",
        {
          applications: formik.values.applications,
          dataSourceId: formik.values.dataSourceId,
          productOriginId: formik.values.productOriginId,
          datalake: {
            name: formik.values.name,
            idDataSource: formik.values.idDataSource,
            type: datalakeType,
          },
        },
        "Platform"
      );
      if (status === 200) {
        console.log("Datalake created", data);
        showToast({
          severity: "success",
          message: <LanguageProvider id="datalake.created" />,
        });
        if (datalakeType === settings.DatalakeType.Database) {
          console.log(formik.values);
          navigate("database/edit/" + data.data.idDataSource);
        }
        if (datalakeType === settings.DatalakeType.Report) {
          const productDomain = filterApplicationDomain(
            formik.values.applications[0]
          );
          if (window.location.href.endsWith("/")) {
            navigate(
              `../${productDomain}/reports/edit/${data.data.idDataSource}`
            );
          } else {
            navigate(
              `/${productDomain}/reports/edit/${data.data.idDataSource}`
            );
          }
        }
        onClose();
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Dialog
        visible={mainDialogVisible}
        onHide={onClose}
        className="datalake-dialog"
        headerStyle={{ padding: "20px" }}
        contentStyle={{
          paddingTop: "20px",
          borderBottom: "1px solid var(--systemBackground)",
          borderTop: "1px solid var(--systemBackground)",
          padding: "30px 0px 0px 0px",
        }}
        header={
          <>
            <span className="dashboards-header">
              <LanguageProvider id={headerTitle} />
            </span>
          </>
        }
        onClick={(e) => e.stopPropagation()}
      >
        {newDatalakeOptionVisible && (
          <div className="new-datalake-container">
            <span className="create-datalake-description">
              <LanguageProvider id={"datalake.new.description.dialog"} />
            </span>
            <div className="datalake-buttons-wrapper">
              <div className="static-data-wrapper">
                <div
                  className="internal-container"
                  onClick={() => {
                    setNewDatalakeOptionVisible(false);
                    setNewDatabaseSelectedOptionVisible(true);
                    setHeaderTitle("gen.datalakes.static.data");
                    setDatalakeType(settings.DatalakeType.Database);
                    setFormikKey((prevKey) => prevKey + 1);
                  }}
                >
                  <span className="internal-container-title">
                    <LanguageProvider id={"gen.datalakes.static.data"} />
                  </span>
                  <span className="internal-container-description">
                    <LanguageProvider
                      id={"gen.datalakes.static.data.description"}
                    />
                  </span>

                  <div className="datalake-icon">
                    <div className="database-icon">
                      <Icon icon={"database-03"} size={36} color="#95A1F7" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="dynamic-data-wrapper">
                <div className="static-data-wrapper">
                  <div
                    className="internal-container"
                    onClick={() => {
                      setNewDatalakeOptionVisible(false);
                      setNewReportSelectedOptionVisible(true);
                      setHeaderTitle("gen.datalakes.dynamic.data");
                      setDatalakeType(settings.DatalakeType.Report);
                      setFormikKey((prevKey) => prevKey + 1);
                    }}
                  >
                    <span className="internal-container-title">
                      <LanguageProvider id={"gen.datalakes.dynamic.data"} />
                    </span>
                    <span className="internal-container-description">
                      <LanguageProvider
                        id={"gen.datalakes.dynamic.data.description"}
                      />
                    </span>
                    <div className="datalake-icon">
                      <div className="report-icon">
                        <Icon icon={"data"} size={36} color="#FF8B62" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {newDatabaseSelectedOptionVisible && (
          <div className="database-selected-option">
            <div className="datalake-selected-form-wrapper">
              <Form key={formikKey}>
                <FormRow>
                  <InputContainer label={"Nome"}>
                    <InputText
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      autoFocus
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <small className="p-error">{formik.errors.name}</small>
                    ) : (
                      <small className="p-error">&nbsp;</small>
                    )}
                  </InputContainer>
                </FormRow>
                <FormRow>
                  <InputContainer
                    label={<LanguageProvider id={"gen.visualize.in"} />}
                  >
                    <MultiSelect
                      id="applications"
                      name="applications"
                      className="datalake-multi-select"
                      options={productListArray}
                      value={formik.values.applications}
                      onChange={(e) => {
                        formik.setFieldValue("applications", e.value);
                      }}
                      optionLabel="key"
                      optionValue="value"
                      placeholder={LanguageProviderWithoutContext({
                        id: "gen.datalakes.product.selection",
                        currentLanguage,
                      })}
                      maxSelectedLabels={5}
                      display="chip"
                      type="checkbox"
                    />
                    {formik.touched.applications &&
                    formik.errors.applications ? (
                      <small className="p-error">
                        {formik.errors.applications}
                      </small>
                    ) : (
                      <small className="p-error">&nbsp;</small>
                    )}
                  </InputContainer>
                </FormRow>
              </Form>
            </div>
            <div className="modal-footer-wrapper">
              <div className="modal-footer">
                <div className="dcp-btn-wrapper-manage-modal">
                  <Button
                    label={<LanguageProvider id={"gen.back"} />}
                    size="medium"
                    icon={<Icon icon={"arrow-left"} color={"#4146FF"} />}
                    color="#4146FF"
                    iconPos="left"
                    className="p-button p-button-text-plain"
                    onClick={() => {
                      setNewDatalakeOptionVisible(true);
                      setNewDatabaseSelectedOptionVisible(false);
                      setHeaderTitle("gen.datalakes");
                      formik.resetForm();
                    }}
                  />
                  <Button
                    label={<LanguageProvider id={"gen.save.button"} />}
                    onClick={formik.submitForm}
                    loading={submitting}
                    type="submit"
                    size="medium"
                    appearance="primary"
                    className="p-button"
                    autoFocus
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {newReportSelectedOptionVisible && (
          <div className="report-selected-option">
            <div className="datalake-selected-form-wrapper">
              <Form key={formikKey}>
                <FormRow>
                  <InputContainer label={"Nome"}>
                    <InputText
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      autoFocus
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <small className="p-error">{formik.errors.name}</small>
                    ) : (
                      <small className="p-error">&nbsp;</small>
                    )}
                  </InputContainer>
                </FormRow>
                <FormRow className="report-products-row-container">
                  <InputContainer
                    label={
                      <LanguageProvider id={"gen.datalakes.origin.product"} />
                    }
                  >
                    <Dropdown
                      id="productOriginId"
                      name="productOriginId"
                      value={formik.values.productOriginId}
                      options={productListArray}
                      onChange={async (e) => {
                        await loadDataSource(e.value);
                        formik.setFieldValue("productOriginId", e.value);
                      }}
                      optionLabel="key"
                      placeholder={
                        <LanguageProvider
                          id={"gen.datalakes.select.origin.product"}
                        />
                      }
                    />
                    {formik.touched.productOriginId &&
                    formik.errors.productOriginId ? (
                      <small className="p-error">
                        {formik.errors.productOriginId}
                      </small>
                    ) : (
                      <small className="p-error">&nbsp;</small>
                    )}
                  </InputContainer>
                  <InputContainer
                    label={"Fonte de dados"}
                    className="data-source-input-container"
                  >
                    <Dropdown
                      id="dataSourceId"
                      name="dataSourceId"
                      value={formik.values.dataSourceName}
                      options={dataSourceList}
                      onChange={(e) => {
                        formik.setFieldValue("dataSourceId", e.value.id);
                        formik.setFieldValue("dataSourceName", e.value);
                      }}
                      optionLabel="labelCode"
                      placeholder={LanguageProviderWithoutContext({
                        id: "gen.datalakes.select.origin.product",
                        currentLanguage,
                      })}
                      disabled={!formik.values.productOriginId}
                    />
                    {formik.touched.dataSourceId &&
                    formik.errors.dataSourceId ? (
                      <small className="p-error">
                        {formik.errors.dataSourceId}
                      </small>
                    ) : (
                      <small className="p-error">&nbsp;</small>
                    )}
                  </InputContainer>
                </FormRow>
                <FormRow>
                  <InputContainer
                    label={<LanguageProvider id={"gen.visualize.in"} />}
                  >
                    <MultiSelect
                      id="applications"
                      name="applications"
                      className="datalake-multi-select"
                      options={productListArray}
                      value={formik.values.applications}
                      onChange={(e) => {
                        formik.setFieldValue("applications", e.value);
                      }}
                      optionLabel="key"
                      placeholder={LanguageProviderWithoutContext({
                        id: "gen.datalakes.product.selection",
                        currentLanguage,
                      })}
                      display="chip"
                      maxSelectedLabels={5}
                    />
                    {formik.touched.applications &&
                    formik.errors.applications ? (
                      <small className="p-error">
                        {formik.errors.applications}
                      </small>
                    ) : (
                      <small className="p-error">&nbsp;</small>
                    )}
                  </InputContainer>
                </FormRow>
              </Form>
            </div>
            <div className="modal-footer-wrapper">
              <div className="modal-footer">
                <div className="dcp-btn-wrapper-manage-modal">
                  <Button
                    label={<LanguageProvider id={"gen.back"} />}
                    size="medium"
                    icon={<Icon icon={"arrow-left"} color={"#4146FF"} />}
                    color="#4146FF"
                    iconPos="left"
                    className="p-button p-button-text-plain"
                    onClick={() => {
                      setNewDatalakeOptionVisible(true);
                      setNewReportSelectedOptionVisible(false);
                      setHeaderTitle("gen.datalakes");
                      formik.resetForm();
                    }}
                  />
                  <Button
                    label={<LanguageProvider id={"gen.save.button"} />}
                    onClick={formik.submitForm}
                    loading={submitting}
                    type="submit"
                    size="medium"
                    appearance="primary"
                    className="p-button"
                    autoFocus
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default DatalakeDialogCreate;
