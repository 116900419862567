import { NumberProps } from ".";
import React from "react";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";

const sufixes = {
  kilos: " kg",
  grams: " g",
  temperature: " ℃",
};

export function Number(props: NumberProps): JSX.Element {
  const isCurrency = props.hasInputMask && props.inputMaskName === "currency";
  const sufix = sufixes[props.inputMaskName];

  return (
    <>
      <InputNumber
        tooltip={props.tooltip}
        style={{ width: "100%" }}
        className={classNames({
          "p-invalid": props.error !== null || props.error !== undefined,
        })}
        //id={props.fieldKey}
        value={props.value}
        mode={isCurrency ? "currency" : "decimal"}
        currency="USD"
        locale="en-US"
        minFractionDigits={2}
        maxFractionDigits={2}
        suffix={sufix ?? ""}
        onChange={(e) => {
          if (e.value) props.onChange(e.value.toString());
        }}
      />
      {props.error ? (
        <small className="p-error">{props.error}</small>
      ) : (
        <small className="p-error">&nbsp;</small>
      )}
    </>
  );
}
