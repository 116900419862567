import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import GenericDatabaseHeader from "~/pages/wms/components/generic-database-header";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";

const PrintDetails = () => {
  const { itemId } = useParams();
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(settings.DatabaseProcessType.AMC_SolicitacoesItem);
  }, [loadDatabaseProductProcess]);

  return (
    <>
      <div className="database-items-details">
        <div className="database-items-details-header">
          <div className="header-container">
            <h1>
              <LanguageProvider id="database.detail.order" />
              {settings.Module.Database.printInsert}
            </h1>
          </div>
          <GenericDatabaseHeader itemId={itemId} />
        </div>
      </div>
    </>
  );
};

export default PrintDetails;
