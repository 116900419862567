import { InputNumber } from "primereact/inputnumber";

export default function DecimalNumberConfig({ config, updateValue }) {
  return (
    <InputNumber
      value={config.value}
      useGrouping={false}
      minFractionDigits={1}
      onChange={(e) => updateValue(e.value ? e.value.toString() : "0")}
    />
  );
}
