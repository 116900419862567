import { Area } from "./area";

export interface InventoryListModel {
  totalRecords: number;
  inventories: InventoryModel[];
}

export interface InventoryModel {
  id: number;
  code: string;
  name: string;
  description: string;
  status: InventoryStatus;
  accuracy: number;
  estimatedLocations: number;
  locationsFinished: number;
  locationsWithDivergency: number;
  drone: boolean;
  manual: boolean;
  readType: InventoryReadType;
  duration: string;
  startedAt?: Date;
  finishedAt?: Date;
  programmedTo: Date;
  responsibles: string[];
  drones: Equipment[];
  areas: Area[];
}

export interface OpenInventory {
  name: string;
  id: number;
  accuracy: number;
}

export enum InventoryReadType {
  sku = 1,
  serial = 2,
}

export interface Equipment {
  id: number;
  equipmentType: EquipmentType;
  name: string;
  serial: string;
  firmwareVersion?: string;
  applicationClientId: string;
  userId: string;
  lastActivity: Date;
}

export enum EquipmentType {
  drone = 1,
  mobile = 2,
}

export interface InventoryCountModel {
  status: ItemStatus;
  sku: string;
  idLocationUser: number;
  systemQty: number;
  result: InventoryLocationResult;
  counts: CountResultModel[];
}

interface CountResultModel {
  id: number;
  hasCount: boolean;
  inventoryCountId: number;
  userName: string;
  userId: string;
  count: number;
  difference: number;
  finishedAt?: Date;
}

export interface InventoryLocationResult {
  id: number;
  sku: string;
  result: number;
  allowManualUpdate: boolean;
  updatedAt?: Date;
  updatedUser?: string;
  inventoryLocationId: number;
  systemAmount: number;
}

export enum ItemStatus {
  Valid,
  Divergent,
  Edited,
}

export enum InventoryStatus {
  WaitingStart = 1,
  InProgress = 2,
  Paused = 3,
  Finished = 4,
  Canceled = 5,
}

export interface InventoryUpdateStatusModel {
  inventoryId: number;
  status: InventoryStatus;
}

export enum LocationType {
  Rack = 1,
  FloorArea = 2,
}
