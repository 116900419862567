import "../scss/entry.scss";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import Icon from "~/shared/components/icons";
import qs from "qs";
import LanguageProvider from "~/shared/components/language-provider";
import SetUpEntryRegistrationDialog from "./dialog/set-up-entry-registration-dialog";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { useFormik } from "formik";
import { Button } from "~/shared/components/dcp-button";
import AddItemDialog from "./dialog/add-item-dialog";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { Skeleton } from "primereact/skeleton";
import { InputNumber } from "primereact/inputnumber";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

const Entry = () => {
  // Utils
  const { currentLanguage } = useLanguageContext();
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const applicationId = propertyFrommStorage("authentication", "applicationId");

  // Settings
  const [entryProductsTable, setEntryProductsTable] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(true);

  // Databases
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [skuOptions, setSkuOptions] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);
  const [entryItemModel, setEntryItemModel] = useState([]);

  // Modals and loadings
  const [isEdit, setIsEdit] = useState(false);
  const [displayAddEntryItemDialog, setDisplayAddEntryItemDialog] =
    useState(false);
  const [displayDeleteEntryItemDialog, setDisplayDeleteEntryItemDialog] =
    useState(false);
  const [displayClearDataDialog, setDisplayClearDataDialog] = useState(false);
  const [idItemToRemove, setIdItemToRemove] = useState("");
  const [
    configureEntryRegistrationDialog,
    setConfigureEntryRegistrationDialog,
  ] = useState(false);
  const [entryConfigSettings, setEntryConfigSettings] = useState();
  const [loadingRegister, setLoadingRegister] = useState(true);

  const ValidateEntryForm = (data) => {
    let erros = {};
    try {
      if (data) {
        if (
          entryConfigSettings.batchCode &&
          (!data.batchCode || data.batchCode.length <= 0)
        )
          erros.batchCode = (
            <LanguageProvider id={"warehouse.batch.code.validation.error"} />
          );
        if (entryConfigSettings.nf && (!data.nf || data.nf.length <= 0))
          erros.nf = (
            <LanguageProvider id={"warehouse.invoice.validation.error"} />
          );
        if (
          entryConfigSettings.danfe &&
          (!data.danfe || data.danfe.length <= 0)
        ) {
          erros.danfe = (
            <LanguageProvider id={"warehouse.danfe.validation.error"} />
          );
        }
        if (
          entryConfigSettings.supplier &&
          (!data.supplier || data.supplier.name.length <= 0)
        )
          erros.supplier = (
            <LanguageProvider id={"warehouse.supplier.validation.error"} />
          );
      }
    } catch (error) {
      console.error(error);
    }
    return erros;
  };

  // Submit data from form and table
  const Submit = async () => {
    try {
      const { data, status } = await dcpAxiosService.post(
        `${settings.Urls.Rest.Movements}/post`,
        {
          warehouseMovementItemsData:
            formatEntryTableItemsForRequest(entryProductsTable),
          recebimentGroup: formatEntryFormDataForRequest(entryFormik.values),
          typeMove: settings.Warehouse.moveType.Entry,
        },
        "Warehouse"
      );

      if (status) {
        entryFormik.resetForm();
        setEntryProductsTable([]);
        showToast({
          message: LanguageProviderWithoutContext({
            id: "dcp.platform.warehouse.input.entry.data.saved",
            currentLanguage,
          }),
          severity: "success",
        });
      } else {
        showToast({
          message: LanguageProviderWithoutContext({
            id: "unhandled.error",
            currentLanguage,
          }),
          severity: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleFormValueChange = (changeEvent) => {
    entryFormik.setFieldValue(changeEvent.target.id, changeEvent.target.value);
  };

  const IsFormValid = (name) => {
    return !!(entryFormik.touched[name] && entryFormik.errors[name]);
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">{entryFormik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const entryFormik = useFormik({
    initialValues: {
      batchCode: "",
      nf: "",
      danfe: "",
      supplier: {
        id: 0,
        name: "",
      },
    },
    validate: ValidateEntryForm,
    onSubmit: Submit,
  });

  const loadEntryRegisterData = async () => {
    try {
      setLoadingRegister(true);
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Settings + "/get-entry-settings",
        "Warehouse"
      );

      if (status === 200) {
        console.log(data.data);
        setEntryConfigSettings(data.data);
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: "gen.error",
          currentLanguage,
        }),
      });
    }
    setLoadingRegister(false);
  };

  const addTableData = (item) => {
    console.log(item);
    const itemFormatted = {
      id: item.id,
      applicationId: applicationId,
      skuItem: {
        idItem: item.skuItem.idItem,
        name: item.skuItem.name,
      },
      address: {
        idFrom: item.address.idFrom,
        name: item.address.name,
      },
      qty: item.qty,
      typeMove: settings.Warehouse.moveType.Entry,
    };
    console.log(itemFormatted);

    const existingIndex = entryProductsTable.findIndex(
      (existingItem) => existingItem.id === itemFormatted.id
    );

    if (existingIndex !== -1) {
      const updatedProductsTable = [...entryProductsTable];
      updatedProductsTable[existingIndex] = {
        ...updatedProductsTable[existingIndex],
        ...itemFormatted,
      };

      setEntryProductsTable(updatedProductsTable);
    } else {
      setEntryProductsTable([...entryProductsTable, itemFormatted]);
    }
  };

  const formatEntryTableItemsForRequest = (items) => {
    return items.map((item) => ({
      applicationId: item.applicationId,
      qty: item.qty,
      idItem: item.skuItem.idItem,
      idFrom: item.address.idFrom,
      typeMove: settings.Warehouse.moveType.Entry,
    }));
  };

  const formatEntryFormDataForRequest = (form) => {
    return {
      batchCode: form.batchCode,
      danfe: form.danfe,
      nf: form.nf,
      idSupplier: form.supplier.id,
    };
  };

  const handleDeleteItem = () => {
    setIsFormFilled(false);
    const updatedProducts = entryProductsTable.filter(
      (product) => product.id !== idItemToRemove
    );
    setEntryProductsTable(updatedProducts);
    setDisplayDeleteEntryItemDialog(false);
    setIsFormFilled(false);
  };

  const handleClearData = () => {
    if (entryProductsTable || entryFormik.values) {
      setEntryProductsTable([]);
      entryFormik.resetForm();
    }
    setDisplayClearDataDialog(false);
    setIsFormFilled(false);
  };

  const openModalAddItem = () => {
    setEntryItemModel("");
    setDisplayAddEntryItemDialog(true);
    setIsEdit(false);
  };

  const openModalEditItem = (model) => {
    setEntryItemModel(model);
    setDisplayAddEntryItemDialog(true);
    setIsEdit(true);
  };

  const openModalDeleteItem = (item) => {
    setIdItemToRemove(item.id);
    setDisplayDeleteEntryItemDialog(true);
  };

  useEffect(() => {
    async function loadMainDatabaseDropdownFields() {
      try {
        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.Connection + "/list-data",
          "Platform",
          {
            params: {
              connectionCode: ["suppliers", "sku", "address"],
            },
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: "repeat" }),
          }
        );

        let applicantsFormattedOptions = [];
        let skuFormattedOptions = [];
        let addressFormattedOptions = [];

        if (status === 200) {
          for (const item of data.data) {
            if (item.applicationConnectionCode === "suppliers") {
              applicantsFormattedOptions = Object.entries(item.values).map(
                ([id, name]) => ({
                  id: parseInt(id),
                  name: name.trim(),
                })
              );
            } else if (item.applicationConnectionCode === "sku") {
              skuFormattedOptions = Object.entries(item.values).map(
                ([idItem, name]) => ({
                  idItem: parseInt(idItem),
                  name: name.trim(),
                })
              );
            } else if (item.applicationConnectionCode === "address") {
              addressFormattedOptions = Object.entries(item.values).map(
                ([idFrom, name]) => ({
                  idFrom: parseInt(idFrom),
                  name: name.trim(),
                })
              );
            }
          }
          setSupplierOptions(applicantsFormattedOptions);
          setSkuOptions(skuFormattedOptions);
          setAddressOptions(addressFormattedOptions);
        }
      } catch (error) {
        console.error(error);
      }
    }
    loadMainDatabaseDropdownFields();
    loadEntryRegisterData();
  }, []);

  useEffect(() => {
    const { batchCode, nf, danfe, supplier } = entryFormik.values;
    if (
      batchCode.length > 0 ||
      nf.length > 0 ||
      danfe.length > 0 ||
      supplier.id > 0 ||
      entryProductsTable.length > 0
    ) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }
  }, [entryFormik.values, entryProductsTable]);

  return (
    <>
      <div className="entry-main-container">
        <div className="entry-header-wrapper">
          <div className="container">
            <div className="header">
              <div className="title-wrapper">
                <span className="header-message">
                  <span className="title">
                    <LanguageProvider
                      id={"dcp.side.menu.warehouse.actions.entry"}
                    />
                  </span>
                </span>
              </div>
              <Button
                onClick={() => openModalAddItem()}
                label={
                  <LanguageProvider
                    id={"dcp.platform.warehouse.dialog.add.item"}
                  />
                }
                icon={<Icon icon="plus" color="white" />}
              />
            </div>
            <div className="containerTables">
              <div className="tableLeft">
                <div className="side-menu-title">
                  <div className="tableLeft-title">
                    <LanguageProvider
                      id={"dcp.platform.warehouse.entry.dialog.entry.register"}
                    />
                  </div>
                </div>

                <div className="entry-settings-side-fields">
                  {!loadingRegister && (
                    <Form className="entry-settings-form">
                      <FormRow>
                        {entryConfigSettings &&
                          entryConfigSettings.batchCode && (
                            <InputContainer
                              label={
                                <LanguageProvider
                                  id={
                                    "dcp.platform.warehouse.entry.dialog.entry.batch"
                                  }
                                />
                              }
                            >
                              <InputText
                                id="batchCode"
                                name="batchCode"
                                value={entryFormik.values.batchCode}
                                onChange={HandleFormValueChange}
                                className="inputText"
                              />
                              <GetFormError name={"batchCode"} />
                            </InputContainer>
                          )}
                      </FormRow>
                      <FormRow>
                        {entryConfigSettings && entryConfigSettings.nf && (
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={"dcp.platform.warehouse.entry.note.invoice"}
                              />
                            }
                          >
                            <InputText
                              type="text"
                              name="nf"
                              id="nf"
                              value={entryFormik.values.nf}
                              onChange={HandleFormValueChange}
                              className="inputText"
                            />
                            <GetFormError name={"nf"} />
                          </InputContainer>
                        )}
                      </FormRow>
                      <FormRow>
                        {entryConfigSettings && entryConfigSettings.danfe && (
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={"dcp.platform.warehouse.entry.note.danfe"}
                              />
                            }
                          >
                            <InputText
                              name="danfe"
                              id="danfe"
                              value={entryFormik.values.danfe}
                              onChange={HandleFormValueChange}
                              className="inputText"
                            />
                            <GetFormError name={"danfe"} />
                          </InputContainer>
                        )}
                      </FormRow>
                      {entryConfigSettings && entryConfigSettings.supplier && (
                        <FormRow>
                          <InputContainer
                            label={
                              <LanguageProvider
                                id={"dcp.platform.warehouse.entry.register"}
                              />
                            }
                          >
                            <Dropdown
                              id="supplier"
                              name="supplier"
                              value={entryFormik.values.supplier}
                              onChange={HandleFormValueChange}
                              options={supplierOptions}
                              optionLabel="name"
                              placeholder="Selecionar database"
                              className="inputText"
                              emptyMessage={
                                <LanguageProvider
                                  id={
                                    "dcp.platform.warehouse.dropdown.empty.message"
                                  }
                                />
                              }
                            />
                            <GetFormError name={"supplier"} />
                          </InputContainer>
                        </FormRow>
                      )}
                    </Form>
                  )}
                  {loadingRegister && (
                    <>
                      <div className="loading-fields-wrapper">
                        <Skeleton width="10rem" className="label" />
                        <Skeleton
                          width="240px"
                          height="40px"
                          className="skeleton"
                        />
                        <Skeleton width="10rem" className="label" />
                        <Skeleton
                          width="240px"
                          height="40px"
                          className="skeleton"
                        />
                        <Skeleton width="10rem" className="label" />
                        <Skeleton
                          width="240px"
                          height="40px"
                          className="skeleton"
                        />
                        <Skeleton width="10rem" className="label" />
                        <Skeleton
                          width="240px"
                          height="40px"
                          className="skeleton"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="list-itens">
                <div className="title">
                  <LanguageProvider
                    id={"dcp.side.menu.warehouse.actions.entry.listitens"}
                  />
                </div>
                <DataTable
                  value={entryProductsTable}
                  paginator
                  rows={settings.RowsPerPageOptions.Default}
                  rowsPerPageOptions={settings.RowsPerPageOptions.Options}
                  tableStyle={{ minWidth: "100%" }}
                  emptyMessage={
                    <LanguageProvider id={"gen.table.empty.message"} />
                  }
                  onError={
                    <LanguageProvider
                      id={"dcp.platform.warehouse.entry.register"}
                    />
                  }
                >
                  <Column
                    className="column-title"
                    header={<LanguageProvider id={"gen.code"} />}
                    style={{ width: "25%" }}
                    body={(rowData) => {
                      let codeFormatted = rowData.skuItem.name.substring(
                        0,
                        rowData.skuItem.name.indexOf(" ")
                      );
                      return (
                        <div>
                          <span>{codeFormatted}</span>
                        </div>
                      );
                    }}
                  ></Column>
                  <Column
                    field="Descrição"
                    header={
                      <LanguageProvider
                        id={"dcp.platform.warehouse.entry.description"}
                      />
                    }
                    style={{ width: "25%" }}
                    body={(rowData) => {
                      const regex = /-\s*(.*)$/;
                      const nameFormatted = rowData.skuItem.name.match(regex);
                      return (
                        <div>
                          <span>{nameFormatted[1]}</span>
                        </div>
                      );
                    }}
                  ></Column>
                  <Column
                    field="Endereço"
                    header={
                      <LanguageProvider
                        id={"dcp.platform.warehouse.entry.address"}
                      />
                    }
                    style={{ width: "25%" }}
                    body={(rowData) => {
                      return (
                        <div className="style-address">
                          {rowData.address.name}
                        </div>
                      );
                    }}
                  ></Column>
                  <Column
                    field="Quantidade"
                    header={
                      <LanguageProvider
                        id={"dcp.platform.warehouse.entry.quantity"}
                      />
                    }
                    body={(rowData) => {
                      return (
                        <InputNumber
                          className="quantity-column-number"
                          readOnly
                          value={rowData.qty}
                        />
                      );
                    }}
                    style={{ width: "25%" }}
                  ></Column>
                  {
                    <Column
                      headerStyle={{ width: "10%", minWidth: "100px" }}
                      body={(rowData) => {
                        return (
                          <div className="column-wrapper">
                            <div className="column-actions">
                              <div className="icon-wrapper">
                                <Icon
                                  icon="edit-02"
                                  size={20}
                                  color="#667085"
                                  className="icon-row"
                                  onClick={() => openModalEditItem(rowData)}
                                />
                              </div>
                            </div>
                            <div className="column-actions">
                              <div className="icon-wrapper">
                                <Icon
                                  icon="trash-02"
                                  size={20}
                                  color="#667085"
                                  className="icon-row"
                                  onClick={() => openModalDeleteItem(rowData)}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    />
                  }
                </DataTable>
              </div>
            </div>
            <div className="entryFooter">
              <Button
                className="p-button p-button-text-plain"
                onClick={() => setConfigureEntryRegistrationDialog(true)}
                label={
                  <LanguageProvider
                    id={"dcp.platform.warehouse.configure.register"}
                  />
                }
              />
              <div className="entry-manage-data-buttons">
                <Button
                  id="clearData"
                  className="p-button p-button-text-plain clear-data"
                  label={
                    <LanguageProvider
                      id={"dcp.platform.warehouse.clean.data"}
                    />
                  }
                  disabled={isFormFilled}
                  onClick={() => setDisplayClearDataDialog(true)}
                />
                <Button
                  type="submit"
                  size="medium"
                  appearence="primary"
                  label={<LanguageProvider id={"gen.save.button"} />}
                  onClick={() => entryFormik.handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddItemDialog
        visible={displayAddEntryItemDialog}
        onHide={() => {
          setDisplayAddEntryItemDialog(false);
        }}
        itemModel={entryItemModel}
        skuOptions={skuOptions}
        addressOptions={addressOptions}
        tableData={addTableData}
        isEdit={isEdit}
        isOutput={false}
      />
      <ModalConfirmation
        isOpen={displayDeleteEntryItemDialog}
        isDelete={true}
        modalTitle={<LanguageProvider id={"gen.confirm.delete.item"} />}
        bodyMessage={
          <LanguageProvider id={"gen.message.confirm.delete.message"} />
        }
        onClose={() => setDisplayDeleteEntryItemDialog(false)}
        onConfirm={handleDeleteItem}
        onCancel={() => setDisplayDeleteEntryItemDialog(false)}
      />
      <ModalConfirmation
        isOpen={displayClearDataDialog}
        isDelete={true}
        modalTitle={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data"} />
        }
        bodyMessage={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data.message"} />
        }
        onClose={() => setDisplayClearDataDialog(false)}
        onConfirm={handleClearData}
        onCancel={() => setDisplayClearDataDialog(false)}
      />

      <SetUpEntryRegistrationDialog
        visible={configureEntryRegistrationDialog}
        onHide={() => {
          setConfigureEntryRegistrationDialog(false);
          loadEntryRegisterData();
        }}
        entrySettings={entryConfigSettings ?? ""}
      />
    </>
  );
};

export default Entry;
