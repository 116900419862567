import "./scss/print-document.scss";
import LanguageProvider from "~/shared/components/language-provider";
import React, { useEffect, useRef, useState } from "react";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import { Button } from "~/shared/components/dcp-button";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { useToastContext } from "~/context/ToastContext";
import { InputText } from "primereact/inputtext";
import SetupPrintInputFieldsDialog from "./dialog/setup-print-input-fields-dialog";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { useFormik } from "formik";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { classNames } from "primereact/utils";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import axios from "axios";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

const Print = () => {
  const dcpAxiosService = useDcpAxiosService();
  const { currentLanguage } = useLanguageContext();
  const { showToast } = useToastContext();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  const [displayClearDataDialog, setDisplayClearDataDialog] = useState(false);
  const [
    configureEntryRegistrationDialog,
    setConfigureEntryRegistrationDialog,
  ] = useState(false);
  const [printFieldsSettings, setPrintFieldsSettings] = useState([]);
  const [printRegistrationValues, setPrintRegistrationValues] = useState([]);
  const [printerAllValues, setPrinterAllValues] = useState([]);
  const [printFieldSettingsValuesCount, setPrintFieldSettingsValuesCount] =
    useState(0);
  const [selectedPrinterIp, setSelectedPrinterIp] = useState("");
  const [selectedPrinterPort, setSelectedPrinterPort] = useState("");
  const [urlPrintValue, setUrlPrintValue] = useState("");

  const [isFormFilled, setIsFormFilled] = useState(true);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [loadingSavePrintForm, setLoadingSavePrintForm] = useState(false);

  const inputRefs = useRef([]);

  interface FormValues {
    printer: {
      id: string;
      label: string;
    };
    printFields: {
      [key: string]: string;
    };
  }

  const validatePrintFieldsForm = (values: FormValues) => {
    const errors = {} as {
      printer?: string;
      printFields?: { [key: string]: string };
    };
    if (values.printer.id === "" || values.printer.id === null) {
      errors.printer = LanguageProviderWithoutContext({
        id: "gen.message.print.document.prints.required",
        currentLanguage,
      });
    }

    errors.printFields = Object.keys(values.printFields).reduce(
      (acc, key) => {
        if (
          values.printFields[key] === null ||
          values.printFields[key] === "" ||
          values.printFields[key] === undefined
        ) {
          acc[key] = LanguageProviderWithoutContext({
            id: "gen.message.field.required",
            currentLanguage,
          });
        }
        return acc;
      },
      {} as { [key: string]: string }
    );

    if (errors.printFields && Object.keys(errors.printFields).length === 0) {
      delete errors.printFields;
    }

    return errors;
  };

  const formikPrinter = useFormik({
    initialValues: {
      printer: {
        id: "",
        label: "",
      },
      printFields: printFieldsSettings.reduce((acc, field) => {
        if (field.enable) {
          acc[field.code] = "";
        }
        return acc;
      }, {}),
    },
    enableReinitialize: true,
    validate: validatePrintFieldsForm,
    onSubmit: async (values) => {
      try {
        setLoadingSavePrintForm(true);

        const printDocumentFields = {
          documentFields: printFieldsSettings
            .filter((filter) => filter.enable)
            .map((field) => ({
              code: field.code,
              value: values.printFields[field.code],
            })),
          printSelected: values.printer,
        };

        const { data, status } = await dcpAxiosService.post(
          settings.Urls.Rest.print + "/print-file",
          printDocumentFields,
          "PrintDocument"
        );

        if (status === 200) {
          const documentBase64Code = [
            {
              type: "png",
              encoded: true,
              payload: data.data.documents[0].payload,
            },
          ];
          const dataToPrint = {
            printer: {
              name: formikPrinter.values.printer.label,
              ip: selectedPrinterIp,
              port: selectedPrinterPort,
            },
            documents: documentBase64Code,
          };

          const printerResponse = await axios.post(
            urlPrintValue + "/api/printer/print",
            dataToPrint
          );
          if (printerResponse.data.true === 200) {
            showToast({
              severity: "success",
              message:
                LanguageProviderWithoutContext({
                  id: "gen.message.print.document.saved",
                  currentLanguage,
                }) +
                " " +
                data.data.documents.length,
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSavePrintForm(false);
        clearForm();
      }
    },
  });

  const IsFormValid = (name) => {
    const fieldNameParts = name.split(".");
    const fieldError = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.errors
    );
    const fieldTouched = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.touched
    );
    return !!(fieldError && fieldTouched);
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">
        {formikPrinter.errors.printFields?.[name.split(".")[1]]}
        {formikPrinter.errors[name]}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleKeyDown = async (e, index) => {
    if (
      (e.key === "Tab" || e.key === "Enter") &&
      !e.shiftKey &&
      index === printFieldSettingsValuesCount - 1
    ) {
      e.preventDefault();
      inputRefs.current[0].focus();
    }
    if (
      Object.keys(formikPrinter.errors).length === 0 &&
      (e.key === "Tab" || e.key === "Enter")
    ) {
      await formikPrinter.handleSubmit();
    }
  };

  const loadInterfacePrintFields = async () => {
    try {
      setLoadingRegister(true);
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.fieldSettings + "/list-all",
        "PrintDocument"
      );
      if (status === 200) {
        const enableCount = data.data.filter(
          (value) => value.enable === true
        ).length;
        setPrintFieldSettingsValuesCount(enableCount);

        setPrintFieldsSettings(data.data);
        setLoadingRegister(false);
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id={"gen.error"} />,
      });
    }
  };

  const clearForm = () => {
    formikPrinter.setValues(
      {
        printer: formikPrinter.values.printer,
        printFields: printFieldsSettings.reduce((acc, field) => {
          if (field.enable) {
            acc[field.code] = "";
          }
          return acc;
        }, {}),
      },
      false
    );
  };

  const handleClearData = () => {
    if (formikPrinter.values) {
      clearForm();
    }
    setDisplayClearDataDialog(false);
    setIsFormFilled(false);
  };

  const handlePrinterChange = (e) => {
    const selectedOptionName = printRegistrationValues.find(
      (item) => item.value === e.value
    );

    const selectedPrinterIpAndPort = printerAllValues.find(
      (item) => item.value === e.value
    );

    setSelectedPrinterIp(selectedPrinterIpAndPort.ip);
    setSelectedPrinterPort(selectedPrinterIpAndPort.port);

    formikPrinter.setFieldValue("printer", {
      id: selectedOptionName.value,
      label: selectedOptionName.name,
    });
  };

  function getSettingValueByName(data, groupName, subgroupName, propertyName) {
    const value = data
      .find((group) => group.name === groupName)
      ?.subgroups.find((subgroup) => subgroup.name === subgroupName)
      ?.settings.find((setting) => setting.propertyName === propertyName).value;
    return value;
  }

  function findColumnKeyByName(data, targetName) {
    const columnKey = data.find(
      (column) => column.columnName === targetName
    ).columnKey;
    if (columnKey) {
      return columnKey;
    }
    return null;
  }

  useEffect(() => {
    async function loadSavePrintersDatabaseDropdownFields() {
      try {
        const { data: databaseProductProcess } = await dcpAxiosService.get(
          `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
          "Platform",
          {
            params: {
              databaseProductProcessType:
                settings.DatabaseProcessType.PrintDocument_CadastroImpressoras,
            },
          }
        );
        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.DatabaseItem + "/list-database-items",
          "Platform",
          {
            params: {
              idDatabase: databaseProductProcess.data.idDatabase,
              databaseName: databaseProductProcess.data.nameDatabase,
            },
          }
        );
        const settingsResponse = await dcpAxiosService.get(
          settings.Urls.Rest.Settings + "/list-settings",
          "Platform",
          {
            params: {
              applicationClientId: applicationClientId,
            },
          }
        );

        const columnNameValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print"
        );
        const columnCodeValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print-code"
        );
        const columnIpValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print-ip"
        );
        const columnPortValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-fields-options",
          "fields-print-port"
        );

        const driverPrintUrlValueInSettings = getSettingValueByName(
          settingsResponse.data.data,
          "print",
          "document-print-driver",
          "fields-print-driver"
        );
        setUrlPrintValue(driverPrintUrlValueInSettings);

        if (status === 200) {
          const codeColumnName = findColumnKeyByName(
            data.data?.headers,
            columnNameValueInSettings
          );
          const labelColumnName = findColumnKeyByName(
            data.data?.headers,
            columnCodeValueInSettings
          );
          const labelColumnIp = findColumnKeyByName(
            data.data?.headers,
            columnIpValueInSettings
          );
          const labelColumnPort = findColumnKeyByName(
            data.data?.headers,
            columnPortValueInSettings
          );

          const formattedValues = data.data.items.map((item) => ({
            name: item.columns[labelColumnName],
            value: item.columns[codeColumnName],
          }));

          const printersAllValues = data.data.items.map((item) => ({
            name: item.columns[labelColumnName],
            value: item.columns[codeColumnName],
            ip: item.columns[labelColumnIp],
            port: item.columns[labelColumnPort],
          }));

          setPrinterAllValues(printersAllValues);
          setPrintRegistrationValues(formattedValues);
        }
      } catch (error) {
        console.error(error);
      }
    }
    loadSavePrintersDatabaseDropdownFields();
    loadInterfacePrintFields();
  }, []);

  useEffect(() => {
    const { printFields, printer } = formikPrinter.values;
    if (Object.keys(printFields).length > 0 || printer.id) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }
  }, [formikPrinter.values]);

  return (
    <>
      <div className="print-document-main-container">
        <div className="print-header-wrapper">
          <div className="container">
            <div className="header">
              <div className="title-wrapper">
                <span className="header-message">
                  <span className="title">
                    <LanguageProvider id={"product.print.menu.print"} />
                  </span>
                </span>
              </div>
            </div>
            <div className="containerTables">
              <div className="tableLeft">
                <div className="entry-settings-side-fields">
                  <Form className="entry-settings-form">
                    <FormRow>
                      <InputContainer
                        className="print-registers-dropdown"
                        label="Impressoras"
                      >
                        <Dropdown
                          id="printer"
                          className={[
                            "print-document-dropdown",
                            classNames({
                              "p-invalid": IsFormValid("printer"),
                            }),
                          ].join(" ")}
                          placeholder="Selecione uma impressora"
                          options={printRegistrationValues}
                          value={formikPrinter.values.printer.id}
                          optionLabel="value"
                          onChange={(e) => {
                            handlePrinterChange(e);
                          }}
                          loading={loadingRegister}
                        />
                        <GetFormError name={"printer"} />
                      </InputContainer>
                    </FormRow>
                    <div className="side-menu-title">
                      <div className="tableLeft-title">
                        <LanguageProvider
                          id={
                            "dcp.platform.print.document.dialog.print.register"
                          }
                        />
                      </div>
                    </div>

                    {printFieldsSettings?.length > 0 ? (
                      printFieldsSettings
                        .filter((field) => field.enable)
                        .map((field, index) => (
                          <FormRow key={index}>
                            <InputContainer label={field.name}>
                              <InputText
                                id={`printFields.${field.code}`}
                                name={`printFields.${field.code}`}
                                placeholder={`Enter ${field.name}`}
                                value={
                                  formikPrinter.values.printFields[field.code]
                                }
                                onChange={formikPrinter.handleChange}
                                className={[
                                  classNames({
                                    "p-invalid": IsFormValid(
                                      `printFields.${field.code}`
                                    ),
                                  }),
                                ].join(" ")}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onBlur={() => formikPrinter.handleSubmit()}
                              />
                            </InputContainer>
                          </FormRow>
                        ))
                    ) : (
                      <LoadingIndicator />
                    )}
                  </Form>
                </div>
              </div>
            </div>
            <div className="entryFooter">
              <Button
                className="p-button p-button-text-plain"
                onClick={() => setConfigureEntryRegistrationDialog(true)}
              >
                <LanguageProvider
                  id={"dcp.platform.print.document.configure.search.form"}
                />
              </Button>
              <div className="entry-manage-data-buttons">
                <Button
                  id="clearData"
                  className="p-button p-button-text-plain clear-data"
                  onClick={() => setDisplayClearDataDialog(true)}
                  disabled={isFormFilled}
                >
                  <LanguageProvider id={"dcp.platform.warehouse.clean.data"} />
                </Button>
                <Button
                  type="submit"
                  appearance="primary"
                  loading={loadingSavePrintForm}
                  onClick={() => formikPrinter.handleSubmit()}
                >
                  <LanguageProvider id={"dcp.printdocument.print.button"} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmation
        isOpen={displayClearDataDialog}
        isDelete={true}
        modalTitle={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data"} />
        }
        bodyMessage={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data.message"} />
        }
        onConfirm={handleClearData}
        onCancel={() => setDisplayClearDataDialog(false)}
      />
      <SetupPrintInputFieldsDialog
        visible={configureEntryRegistrationDialog}
        onHide={() => {
          setConfigureEntryRegistrationDialog(false);
          loadInterfacePrintFields();
        }}
        printFieldsSettings={printFieldsSettings ?? ""}
      />
    </>
  );
};

export default Print;
