import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { CHART_COLORS } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import "./OpenInventoryAccuracy.scss";

export default function OpenInventoryAccuracy(configs) {
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.Dashboard + "/inventory-acuracity",
          "Inventory"
        );

        if (status !== 200 || !data.data) return;
        setData(data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (useMockData) {
      setData([
        { id: 1, name: "Inventário exemplo 1", value: 52 },
        { id: 2, name: "Inventário exemplo 2", value: 89 },
        { id: 3, name: "Inventário exemplo 3", value: 70 },
        { id: 4, name: "Inventário exemplo 4", value: 40 },
      ]);
    } else {
      loadData();
    }
  }, [useMockData]);
  return (
    <div className="open-inventory-accuracy">
      {Array.isArray(data) &&
        data.map((item, index) => {
          return (
            <div className="inventory-wrapper" key={"inventory-" + index}>
              <p className="label">{item.name}</p>
              <div className="inv-row">
                <div className="bar-container">
                  <div
                    className="bar"
                    style={{
                      background: CHART_COLORS[1],
                      width: `${item.value}%`,
                    }}
                  ></div>
                </div>
                <p className="percentage-label">{item.value}%</p>
              </div>
            </div>
          );
        })}
    </div>
  );
}
