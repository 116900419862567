import React, { useEffect } from "react";
import { useState } from "react";
import {
  deleteFloorArea,
  listFloorAreas,
} from "~/services/api/platform/floor-area";
import "./scss/floor-area.scss";
import PageHeader from "~/shared/components/page-header/page-header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "~/context/ToastContext";
import * as Interfaces from "~/shared/interfaces";
import { Button } from "~/shared/components/dcp-button";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import settings from "~/services/settings.json";

export function FloorArea(): JSX.Element {
  const [floorAreas, setFloorAreas] = useState<Interfaces.FloorArea[]>([]);
  const [selectedAreaId, setSelectedAreaId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const { showToast } = useToastContext();
  const navigate = useNavigate();

  async function loadFloorAreas() {
    try {
      setLoading(true);
      const data = await listFloorAreas();

      if (data) {
        setFloorAreas(data);
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
    setLoading(false);
  }

  async function onEdit(id: number) {
    try {
      navigate("edit/" + id);
    } catch (error) {
      console.error(error);
    }
  }

  async function onCreate() {
    try {
      navigate("edit/0");
    } catch (error) {
      console.error(error);
    }
  }

  async function onDelete() {
    try {
      const status = await deleteFloorArea(selectedAreaId);
      setDeleteConfirmationOpen(false);

      if (status === true) {
        showToast({
          severity: "success",
          message: <LanguageProvider id="floor.area.delete" />,
        });
        await loadFloorAreas();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
  }

  useEffect(() => {
    loadFloorAreas();
  }, []);

  function headerActions() {
    return (
      <div className="header-actions">
        <Button onClick={onCreate}>
          <LanguageProvider id="floor.area.create" />
        </Button>
      </div>
    );
  }

  return (
    <div className="floor-area">
      <div className="table-wrapper">
        <PageHeader
          titleTemplate={undefined}
          recordsCount={floorAreas.length}
          onReturn={undefined}
          actions={headerActions}
          title="Áreas de piso"
        ></PageHeader>
        <div className="datatable-container">
          <DataTable
            value={floorAreas}
            loading={loading}
            paginator
            rows={settings.RowsPerPageOptions.Default}
            scrollable
            scrollHeight="flex"
            rowsPerPageOptions={settings.RowsPerPageOptions.Options}
            emptyMessage={<LanguageProvider id="gen.no.registers" />}
          >
            <Column field="name" header={<LanguageProvider id="Nome" />} />
            <Column
              field="color"
              header={<LanguageProvider id="Cor" />}
              body={colorColumnTemplate}
            />
            <Column
              field="width"
              header={<LanguageProvider id="Comprimento" />}
            />
            <Column field="length" header={<LanguageProvider id="Largura" />} />
            <Column
              field="locationQtd"
              header={<LanguageProvider id="Qtd. Locações" />}
            />
            <Column
              headerStyle={{ width: "10%", minWidth: "100px" }}
              body={(rowData) => {
                return (
                  <div className="column-wrapper">
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => onEdit(rowData.id)}
                      >
                        <Icon
                          icon="edit-02"
                          size={20}
                          color="#667085"
                          className="icon-row"
                        />
                      </div>
                    </div>
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => {
                          setSelectedAreaId(rowData.id);
                          setDeleteConfirmationOpen(true);
                        }}
                      >
                        <Icon
                          icon="trash-02"
                          size={20}
                          color="#667085"
                          className="icon-row"
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        modalTitle={<LanguageProvider id="gen.exclude.button" />}
        bodyMessage={
          <LanguageProvider id="gen.message.confirm.delete.message" />
        }
        isDelete={true}
        onConfirm={onDelete}
        onCancel={() => setDeleteConfirmationOpen(false)}
      />
    </div>
  );
}

export function colorColumnTemplate({ color }) {
  return (
    <div className="color-column-template">
      <span
        className="color-circle"
        style={{ background: color.padStart(7, "#") }}
      ></span>
      <span>{color.replace("#", "")}</span>
    </div>
  );
}
