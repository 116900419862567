import "./scss/inventory-area-view.scss";

import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import { InputText } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import { useEffect, useState } from "react";
import { WarehouseMap } from "~/pages/inventory/area-map/components/warehouse-map";

const InventoryViewArea = () => {
  const { showToast } = useToastContext();
  const [loading, setLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [areaItems, setAreaItems] = useState([]);
  const [groups, setGroups] = useState([]);

  const dcpAxiosService = useDcpAxiosService();

  const loadMapItems = async (_groups) => {
    try {
      const { status, data } = await dcpAxiosService.get(
        settings.Urls.Rest.AreaMap + "/list-map-items",
        "Inventory",
        { params: { areaId: 5 } }
      );

      if (status == 200) {
        let items = [];

        data.data.forEach((item) => {
          const group = _groups.find(
            (g) => g.areaGroup.id === item.areaGroupId
          );
          if (group) {
            items.push({
              ...item,
              x: item.posX,
              y: item.posY,
              group,
            });
          }
        });

        setAreaItems(items);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadGroups = async () => {
    setLoading(true);
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.AreaGroup}/list`,
        "Inventory",
        { params: { areaId: 5 /*selectedAreaMap.id*/ } }
      );

      if (Array.isArray(data.data)) {
        setGroups(data.data);
        loadMapItems(data.data);
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const HandleSearchValueChange = (searchValue) => {
    setSearchValue(searchValue);
  };

  useEffect(() => {
    loadGroups();
  }, []);

  return (
    <div className="inventory-area-view-dashboard">
      <div className="header">
        <div className="inventory-search">
          <span className="search-input">
            <Icon icon={"search-md"} color="#667085" size={20} />
            <InputText
              className="p-inputtext-md inventory-search"
              placeholder={LanguageProvider({
                id: "search.field.placeholder",
              })}
              onChange={(e) => HandleSearchValueChange(e.target.value)}
            />
          </span>
        </div>
        <div className="status-wrapper">
          <div className="btn-exception">
            <Button
              className={
                "p-button p-button-sm p-button-secondary inventory-btn-exception"
              }
              label={<LanguageProvider id={"gen.exception"} />}
            />
          </div>
          <div className="btn-audited">
            <Button
              className={
                "p-button p-button-sm p-button-secondary inventory-btn-audited"
              }
              label={<LanguageProvider id={"gen.audited"} />}
            />
          </div>
          <div className="btn-correspond">
            <Button
              className={
                "p-button p-button-sm p-button-secondary inventory-btn-correspond"
              }
              label={<LanguageProvider id={"gen.correspond"} />}
            />
          </div>
        </div>
      </div>
      <div className="area-map-view">
        <WarehouseMap loading={loading} items={areaItems} isStatic={true} />
      </div>
    </div>
  );
};

export default InventoryViewArea;
