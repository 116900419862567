import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import {
  CHART_COLORS,
  defaultChartOptions,
} from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import { ThemeContext } from "~/app";

export default function OccupationLevel(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({ free: 0, inUse: 0 });

  useEffect(() => {
    if (useMockData) {
      setData({ free: 50, inUse: 300 });
    } else {
      // get data from api
      setData({ free: 0, inUse: 0 });
    }
  }, [useMockData]);

  const dataset = {
    labels: ["Ocupado", "Vazio"],
    datasets: [
      {
        data: [data.inUse, data.free],
        backgroundColor: [CHART_COLORS[0], CHART_COLORS[1]],
      },
    ],
  };
  const options = {
    ...defaultChartOptions,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          color: currentTheme.dashboardDataColor,
        },
      },
    },
  };

  return (
    <Chart
      type="pie"
      data={dataset}
      options={options}
      height="100%"
      width="100%"
    />
  );
}
