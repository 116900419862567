import { useEffect, useState } from "react";

import LoadingIndicator from "./components/dcp-loading-indicator";
import { Outlet } from "react-router-dom";
import { ThemeContext } from "../app";
import ThemeProvider from "../theme/theme";

const ThemeLayout = () => {
  const [theme, setTheme] = useState("light");
  const [currentTheme, setCurrentTheme] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const SetUpTheme = () => {
      const _theme = ThemeProvider(theme);
      setCurrentTheme(_theme);
      try {
        for (var prop in _theme) {
          document.documentElement.style.setProperty(`--${prop}`, _theme[prop]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    SetUpTheme();
    setLoading(false);
  }, [theme]);

  if (!loading)
    return (
      <ThemeContext.Provider value={{ theme, setTheme, currentTheme }}>
        <Outlet />
      </ThemeContext.Provider>
    );
  return <LoadingIndicator />;
};

export default ThemeLayout;
