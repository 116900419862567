import { useParams } from "react-router-dom";
import settings from "~/services/settings.json";

import LanguageProvider from "~/shared/components/language-provider";
import GenericDatabaseHeader from "../../components/generic-database-header";

const DockDetails = () => {
  const { itemId } = useParams();

  return (
    <>
      <div className="database-items-details">
        <div className="database-items-details-header">
          <div className="header-container">
            <h1>
              <LanguageProvider id="database.detail.of" />
              {settings.Module.Database.docks}
            </h1>
          </div>
          <GenericDatabaseHeader itemId={itemId} />
        </div>
      </div>
    </>
  );
};

export default DockDetails;
