import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import settings from "~/services/settings.json";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

interface FormValues {
  id: 0;
  sku: {
    name: string;
    value: string;
  };
  quantity: string;
}

const AreaAuditCountAddSkuDialog = ({
  isOpen,
  onClose,
  refreshList,
  isEdit = false,
  idLocationUser,
  skuReadToEdit,
}) => {
  const { showToast } = useToastContext();
  const { currentLanguage } = useLanguageContext();
  const axiosService = useDcpAxiosService();
  // Loading
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [skuOptions, setSkuOptions] = useState([]);

  const validatePrintFieldsForm = (values: FormValues) => {
    const errors = {} as {
      sku?: string;
      quantity?: string;
    };
    try {
      if (values.sku.value === "" || values.sku.value === null) {
        errors.sku = LanguageProviderWithoutContext({
          id: "gen.message.area.audit.add.sku.field.sku.required",
          currentLanguage,
        });
      }
      if (values.quantity === "" || values.quantity === null) {
        errors.quantity = LanguageProviderWithoutContext({
          id: "gen.message.area.audit.add.sku.field.quantity.required",
          currentLanguage,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
    return errors;
  };

  const formikAuditSku = useFormik({
    initialValues: {
      id: 0,
      sku: {
        name: "",
        value: "",
      },
      quantity: "",
    },
    enableReinitialize: true,
    validate: validatePrintFieldsForm,
    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        const inventoryLocationObj = {
          id: values.id,
          readCode: values.sku.value,
          amount: Number(values.quantity),
          idLocationUser: idLocationUser,
        };

        const { status, data } = await axiosService.post(
          settings.Urls.Rest.Inventory + "/sku-read-save",
          inventoryLocationObj,
          "Inventory"
        );
        if (status === 200 && data.data) {
          showToast({
            severity: "success",
            message: LanguageProviderWithoutContext({
              id: "gen.message.sku.read.saved",
              currentLanguage,
            }),
          });
          refreshList(!refreshList);
          onClose();
        } else {
          showToast({
            severity: "error",
            message: LanguageProviderWithoutContext({
              id: "gen.error",
              currentLanguage,
            }),
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
        formikAuditSku.resetForm();
      }
    },
  });

  const IsFormValid = (name) => {
    return !!(formikAuditSku.touched[name] && formikAuditSku.errors[name]);
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">{formikAuditSku.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleChangeSkuDropdown = async (e) => {
    const selectedOption = skuOptions.find(
      (option) => option.value === e.value
    );
    formikAuditSku.setFieldValue("sku", {
      name: selectedOption.name,
      value: selectedOption.value,
    });
  };

  useEffect(() => {
    if (skuReadToEdit) {
      formikAuditSku.setFieldValue("id", skuReadToEdit.id);
      formikAuditSku.setFieldValue("sku", {
        value: skuReadToEdit.sku,
      });
      formikAuditSku.setFieldValue("quantity", skuReadToEdit.quantity);
    }
  }, [skuReadToEdit]);

  useEffect(() => {
    const getSkusValues = async () => {
      try {
        setLoading(true);
        const { status, data } = await axiosService.get(
          settings.Urls.Rest.Inventory + "/list-sku",
          "Inventory"
        );

        if (status === 200 && data.data) {
          const skusOptionContent = data.data.connectionRows.map((row) => ({
            name: row.name + " - " + row.code,
            value: row.code,
          }));
          setSkuOptions(skusOptionContent);
        } else return [];
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getSkusValues();
  }, []);

  return (
    <Dialog
      visible={isOpen}
      onHide={onClose}
      className="datalake-dialog"
      headerStyle={{ padding: "20px" }}
      contentStyle={{
        paddingTop: "20px",
        borderBottom: "1px solid var(--systemBackground)",
        borderTop: "1px solid var(--systemBackground)",
        padding: "30px 0px 0px 0px",
      }}
      header={
        <>
          {isEdit ? (
            <span className="dashboards-header">
              <LanguageProvider id={"gen.edit.sku"} />
            </span>
          ) : (
            <span className="dashboards-header">
              <LanguageProvider id={"gen.create.sku"} />
            </span>
          )}
        </>
      }
      onClick={(e) => e.stopPropagation()}
    >
      <div className="database-selected-option">
        <div className="datalake-selected-form-wrapper">
          <Form>
            <FormRow>
              <InputContainer label={<LanguageProvider id={"gen.sku"} />}>
                <Dropdown
                  id="sku"
                  className={[
                    "sku",
                    classNames({
                      "p-invalid": IsFormValid("sku"),
                    }),
                  ].join(" ")}
                  placeholder={LanguageProviderWithoutContext({
                    id: "gen.placeholder.select",
                    currentLanguage,
                  })}
                  filter
                  value={formikAuditSku.values.sku.value}
                  title={formikAuditSku.values.sku.name}
                  options={skuOptions}
                  onChange={(e) => handleChangeSkuDropdown(e)}
                  optionLabel="name"
                  optionValue="value"
                />
                <GetFormError name={"sku"} />
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer label={<LanguageProvider id={"gen.qtd"} />}>
                <InputText
                  id="quantity"
                  className={[
                    "quantity",
                    classNames({
                      "p-invalid": IsFormValid("quantity"),
                    }),
                  ].join(" ")}
                  onChange={(e) => {
                    formikAuditSku.setFieldValue(
                      "quantity",
                      parseInt(e.target.value, 10)
                    );
                  }}
                  value={formikAuditSku.values.quantity}
                />
                <GetFormError name={"quantity"} />
              </InputContainer>
            </FormRow>
          </Form>
        </div>
        <div className="modal-footer-wrapper">
          <div className="modal-footer">
            <div className="dcp-btn-wrapper-manage-modal">
              <Button
                label={LanguageProviderWithoutContext({
                  id: "gen.cancel",
                  currentLanguage,
                })}
                className="p-button p-button-text p-button-plain"
                onClick={() => {
                  formikAuditSku.resetForm();
                  onClose();
                }}
              />
              <Button
                label={LanguageProviderWithoutContext({
                  id: "gen.save.button",
                  currentLanguage,
                })}
                onClick={formikAuditSku.submitForm}
                loading={submitting}
                type="submit"
                className="p-button"
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AreaAuditCountAddSkuDialog;
