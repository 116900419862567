import "../scss/output.scss";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { useCallback, useEffect, useState } from "react";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import settings from "~/services/settings.json";
import qs from "qs";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useToastContext } from "~/context/ToastContext";
import { Button } from "~/shared/components/dcp-button";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import AddItemDialog from "./dialog/add-item-dialog";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { useFormik } from "formik";
import SetUpOutputRegistrationDialog from "./dialog/set-up-output-registration-dialog";
import { Skeleton } from "primereact/skeleton";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { InputNumber } from "primereact/inputnumber";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

const Output = () => {
  // Utils
  const { currentLanguage } = useLanguageContext();
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const applicationId = propertyFrommStorage("authentication", "applicationId");

  // Settings
  const [outputProductsTable, setOutputProductsTable] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [databaseRow, setDatabaseRow] = useState([]);
  const [databaseColumns, setDatabaseColumns] = useState([]);

  // Databases
  const [applicantsOptions, setApplicantsOptions] = useState([]);
  const [skuOptions, setSkuOptions] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);
  const [outputItemModel, setOutputItemModel] = useState([]);

  // Modals and loadings
  const [isEdit, setIsEdit] = useState(false);
  const [displayAddOutputItemDialog, setDisplayAddOutputItemDialog] =
    useState(false);
  const [displayDeleteOutputItemDialog, setDisplayDeleteOutputItemDialog] =
    useState(false);
  const [displayClearDataDialog, setDisplayClearDataDialog] = useState(false);
  const [idItemToRemove, setIdItemToRemove] = useState("");
  const [
    configureOutputRegistrationDialog,
    setConfigureOutputRegistrationDialog,
  ] = useState(false);
  const [outputConfigSettings, setOutputConfigSettings] = useState(false);
  const [loading, setLoading] = useState();

  const ValidateOutputForm = (data) => {
    let errors = {};
    try {
      if (data) {
        if (!data.applicant || data.applicant.name.length <= 0)
          errors.applicant = (
            <LanguageProvider id={"warehouse.applicant.validation.error"} />
          );
      }
    } catch (error) {
      console.error(error);
    }
    return errors;
  };

  const Submit = async () => {
    try {
      const { data, status } = await dcpAxiosService.post(
        `${settings.Urls.Rest.Movements}/post`,
        {
          warehouseMovementItemsData:
            formatOutputTableItemsForRequest(outputProductsTable),
          outputGroup: formatOutputFormDataForRequest(outputFormik.values),
          typeMove: settings.Warehouse.moveType.Output,
        },
        "Warehouse"
      );

      if (status) {
        outputFormik.resetForm();
        setOutputProductsTable([]);
        showToast({
          message: LanguageProviderWithoutContext({
            id: "dcp.platform.warehouse.output.entry.data.saved",
            currentLanguage,
          }),
          severity: "success",
        });
      } else {
        showToast({
          message: LanguageProviderWithoutContext({
            id: "unhandled.error",
            currentLanguage,
          }),
          severity: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const outputFormik = useFormik({
    initialValues: {
      applicant: {
        id: 0,
        name: "",
      },
    },
    validate: ValidateOutputForm,
    onSubmit: Submit,
  });

  const HandleFormValueChange = (changeEvent) => {
    outputFormik.setFieldValue(changeEvent.target.id, changeEvent.target.value);
  };
  const IsFormValid = (name) => {
    return !!(outputFormik.touched[name] && outputFormik.errors[name]);
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">{outputFormik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const loadOutputRegisterData = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Settings + "/get-output-settings",
        "Warehouse"
      );
      if (status === 200) {
        setOutputConfigSettings(data.data.showApplicantDetails);
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: "gen.error",
          currentLanguage,
        }),
      });
    }
  };

  const loadApplicantDetailsField = useCallback(async (itemId) => {
    try {
      setLoading(true);
      const { data: fieldsData, status: fieldsStatus } =
        await dcpAxiosService.get(
          `${settings.Urls.Rest.DatabaseItem}/single-database-list-item`,
          "Platform",
          {
            params: {
              itemId: itemId,
            },
          }
        );

      if (fieldsStatus) {
        setDatabaseRow(fieldsData.data.item);
        const columns = fieldsData.data.headers;
        setDatabaseColumns(columns);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const renderField = (column, row) => {
    return row[column.columnKey];
  };

  const addTableData = (item) => {
    const itemFormatted = {
      id: item.id,
      applicationId: applicationId,
      skuItem: {
        idItem: item.skuItem.idItem ?? item.skuItem.id,
        name: item.skuItem.name,
      },
      address: {
        idFrom: item.address.idFrom ?? item.address.id,
        name: item.address.name,
      },
      qty: item.qty,
      typeMove: settings.Warehouse.moveType.Output,
    };

    const existingIndex = outputProductsTable.findIndex(
      (existingItem) => existingItem.id === itemFormatted.id
    );
    if (existingIndex !== -1) {
      const updatedProductsTable = [...outputProductsTable];
      updatedProductsTable[existingIndex] = {
        ...updatedProductsTable[existingIndex],
        ...itemFormatted,
      };
      setOutputProductsTable(updatedProductsTable);
    } else {
      setOutputProductsTable([...outputProductsTable, itemFormatted]);
    }
  };

  const handleDeleteItem = () => {
    return new Promise((resolve, reject) => {
      const updatedProducts = outputProductsTable.filter(
        (product) => product.id !== idItemToRemove
      );
      setOutputProductsTable(updatedProducts);
      setDisplayDeleteOutputItemDialog(false);
      resolve();
    });
  };

  const handleClearData = (response) => {
    if (response) {
      setOutputProductsTable([]);
      outputFormik.resetForm();
    }
    setDisplayClearDataDialog(false);
    setIsFormFilled(false);
  };

  const openModalAddItem = () => {
    setOutputItemModel("");
    setDisplayAddOutputItemDialog(true);
    setIsEdit(false);
  };

  const openModalEditItem = (model) => {
    setOutputItemModel({
      address: {
        id: model.address.idFrom,
        name: model.address.name,
      },
      skuItem: {
        id: model.skuItem.idItem,
        name: model.skuItem.name,
      },
      qty: model.qty,
    });
    setDisplayAddOutputItemDialog(true);
    setIsEdit(true);
  };

  const openModalDeleteItem = (item) => {
    setIdItemToRemove(item.id);
    setDisplayDeleteOutputItemDialog(true);
  };

  const formatOutputTableItemsForRequest = (items) => {
    return items.map((item) => ({
      applicationId: item.applicationId,
      qty: item.qty,
      idItem: item.skuItem.idItem,
      idFrom: item.address.idFrom,
      typeMove: settings.Warehouse.moveType.Output,
    }));
  };

  const formatOutputFormDataForRequest = (form) => {
    return {
      applicationId: applicationId,
      idRequester: form.applicant.id,
    };
  };

  useEffect(() => {
    async function loadMainDatabaseDropdownFields() {
      try {
        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.Connection + "/list-data",
          "Platform",
          {
            params: {
              connectionCode: ["applicants", "sku", "address"],
            },
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: "repeat" }),
          }
        );

        let applicantsFormattedOptions = [];
        let skuFormattedOptions = [];
        let addressFormattedOptions = [];

        if (status === 200) {
          for (const item of data.data) {
            if (item.applicationConnectionCode === "applicants") {
              applicantsFormattedOptions = Object.entries(item.values).map(
                ([id, name]) => ({
                  id: parseInt(id),
                  name: name.trim(),
                })
              );
            } else if (item.applicationConnectionCode === "sku") {
              skuFormattedOptions = Object.entries(item.values).map(
                ([id, name]) => ({
                  id: parseInt(id),
                  name: name.trim(),
                })
              );
            } else if (item.applicationConnectionCode === "address") {
              addressFormattedOptions = Object.entries(item.values).map(
                ([id, name]) => ({
                  id: parseInt(id),
                  name: name.trim(),
                })
              );
            }
          }
          setApplicantsOptions(applicantsFormattedOptions);
          setSkuOptions(skuFormattedOptions);
          setAddressOptions(addressFormattedOptions);
        }
      } catch (error) {
        console.error(error);
      }
    }
    loadMainDatabaseDropdownFields();
    loadOutputRegisterData();
  }, []);

  useEffect(() => {
    if (outputFormik.values.applicant.id > 0)
      loadApplicantDetailsField(outputFormik.values.applicant.id);
  }, [outputFormik.values.applicant.id]);

  useEffect(() => {
    const { applicant } = outputFormik.values;
    if (applicant.id > 0 || outputProductsTable.length > 0) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }
  }, [outputFormik.values, outputProductsTable]);

  return (
    <>
      <div className="output-main-container">
        <div className="output-header-wrapper">
          <div className="container">
            <div className="header">
              <div className="title-wrapper">
                <span className="header-message">
                  <span className="title">
                    <LanguageProvider
                      id={"dcp.side.menu.warehouse.actions.output"}
                    />
                  </span>
                </span>
              </div>
              <Button
                onClick={() => openModalAddItem()}
                label={
                  <LanguageProvider
                    id={"dcp.platform.warehouse.dialog.add.item"}
                  />
                }
                icon={<Icon icon="plus" color="white" />}
              />
            </div>
            <div className="containerTables">
              <div className="tableLeft">
                <div className="side-menu-title">
                  <div className="tableLeft-title">
                    <LanguageProvider
                      id={
                        "dcp.platform.warehouse.dropdown.output.applicant.placeholder"
                      }
                    />
                  </div>
                  <div className="output-settings-side-fields">
                    <Form>
                      <FormRow>
                        <InputContainer
                          label={
                            <LanguageProvider
                              id={"dcp.platform.warehouse.output.requester"}
                            />
                          }
                        >
                          <Dropdown
                            id="applicant"
                            name="applicant"
                            value={outputFormik.values.applicant}
                            onChange={HandleFormValueChange}
                            options={applicantsOptions}
                            emptyMessage={
                              <LanguageProvider
                                id={
                                  "dcp.platform.warehouse.dropdown.empty.message"
                                }
                              />
                            }
                            optionLabel="name"
                            placeholder="Selecionar database"
                            className="inputText"
                          />
                          <GetFormError name={"applicant"} />
                        </InputContainer>
                      </FormRow>
                      {outputConfigSettings &&
                        !loading &&
                        databaseColumns.map((column) => {
                          return (
                            <FormRow key={`row-key-${column.columnKey}`}>
                              <InputContainer label={[column.columnName]}>
                                <InputText
                                  value={renderField(
                                    column,
                                    databaseRow.columns
                                  )}
                                  disabled={true}
                                  className="inputText"
                                />
                              </InputContainer>
                            </FormRow>
                          );
                        })}
                      {loading && (
                        <>
                          <div className="loading-fields-wrapper">
                            <Skeleton width="10rem" className="label" />
                            <Skeleton
                              width="240px"
                              height="40px"
                              className="skeleton"
                            />
                            <Skeleton width="10rem" className="label" />
                            <Skeleton
                              width="240px"
                              height="40px"
                              className="skeleton"
                            />
                            <Skeleton width="10rem" className="label" />
                            <Skeleton
                              width="240px"
                              height="40px"
                              className="skeleton"
                            />
                            <Skeleton width="10rem" className="label" />
                            <Skeleton
                              width="240px"
                              height="40px"
                              className="skeleton"
                            />
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </div>

              <div className="ListItens">
                <div className="title">
                  <LanguageProvider
                    id={"dcp.side.menu.warehouse.actions.entry.listitens"}
                  />
                </div>
                <DataTable
                  value={outputProductsTable}
                  paginator
                  rows={settings.RowsPerPageOptions.Default}
                  rowsPerPageOptions={settings.RowsPerPageOptions.Options}
                  tableStyle={{ minWidth: "100%" }}
                  emptyMessage={
                    <LanguageProvider id={"gen.table.empty.message"} />
                  }
                >
                  <Column
                    className="column-title"
                    header={<LanguageProvider id={"gen.code"} />}
                    style={{ width: "25%" }}
                    body={(rowData) => {
                      let codeFormatted = rowData.skuItem.name.substring(
                        0,
                        rowData.skuItem.name.indexOf(" ")
                      );
                      return (
                        <div>
                          <span>{codeFormatted}</span>
                        </div>
                      );
                    }}
                  ></Column>
                  <Column
                    field="Descrição"
                    header={
                      <LanguageProvider
                        id={"dcp.platform.warehouse.output.description"}
                      />
                    }
                    style={{ width: "25%" }}
                    body={(rowData) => {
                      const regex = /-\s*(.*)$/;
                      const nameFormatted = rowData.skuItem.name.match(regex);
                      return (
                        <div>
                          <span>{nameFormatted[1]}</span>
                        </div>
                      );
                    }}
                  ></Column>
                  <Column
                    field="Endereço"
                    header={
                      <LanguageProvider
                        id={"dcp.platform.warehouse.output.address"}
                      />
                    }
                    style={{ width: "25%" }}
                    body={(rowData) => {
                      return (
                        <div className="style-address">
                          {rowData.address.name}
                        </div>
                      );
                    }}
                  ></Column>
                  <Column
                    field="Quantidade"
                    header={
                      <LanguageProvider
                        id={"dcp.platform.warehouse.output.quantity"}
                      />
                    }
                    body={(rowData) => {
                      return (
                        <InputNumber
                          className="quantity-column-number"
                          readOnly
                          value={rowData.qty}
                        />
                      );
                    }}
                    style={{ width: "25%" }}
                  ></Column>
                  {
                    <Column
                      headerStyle={{ width: "10%", minWidth: "100px" }}
                      body={(rowData) => {
                        return (
                          <div className="column-wrapper">
                            <div className="column-actions">
                              <div className="icon-wrapper">
                                <Icon
                                  icon="edit-02"
                                  size={20}
                                  color="#667085"
                                  className="icon-row"
                                  onClick={() => openModalEditItem(rowData)}
                                />
                              </div>
                            </div>
                            <div className="column-actions">
                              <div className="icon-wrapper">
                                <Icon
                                  icon="trash-02"
                                  size={20}
                                  color="#667085"
                                  className="icon-row"
                                  onClick={() => openModalDeleteItem(rowData)}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    />
                  }
                </DataTable>
              </div>
            </div>
            <div className="outputFooter">
              <Button
                className="p-button p-button-text-plain"
                onClick={() => setConfigureOutputRegistrationDialog(true)}
                label={
                  <LanguageProvider
                    id={"dcp.platform.warehouse.configure.register"}
                  />
                }
              />
              <span className="output-manage-data-buttons">
                <Button
                  id="clearData"
                  onClick={() => setDisplayClearDataDialog(true)}
                  className="p-button p-button-text-plain clear-data"
                  label={
                    <LanguageProvider
                      id={"dcp.platform.warehouse.clean.data"}
                    />
                  }
                  disabled={isFormFilled}
                />
                <Button
                  type="submit"
                  size="medium"
                  appearence="primary"
                  label={<LanguageProvider id={"gen.save.button"} />}
                  onClick={() => outputFormik.handleSubmit()}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <AddItemDialog
        visible={displayAddOutputItemDialog}
        onHide={() => {
          setDisplayAddOutputItemDialog(false);
        }}
        itemModel={outputItemModel}
        skuOptions={skuOptions}
        addressOptions={addressOptions}
        tableData={addTableData}
        isEdit={isEdit}
        isOutput={true}
      />
      <ModalConfirmation
        isOpen={displayDeleteOutputItemDialog}
        isDelete={true}
        modalTitle={<LanguageProvider id={"gen.confirm.delete.item"} />}
        bodyMessage={
          <LanguageProvider id={"gen.message.confirm.delete.message"} />
        }
        onClose={() => setDisplayDeleteOutputItemDialog(false)}
        onConfirm={handleDeleteItem}
        onCancel={() => setDisplayDeleteOutputItemDialog(false)}
      />
      <ModalConfirmation
        isOpen={displayClearDataDialog}
        isDelete={true}
        modalTitle={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data"} />
        }
        bodyMessage={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data.message"} />
        }
        onClose={() => setDisplayClearDataDialog(false)}
        onConfirm={handleClearData}
        onCancel={() => setDisplayClearDataDialog(false)}
      />

      <SetUpOutputRegistrationDialog
        visible={configureOutputRegistrationDialog}
        onHide={() => {
          setConfigureOutputRegistrationDialog(false);
          loadOutputRegisterData();
        }}
        outputSettings={outputConfigSettings ?? ""}
      />
    </>
  );
};

export default Output;
