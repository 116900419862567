import "./andon-board.scss";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import DcpAxiosService, {
  useDcpAxiosService,
} from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { ItemListDialog } from "~/pages/shared-modules/dashboard-graphs/components/item-list-dialog/item-list-dialog";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import { classNames } from "primereact/utils";
import LanguageProvider from "~/shared/components/language-provider";
import { AndonComponentMenuItem } from "./andon-component-menu-items";
import { AndonTripMenuItems } from "./andon-trip-menu-items";
import { OverlayPanel } from "primereact/overlaypanel";
import {
  DashboardMenuItem,
  DashboardMenuItemCategory,
} from "~/pages/shared-modules/dashboard-graphs/components/item-list-dialog/menu-items";
import { AndonSettingsContext } from "../context/AndonDashboardSettingsContext";
import { TextConfigMenu } from "./text-config-menu";
import { ComponentPositionInfo } from "~/shared/interfaces/sequence.ts";
import { getComponentPositions } from "~/services/api/sequence/andon.ts";

const itemTypeSizeConstraints = {
  position: {
    minWidth: 1,
    minHeight: 5,
    maxwidth: 3,
    maxheight: 20,
  },
  divider: {
    minWidth: 1,
    minHeight: 1,
    maxwidth: 100,
    maxheight: 2,
  },
  "andon-text": {
    minWidth: 1,
    minHeight: 5,
    maxwidth: 100,
    maxheight: 100,
  },
  errors: {
    minWidth: 4,
    minHeight: 10,
    maxwidth: 100,
    maxheight: 100,
  },
  "last-integration-order": {
    minWidth: 6,
    minHeight: 13,
    maxwidth: 100,
    maxheight: 100,
  },
  "last-integration-order-number": {
    minWidth: 6,
    minHeight: 13,
    maxwidth: 100,
    maxheight: 100,
  },
  "open-trips": {
    minWidth: 3,
    minHeight: 10,
    maxwidth: 100,
    maxheight: 100,
  },
  "pending-orders": {
    minWidth: 3,
    minHeight: 10,
    maxwidth: 100,
    maxheight: 100,
  },
  "waiting-shipping-orders": {
    minWidth: 4,
    minHeight: 10,
    maxwidth: 100,
    maxheight: 100,
  },
};

export interface DashboardItem {
  id: number;
  gridKey: string;
  itemType: string;
  width: number;
  height: number;
  posX: number;
  posY: number;
  content: ComponentPositionInfo;
  clientId: string;
  dashboardType: number;
}

export interface AndonBoardProps {
  dashboardType: number;
}

export function AndonBoard(props: AndonBoardProps) {
  const [reRender, setReRender] = useState(false);
  const axiosService = useDcpAxiosService();
  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    [reRender]
  );
  const menuRef = useRef(null);
  const settings = useContext(AndonSettingsContext);

  // Data
  const [dashboardItems, setDashboardItems] = useState<DashboardItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<DashboardItem>(undefined);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [componentPositions, setComponentPositions] = useState<
    ComponentPositionInfo[]
  >([]);

  // Element Visibility
  const [itemListDialogOpen, setItemListDialogOpen] = useState(false);
  const [editModeActive, setEditModeActive] = useState(false);
  const [isInFullscreenMode, setIsInFullscreenMode] = useState(false);
  const [textConfigMenuOpen, setTextConfigMenuOpen] = useState(false);

  // Loading
  const [submiting, setSubmiting] = useState(false);

  type MenuItemSConcat = {
    [key: string]: {
      item: DashboardMenuItem;
      category: DashboardMenuItemCategory;
    };
  };

  const menuItemsConcat = useMemo<MenuItemSConcat>(() => {
    let items: MenuItemSConcat = {};

    if (Array.isArray(menuItems) && menuItems.length > 0) {
      menuItems.forEach((category) => {
        category.items.forEach((item) => {
          items[item.type] = { item, category };
        });
      });
    }

    return items;
  }, [menuItems]);

  const loadDashboardItems = useCallback(
    async (dashboardType: string | number) => {
      try {
        var positions = await getComponentPositions();
        var dashboardItems = await fetchDashboardItems(
          axiosService,
          dashboardType
        );

        dashboardItems.forEach((dashboardItem) => {
          var position = positions.find(
            (p) => p.position.toString() === dashboardItem.content
          );
          dashboardItem.content = position;
        });
        setDashboardItems(dashboardItems);
        setComponentPositions(positions);
      } catch (error) {
        console.error(error);
      }
    },
    [axiosService]
  );

  function updatePositionNumbers(items) {
    let updatedItems = [...items];
    let counter = 1;
    updatedItems.forEach((item) => {
      if (item.itemType === "position" && item.id >= 0) {
        item.content = componentPositions.find(
          (x) => x.position.toString() === counter.toString()
        );
        counter++;
      }
    });
    return updatedItems;
  }

  function onAddItem(itemType: string) {
    try {
      const cols = 12;
      const totalItemsLength = dashboardItems.reduce(
        (prev, curr) => (curr.id >= 0 ? prev + curr.width : prev),
        0
      );

      const newItem = {
        id: 0,
        gridKey: generateUniqueKey(dashboardItems),
        itemType: itemType,
        width: itemTypeSizeConstraints[itemType].minWidth,
        height: itemTypeSizeConstraints[itemType].minHeight,
        posX: totalItemsLength % cols,
        posY: 0,
        content:
          itemType === "andon-text" ? '{"text": "", "fontSize": 20}' : "",
        clientId: "00000000-0000-0000-0000-000000000000",
        dashboardType: props.dashboardType ?? 1,
      };
      const updatedItems = [...dashboardItems, newItem];
      setDashboardItems(updatePositionNumbers(updatedItems));
      setItemListDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function onSave() {
    try {
      setSubmiting(true);
      var payload = dashboardItems.map((i) => {
        i.content = i.content.position.toString();
        return i;
      });
      await saveDashboardItems(axiosService, payload);
      setEditModeActive(false);
      setReRender(!reRender);
    } catch (error) {
      console.error(error);
    }
    setSubmiting(false);
  }

  const onEditItem = useCallback((item: DashboardItem) => {}, []);

  const onDelete = useCallback(
    (item: DashboardItem) => {
      try {
        if (item.id === 0) {
          // remove the item from the list if it has not been saved
          setDashboardItems(
            dashboardItems.filter((i) => i.gridKey !== item.gridKey)
          );
        } else if (item.id > 0) {
          // negativate the item id to mark it for deletion
          const itemIndex = dashboardItems.findIndex((i) => i.id === item.id);
          const updatedItems = [...dashboardItems];
          updatedItems[itemIndex].id = item.id * -1;
          setDashboardItems(updatePositionNumbers(updatedItems));
        } else {
          // item is already marked for deletion
          return null;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dashboardItems]
  );

  const onLayoutChange = useCallback(
    (layout: ReactGridLayout.Layout[]) => {
      const updatedItems = dashboardItems.map((item) => {
        let layoutItem = layout.find((li) => li.i === item.gridKey);

        if (layoutItem) {
          return {
            ...item,
            posX: layoutItem.x,
            posY: layoutItem.y,
            height: layoutItem.h,
            width: layoutItem.w,
          };
        } else {
          return item;
        }
      });

      setDashboardItems(updatedItems);
    },
    [dashboardItems]
  );

  const renderLayout = useCallback(() => {
    return (
      <ResponsiveReactGridLayout
        onLayoutChange={onLayoutChange}
        compactType={null}
        preventCollision={true}
        margin={[5, 5]}
        rowHeight={10}
        breakpoints={{
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 4 }}
      >
        {dashboardItems.length > 0 &&
          dashboardItems.map((item) => {
            // dont render items marked for deletion
            if (item.id < 0) return null;

            let menuItem = menuItemsConcat[item.itemType];
            if (!menuItem || !menuItem.item) return null;

            let component = menuItem.item.component;
            if (!component) return null;

            let customStyle = {};

            if (item.itemType === "divider" || item.itemType === "andon-text") {
              customStyle = {
                padding: 0,
                background: "none",
                border: "none",
              };
            }

            if (item.itemType === "position" && item.content) {
              var positionBgColor = "";
              if (item.content.isNext)
                positionBgColor = settings.currentPositionColor;
              if (item.content.hasSamePartNumber)
                positionBgColor = settings.samePnPositionColor;
              if (item.content.hasError)
                positionBgColor = settings.errorPositionColor;
              if (item.content.isValidated)
                positionBgColor = settings.validatedPositionColor;

              customStyle["background"] = positionBgColor;
            }

            const gridItem = {
              i: item.gridKey,
              x: item.posX,
              y: item.posY,
              w: item.width,
              h: item.height,
              minH: itemTypeSizeConstraints[item.itemType].minHeight,
              minW: itemTypeSizeConstraints[item.itemType].minWidth,
              static: editModeActive,
              isDraggable: editModeActive,
              isResizable: editModeActive,
            };

            return (
              <div
                className="grid-item-wrapper"
                key={gridItem.i}
                data-grid={gridItem}
                onClick={(e) => {
                  if (editModeActive) {
                    menuRef.current.toggle(e);
                    setSelectedItem(item);
                  }
                }}
              >
                <GridItem
                  item={item}
                  component={component}
                  customStyle={customStyle}
                  editModeActive={editModeActive}
                />
              </div>
            );
          })}
      </ResponsiveReactGridLayout>
    );
  }, [
    ResponsiveReactGridLayout,
    dashboardItems,
    editModeActive,
    menuItemsConcat,
    onLayoutChange,
    settings.currentPositionColor,
    settings.validatedPositionColor,
  ]);

  useEffect(() => {
    async function load() {
      await loadDashboardItems(props.dashboardType);
    }
    load();
  }, [loadDashboardItems, props.dashboardType]);

  useEffect(() => {
    async function load() {
      if (!editModeActive) {
        await loadDashboardItems(props.dashboardType);
        setReRender(!reRender);
      }
    }

    var intervalId = setInterval(
      load,
      settings?.refreshIntervalSeconds
        ? settings.refreshIntervalSeconds * 1000
        : 10 * 1000
    );
    return () => {
      clearInterval(intervalId);
    };
  }, [editModeActive]);

  useEffect(() => {
    setMenuItems(
      props.dashboardType === 1 ? AndonComponentMenuItem : AndonTripMenuItems
    );
  }, [props.dashboardType]);

  return (
    <div
      className={classNames("andon-board", {
        full: isInFullscreenMode,
      })}
    >
      <header>
        {editModeActive ? (
          <div className="header-buttons">
            <Button
              onClick={() => setItemListDialogOpen(true)}
              buttonStyle="outline"
              outlined
              className="add-item-button"
            >
              <LanguageProvider id="dashboard.add.element" />
              <Icon
                icon="plus-circle"
                color="var(--primary)"
                size={undefined}
              />
            </Button>
            <Button onClick={onSave} loading={submiting}>
              <LanguageProvider id="gen.save.button" />
            </Button>
            <Button
              severity="secondary"
              onClick={() => document.location.reload()}
            >
              <LanguageProvider id="gen.cancel.button" />
            </Button>
          </div>
        ) : (
          <div className="header-buttons">
            <Button
              appearance="secondary"
              className="menu-btn p-button-plain"
              onClick={() => {
                setIsInFullscreenMode(!isInFullscreenMode);
                const element = document.getElementById("root");
                if (isInFullscreenMode) document.exitFullscreen();
                else element.requestFullscreen();
              }}
            >
              <Icon
                icon={isInFullscreenMode ? "minimize-01" : "maximize-01"}
                size={undefined}
                color={undefined}
              ></Icon>
            </Button>
            <Button
              appearance="secondary"
              className="menu-btn p-button-plain"
              onClick={() => {
                setEditModeActive(true);
                setReRender(!reRender);
              }}
            >
              <Icon icon="edit-02" size={undefined} color={undefined} />
            </Button>
          </div>
        )}
      </header>
      <div className="layout">{renderLayout()}</div>
      {Array.isArray(menuItems) && menuItems.length > 0 && (
        <ItemListDialog
          open={itemListDialogOpen}
          onClose={() => setItemListDialogOpen(false)}
          onAddItem={onAddItem}
          menuItems={menuItems}
        />
      )}
      <OverlayPanel className="options-menu" appendTo={"self"} ref={menuRef}>
        <div className="detail"></div>
        <div className="icons">
          {selectedItem && selectedItem.itemType === "andon-text" && (
            <Button
              className="icon-btn p-button-plain"
              text
              onClick={(e) => {
                setTextConfigMenuOpen(true);
              }}
            >
              <Icon icon="edit-02" size="20px" color="#6468ff" />
            </Button>
          )}
          <Button
            className="icon-btn p-button-plain"
            text
            onClick={() => {
              menuRef.current.hide();
              onDelete(selectedItem);
            }}
          >
            <Icon icon="trash-02" size="20px" color="#6468ff" />
          </Button>
        </div>
      </OverlayPanel>
      <TextConfigMenu
        open={textConfigMenuOpen}
        item={selectedItem}
        onCancel={() => setTextConfigMenuOpen(false)}
        onSave={(item) => {
          setTextConfigMenuOpen(false);
          const updatedItems = dashboardItems.map((i) => {
            if (i.gridKey === item.gridKey) {
              return item;
            } else {
              return i;
            }
          });
          setDashboardItems(updatedItems);
          setSelectedItem(null);
        }}
      />
    </div>
  );
}

function GridItem(props) {
  const style = { ...props.customStyle };
  if (props.editModeActive) {
    style.border = "1px solid var(--painelBorderColor)";
  }

  return (
    <div
      style={style}
      className={classNames("grid-item", {
        "edit-mode": props.editModeActive,
      })}
    >
      {props.component(props.item, null, null)}
    </div>
  );
}

async function fetchDashboardItems(
  axiosService: DcpAxiosService,
  dashboardType: string | number
): Promise<DashboardItem[]> {
  try {
    const { data } = await axiosService.get(
      settings.Urls.Rest.Sequence + "/andon/dashboard/",
      "Sequence",
      { params: { dashboardType } }
    );

    if (Array.isArray(data.data)) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}

async function saveDashboardItems(
  axiosService: DcpAxiosService,
  items: DashboardItem[]
): Promise<any[]> {
  try {
    const { data } = await axiosService.post(
      settings.Urls.Rest.Sequence + "/andon/dashboard",
      items,
      "Sequence"
    );

    if (Array.isArray(data.data)) return data.data;
  } catch (error) {
    console.error(error);
  }
  return null;
}

const generateUniqueKey = (items) => {
  try {
    let key = (Math.floor(Math.random() * 9999) + 1).toString();
    const existingKey = items.find((item) => item.gridKey === key);
    if (!existingKey) {
      return key;
    } else {
      generateUniqueKey(items);
    }
  } catch (error) {
    console.error(error);
  }
};
