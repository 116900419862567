import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs.jsx";
import { GenericCounter } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/custom-charts-components/generic-counter";

export default function WarehouseGenericCounter(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (useMockData) {
      setCurrentValue(270);
      setTitle("Qtd Produtos Cadasatrados");
    } else {
      // load from api
      setCurrentValue(0);
      setTitle("");
      // setTitle(getConfigValue(configs, "count-title") ?? "");
    }
  }, [useMockData, configs]);

  return <GenericCounter title={title} value={currentValue} />;
}
