import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { AreaListModel, LocationType } from "~/shared/interfaces";
import { AreaItemNameModel } from "~/shared/interfaces/area-group.ts";

function axiosService(): DcpAxiosService {
  return new DcpAxiosService();
}

/**
 * Retrieves a list of areaGroups by the areaId
 * @param areaId - The ID of area map.
 * @returns A Promise that resolves to the list of area groups.
 */
export async function listAreaMaps(): Promise<AreaListModel[]> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.AreaMap + "/list-areas",
      "Platform"
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves a list of areaGroups by the areaId
 * @param areaId - The ID of area map.
 * @returns A Promise that resolves to the list of area groups.
 */
export async function listAreaGroups(areaId: number | string): Promise<any> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.AreaGroup + "/list",
      "Platform",
      {
        params: {
          areaId: areaId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves a list of areaGroups by the areaId
 * @param areaId - The ID of area map.
 * @returns A Promise that resolves to the list of area groups.
 */
export async function listGroupNames(
  areaId: number | string,
): Promise<AreaItemNameModel[]> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.AreaGroup + "/list-groups",
      "Platform",
      {
        params: {
          areaId: areaId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function listLocationNames(
  areaGroupId: number | string,
  locationType: LocationType
): Promise<AreaItemNameModel[]> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.AreaGroup + "/list-locations",
      "Platform",
      {
        params: {
          areaGroupId: areaGroupId,
          locationType: locationType,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function deleteAreaGroup(groupId: number | string): Promise<any> {
  try {
    const { data } = await axiosService().delete(
      settings.Urls.Rest.AreaGroup + "/delete",
      "Platform",
      {
        params: {
          id: groupId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}