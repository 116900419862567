import React, { useEffect } from "react";
import { useState } from "react";
import {
  deleteFloorArea,
  listFloorAreas,
} from "~/services/api/platform/floor-area";
import "./scss/rack-list.scss";
import PageHeader from "~/shared/components/page-header/page-header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "~/context/ToastContext";
import * as Interfaces from "~/shared/interfaces";
import { Button } from "~/shared/components/dcp-button";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { deleteRack } from "~/services/api/platform/layout";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function RackList(): JSX.Element {
  const { currentLanguage } = useLanguageContext();
  const [racks, setRacks] = useState<Interfaces.FloorArea[]>([]);
  const [selectedRackId, setSelectedRackId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const dcpAxiosService = useDcpAxiosService();

  async function loadRacks() {
    try {
      setLoading(true);
      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Layout}/list-racks`,
        "Platform"
      );

      if (data.data.length > 0) {
        setRacks(data.data);
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: data.message,
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: "unhandled.error",
          currentLanguage,
        }),
      });
    }
    setLoading(false);
  }

  async function onEdit(id: number) {
    try {
      navigate("edit/" + id);
    } catch (error) {
      console.error(error);
    }
  }

  async function onCreate() {
    try {
      navigate("edit/0");
    } catch (error) {
      console.error(error);
    }
  }

  async function onDelete() {
    try {
      const status = await deleteRack(selectedRackId);
      setDeleteConfirmationOpen(false);

      if (status) {
        showToast({
          severity: "success",
          message: <LanguageProvider id="gen.message.rack.deleted" />,
        });
        await loadRacks();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
  }

  useEffect(() => {
    loadRacks();
  }, []);

  function headerActions() {
    return (
      <div className="header-actions">
        <Button onClick={onCreate}>
          <LanguageProvider id="gen.rack.create" />
        </Button>
      </div>
    );
  }

  return (
    <div className="rack-list-container">
      <div className="table-wrapper">
        <PageHeader
          titleTemplate={undefined}
          recordsCount={racks.length}
          onReturn={undefined}
          actions={headerActions}
          title={LanguageProvider({ id: "product.wms.menu.racks" })}
        ></PageHeader>
        <div className="datatable-container">
          <DataTable
            value={racks}
            loading={loading}
            paginator
            rows={settings.RowsPerPageOptions.Default}
            scrollable
            scrollHeight="flex"
            rowsPerPageOptions={settings.RowsPerPageOptions.Options}
            emptyMessage={<LanguageProvider id="gen.no.registers" />}
          >
            <Column field="name" header={<LanguageProvider id="gen.name" />} />
            <Column
              field="color"
              header={<LanguageProvider id="gen.color" />}
              body={colorColumnTemplate}
            />
            <Column
              field="width"
              header={<LanguageProvider id="gen.width" />}
            />
            <Column
              field="height"
              header={<LanguageProvider id="gen.height" />}
            />
            <Column
              field="topShelf"
              header={<LanguageProvider id="gen.top.shelf" />}
              body={topShelfColumnTemplate}
            />
            <Column
              field="locationQtd"
              header={<LanguageProvider id="gen.qtd.total.locations" />}
              body={(rowData) => {
                if (rowData.shelves.length < 1) return 0;
                return rowData.shelves.reduce(
                  (acc, curr) => acc + curr.locationsAmount,
                  0
                );
              }}
            />
            <Column
              headerStyle={{ width: "10%", minWidth: "100px" }}
              body={(rowData) => {
                return (
                  <div className="column-wrapper">
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => onEdit(rowData.id)}
                      >
                        <Icon
                          icon="edit-02"
                          size={20}
                          color="#667085"
                          className="icon-row"
                        />
                      </div>
                    </div>
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => {
                          setSelectedRackId(rowData.id);
                          setDeleteConfirmationOpen(true);
                        }}
                      >
                        <Icon
                          icon="trash-02"
                          size={20}
                          color="#667085"
                          className="icon-row"
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        modalTitle={<LanguageProvider id="gen.exclude.button" />}
        bodyMessage={
          <LanguageProvider id="gen.message.confirm.delete.message" />
        }
        isDelete={true}
        onConfirm={onDelete}
        onCancel={() => setDeleteConfirmationOpen(false)}
      />
    </div>
  );
}

export function colorColumnTemplate({ color }) {
  return (
    <div className="color-column-template">
      <span
        className="color-circle"
        style={{ background: color.padStart(7, "#") }}
      ></span>
      <span>{color.replace("#", "").toUpperCase()}</span>
    </div>
  );
}

export function topShelfColumnTemplate({ topShelf }) {
  return (
    <span className={`top-shelf-column-template ${topShelf ? "no" : ""}`}>
      <LanguageProvider id={topShelf ? "gen.message.yes" : "gen.message.no"} />
    </span>
  );
}
