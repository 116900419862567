import { createContext, useContext, useEffect, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

export const UsersContext = createContext();

export function UsersContextProvider({ children }) {
  const dcpAxiosService = useDcpAxiosService();
  const [users, setUsers] = useState([]);

  async function loadUsers() {
    try {
      const { data, status } = await dcpAxiosService.get(
        "user/get-users",
        "Auth"
      );
      if (status === 200) {
        setUsers(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <UsersContext.Provider value={{ users }}>{children}</UsersContext.Provider>
  );
}

export function useUsersContext() {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error(
      "useUsersContext have to be used within MenuItemsContextProvider"
    );
  }

  return context.users;
}
