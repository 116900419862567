import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";

import "./scss/dcp-modal-confirmation.scss";

const ModalConfirmation = ({
  isOpen,
  modalTitle,
  bodyMessage,
  isDelete,
  onConfirm,
  onCancel,
  loading = false,
  confirmButtonText = undefined,
}) => {
  const OnSubmit = () => {
    onConfirm(true);
  };

  const CloseModal = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <ConfirmDialog
      className="confirmation-dialog"
      position="center"
      visible={isOpen}
      onHide={CloseModal}
      message={bodyMessage}
      header={() => {
        let iconType;
        let iconColor;
        let iconClassName;
        if (isDelete) {
          iconType = "alert-circle";
          iconColor = "#E91F35";
          iconClassName = "modal-icon-delete";
        } else {
          iconType = "help-circle";
          iconColor = "#4146FF";
          iconClassName = "modal-icon-help";
        }

        return (
          <>
            <div className="modal-header">
              <div className="modal-icon-wrapper">
                <div className={iconClassName}>
                  <Icon icon={iconType} color={iconColor} size={"20px"} />
                </div>
              </div>
            </div>
            <div className="modal-title-wrapper">
              <p className="modal-title">{modalTitle}</p>
            </div>
          </>
        );
      }}
      footer={() => {
        let labelButtonCancel;
        let labelButtonConfirm;
        let className;

        if (isDelete) {
          labelButtonCancel = <LanguageProvider id={"gen.cancel.button"} />;
          labelButtonConfirm = <LanguageProvider id={"gen.delete.button"} />;
          className = "p-button p-button-danger";
        } else {
          labelButtonCancel = <LanguageProvider id={"gen.message.no"} />;
          labelButtonConfirm = <LanguageProvider id={"gen.message.yes"} />;
          className = "p-button p-primary";
        }
        return (
          <div className="card flex justify-content-center">
            <Button
              label={labelButtonCancel}
              appearance="subtle"
              className="p-button p-button-text p-button-plain"
              onClick={CloseModal}
            />
            <Button
              label={confirmButtonText ?? labelButtonConfirm}
              appearance="danger"
              className={className}
              onClick={OnSubmit}
              autoFocus
              loading={loading}
            />
          </div>
        );
      }}
    />
  );
};

export default ModalConfirmation;
