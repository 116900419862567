import React, { useContext, useEffect } from "react";
import { ThemeContext, UserContext } from "~/app";
import DashboardGraphs from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import DcpDeniedAccess from "~/shared/components/dcp-denied-access";

const Home = () => {
  const { currentTheme } = useContext(ThemeContext);
  const { user, handleVerifyDashboardRolePermission, userRoles } =
    useContext(UserContext);

  useEffect(() => {
    const productRoles = user.userClienteRoles.filter(
      (role) => role.applicationName === "product.inventory"
    );
    handleVerifyDashboardRolePermission(productRoles);
  }, []);

  return (
    <>
        <div className="home">
          <DashboardGraphs previewMode={true} isMain={true} />
        </div>
    </>
  );
};

export default Home;
