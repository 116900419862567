import { Dialog, DialogProps } from "primereact/dialog";
import "../../scss/template-pattern-dialog.scss";
import React from "react";
import { useState } from "react";
import { Button } from "~/shared/components/dcp-button";
import { InputContainer } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import { Mention } from "primereact/mention";

interface TemplatePatterDialogProps extends DialogProps {
  onSave: (value: string) => void;
  onChange: (value: string) => void;
  fields: any[];
  suggestions: Suggestion[];
  templatePattern: string;
}

interface Suggestion {
  viewName: string;
  keyName: string;
  fieldId: number;
}

export function TemplatePatterDialog(props: TemplatePatterDialogProps) {
  const [value, setValue] = useState(props.templatePattern);
  const [suggestions, setSuggestions] = useState(props.suggestions);

  const onSearch = (e) => {
    if (!Array.isArray(props.suggestions)) return;

    const query: string = e.query?.trim();
    let suggestions: Suggestion[];

    if (query.length < 1) {
      suggestions = getAllSugestions();
    } else {
      suggestions = getFilteredSuggestions(query);
    }

    setSuggestions(suggestions);
  };

  function getAllSugestions(): Suggestion[] {
    return props.suggestions.map((suggestion: Suggestion) => {
      return {
        ...suggestion,
        patternField: `{${suggestion.keyName}}`,
      };
    });
  }

  function getFilteredSuggestions(query: string): Suggestion[] {
    return props.suggestions
      .filter((sug) => {
        return sug.viewName.toLowerCase().startsWith(query.toLowerCase());
      })
      .map((suggestion: Suggestion) => {
        return {
          ...suggestion,
          patternField: `{${suggestion.keyName}}`,
        };
      });
  }

  const sugestionItemTemplate = (suggestion: Suggestion) => {
    return (
      <div className="flex align-items-center">
        <span className="flex flex-column ml-2">{suggestion.viewName}</span>
      </div>
    );
  };

  return (
    <Dialog onHide={props.onHide} visible={props.visible}>
      <div className="template-pattern-dialog">
        <InputContainer
          label={<LanguageProvider id="connection.display.pattern" />}
        >
          <p className="pattern-description"></p>
          <Mention
            autoResize
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            field={"patternField"}
            suggestions={suggestions}
            itemTemplate={sugestionItemTemplate}
            trigger={["@"]}
            onSearch={onSearch}
            className="pattern-field"
            rows={1}
            onSelect={(e) => setValue(e.originalEvent.target.value)}
          />
        </InputContainer>
        <div className="btn-container">
          <Button
            className="save-pattern-btn"
            type="button"
            onClick={() => props.onSave(value)}
          >
            <LanguageProvider id="gen.save.button" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
