import { Dialog } from "primereact/dialog";
import "../scss/database-details.scss";
import React, { useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LanguageProvider from "~/shared/components/language-provider";
import { ThemeContext } from "~/app";
import { FormRow, InputContainer } from "~/shared/components/dcp-form";
import { InputText } from "primereact/inputtext";
import { propertyFrommStorage } from "~/services/storage/storage-access";

export const DetailsDatabaseData = ({ visible, databaseDetails, onHide }) => {
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  const dialogHeader = () => {
    return (
      <>
        <span className="database-details-title">Detalhes de Integração</span>
      </>
    );
  };

  return (
    <>
      <Dialog
        visible={visible}
        onHide={onHide}
        header={dialogHeader}
      >
        <div className="database-details-container">
          <div className="database-details-table-wrapper">
            <FormRow className="database-details-fields">
              <InputContainer label={"Database Id"}>
                <InputText
                  style={{ textOverflow: "ellipsis", width: "100%" }}
                  value={databaseDetails.databaseId}
                  disabled
                />
              </InputContainer>
              <InputContainer label={"Application Client Id"}>
                <InputText
                  title={applicationClientId}
                  style={{ textOverflow: "ellipsis", width: "100%" }}
                  value={applicationClientId}
                  disabled
                />
              </InputContainer>
            </FormRow>
            <div className="database-details-table">
              <DataTable
                lazy
                loading={false}
                value={databaseDetails.columns}
                key={databaseDetails.length}
                emptyMessage={<LanguageProvider id="gen.no.registers" />}
              >
                <Column
                  body={(rowData) => {
                    return (
                      <div className="tb-cell-content">{rowData.columnKey}</div>
                    );
                  }}
                  header={() => (
                    <span className="tb-header-title">
                      <LanguageProvider id={"gen.code"} />
                    </span>
                  )}
                />
                <Column
                  body={(rowData) => {
                    return (
                      <div className="tb-cell-content">
                        {rowData.columnName}
                      </div>
                    );
                  }}
                  header={() => (
                    <span className="tb-header-title">
                      <LanguageProvider id={"gen.name"} />
                    </span>
                  )}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
