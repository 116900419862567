import "./scss/inventory.scss";
import React, { useCallback, useEffect, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { useUsersContext } from "~/context/UsersContext";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import PageHeader from "~/shared/components/page-header/page-header";
import { InventoryCreate } from "./inventory-create/inventory-create";
import { listInventories, UpdateInventoryStatus } from "~/services/api";
import { InventoryStatusBadge } from "~/pages/inventory/inventory/inventory-details/components/inventory-status.tsx";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { useToastContext } from "~/context/ToastContext";
import {
  InventoryStatus,
  InventoryUpdateStatusModel,
} from "~/shared/interfaces";
import { MultiSelect } from "primereact/multiselect";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";

export function Inventory() {
  const navigate = useNavigate();
  const users = useUsersContext();
  const { showToast } = useToastContext();

  // Data
  const [inventories, setInventories] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState();
  const [selectedStatus, setSelectedStatus] = useState([
    InventoryStatus.WaitingStart,
    InventoryStatus.InProgress,
    InventoryStatus.Paused,
  ]);

  // Filter
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState({
    first: 0,
    page: 0,
    rows: settings.RowsPerPageOptions.Default,
    sortField: null,
    sortOrder: 1, // 1 or -1
    inventorySelectedStatus: selectedStatus,
    filters: {
      code: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      name: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      status: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.CUSTOM,
          },
        ],
      },
      estimatedLocations: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.GREATER_THAN,
          },
        ],
      },
      accuracy: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.GREATER_THAN,
          },
        ],
      },
      duration: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      responsibles: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
    },
  });

  // Dialog controll
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);

  // Loading indicators
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const loadInventories = useCallback(async () => {
    try {
      setLoading(true);
      let _inventories = await listInventories(formatFilterRequest(filter));

      if (_inventories && Array.isArray(_inventories.inventories)) {
        setInventories(_inventories.inventories);
        setTotalRecords(_inventories.totalRecords);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [filter, selectedStatus]);

  async function onDelete() {
    try {
      setDeleting(true);
      var payload: InventoryUpdateStatusModel = {
        inventoryId: selectedInventory,
        status: InventoryStatus.Canceled,
      };

      const status = await UpdateInventoryStatus(payload);
      if (status) {
        showToast({
          severity: "success",
          message: LanguageProvider({ id: "inventory.delete.success" }),
        });
        setDeleteConfirmationVisible(false);
        loadInventories();
      }
    } catch (error) {
      console.error(error);
    }
    setDeleting(false);
  }

  const headerActions = () => {
    var options = [
      {
        id: 1,
        name: LanguageProvider({ id: "inventory.status.waitingStart" }),
      },
      {
        id: 2,
        name: LanguageProvider({ id: "inventory.status.inProgress" }),
      },
      {
        id: 3,
        name: LanguageProvider({ id: "inventory.status.paused" }),
      },
      {
        id: 4,
        name: LanguageProvider({ id: "inventory.status.finished" }),
      },
      {
        id: 5,
        name: LanguageProvider({ id: "inventory.status.canceled" }),
      },
    ];

    return (
      <div className="button-actions">
        <MultiSelect
          className="status-filter"
          value={selectedStatus}
          options={options}
          optionValue={"id"}
          optionLabel={"name"}
          display={"chip"}
          onChange={(e) => {
            setSelectedStatus(e.value);
            const inventoryStatusSelected: InventoryStatus[] =
              [] as InventoryStatus[];
            inventoryStatusSelected.push(...e.value);
            onInventoryStatusFilter(inventoryStatusSelected);
          }}
        ></MultiSelect>
        <Button onClick={() => setCreateDialogVisible(true)}>
          <LanguageProvider id="inventory.create" />
        </Button>
      </div>
    );
  };

  // Column templates
  const statusColumnTemplate = (rowData) => {
    return (
      <InventoryStatusBadge
        inventoryStatus={rowData.status}
        removeBg
      ></InventoryStatusBadge>
    );
  };

  const accuracyColumnTemplate = (rowData) => {
    const color = rowData.accuracy > 60 ? "#03CEB7" : "#F9485B";

    return (
      <p className="cell-text" style={{ color }}>
        {rowData.accuracy}%
      </p>
    );
  };

  const typeColumnTemplate = (rowData) => {
    let type;
    if (rowData.drone && rowData.manual) type = "both";
    else if (rowData.drone && !rowData.manual) type = "drone";
    else type = "manual";

    return (
      <p className="">
        <LanguageProvider id={`inventory.type.${type}`} />
      </p>
    );
  };

  const responsiblesColumnTemplate = (rowData) => {
    return (
      <AvatarGroup>
        {rowData?.responsibles?.map((userId, index) => {
          if (index < 3) {
            const user = users?.find((u) => u.userId === userId);
            if (!user) return null;

            let initials = user.name[0];
            if (user.name.split(" ").length > 1) {
              initials = user.name
                .split(" ")
                .map((n) => n[0])
                .join("");
            }

            return (
              <Avatar label={initials} shape="circle" size="large"></Avatar>
            );
          } else if (index === 4) {
            return (
              <Avatar
                key={index}
                label={`+${rowData.responsibles.length - 3}`}
                shape="circle"
                size="large"
              ></Avatar>
            );
          } else {
            return null;
          }
        })}
      </AvatarGroup>
    );
  };

  const columnActionsTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          className="p-button-plain p-button-text"
          onClick={() => navigate(rowData.id.toString())}
        >
          <Icon
            icon="edit-02"
            className="action-edit"
            size={undefined}
            color={undefined}
          />
        </Button>
        <Button
          className="p-button-plain p-button-text"
          onClick={() => {
            setSelectedInventory(rowData.id);
            setDeleteConfirmationVisible(true);
          }}
          disabled={rowData.status === InventoryStatus.Canceled}
        >
          <Icon
            icon="trash-02"
            className="action-delete"
            size={undefined}
            color={undefined}
          />
        </Button>
      </div>
    );
  };

  const onFilter = (e) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...e,
      inventorySelectedStatus: selectedStatus,
    }));
    let _event = { ...e, first: 0 };

    // hack to fix clear button
    for (const filter in _event.filters) {
      if (Object.hasOwnProperty.call(_event.filters, filter)) {
        let element = _event.filters[filter];
        if (Object.hasOwnProperty.call(element, "value")) {
          delete _event.filters[filter].value;
          delete _event.filters[filter].matchMode;
          _event.filters[filter].constraints[0].value = null;
        }
      }
    }

    let filtersWithInventoryStatus = null;
    filtersWithInventoryStatus = {
      ...filter,
      inventorySelectedStatus: selectedStatus,
    };
    setFilter(filtersWithInventoryStatus);
  };

  const onInventoryStatusFilter = (inventoryStatus = selectedStatus) => {
    setSelectedStatus(inventoryStatus);
    setFilter((prevFilter) => ({
      ...prevFilter,
      inventorySelectedStatus: inventoryStatus,
    }));
  };

  // Format the filter object to be accepted by the endpoint
  function formatFilterRequest(filter) {
    try {
      let payload = { ...filter, inventorySelectedStatus: selectedStatus };
      payload.sortOrder = payload.sortOrder ?? 0;

      let _filters = [];

      for (const columnName in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, columnName)) {
          const _filter = payload.filters[columnName];

          if (_filter.constraints[0].value)
            _filters.push({
              columnName,
              value: _filter.constraints[0].value,
              matchMode: _filter.constraints[0].matchMode,
            });
        }
      }

      payload.filters = _filters;
      return {
        ...payload,
        inventorySelectedStatus: payload.inventorySelectedStatus,
      };
    } catch (error) {
      console.error(error);
    }
  }

  function statusFilterItemTemplate(option) {
    return (
      <InventoryStatusBadge inventoryStatus={option}></InventoryStatusBadge>
    );
  }

  useEffect(() => {
    loadInventories();
  }, [loadInventories]);

  return (
    <div className="inventory-list">
      <PageHeader
        title={<LanguageProvider id="inventories" />}
        recordsCount={totalRecords}
        actions={headerActions}
        titleTemplate={undefined}
        onReturn={undefined}
      />
      <div className="datatable-container">
        {/*@ts-ignore*/}
        <DataTable
          lazy
          loading={loading}
          emptyMessage={<LanguageProvider id="gen.no.registers" />}
          loadingIcon={<LoadingIndicator />}
          value={inventories}
          className="dcp-table"
          rows={filter.rows}
          first={filter.first}
          onPage={setFilter}
          onSort={setFilter}
          onFilter={(e) => onFilter(e)}
          totalRecords={totalRecords}
          sortField={filter.sortField}
          sortOrder={filter.sortOrder}
          filters={filter.filters}
          paginator
          rowsPerPageOptions={settings.RowsPerPageOptions.Options}
        >
          <Column
            sortable
            filter
            field="code"
            header={<LanguageProvider id="gen.code" />}
          ></Column>
          <Column
            sortable
            filter
            field="name"
            header={<LanguageProvider id="gen.name" />}
          ></Column>
          <Column
            field="status"
            sortable
            header={<LanguageProvider id="gen.status" />}
            body={statusColumnTemplate}
          ></Column>
          <Column
            sortable
            filter
            dataType="numeric"
            field="estimatedLocations"
            header={<LanguageProvider id="locations.qtd" />}
          ></Column>
          <Column
            sortable
            header={<LanguageProvider id="inventory.accuracy" />}
            body={accuracyColumnTemplate}
          ></Column>
          <Column
            sortable
            body={typeColumnTemplate}
            header={<LanguageProvider id="gen.type" />}
          ></Column>
          <Column
            sortable
            filter
            dataType="numeric"
            field="duration"
            header={<LanguageProvider id="gen.duration" />}
            body={(rowData) => rowData.duration.slice(0, -8)}
          ></Column>
          <Column
            body={responsiblesColumnTemplate}
            header={<LanguageProvider id="gen.responsibles" />}
          ></Column>
          <Column body={columnActionsTemplate}></Column>
        </DataTable>
      </div>
      <InventoryCreate
        isOpen={createDialogVisible}
        onHide={() => setCreateDialogVisible(false)}
        onSave={loadInventories}
      />
      <ModalConfirmation
        isOpen={deleteConfirmationVisible}
        modalTitle={<LanguageProvider id={"inventory.delete.confirmation"} />}
        bodyMessage={
          <LanguageProvider id={"inventory.delete.confirmation.desc"} />
        }
        isDelete={true}
        onConfirm={onDelete}
        loading={deleting}
        onCancel={() => setDeleteConfirmationVisible(false)}
      ></ModalConfirmation>
    </div>
  );
}
