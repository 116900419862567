import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { defaultChartOptions } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/sequence/total-dispatch-volume.json";
import { ThemeContext } from "~/app";

export default function TotalDispatchVolume(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (useMockData) {
      setData(fakeData);
    } else {
      setData({ labels: [], datasets: [] });
    }
  }, [useMockData]);

  const options = {
    ...defaultChartOptions,
    cutout: "50%",
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          color: currentTheme.dashboardDataColor,
        },
      },
    },
  };

  return (
    <Chart
      type="doughnut"
      data={data}
      options={options}
      width="100%"
      height="100%"
    />
  );
}
