import React, { useEffect, useState } from "react";

import LoadingIndicator from "./components/dcp-loading-indicator";
import { Outlet } from "react-router-dom";
import "./scss/login-layout.scss";

const LoginLayout = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (!loading)
    return (
      <div className="login-layout">
        <div className="content">
          <div className="body">
            <Outlet />
          </div>
        </div>
      </div>
    );

  return <LoadingIndicator />;
};

export default LoginLayout;
