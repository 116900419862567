import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AreaCounts } from "./area-counts";
import "./scss/inventory-details.scss";
import { Summary } from "./summary";
import { getInventory } from "~/services/api";
import { InventoryModel } from "~/shared/interfaces";

interface InventoryContextProps {
  inventory?: InventoryModel;
  setInventory?: (inventory: InventoryModel) => void;
  refreshUnknownImageCounterBadge?: boolean;
  setRefreshUnknownImageCounterBadge?: (value: boolean) => void;
}

export const InventoryContext = createContext<InventoryContextProps>({
  inventory: undefined,
  setInventory: undefined,
  refreshUnknownImageCounterBadge: false,
  setRefreshUnknownImageCounterBadge: undefined,
});

export function InventoryDetails() {
  const { idInventory } = useParams();

  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true);

  // Data
  const [inventory, setInventory] = useState<InventoryModel>(undefined);
  const [refreshUnknownImageCounterBadge, setRefreshUnknownImageCounterBadge] =
    useState<boolean>(false);

  useEffect(() => {
    async function loadInventory() {
      try {
        setLoading(true);
        const data = await getInventory(idInventory);
        if (data) {
          setInventory(data);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    loadInventory();
  }, [idInventory]);

  const handleRefreshUnknownImages = (value: boolean) => {
    console.log(value);

    setRefreshUnknownImageCounterBadge(value);
    setTimeout(() => setRefreshUnknownImageCounterBadge(false), 0);
  };

  return (
    <div className="inventory-details">
      <InventoryContext.Provider
        value={{
          inventory,
          setInventory,
          refreshUnknownImageCounterBadge,
          setRefreshUnknownImageCounterBadge,
        }}
      >
        <Summary loading={loading} />
        <AreaCounts loading={loading} />
      </InventoryContext.Provider>
    </div>
  );
}
