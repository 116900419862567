import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { DiscardItemModel } from "~/shared/interfaces/warehouse";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function discardItem(
  model: DiscardItemModel
): Promise<DiscardItemModel> {
  try {
    const { data } = await axiosService().post(
      settings.Urls.Rest.Movements + "/discard-item",
      model,
      "Warehouse"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}
