export interface Database {
  id: number;
  name: string;
  clientId: string;
  displayPattern: string;
  idApplication: string;
  idKeyField?: number;
  field?: Field;
  isMainDatabase: boolean;
  idParentDatabase?: number;
  parentDatabase?: Database;
  databaseType: DatabaseType;
}

export interface Field {
  id: number;
  viewName: string;
  keyName: string;
  idTypeValue: number;
  typeValue: TypeValue;
  idDatabase: number;
  database?: Database;
  sequence: number;
  required: boolean;
  typeDatabaseId?: number;
  hasDescription: boolean;
  description: string;
  hasTextHelp: boolean;
  textHelp: string;
  deleted: boolean;
}

export interface UniqueSelectionDatabaseField {
  id: number;
  idField: number;
  field?: Field;
  title: string;
}

export interface ListFieldModel extends Field {
  options?: UniqueSelectionDatabaseField[];
}

export interface UniqueSelectionDropdownDatabaseField {
  id: number;
  idField: number;
  field?: Field;
  title: string;
}

export interface ListFieldModel extends Field {
  options?: UniqueSelectionDropdownDatabaseField[];
}

export interface TypeValue {
  id: number;
  name: string;
  displaySequence: number;
  icon: string;
  isReadOnly: boolean;
}

export interface DatabaseItem {
  id: number;
  idDatabase: number;
  database?: Database;
  idReferenceParentItem?: number;
}

export interface DatabaseUploadedFile {
  id: number;
  idDatabase: number;
  totalDatabaseItemsUploaded: number;
  fileUploadedTitle: string;
  createdAt: Date;
  userName: string;
}

export interface FormatedDatabaseItems {
  databaseId: number;
  values: { [key: number]: string };
}

export enum DatabaseType {
  regular,
  parent,
}
