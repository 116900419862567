import React from "react";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import { DateTimeProps } from ".";

export function DateTime(props: DateTimeProps): JSX.Element {
  return (
    <>
      <Calendar
        tooltip={props.tooltip}
        tooltipOptions={{ position: "top" }}
        style={{ width: "100%" }}
        className={classNames({
          "p-invalid": props.error !== null || props.error !== undefined,
        })}
        value={
          props.value && props.value.length > 0
            ? new Date(props.value)
            : props.value
        }
        id={props.fieldKey}
        onChange={props.onChange}
        onBlur={(e) => {
          if (props.formik) props.formik.handleBlur(e);
        }}
        placeholder={props.placeholder}
        showTime={props.showTime}
        timeOnly={props.timeOnly}
        dateFormat="dd/mm/yy"
        hourFormat="24"
      />
      {props.error ? (
        <small className="p-error">{props.error}</small>
      ) : (
        <small className="p-error">&nbsp;</small>
      )}
    </>
  );
}
