import React from "react";
import { DatabaseFieldFormatter } from ".";

export function DateTime({ data }: DatabaseFieldFormatter) {
  const date = new Date(data);
  return (
    <span>
      {data && date.toLocaleDateString()}{" "}
      {data &&
        date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
    </span>
  );
}
