import { Dropdown } from "primereact/dropdown";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function WarehouseCounterSourceConfig({
  config,
  updateValue = function () {},
}) {
  const { currentLanguage } = useLanguageContext();

  const options = [
    {
      code: "qtd-prods",
      label: LanguageProviderWithoutContext({
        id: `dropdown.option.qtd-prods`,
        currentLanguage,
      }),
    },
    {
      code: "product-return-percentage",
      label: LanguageProviderWithoutContext({
        id: "dropdown.option.product-return-percentage",
        currentLanguage,
      }),
    },
    {
      code: "qtd-items-in-stock",
      label: LanguageProviderWithoutContext({
        id: "dropdown.option.qtd-items-in-stock",
        currentLanguage,
      }),
    },
    {
      code: "qtd-items-held-by-customer",
      label: LanguageProviderWithoutContext({
        id: "dropdown.option.qtd-items-held-by-customer",
        currentLanguage,
      }),
    },
  ];

  return (
    <Dropdown
      value={config.value}
      options={options}
      optionLabel="label"
      optionValue="code"
      onChange={(e) => updateValue(e.value)}
    ></Dropdown>
  );
}
