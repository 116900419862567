import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import qs from "qs";
import {
  MoveItemModel,
  ReturnItemListModel,
  ReturnItemModel,
  TransferBranchHeaderItemsAModel,
} from "~/shared/interfaces/warehouse";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function moveItem(model: MoveItemModel): Promise<MoveItemModel> {
  try {
    const { data } = await axiosService().post(
      settings.Urls.Rest.Movements + "/move-item",
      model,
      "Warehouse"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function listReturnItems(
  sku: number | string,
  applicant: number | string
): Promise<ReturnItemListModel[]> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.Movements + "/list-return",
      "Warehouse",
      {
        params: {
          itemId: sku,
          applicatId: applicant,
        },
      }
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function returnItem(
  model: ReturnItemModel
): Promise<ReturnItemModel> {
  try {
    const { data } = await axiosService().post(
      settings.Urls.Rest.Movements + "/return-items",
      model,
      "Warehouse"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function loadWarehouseConnections(connections: string[]) {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.Connection + "/list-data",
      "Platform",
      {
        params: {
          connectionCode: connections,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      }
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the list of branches for the current product.
 * @returns A Promise that resolves to the list of branches.
 */
export async function getBranches(productId: string): Promise<any> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Movements + "/list-warehouse-branches",
      "Warehouse",
      {
        params: {
          applicationId: productId,
        },
      }
    );

    if (status) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the item list of branches for the current product.
 * @returns A Promise that resolves to the items to save from the list of branches.
 */
export async function saveTransferBranchItems(
  model: TransferBranchHeaderItemsAModel
): Promise<TransferBranchHeaderItemsAModel> {
  try {
    const { data, status } = await axiosService().post(
      settings.Urls.Rest.Movements + "/save-branch-transfer-items",
      model,
      "Warehouse"
    );

    if (status) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}
