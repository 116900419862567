import axios from "axios";
import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { useCallback, useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import {
  CHART_COLORS,
  getConfigValue,
} from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/mes/productivity-day-bar.json";
import { ThemeContext } from "~/app";

export default function LibracomProducaoBarChart(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const loadFromApi = useCallback(async () => {
    try {
      setLoading(true);
      const tonThreshold = getConfigValue(configs, "ton-threshold");
      const startDate = getConfigValue(configs, "start-date");
      const endDate = getConfigValue(configs, "end-date");
      if (!startDate || !endDate) return;

      const data = await fetchData(startDate, endDate);
      const parsedData = parseChartData(data);
      setChartData(parsedData, tonThreshold);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [configs]);

  useEffect(() => {
    if (useMockData) {
      // mock data
      setChartData(fakeData);
    } else {
      loadFromApi();
    }
  }, [useMockData, loadFromApi]);

  const options = {
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return !loading ? (
    <Chart
      type="bar"
      data={chartData}
      options={options}
      width="100%"
      height="100%"
    />
  ) : (
    <div className="chart-load">
      <Skeleton height="33%"></Skeleton>
      <Skeleton height="100%"></Skeleton>
      <Skeleton height="66%"></Skeleton>
    </div>
  );
}

function parseChartData(data, thresholdValues = [0, 0]) {
  try {
    if (!data.data) {
      return {
        labels: ["Sem dados"],
        datasets: [
          {
            type: "bar",
            label: "Sem dados",
            backgroundColor: CHART_COLORS[0],
            data: [0],
          },
        ],
      };
    }

    const datasets = [
      {
        type: "bar",
        label: "Total",
        backgroundColor: data.data.map((item) => {
          if (item.value >= thresholdValues[0]) return "rgba(34, 197, 94, 1)";
          else if (item.value >= thresholdValues[1])
            return "rgba(234, 179, 8, 1)";
          else return "rgba(255, 61, 50, 1)";
        }),
        data: data.data.map((item) => item.value),
      },
    ];

    let labels = data.data.map((item) => item.day);
    return { labels, datasets };
  } catch (error) {
    console.error(error);
  }
}

async function fetchData(startDate, endDate) {
  try {
    const { data, status } = await axios.get(
      "https://api.duettsoftware.com/dashboard/api/open-dashboard/get-toneladas-produzidas-chart",
      {
        params: {
          dataStart: startDate.setUTCHours(0, 0, 0, 0).toString(),
          dataEnd: endDate.setUTCHours(23, 59, 59, 999).toString(),
        },
      }
    );
    if (status === 200) {
      return data.data;
    } else {
      console.error("LibracomProducaoBarCharts fetch error", data);
    }
  } catch (error) {
    console.error(error);
  }
}
