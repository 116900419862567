import { Dropdown } from "primereact/dropdown";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function SequenceCounterSourceConfig({
  config,
  updateValue = function () {},
}) {
  const { currentLanguage } = useLanguageContext();

  const options = [
    {
      code: "open-orders",
      label: LanguageProviderWithoutContext({
        id: `dropdown.option.open-orders`,
        currentLanguage,
      }),
    },
    {
      code: "open-trips",
      label: LanguageProviderWithoutContext({
        id: "dropdown.option.open-trips",
        currentLanguage,
      }),
    },
  ];

  return (
    <Dropdown
      value={config.value}
      options={options}
      optionLabel="label"
      optionValue="code"
      onChange={(e) => updateValue(e.value)}
    ></Dropdown>
  );
}
