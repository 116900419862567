import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import LanguageProvider from "~/shared/components/language-provider";
import { getConfigValue } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import "./ProductionCount.scss";

export default function ProductionCount(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [title, setTitle] = useState("");
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (useMockData) {
      // mock data
      setTitle(getConfigValue(configs, "count-title") ?? "");
      setValue(3.12);
    } else {
      // get data from api
      setTitle(getConfigValue(configs, "title") ?? "");
      const apiData = {};
      setValue(0);
    }
  }, [useMockData, configs]);

  return (
    <div className="production-count">
      <div className="label">{title || ""}</div>
      <div className="value">{value.toFixed(1)}</div>
      <div className="label">
        <LanguageProvider id="unit.tons" />
      </div>
    </div>
  );
}
