import { Checkbox } from "primereact/checkbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { useCallback, useEffect, useRef, useState } from "react";
import { NewEntryEditDialog } from "~/pages/shared-modules/databases/components/new-entry-edit";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import "./scss/generic-database-header.scss";

const GenericDatabaseHeader = ({ itemId }) => {
  // Data
  const [database, setDatabase] = useState({});
  const [databaseRow, setDatabaseRow] = useState([]);
  const [databaseColumns, setDatabaseColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemId, setSelectedItemId] = useState();
  const [filterColumns, setFilterColumns] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isActiveBtnColumns, setIsActiveBtnColumns] = useState(false);
  const [originalColumnOrder, setOriginalColumnOrder] = useState([]);
  const dcpAxiosService = useDcpAxiosService();

  const handleClickBtnDetails = () => {
    setIsActive(true);
  };

  const handleClickBtnColumns = () => {
    setIsActiveBtnColumns(true);
  };

  // Dialog Controll
  const [detailsDialogVisible, setDetailsDialogVisible] = useState(false);

  // Loading Indicator
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Refs
  const columnsOpRef = useRef(null);

  const renderField = (column, row) => {
    switch (column.columnType.name) {
      case "datetime":
        return (
          <>
            <p className="receiving-itens-field">
              <Icon icon={"calendar"} color={"#667085"} size={20} />
              {formatDate(row[column.columnKey])}
            </p>
          </>
        );
        break;
      case "type-separation":
      case "tipo-recebimento":
        let iconReceivingTypeColor = receivingTypeIconColor(
          row[column.columnKey]
        );
        return (
          <>
            <div className="receiving-itens-field">
              <div className={`type-receiving type-${row[column.columnKey]}`}>
                <Icon icon="dot" size={8} color={iconReceivingTypeColor} />
                <p className={`type-receiving type-${row[column.columnKey]}`}>
                  {row[column.columnKey]}
                </p>
              </div>
            </div>
          </>
        );
        break;
      case "dispatch-status":
      case "receiving-status":
        let iconReceivingStatusColor = receivingStatusStyle(
          row[column.columnKey]
        );
        return (
          <>
            <div className="status-receiving">
              <div className="dropdown">
                <Icon
                  icon={iconReceivingStatusColor.icon}
                  color={iconReceivingStatusColor.color}
                  size={20}
                />
                <span
                  className={"status-receiving"}
                  style={{ color: iconReceivingStatusColor.color }}
                >
                  {row[column.columnKey]}
                </span>
                <Icon icon="chevron-down" color="#475467" size={20} />
              </div>
            </div>
          </>
        );
      case "receiving-storage":
        return (
          <>
            <div className="receiving-itens-field">
              <p className="item-storage">{row[column.columnKey]}</p>
            </div>
          </>
        );
        break;
      case "dispatch-dock":
      case "receiving-dock":
        return (
          <>
            <div className="receiving-itens-field">
              <p className="item-dock">{row[column.columnKey]}</p>
            </div>
          </>
        );
        break;
      default:
        return (
          <>
            <p className="receiving-itens-field">{row[column.columnKey]}</p>
          </>
        );
        break;
    }
  };

  const receivingStatusStyle = (receivingStatus) => {
    let statusProps = {};
    switch (receivingStatus) {
      // Receiving icons
      case "Pendente":
      case "Aberto":
        return (statusProps = {
          color: "#667085",
          icon: "circle",
        });
        break;
      case "Aguardando pesagem":
        return (statusProps = {
          color: "#717BBC",
          icon: "clock-snooze",
        });
        break;
      case "Aguardando descarga":
        return (statusProps = {
          color: "#6172F3",
          icon: "clock-snooze",
        });
        break;
      case "Carregado":
      case "Conferido":
        return (statusProps = {
          color: "#2E90FA",
          icon: "package-search",
        });
        break;
      case "Packing":
      case "Aguardando recebimento":
        return (statusProps = {
          color: "#EE46BC",
          icon: "clock-snooze",
        });
        break;
      case "Aguardando conferência":
        return (statusProps = {
          color: "#F0531D",
          icon: "clock-snooze",
        });
        break;
      case "Finalizado":
      case "Recebido":
        return (statusProps = {
          color: "#03CEB7",
          icon: "package-check",
        });
        break;
      case "Armazenado":
        return (statusProps = {
          color: "#028274",
          icon: "package",
        });
        break;

      default:
        break;
    }
  };

  const receivingTypeIconColor = (receivingType) => {
    switch (receivingType) {
      case "Direta":
      case "Sacaria":
        return "#4146ff";
        break;
      case "Packing":
      case "Bag":
        return "#DD2590";
        break;
      case "Granel":
        return "#FF642E";
        break;
      case "Staging":
      case "Peças":
        return "#04AA77";
        break;
      default:
        break;
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (!dateStr) return "-";
    return `${date.getDate().toString().padStart(2, 0)}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, 0)}/${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`;
  };

  const onColumnFilterChange = (e) => {
    let _filterColumns = [...filterColumns];

    if (e.checked) _filterColumns.push(e.value);
    else
      _filterColumns = _filterColumns.filter(
        (column) => column.columnKey !== e.value.columnKey
      );

    const orderedColumns = originalColumnOrder.filter((column) =>
      _filterColumns.some((item) => item.columnKey === column.columnKey)
    );

    setFilterColumns(orderedColumns);
  };

  const onDetails = () => {
    setDetailsDialogVisible(true);
  };

  const saveDataItem = async (dataItem) => {
    setLoading(true);
    setSaving(true);
    delete dataItem.itemId;

    try {
      const payload = {
        databaseId: database.id,
        columns: dataItem,
        itemId: selectedItemId,
      };

      const { status } = await dcpAxiosService.post(
        `${settings.Urls.Rest.DatabaseItem}/create-database-item`,
        payload,
        "Platform"
      );

      if (status === 200) {
        loadDatabaseData();
      }
    } catch (error) {
      console.error(error);
    }
    setDetailsDialogVisible(false);
    setSaving(false);
    loadDatabaseData(itemId);
    setIsActive(false);
  };

  const loadDatabaseData = useCallback(async (itemId) => {
    try {
      const { data: fieldsData, status: fieldsStatus } =
        await dcpAxiosService.get(
          `${settings.Urls.Rest.DatabaseItem}/single-database-list-item`,
          "Platform",
          {
            params: {
              itemId: itemId,
            },
          }
        );

      if (fieldsStatus !== 200) {
        return;
      }

      setDatabaseRow(fieldsData.data.item);
      setSelectedItemId(fieldsData.data.item.itemId);
      setSelectedItem(fieldsData.data.item.columns);
      setDatabase({
        id: fieldsData.data.idDatabase,
        name: fieldsData.data.item.databaseName,
      });

      const columns = fieldsData.data.headers;
      setDatabaseColumns(columns);
      setFilterColumns(columns);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    loadDatabaseData(itemId);
  }, [itemId, loadDatabaseData]);

  useEffect(() => {
    setOriginalColumnOrder([...databaseColumns]);
  }, [databaseColumns]);

  return (
    <div className="header-wrapper">
      <div className="icons-wrapper">
        <Tooltip target=".btn-column" position="top">
          <span>
            <LanguageProvider id="datatable.list.hide.columns" />
          </span>
        </Tooltip>
        <Tooltip target=".btn-details" position="top">
          <span>
            <LanguageProvider id="datatable.list.hide.details" />
          </span>
        </Tooltip>
        <Button
          className="p-button p-button-text btn-details"
          onClick={() => onDetails()}
        >
          <Icon
            icon={"file-05"}
            onClick={handleClickBtnDetails}
            color={isActive ? "#4146ff" : "#667085"}
          />
        </Button>
        <Button
          className="p-button p-button-text btn-column"
          onClick={(e) => {
            columnsOpRef.current.toggle(e);
            !isActiveBtnColumns && setIsActiveBtnColumns(false);
          }}
        >
          <Icon
            icon={"columns-04"}
            onClick={handleClickBtnColumns}
            color={isActiveBtnColumns ? "#4146ff" : "#667085"}
          />
        </Button>
      </div>
      <div className="content-container">
        {filterColumns &&
          !loading &&
          filterColumns.map((column, columnIndex) => {
            return (
              <>
                <div id={"first-section"} className={`info-wrapper`}>
                  <div className="info-wrapper">
                    <div className="field">
                      <p key={"column-" + columnIndex} className="label">
                        {column.columnName}
                      </p>
                      <p key={"row-0"} className="text">
                        {renderField(column, databaseRow.columns)}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        {loading && (
          <>
            <div className="input-wrapper skeleton">
              <Skeleton width="10rem" className="label" />
              <Skeleton width="6rem" className="label" />
              <Skeleton height="3rem" className="" />
            </div>
            <div className="input-wrapper skeleton">
              <Skeleton width="10rem" className="label" />
              <Skeleton width="6rem" className="label" />
              <Skeleton height="3rem" className="" />
            </div>
            <div className="input-wrapper skeleton">
              <Skeleton width="10rem" className="label" />
              <Skeleton width="6rem" className="label" />
              <Skeleton height="3rem" className="" />
            </div>
          </>
        )}
      </div>

      <NewEntryEditDialog
        visible={detailsDialogVisible}
        onHide={() => {
          setDetailsDialogVisible(false);
          setIsActive(false);
        }}
        onFinish={saveDataItem}
        database={database}
        loading={saving}
        selectedItem={selectedItem}
      ></NewEntryEditDialog>

      <OverlayPanel ref={columnsOpRef} className="database-list-columns">
        <div className="popup-wrapper">
          <div className="popup-header">
            <span className="popup-title">
              <LanguageProvider id="gen.columns" />
            </span>
          </div>
          <div className="popup-column-list-wrapper">
            {databaseColumns.map((column) => {
              return (
                <div
                  key={`column-filter-${column.columnKey}`}
                  className="popup-column-list"
                >
                  <Checkbox
                    className="checkbox-icon"
                    inputId={column.columnKey}
                    name="columnFilter"
                    value={column}
                    onChange={onColumnFilterChange}
                    checked={filterColumns.some(
                      (item) => item.columnKey === column.columnKey
                    )}
                  />
                  <label className="column-list-label">
                    {column.columnName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default GenericDatabaseHeader;
