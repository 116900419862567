import "../scss/create-group.scss";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useRef, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import DeleteConfirmationOverlay from "~/shared/components/confirmation-popup";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import { InputContainer } from "~/shared/components/dcp-form";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "~shared/components/language-provider";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { RackModel } from "~/shared/components/rack-model";
import { Tooltip } from "primereact/tooltip";
import rackHorizontal from "~/theme/media/assets/rack-horizontal-base.jpg";
import rackVertical from "~/theme/media/assets/rack-vertical-base.jpg";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { deleteAreaGroup } from "~/services/api";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { useToastContext } from "~/context/ToastContext";

export const CreateGroup = ({
  area,
  group,
  onCancel,
  onCreate,
  onDelete,
  onDuplicate,
}) => {
  // Hooks
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();

  // Loading indicators
  const [loading, setLoading] = useState();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [duplicating, setDuplicating] = useState(false);

  // Visibility
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [duplicateConfirmationVisible, setDuplicateConfirmationVisible] =
    useState(false);

  // Data
  const [searchValue, setSearchValue] = useState();
  const [racks, setRacks] = useState([]);
  const [filteredRack, setFilteredRacks] = useState([]);
  const [groupRackTypes, setGroupRackTypes] = useState([]);
  const [areaGroupRacks, setAreaGroupRacks] = useState([]);
  const [selectedGroupRack, setSelectedGroupRack] = useState();
  const [groupDimentions, setGroupDimentions] = useState({ x: 0, y: 0 });
  const [pendingRackDelete, setPendingRackDelete] = useState([]);

  const op = useRef(null);

  const loadRacks = async () => {
    setLoading(true);
    const { data, status } = await dcpAxiosService.get(
      `${settings.Urls.Rest.Layout}/list-racks`,
      "Platform"
    );

    if (status === 200) {
      setRacks(data.data);
      setFilteredRacks(data.data);

      // if is editing
      if (group) {
        const _rackTypes = group.groupRacks.map((gr) => {
          const type = data.data.find((r) => r.id === gr.rackType.id);
          if (type) {
            return {
              ...type,
              shelves: type.shelves.toReversed(),
              _groupRackId: gr.rack.id,
            };
          }
        });

        formik.setValues(group.areaGroup);
        setGroupRackTypes(_rackTypes);
        setAreaGroupRacks(group.groupRacks);
      }
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      idArea: area.id,
      roadName: "",
      name: "",
      orientation: "horizontal",
      horizontalNaming: "1n",
      verticalNaming: "az",
      horizontalNamingStart: "",
      verticalNamingStart: "",
      customNaming: false,
    },
  });

  const onSaveGroup = async () => {
    try {
      setSaving(true);
      await deleteRackGroups();
      const { data, status } = await dcpAxiosService.post(
        `${settings.Urls.Rest.AreaGroup}/create`,
        { areaGroup: { ...formik.values }, groupRacks: areaGroupRacks },
        "Platform"
      );
      if (status === 200) {
        onCancel();
      }
      setSaving(false);
      onCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteGroup = async () => {
    try {
      setDeleting(true);
      const id = formik.values.id;
      if (!id || id === 0) return;
      const group = await deleteAreaGroup(id);
      onDelete(group);
    } catch (e) {
      console.error(e);
    }
    setDeleting(false);
  };

  const onDuplicateGroup = async () => {
    try {
      setDuplicating(true);
      const id = formik.values.id;
      if (!id || id === 0) return;
      const statusDuplicate = await duplicateGroup(id);
      if (statusDuplicate) onDuplicate(group);
    } catch (e) {
      console.error(e);
    }
    setDuplicating(false);
  };

  const duplicateGroup = async () => {
    try {
      try {
        await deleteRackGroups();
        const { data, status } = await dcpAxiosService.post(
          `${settings.Urls.Rest.AreaGroup}/duplicate?areaGroupId=` +
            formik.values.id,
          null,
          "Platform"
        );

        if (status) {
          showToast({
            severity: "success",
            message: LanguageProvider({
              id: "area.map.duplicate.group.duplicate.success",
            }),
          });
          setDuplicateConfirmationVisible(false);
          return true;
        }
      } catch (error) {
        console.error(error);
        showToast({
          severity: "error",
          message: <LanguageProvider id={"gen.error"} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isFormFieldInvalid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const namingOrientationTemplate = (e) => {
    if (e) {
      switch (e) {
        case "az":
          return "A-Z";
        case "za":
          return "Z-A";
        case "1n":
          return "1-N";
        case "n1":
          return "N-1";
        default:
          return "";
      }
    }
    return "";
  };

  const filterRackList = (search) => {
    setSearchValue(search);
    if (search.length > 0) {
      const _racks = racks.filter((rack) =>
        rack.name.toUpperCase().includes(search.toUpperCase())
      );
      setFilteredRacks(_racks);
    } else {
      setFilteredRacks(racks);
    }
  };

  const removeRack = async () => {
    const groupRackId = areaGroupRacks[selectedGroupRack].rack.id;

    if (groupRackId !== 0)
      setPendingRackDelete([...pendingRackDelete, groupRackId]);

    const _rackTypes = [...groupRackTypes];
    _rackTypes.splice(selectedGroupRack, 1);
    setGroupRackTypes([..._rackTypes]);
    op.current.hide();
  };

  const deleteRackGroups = async () => {
    try {
      for (let i = 0; i < pendingRackDelete.length; i++) {
        const { response } = await dcpAxiosService.delete(
          settings.Urls.Rest.AreaGroup + "/delete-group-rack",
          "Platform",
          { params: { groupRackId: pendingRackDelete[i] } }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onAddRackToGroup = async (rack) => {
    try {
      let reversedShelvesRack = { ...rack };
      reversedShelvesRack.shelves = reversedShelvesRack.shelves.toReversed();
      reversedShelvesRack._groupRackId = 0;
      setGroupRackTypes([...groupRackTypes, reversedShelvesRack]);
    } catch (error) {
      console.error(error);
    }
  };

  const updateGroupDimentions = (_groupRacks) => {
    let x = 0;
    let y = 0;

    if (_groupRacks.length > 0) {
      for (const gr of _groupRacks) {
        x += parseInt(gr.rack.rack.width);
        if (gr.rack.rack.height > y) y = gr.rack.rack.height;
      }
    }

    setGroupDimentions({ x, y });
  };

  /**
   * Convert the racks array to fit the formik groupRacks prop.
   * @param {*} racks racks array
   */
  const formatLocationNaming = (racks) => {
    const formatLocations = (rack) => {
      let locations = [];
      const shelvesQtd = rack.shelves.length;
      for (let i = 0; i < shelvesQtd; i++) {
        let shelf = rack.shelves[i];

        if (!shelf.locations) continue;
        for (let j = 0; j < shelf.locations.length; j++) {
          const _location = shelf.locations[j];

          locations.push({
            shelfLevel: i + 1,
            sequenceLocation: j + 1,
            name: _location.name,
            color: rack.color,
            height: shelf.height,
          });
        }
      }

      return locations;
    };

    let _groupRacks = [];
    racks.forEach((rack, index) => {
      const _groupRack = {
        rack: {
          id: rack._groupRackId,
          sequenceRack: index + 1,
          idRack: rack.id,
          idAreaGroup: formik.values.id,
          rack,
        },
        rackLocations: formatLocations(rack),
      };

      _groupRacks.push({ ..._groupRack });
    });

    updateGroupDimentions(_groupRacks);
    setAreaGroupRacks(_groupRacks);
  };

  function setLocationNaming() {
    let racks = [];

    // Racks
    for (let rackIndex = 0; rackIndex < groupRackTypes.length; rackIndex++) {
      let rack = groupRackTypes[rackIndex];
      let shelves = [];

      // Shelves
      for (let shelfIndex = 0; shelfIndex < rack.shelves.length; shelfIndex++) {
        let shelf = { ...rack.shelves[shelfIndex] };
        shelf["locations"] = [];

        for (
          let locationIndex = 0;
          locationIndex < shelf.locationsAmount;
          locationIndex++
        ) {
          const lastRackBiggestVIndex =
            rackIndex > 0
              ? getLastRackBiggestHIndex(racks[rackIndex - 1]) + 1
              : 0;

          let hIndex = locationIndex + lastRackBiggestVIndex;
          let vIndex = rack.shelves.length - shelfIndex - 1;

          // Add new location to shelves
          shelf.locations.push({
            name: findLocationCustomName(rackIndex, vIndex, locationIndex),
            vIndex,
            hIndex,
            vValue: vIndex.toString(),
            hValue: hIndex.toString(),
          });
        }

        shelves.push(shelf);
      }

      rack.shelves = setLocationNames(shelves);
      racks.push(rack);
    }

    formatLocationNaming(racks);
  }

  function findLocationCustomName(rackIndex, vIndex, locationIndex) {
    if (!formik.values.customNaming) return undefined;

    for (let groupIndex = 0; groupIndex < areaGroupRacks.length; groupIndex++) {
      const groupRack = areaGroupRacks[groupIndex];

      let sequenceLocation = locationIndex + 1;
      let shelfLevel = groupRack.rack.rack.qtyShelfs - vIndex;

      if (rackIndex === groupIndex) {
        for (const location of groupRack.rackLocations) {
          if (
            location.shelfLevel === shelfLevel &&
            location.sequenceLocation === sequenceLocation
          ) {
            return location.name;
          }
        }
      }
    }

    return undefined;
  }

  // Get the biggest column value from the last rack to continue the value in
  // next, so all locations on the rack start with the same horizontal (column) value
  function getLastRackBiggestHIndex(rack) {
    let biggestIndex = 0;

    for (const shelf of rack.shelves) {
      for (const location of shelf.locations) {
        if (location.hIndex > biggestIndex) biggestIndex = location.hIndex;
      }
    }
    return biggestIndex;
  }

  // Return an array of shelves containing the locations with the updated
  // name according to the naming rules.
  function setLocationNames(shelves) {
    const verticalRule = formik.values.verticalNaming;
    const vStartValue = formik.values.verticalNamingStart;

    const horizontalRule = formik.values.horizontalNaming;
    const hStartValue = formik.values.horizontalNamingStart;

    for (const shelf of shelves) {
      for (const location of shelf.locations) {
        let vValue = "";
        switch (verticalRule) {
          case "az":
            vValue = azNaming(vStartValue, location.vIndex);
            break;
          case "za":
            vValue = zaNaming(vStartValue, location.vIndex);
            break;
          case "1n":
            vValue = ascNaming(vStartValue, location.vIndex);
            break;
          case "n1":
            vValue = descNaming(vStartValue, location.vIndex);
            break;
          default:
            break;
        }

        let hValue = "";
        switch (horizontalRule) {
          case "az":
            hValue = azNaming(hStartValue, location.hIndex);
            break;
          case "za":
            hValue = zaNaming(hStartValue, location.hIndex);
            break;
          case "1n":
            hValue = ascNaming(hStartValue, location.hIndex);
            break;
          case "n1":
            hValue = descNaming(hStartValue, location.hIndex);
            break;
          default:
            break;
        }

        const roadName = formik.values.roadName;
        location.vValue = vValue;
        location.hValue = hValue;
        if (location.name === undefined)
          location.name = `${roadName}-${location.hValue}-${location.vValue}`;
      }
    }

    return shelves;
  }

  function azNaming(startValue, index) {
    let charCode = startValue ? startValue.toUpperCase().charCodeAt(0) : 65;
    charCode = charCode + index;
    return String.fromCharCode(charCode);
  }

  function zaNaming(startValue, index) {
    let charCode = startValue ? startValue.toUpperCase().charCodeAt(0) : 90;
    charCode = charCode - index;
    return String.fromCharCode(charCode);
  }

  function ascNaming(startValue, index) {
    startValue = startValue ? parseInt(startValue) : 1;
    const value = isNaN(startValue) ? 1 : startValue;
    return value + index;
  }

  function descNaming(startValue, index) {
    const parsedValue = parseInt(startValue);
    const value = isNaN(parsedValue) ? 999 : parsedValue;
    return value - index;
  }

  const setLocationNaming_old = () => {
    const groupName = formik.values.name;
    const verticalRule = formik.values.verticalNaming;
    const horizontalRule = formik.values.horizontalNaming;
    const horizontalStartingCharacter = formik.values.horizontalNamingStart;
    const verticalStartingCharacter = formik.values.verticalNamingStart;

    let racks = [...groupRackTypes];

    let startCharacterHorizontal;
    let startCharacterVertical;
    let newNamedRacks = [];

    racks.map((rack, rackIndex) => {
      let newRack = {
        ...rack,
        shelves: [],
      };
      let rackName = "";

      // Calculate horizontal rule (columns)
      switch (horizontalRule) {
        case "az":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal =
              horizontalStartingCharacter.charCodeAt(0);
          } else {
            startCharacterHorizontal = 65;
          }
          rackName = String.fromCharCode(startCharacterHorizontal + rackIndex);
          break;
        case "za":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal =
              horizontalStartingCharacter.charCodeAt(0);
          } else {
            startCharacterHorizontal = 90;
          }
          rackName = String.fromCharCode(90 - rackIndex);
          break;
        case "1n":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal = parseInt(horizontalStartingCharacter);
            rackName = `${startCharacterHorizontal + rackIndex}`;
          } else {
            rackName = `${rackIndex + 1}`;
          }
          break;
        case "n1":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal = parseInt(horizontalStartingCharacter);
            rackName = `${startCharacterHorizontal - rackIndex}`;
          } else {
            rackName = `${racks.length - rackIndex}`;
          }
          break;
      }

      // calculate vertical rule (shelves)
      rack.shelves.map((shelf, shelfIndex) => {
        let newShelf = {
          ...shelf,
          locations: [],
        };
        let shelfName = "";
        switch (verticalRule) {
          case "az":
            if (verticalStartingCharacter) {
              startCharacterVertical =
                verticalStartingCharacter.charCodeAt(0) - 1;
            } else {
              startCharacterVertical = 64;
            }
            let azShelfInitialCharacterASC =
              startCharacterVertical + rack.shelves.length;
            shelfName = String.fromCharCode(
              azShelfInitialCharacterASC - shelfIndex
            );
            break;
          case "za":
            if (verticalStartingCharacter) {
              startCharacterVertical =
                verticalStartingCharacter.charCodeAt(0) + 1;
            } else {
              startCharacterVertical = 91;
            }
            let zaShelfInitialCharacterASC =
              startCharacterVertical - rack.shelves.length;
            shelfName = String.fromCharCode(
              zaShelfInitialCharacterASC + shelfIndex
            );
            break;
          case "1n":
            if (verticalStartingCharacter) {
              startCharacterVertical = parseInt(verticalStartingCharacter);
              shelfName = `${
                startCharacterVertical - shelfIndex + rack.shelves.length - 1
              }`;
            } else {
              let nShelfInitialNumber = rack.shelves.length;
              shelfName = `${nShelfInitialNumber - shelfIndex}`;
            }
            break;
          case "n1":
            if (verticalStartingCharacter) {
              startCharacterVertical = parseInt(verticalStartingCharacter);
              shelfName = `${
                startCharacterVertical + shelfIndex - rack.shelves.length + 1
              }`;
            } else {
              shelfName = `${shelfIndex + 1}`;
            }
            break;
        }

        for (let index = 0; index < shelf.locationsAmount; index++) {
          const locationName = `${groupName}-${rackName}-${shelfName}${
            index + 1
          }`;

          newShelf.locations[index] = {
            name: locationName,
          };

          if (formik.values.customNaming) {
            areaGroupRacks.forEach((groupRack, groupIndex) => {
              if (rackIndex == groupIndex) {
                groupRack.rackLocations.forEach((location) => {
                  if (
                    location.shelfLevel == shelfIndex + 1 &&
                    location.sequenceLocation == index + 1
                  ) {
                    newShelf.locations[index] = {
                      name: location.name,
                    };
                  }
                });
              }
            });
          }
        }
        newRack.shelves.push(newShelf);
      });
      newNamedRacks.push(newRack);
    });

    formatLocationNaming(newNamedRacks);
  };

  const updateLocationName = (e, sequenceRack, location) => {
    let _areaGroupsRacks = [...areaGroupRacks];

    _areaGroupsRacks.forEach((_areaGroup, groupIndex) => {
      if (groupIndex + 1 == sequenceRack) {
        _areaGroup.rackLocations.forEach((_location) => {
          if (
            _location.shelfLevel === location.shelfLevel &&
            _location.sequenceLocation == location.sequenceLocation
          ) {
            _location.name = e;
          }
        });
      }
    });

    setAreaGroupRacks(_areaGroupsRacks);
  };

  const reorder = (result) => {
    const { destination, source } = result;

    if (destination.index == source.index) return;

    // update rack types
    const _groupRackTypes = [...groupRackTypes];
    const removedRackType = _groupRackTypes.splice(source.index, 1)[0];
    _groupRackTypes.splice(destination.index, 0, removedRackType);

    // update area groups
    const _areaGroupRacks = [...areaGroupRacks];
    const removedGroupRack = _areaGroupRacks.splice(source.index, 1)[0];
    _areaGroupRacks.splice(destination.index, 0, removedGroupRack);

    // update rack sequence
    _areaGroupRacks.forEach((groupRack, index) => {
      groupRack.rack.sequenceRack = index + 1;
    });

    setAreaGroupRacks(_areaGroupRacks);
    setGroupRackTypes(_groupRackTypes);
  };

  useEffect(() => {
    loadRacks();
  }, [group]);

  useEffect(() => {
    setLocationNaming();
  }, [formik.values, groupRackTypes]);

  return (
    <>
      <div className="create-group">
        <div className="left-column">
          <div className="header">
            <p className="title">
              <LanguageProvider id={"gen.rack.types"} />
            </p>
            <p className="description">
              <LanguageProvider id={"area.map.new.group.description"} />
            </p>
          </div>
          <span className="p-input-icon-left search-input">
            <div className="icon-search">
              <Icon icon={"search-md"} color="#98A2B3" size={20} />
            </div>
            <InputText
              value={searchValue}
              onChange={(e) => {
                filterRackList(e.target.value);
              }}
              placeholder={LanguageProvider({
                id: "search.field.placeholder",
              })}
            />
          </span>
          <div className="rack-list">
            {loading ? (
              <LoadingIndicator size="small" color="var(--primary)" />
            ) : (
              <ul>
                {filteredRack.map((rack) => {
                  return (
                    <li
                      className={`rack rack-${rack.id}`}
                      key={`rack-${rack.id}`}
                      onClick={() => onAddRackToGroup(rack)}
                    >
                      <span className="rack-name" title={rack.name}>
                        {rack.name}
                      </span>
                      <span className="icon pi pi-plus"></span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="right-column">
          <div className="form-container">
            <div className="config">
              <div className="title">
                <LanguageProvider id="area.map.rack.config" />
              </div>
              <div className="dcp-form">
                <div className="form-row">
                  <InputContainer
                    label={<LanguageProvider id="area.map.group.name" />}
                  >
                    <InputText
                      placeholder={LanguageProvider({
                        id: "area.map.rack.name.placeholder",
                      })}
                      value={formik.values.name}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                    />
                  </InputContainer>
                  <InputContainer label={"Rua"}>
                    <InputText
                      placeholder={"Nome da rua"}
                      value={formik.values.roadName}
                      onChange={(e) => {
                        formik.setFieldValue("roadName", e.target.value);
                      }}
                    />
                  </InputContainer>
                </div>
              </div>
            </div>
            <div className="config">
              <div className="title">
                <LanguageProvider id="area.map.location.config" />
              </div>
              <div className="dcp-form">
                <div className="form-row">
                  <InputContainer
                    label={
                      <LanguageProvider id="area.map.rack.vertical.naming" />
                    }
                  >
                    <div className="p-inputgroup">
                      <Dropdown
                        value={formik.values.verticalNaming}
                        options={["az", "za", "1n", "n1"]}
                        itemTemplate={namingOrientationTemplate}
                        valueTemplate={namingOrientationTemplate}
                        onChange={(e) => {
                          formik.setFieldValue("verticalNaming", e.value);
                        }}
                      ></Dropdown>
                      <InputText
                        placeholder={LanguageProvider({
                          id: "area.map.rack.vertical.placeholder",
                        })}
                        value={formik.values.verticalNamingStart}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "verticalNamingStart",
                            e.target.value
                          )
                        }
                      />{" "}
                      <Tooltip
                        target=".p-inputgroup-addon.vertical"
                        position="right"
                      >
                        <img
                          alt="logo"
                          src={rackVertical}
                          style={{ width: "300px", height: "167" }}
                        />
                        <div className="flex align-items-center">
                          <span
                            style={{ minWidth: "226px", fontWeight: "600" }}
                          >
                            Classificar da base esquerda vertical
                          </span>
                          <p style={{ width: "300px", textAlign: "left" }}>
                            Classifique as locações verticais iniciando na base,
                            no sentido base para topo. Este campo é
                            alfanumérico. Utilize Z-A para ordem decrescente das
                            letras.
                          </p>
                        </div>
                      </Tooltip>
                      <span className="p-inputgroup-addon vertical">
                        <i className="pi pi-question-circle"></i>
                      </span>
                    </div>
                  </InputContainer>
                  <InputContainer
                    label={
                      <LanguageProvider id="area.map.rack.horizontal.naming" />
                    }
                  >
                    <div className="p-inputgroup">
                      <Dropdown
                        options={["az", "za", "1n", "n1"]}
                        value={formik.values.horizontalNaming}
                        itemTemplate={namingOrientationTemplate}
                        valueTemplate={namingOrientationTemplate}
                        onChange={(e) => {
                          formik.setFieldValue("horizontalNaming", e.value);
                        }}
                      ></Dropdown>
                      <InputText
                        placeholder={LanguageProvider({
                          id: "area.map.rack.horizontal.placeholder",
                        })}
                        value={formik.values.horizontalNamingStart}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "horizontalNamingStart",
                            e.target.value
                          )
                        }
                      />{" "}
                      <Tooltip
                        target=".p-inputgroup-addon.horizontal"
                        position="right"
                      >
                        <img
                          alt="logo"
                          src={rackHorizontal}
                          style={{ width: "300px", height: "167" }}
                        />
                        <div className="flex align-items-center">
                          <span style={{ width: "226px", fontWeight: "600" }}>
                            Classificar da base esquerda horizontal
                          </span>
                          <p style={{ width: "300px", textAlign: "left" }}>
                            Classifique as locações horizontais iniciando na
                            base, no sentido esquerda para direita. Este campo é
                            alfanumérico. Utilize N-1 para ordem decrescente dos
                            números.
                          </p>
                        </div>
                      </Tooltip>
                      <span className="p-inputgroup-addon horizontal">
                        <i className="pi pi-question-circle"></i>
                      </span>
                    </div>
                  </InputContainer>

                  <InputContainer
                    label={
                      <LanguageProvider id="area.map.rack.manual.config" />
                    }
                  >
                    <InputSwitch
                      checked={formik.values.customNaming}
                      className="manual-config-switch"
                      onChange={(e) =>
                        formik.setFieldValue("customNaming", e.value)
                      }
                    />
                  </InputContainer>
                </div>
              </div>
            </div>
          </div>
          <div className="rack-container">
            <div className="header">
              <span>
                <LanguageProvider id={"rack.front.side"} />
              </span>
              <span>
                <LanguageProvider id={"rack.size"} />:{" "}
                {`X: ${groupDimentions.x}cm Y: ${groupDimentions.y}cm`}
              </span>
            </div>
            <div className="rack-render-area">
              <div className="generated-rack">
                <DragDropContext onDragEnd={reorder}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                        {...provided.droppableProps}
                      >
                        {areaGroupRacks.length > 0 &&
                          areaGroupRacks.map((groupRack, index) => {
                            return (
                              <Draggable
                                draggableId={`draggable-${index}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="wrapper"
                                    key={"rack-model-" + index}
                                  >
                                    <div
                                      className="header"
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="name">
                                        <div
                                          className="color"
                                          style={{
                                            background:
                                              groupRack.rack.rack.color,
                                          }}
                                        />
                                        <span
                                          className="rack-name"
                                          style={{
                                            color: groupRack.rack.rack.color,
                                          }}
                                        >
                                          {groupRack.rack.rack.name}
                                        </span>
                                      </div>
                                      <div
                                        onClick={(e) => {
                                          op.current.toggle(e);
                                          setSelectedGroupRack(index);
                                        }}
                                      >
                                        <Icon
                                          icon="trash-02"
                                          className="icon"
                                        />
                                      </div>
                                    </div>
                                    <RackModel
                                      groupRack={groupRack}
                                      onUpdateName={updateLocationName}
                                      manualConfig={formik.values.customNaming}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
          <div className="buttons-bar">
            <div className="button-container">
              <Button
                className={"group-delete-btn"}
                severity={"danger"}
                disabled={formik.values.id === 0}
                onClick={() => setDeleteConfirmationVisible(true)}
                loading={deleting}
              >
                <LanguageProvider id={"gen.exclude.button"} />
              </Button>
              <Button
                label={<LanguageProvider id="area.map.duplicate" />}
                className="p-button-secondary"
                appearance="subtle"
                onClick={() => setDuplicateConfirmationVisible(true)}
              ></Button>
              <Button onClick={onSaveGroup} loading={saving}>
                <LanguageProvider id="gen.save.button" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationOverlay
        refProp={op}
        onConfirm={() => {
          removeRack();
        }}
        onCancel={(e) => op.current.hide(e)}
      />
      <ModalConfirmation
        isOpen={deleteConfirmationVisible}
        modalTitle={<LanguageProvider id="area.map.delete.rack.group" />}
        bodyMessage={<LanguageProvider id="area.map.delete.rack.group.msg" />}
        onConfirm={onDeleteGroup}
        onCancel={() => setDeleteConfirmationVisible(false)}
        loading={deleting}
        isDelete={true}
      />
      <ModalConfirmation
        isOpen={duplicateConfirmationVisible}
        modalTitle={<LanguageProvider id="area.map.duplicate.rack.group" />}
        bodyMessage={
          <LanguageProvider id="area.map.duplicate.rack.group.msg" />
        }
        onConfirm={onDuplicateGroup}
        onCancel={() => setDuplicateConfirmationVisible(false)}
        loading={duplicating}
        isDelete={false}
      />
    </>
  );
};
