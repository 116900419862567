import React from "react";
import { useEffect } from "react";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";

const PrintedData = () => {
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(settings.DatabaseProcessType.Print_DadosImpressao);
  }, [loadDatabaseProductProcess]);

  return (
    databaseProductProcessData && (
      <DatabaseList
        id={databaseProductProcessData.idDatabase}
        hasDetails={databaseProductProcessData.hasDetails}
        uriDetails={databaseProductProcessData.uriDetails}
        showIcon={false}
      />
    )
  );
};

export default PrintedData;
