import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import "./scss/dcp-home-product.scss";
import LanguageProvider from "./language-provider";

const HomeProduct = ({
  title,
  bodyMessage,
  image,
  isVisible,
  showButton = false,
}) => {
  return (
    <div className="home-product">
      <div className="img-empty">
        <img
          src={image}
          alt="Image empty product"
          className="image-background"
        />
      </div>
      <div className="body-wrapper">
        <span className="title">
          <LanguageProvider id={"gen.empty.card.message.one"} /> {title}{" "}
          <LanguageProvider id={"gen.empty.card.message.created"} />
        </span>
        <div className="home-product-content">
          <p className="home-product-body">{bodyMessage}</p>
          {showButton && (
            <div className="button-wrapper">
              <Button
                appearance="primary"
                className="p-button product-btn"
                icon={<Icon icon={"plus"} color="#fff" />}
                iconPos="left"
                onClick={() => {
                  isVisible(true);
                }}
              >
                Criar {title}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeProduct;
