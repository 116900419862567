import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useToastContext } from "~/context/ToastContext";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import "../scss/user-new-password-dialog.scss";

const UserNewPasswordDialog = ({
  visible,
  newClientAction,
  onClose,
  newPassword,
  userName,
}) => {
  const { showToast } = useToastContext();
  let successMessage = "";

  if (newClientAction) {
    successMessage = LanguageProvider({
      id: "user.create.success",
    });
  } else {
    successMessage = LanguageProvider({
      id: "user.update.success",
    });
  }

  const CopyAndClose = () => {
    navigator.clipboard.writeText(newPassword);

    showToast({
      severity: "success",
      message: successMessage,
    });
    onClose();
  };

  return (
    <Dialog
      className="user-new-password-dialog"
      position="center"
      closable={false}
      visible={visible}
      onHide={CopyAndClose}
      header={() => {
        return (
          <>
            <div className="user-new-password-dialog-header-icon-container">
              <Icon
                className={"user-new-password-dialog-icon"}
                icon={"alert-triangle"}
                color={"#F0AA23"}
                size={"20px"}
              />
            </div>
          </>
        );
      }}
      footer={() => {
        return (
          <>
            <Button
              label={<LanguageProvider id={"gen.copy.and.close"} />}
              onClick={CopyAndClose}
              size="medium"
            />
          </>
        );
      }}
    >
      <>
        <h1 className="user-new-password-dialog-title">
          <LanguageProvider id={"user.new.password.dialog.title"} />
        </h1>
        <p className="user-new-password-dialog-message">
          <LanguageProvider id={"user.new.password.message.01"} />{" "}
          <span style={{ fontWeight: 700 }}>{userName}</span>
          <LanguageProvider id={"user.new.password.message.02"} />
        </p>

        <div className="p-inputgroup">
          <InputText
            value={newPassword}
            readOnly
            onClick={() => navigator.clipboard.writeText(newPassword)}
          />
          <span className="p-inputgroup-addon">
            <Icon
              icon={"copy-03"}
              size={"20px"}
              color="#4146FF"
              background-color="#667085"
            />
          </span>
        </div>
      </>
    </Dialog>
  );
};

export default UserNewPasswordDialog;
