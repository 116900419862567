import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LanguageProvider from "~/shared/components/language-provider";
import "./scss/custom-toast.scss";

const showToast = (options) => {
  const { severity, message } = options;

  switch (severity) {
    case "success":
      toast.success(
        <div className="toast-icon-wrapper">
          <span className="toast-icon">
            <svg
              width="34"
              height="34"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0.9375C8.59184 0.9375 0.9375 8.59184 0.9375 18C0.9375 27.4082 8.59184 35.0625 18 35.0625C27.4082 35.0625 35.0625 27.4082 35.0625 18C35.0625 8.59184 27.4082 0.9375 18 0.9375ZM26.8799 12.2816L15.8549 25.4066V25.4066C15.6102 25.6981 15.251 25.869 14.8705 25.875H14.8484V25.875C14.4763 25.8749 14.1218 25.7168 13.873 25.4402L9.14801 20.1902V20.1902C8.65074 19.6628 8.67519 18.8321 9.20261 18.3349C9.73002 17.8376 10.5607 17.8621 11.058 18.3895C11.0719 18.4043 11.0855 18.4194 11.0987 18.4348L14.8139 22.5626L24.8701 10.5934V10.5934C25.3443 10.0452 26.1732 9.98513 26.7214 10.4593C27.2585 10.9239 27.3287 11.7313 26.8799 12.2816L26.8799 12.2816Z"
                fill="#03CEB7"
              />
            </svg>
          </span>
          <div className="toast-message-wrapper">
            <span className="toast-title">
              <LanguageProvider id={"gen.success"} />
            </span>
            <span className="toast-description">{options.message ?? ""}</span>
          </div>
        </div>,
        {
          autoClose: 3000,
        }
      );
      break;
    case "info":
      toast.info(
        <div className="toast-icon-wrapper">
          <span className="toast-icon">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 0.59375C7.95359 0.59375 0.59375 7.95359 0.59375 17C0.59375 26.0464 7.95359 33.4062 17 33.4062C26.0464 33.4062 33.4062 26.0464 33.4062 17C33.4062 7.95359 26.0464 0.59375 17 0.59375ZM17 7.32031V7.32031C18.1779 7.32031 19.1328 8.27521 19.1328 9.45312C19.1328 10.631 18.1779 11.5859 17 11.5859C15.8221 11.5859 14.8672 10.631 14.8672 9.45312V9.45312C14.8672 8.27521 15.8221 7.32031 17 7.32031C17 7.32031 17 7.32031 17 7.32031V7.32031ZM20.9375 25.8594H13.7188V25.8594C12.9939 25.8594 12.4062 25.2717 12.4062 24.5469C12.4063 23.822 12.9939 23.2344 13.7188 23.2344H16.0156V16.0156H14.7031V16.0156C13.9783 16.0156 13.3906 15.428 13.3906 14.7031C13.3906 13.9783 13.9783 13.3906 14.7031 13.3906H17.3281V13.3906C18.053 13.3906 18.6406 13.9783 18.6406 14.7031V23.2344H20.9375V23.2344C21.6624 23.2344 22.25 23.822 22.25 24.5469C22.25 25.2717 21.6624 25.8594 20.9375 25.8594V25.8594Z"
                fill="#6172F3"
              />
            </svg>
          </span>
          <div className="toast-message-wrapper">
            <span className="toast-title">
              <LanguageProvider id={"gen.info"} />
            </span>
            <span className="toast-description">{options.message}</span>
          </div>
        </div>,
        {
          autoClose: 3000,
        }
      );
      break;
    case "warn":
      toast.warn(
        <div className="toast-icon-wrapper">
          <span className="toast-icon">
            <svg
              width="35"
              height="32"
              viewBox="0 0 35 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.838 27.737L19.8574 1.77414C18.8664 -0.0666407 16.2267 -0.0666407 15.2349 1.77414L1.25514 27.737H1.25514C0.567867 29.0135 1.04552 30.6055 2.32201 31.2927C2.70336 31.4981 3.12958 31.6058 3.56268 31.6064H31.5263V31.6064C32.9761 31.6066 34.1515 30.4315 34.1517 28.9818C34.1517 28.5473 34.0439 28.1196 33.838 27.737V27.737ZM17.5465 27.5869V27.5869C16.6405 27.5869 15.9059 26.8524 15.9059 25.9463C15.9059 25.0402 16.6405 24.3057 17.5465 24.3057C18.4526 24.3057 19.1872 25.0402 19.1872 25.9463V25.9463V25.9463C19.1872 26.8524 18.4526 27.5869 17.5465 27.5869V27.5869ZM19.3283 11.0863L18.8574 21.0941C18.8574 21.819 18.2698 22.4066 17.5449 22.4066C16.82 22.4066 16.2324 21.819 16.2324 21.0941L15.7615 11.0904V11.0904C15.718 10.1069 16.48 9.27434 17.4635 9.2308C17.4841 9.22989 17.5046 9.22934 17.5252 9.22914H17.5425V9.22914C18.5274 9.22863 19.3262 10.0267 19.3267 11.0116C19.3267 11.0381 19.3262 11.0647 19.325 11.0913L19.3283 11.0863Z"
                fill="#DD9303"
              />
            </svg>
          </span>
          <div className="toast-message-wrapper">
            <span className="toast-title">
              <LanguageProvider id={"gen.warning"} />
            </span>
            <span className="toast-description">{options.message}</span>
          </div>
        </div>,
        {
          autoClose: 3000,
        }
      );
      break;
    case "error":
      toast.error(
        <div className="toast-icon-wrapper">
          <span className="toast-icon">
            <svg
              width="34"
              height="34"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0.9375C8.59184 0.9375 0.9375 8.59184 0.9375 18C0.9375 27.4082 8.59184 35.0625 18 35.0625C27.4082 35.0625 35.0625 27.4082 35.0625 18C35.0625 8.59184 27.4082 0.9375 18 0.9375ZM24.1778 22.3222V22.3222C24.7033 22.8215 24.7246 23.6523 24.2253 24.1778C23.726 24.7033 22.8953 24.7246 22.3697 24.2253C22.3535 24.2099 22.3377 24.194 22.3222 24.1778L18 19.8564L13.6778 24.1778V24.1778C13.1523 24.677 12.3215 24.6558 11.8222 24.1303C11.3409 23.6237 11.3409 22.8288 11.8222 22.3222L16.1436 18L11.8222 13.6778V13.6778C11.323 13.1523 11.3442 12.3215 11.8697 11.8222C12.3763 11.3409 13.1712 11.3409 13.6778 11.8222L18 16.1436L22.3222 11.8222V11.8222C22.8477 11.323 23.6785 11.3442 24.1778 11.8697C24.6591 12.3763 24.6591 13.1712 24.1778 13.6778L19.8564 18L24.1778 22.3222Z"
                fill="#F9485B"
              />
            </svg>
          </span>
          <div className="toast-message-wrapper">
            <span className="toast-title">
              <LanguageProvider id={"gen.error"} />
            </span>
            <span className="toast-description">{options.message}</span>
          </div>
        </div>,
        {
          autoClose: 3000,
        }
      );
      break;
    default:
      toast(message);
      break;
  }
};

export default showToast;
