import React, { useRef } from "react";
import LanguageProvider from "./language-provider";
import uploadImg from "~/theme/media/assets/Upload.svg";
import "./scss/file-upload-box.scss";

export interface ImageUploadAreaProps {
  onFileSelect: (file: File) => void;
  onMultipleFileSelect: (files: FileList) => void;
  accept: string;
  multiple?: boolean;
}

export function FileUploadBox(props: ImageUploadAreaProps) {
  const ref = useRef(null);

  function onFileDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (props.multiple) {
      props.onMultipleFileSelect(event.dataTransfer.files);
      return;
    }
  }

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (props.multiple) {
      props.onMultipleFileSelect(files);
      return;
    }

    if (files[0]) props.onFileSelect(files[0]);
  }

  return (
    <div className="file-upload-box">
      <div
        className="upload-file-container"
        onClick={() => ref.current?.click()}
        onDragOver={(e) => e.preventDefault()}
        onDrop={onFileDrop}
      >
        <div className="upload-file-wrapper">
          <img className="upload-file-img" src={uploadImg} alt="Upload" />
          <input
            className="upload-file-input"
            ref={ref}
            type="file"
            accept={props.accept}
            onChange={onFileChange}
            multiple={props.multiple}
          />
          <span className="upload-input-description">
            <LanguageProvider
              id={"database.upload.file.title.drag.file.description"}
            />
          </span>
        </div>
      </div>
      <span className="upload-file-format">
        {`${LanguageProvider({
          id: "upload.file.accepted.formats",
        })}: ${props.accept
          .toUpperCase()
          .split(",")
          .join(", ")
          .replace(/\./g, "")}`}
      </span>
    </div>
  );
}
