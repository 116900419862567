// @jsx: react

import "./generic-counter.scss";
import React from "react";

interface Props {
  title: string;
  value: number;
  valueTemplate?: (value: number) => string;
}

export function GenericCounter(props: Props) {
  let value = props.value.toString();
  if (props.valueTemplate) {
    value = props.valueTemplate(props.value);
  }

  return (
    <div className="generic-count">
      <div className="label">{props.title || ""}</div>
      <div className="value">{value}</div>
    </div>
  );
}
