import { classNames } from "primereact/utils";
import "./text-config-menu.scss";
import React, { useEffect, useState } from "react";
import { DashboardItem } from "./andon-board";
import LanguageProvider from "~/shared/components/language-provider";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { Button } from "~/shared/components/dcp-button";
import { InputNumber } from "primereact/inputnumber";

export interface ConfigMenuProps {
  open: boolean;
  onCancel: () => void;
  item: DashboardItem;
  onSave: (item: DashboardItem) => void;
}

export function TextConfigMenu(props: ConfigMenuProps) {
  const [itemContent, setItemContent] = useState(null);

  useEffect(() => {
    try {
      const content = JSON.parse(props.item.content);
      if (
        content &&
        Object.keys(content).includes("text") &&
        Object.keys(content).includes("fontSize")
      ) {
        setItemContent(content);
      }
    } catch (error) {}
  }, [props.item]);

  return (
    <div
      className={classNames("text-config-menu", {
        open: props.open && itemContent,
      })}
    >
      <div className="content">
        {itemContent && (
          <Form>
            <FormRow>
              <InputContainer
                label={<LanguageProvider id="dashboard.config.content" />}
              >
                <InputText
                  value={itemContent.text}
                  onChange={(e) => {
                    setItemContent({ ...itemContent, text: e.target.value });
                  }}
                />
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer
                label={<LanguageProvider id="dashboard.config.font-size" />}
              >
                <InputNumber
                  value={Number(itemContent.fontSize)}
                  allowEmpty={false}
                  min={1}
                  max={700}
                  onChange={(e) =>
                    setItemContent({ ...itemContent, fontSize: e.value || 1 })
                  }
                />
              </InputContainer>
            </FormRow>
            <FormRow className="actions">
              <Button
                className="p-button p-button-plain dcp-button p-button-text"
                onClick={() => props.onCancel()}
                type="button"
              >
                <LanguageProvider id="gen.cancel.button" />
              </Button>
              <Button
                type="button"
                onClick={() => {
                  props.onSave({
                    ...props.item,
                    content: JSON.stringify(itemContent),
                  });
                }}
              >
                <LanguageProvider id="gen.save.button" />
              </Button>
            </FormRow>
          </Form>
        )}
      </div>
    </div>
  );
}
