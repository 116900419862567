import React from "react";
import { DatabaseFieldFormatter } from ".";

export function Time({ data }: DatabaseFieldFormatter) {
  const time = new Date(data);
  return (
    <span>
      {data &&
        time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
    </span>
  );
}
