import "./inventory-status.scss";
import React from "react";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { InventoryStatus } from "~/shared/interfaces";

export interface InventoryStatusBadgeProps {
  inventoryStatus: InventoryStatus;
  removeBg?: boolean;
}

export function InventoryStatusBadge(props: InventoryStatusBadgeProps) {
  let currentStatus = {
    label: "inventory.status.waitingStart",
    icon: "check-circle",
    className: "waiting-start",
    iconColor: "var(--yellow-600)",
  };
  switch (props.inventoryStatus) {
    case InventoryStatus.InProgress:
      currentStatus.label = "inventory.status.inProgress";
      currentStatus.icon = "clock-rewind";
      currentStatus.className = "in-progress";
      currentStatus.iconColor = "var(--blue-600)";
      break;
    case InventoryStatus.Paused:
      currentStatus.label = "inventory.status.paused";
      currentStatus.icon = "pause-circle";
      currentStatus.className = "paused";
      currentStatus.iconColor = "var(--gray-600)";
      break;
    case InventoryStatus.Canceled:
      currentStatus.label = "inventory.status.canceled";
      currentStatus.icon = "check-circle";
      currentStatus.className = "canceled";
      currentStatus.iconColor = "var(--red-600)";
      break;
    case InventoryStatus.Finished:
      currentStatus.label = "inventory.status.finished";
      currentStatus.icon = "check-circle";
      currentStatus.className = "finished";
      currentStatus.iconColor = "var(--green-600)";
      break;
  }

  return (
    <div className="inventory-status-badge">
      <div
        className={`wrapper ${props.removeBg ? "remove-bg" : null} ${
          currentStatus.className
        }`}
      >
        <Icon
          icon={currentStatus.icon}
          color={currentStatus.iconColor}
          size={20}
        />
        <span>
          <LanguageProvider id={currentStatus.label} />
        </span>
      </div>
    </div>
  );
}
