export enum MapItemType {
  Floor = 0,
  Rack = 1,
}

export interface MapItemModel {
  id: number;
  name: string;
  x: number;
  y: number;
  w: number;
  h: number;
  type: MapItemType;
  orientation: string;
  area_id: number;
  area_group_id: number | null;
  floor_area_id: number | null;
  color: string;
}
