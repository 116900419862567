import "./scss/inventory.scss";

import { useContext, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Icon from "~/shared/components/icons";
import InventoryViewArea from "./inventory-area-view";
import LanguageProvider from "~shared/components/language-provider";
import { ThemeContext } from "~/app";
import { Tooltip } from "primereact/tooltip";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useToastContext } from "~/context/ToastContext";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";

const Inventory = () => {
  const { currentLanguage } = useLanguageContext();
  const { currentTheme } = useContext(ThemeContext);
  const { showToast } = useToastContext();
  const [totalItems, setTotalItems] = useState(0);
  const [showInventoryList, setShowInventoryList] = useState(true);

  const dcpAxiosService = useDcpAxiosService();

  const totalRegisterMessage = `240 ${LanguageProvider({
    id: "gen.registers",
  })}`;

  // Table data
  const [loading, setLoading] = useState(true);
  const [inventory, setInventory] = useState([]);
  const [activeButton, setActiveButton] = useState("list");

  // Modals
  const [modalManageLocationIsOpen, setModalManageLocationIsOpen] =
    useState(false);
  const [manageLocationModel, setManageLocationModel] = useState();
  const [deleteConfirmationModalTitle, setDeleteConfirmationModalTitle] =
    useState("");
  const [
    deleteConfirmationModalBodyMessage,
    setDeleteConfirmationModalBodyMessage,
  ] = useState("");
  const [modalConfirmationIsOpen, setModalConfirmationIsOpen] = useState(false);
  const [ConfirmDeleteLocationModel, setConfirmDeleteLocationModel] =
    useState();
  const [modalIsDelete, setModalIsDelete] = useState(false);

  const inventoryData = [
    {
      area: "Recebimento",
      street: "A",
      sku: "GS30327582321587",
      location: "A-01-B-1",
      status: "Exceção",
      code: "Locação vazia",
      createdAt: Date.now().toLocaleString(),
    },
    {
      area: "Estoque",
      street: "A",
      sku: "GS30327582321587",
      location: "A-01-B-1",
      status: "Corresponde",
      code: " ",
      createdAt: Date.now().toLocaleString(),
    },
    {
      area: "Recebimento",
      street: "A",
      sku: "GS30327582321587",
      location: "A-01-B-1",
      status: "Auditado",
      code: "Inconsistência na leitura",
      createdAt: Date.now().toLocaleString(),
    },
  ];

  const OpenModalManageLocation = (model) => {
    setManageLocationModel(model);
    setModalManageLocationIsOpen(true);
  };
  const CloseModalManageLocation = () => {
    setModalManageLocationIsOpen(false);
    setManageLocationModel();
    LoadInventory();
  };
  const OpenModalConfirmationDelete = (model) => {
    setConfirmDeleteLocationModel(model);
    setModalIsDelete(true);
    setDeleteConfirmationModalTitle(
      <LanguageProvider id={"gen.confirm.user.delete"} />
    );
    setDeleteConfirmationModalBodyMessage(
      <LanguageProvider id={"gen.message.user.delete"} />
    );
    setModalConfirmationIsOpen(true);
  };

  const LoadInventory = async () => {
    const { data } = await dcpAxiosService.get("", "Auth");
    if (data.status) {
      setInventory(data.data);
      setTotalItems(data.data.length);
    } else {
      showToast({
        severity: "error",
        message: LanguageProviderWithoutContext({
          id: data.message,
          currentLanguage,
        }),
      });
    }
    setLoading(false);
  };

  const CloseResponse = () => {
    setModalConfirmationIsOpen(false);
    setConfirmDeleteLocationModel();
    LoadInventory();
  };

  const handleListButtonClick = () => {
    setShowInventoryList(true);
    setActiveButton("list");
  };

  const handleMapButtonClick = () => {
    setShowInventoryList(false);
    setActiveButton("map");
  };

  return (
    <div className="inventory-dashboard">
      <div className="user-dashboard-container">
        <div className="user-dashboard-content">
          <div className="content-header">
            <div className="total-items-wrapper">
              <span className="header-message">
                <div className="page-identifier-wrapper">
                  <span className="page-name">
                    {showInventoryList ? (
                      "Armazém 020"
                    ) : (
                      <LanguageProvider id={"product.inventory"} />
                    )}
                  </span>
                </div>
                <div className="total-table-itens">
                  <span className="total-register-message">
                    {totalRegisterMessage}
                  </span>
                </div>
              </span>
            </div>
            <div className="action-buttons-wrapper">
              <Tooltip target=".inventory-icon-dots" position="top">
                <span>
                  <LanguageProvider id={"gen.list"} />
                </span>
              </Tooltip>
              <Button
                className={`p-button p-button-outlined p-button-transparent inventory-icon-dots ${
                  activeButton === "list" ? "active" : ""
                }`}
                icon={<Icon icon={"dotpoints-01"} color="#4146ff" size={20} />}
                onClick={handleListButtonClick}
              />
              <Tooltip target=".inventory-icon-map" position="top">
                <span>
                  <LanguageProvider id={"gen.map"} />
                </span>
              </Tooltip>
              <Button
                className={`p-button p-button-outlined p-button-transparent inventory-icon-map ${
                  activeButton === "map" ? "active" : ""
                }`}
                icon={<Icon icon={"map-01"} color="#4146ff" size={20} />}
                onClick={handleMapButtonClick}
              />
              <Tooltip target=".inventory-btn-download" position="top">
                <span>
                  <LanguageProvider id={"gen.download"} />
                </span>
              </Tooltip>
              <Button
                className="p-button p-button-secondary inventory-btn-download"
                icon={<Icon icon={"download-01"} color="#4146ff" size={20} />}
              />
            </div>
          </div>
          <div className="content-body">
            {showInventoryList ? (
              <div className="dcp-table">
                <DataTable
                  value={inventoryData}
                  removableSort
                  rows={20}
                  paginator
                  loadingIcon={<LoadingIndicator />}
                >
                  <Column
                    header={<LanguageProvider id={"product.drone.area"} />}
                    field="area"
                    filter
                    showFilterOperator={false}
                    filterPlaceholder={
                      <LanguageProvider id={"gen.filter.by.area"} />
                    }
                    sortable
                  />
                  <Column
                    header={<LanguageProvider id={"gen.street"} />}
                    field="street"
                    body={(rowData) => {
                      return (
                        <>
                          <div className="street-name-wrapper">
                            <div className="street-name">{rowData.street}</div>
                          </div>
                        </>
                      );
                    }}
                    filter
                    filterPlaceholder={
                      <LanguageProvider id={"gen.filter.by.street"} />
                    }
                    sortable
                  />
                  <Column
                    header={<LanguageProvider id={"gen.sku"} />}
                    field="sku"
                    filter
                    showFilterOperator={false}
                    filterPlaceholder={
                      <LanguageProvider id={"gen.filter.by.sku"} />
                    }
                    sortable
                  />
                  <Column
                    header={<LanguageProvider id={"gen.location"} />}
                    field="location"
                    body={(rowData) => {
                      return (
                        <>
                          <div className="location-wrapper">
                            {rowData.location}
                          </div>
                        </>
                      );
                    }}
                    filter
                    sortable
                    filterPlaceholder={
                      <LanguageProvider id={"gen.filter.by.location"} />
                    }
                  />
                  <Column
                    header={<LanguageProvider id={"gen.status"} />}
                    headerClassName="custom-header-created-at-class"
                    field="status"
                    body={(rowData) => {
                      let statusType;
                      if (rowData.status == "Exceção") statusType = "exception";
                      if (rowData.status == "Corresponde")
                        statusType = "correspond";
                      if (rowData.status == "Auditado") statusType = "audited";

                      return (
                        <>
                          <div className={`status-wrapper ${statusType}`}>
                            <span className={`status- ${statusType}`}>
                              {rowData.status}
                            </span>
                          </div>
                        </>
                      );
                    }}
                    filter
                    filterPlaceholder={
                      <LanguageProvider id={"gen.filter.by.status"} />
                    }
                    sortable
                  />
                  <Column
                    header={<LanguageProvider id={"gen.problem.type"} />}
                    field="code"
                    filter
                    showFilterOperator={false}
                    filterPlaceholder={
                      <LanguageProvider id={"gen.filter.by.code"} />
                    }
                    sortable
                  />
                  <Column
                    header={<LanguageProvider id={"gen.scanned.at"} />}
                    field="createdAt"
                    filter
                    showFilterOperator={false}
                    filterPlaceholder={
                      <LanguageProvider id={"gen.filter.by.creation"} />
                    }
                    sortable
                  />
                  <Column
                    header={null}
                    headerClassName="custom-header-actions-class"
                    key={"actions"}
                    body={(rowData) => {
                      return (
                        <div className="table-actions-wrapper">
                          <div className="table-actions">
                            <div className="icon-wrapper">
                              <Icon
                                icon={"edit-02"}
                                size={20}
                                color="#667085"
                                className="icon-row"
                              />
                            </div>
                          </div>
                          <div className="table-actions">
                            <div className="icon-wrapper">
                              <Icon
                                icon={"trash-02"}
                                size={20}
                                color="#667085"
                                className="icon-row"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </DataTable>
              </div>
            ) : (
              <InventoryViewArea />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;
