import "./Position.scss";
import { useRef } from "react";

export default function Position(configs) {
  const parentRef = useRef(null);

  console.log(configs);

  return (
    <div className="position" ref={parentRef}>
      <h1
      // style={{
      //   fontSize: parentRef.current
      //     ? parentRef.current.offsetWidth + "px"
      //     : "6rem",
      // }}
      >
        {configs.content?.position}
      </h1>
      <span>{configs.content?.partNumber}</span>
    </div>
  );
}
