import { GenericCounter } from "../../custom-charts-components/generic-counter";
import LanguageProvider from "~/shared/components/language-provider.jsx";
import "./AndonOpenTrips.scss";

export default function AndonOpenTrips() {
  const data = 1;

  return (
    <div className="andon-open-trips">
      <GenericCounter
        title={<LanguageProvider id="dashboard.andon.open.trips" />}
        value={data}
      />
    </div>
  );
}
