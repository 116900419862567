import { AndonBoard } from "./components/andon-board";
import { AndonSettingsContextProvider } from "./context/AndonDashboardSettingsContext";

export default function AndonComponent() {
  return (
    <AndonSettingsContextProvider>
      <AndonBoard dashboardType={1} />
    </AndonSettingsContextProvider>
  );
}
