import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { Template } from "~/shared/interfaces/template";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function getTemplate(templateId: number): Promise<Template> {
  try {
    const data = await axiosService().get(
      settings.Urls.Rest.Templates + "/get-template",
      "Platform",
      {
        params: {
          id: templateId,
        },
      }
    );

    if (data.data) return data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function listTemplates(): Promise<Template> {
  try {
    const data = await axiosService().get(
      settings.Urls.Rest.Templates + "/list-templates",
      "Platform"
    );
    if (data && data.data) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function createTemplate(template: Template): Promise<Template> {
  try {
    const { data } = await axiosService().post(
      settings.Urls.Rest.Templates + "/create",
      template,
      "Platform"
    );

    if (data && data.data) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function updateTemplate(template: Template): Promise<Template> {
  try {
    const { data } = await axiosService().post(
      settings.Urls.Rest.Templates + "/update",
      template,
      "Platform"
    );

    if (data && data.data) return data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function DeleteTemplate(templateId: number): Promise<Boolean> {
  try {
    const { data } = await axiosService().delete(
      `${settings.Urls.Rest.Templates}/delete?idTemplate=${templateId}`,
      "Platform"
    );

    if (data && data.data) return true;
    else return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}
