import { useEffect } from "react";
import { useParams } from "react-router-dom";
import GenericDatabaseHeader from "~/pages/wms/components/generic-database-header";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";

const ApplicantsDetails = () => {
  const { itemId } = useParams();

  const { loadDatabaseProductProcess } = UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(
      settings.DatabaseProcessType.Warehouse_Solicitantes
    );
  }, [loadDatabaseProductProcess]);

  return (
    <>
      <div className="database-items-details">
        <div className="database-items-details-header">
          <div className="header-container">
            <h1>
              <LanguageProvider id="database.detail.order" />
              {settings.Module.Database.applicants}
            </h1>
          </div>
          <GenericDatabaseHeader itemId={itemId} />
        </div>
      </div>
    </>
  );
};

export default ApplicantsDetails;
