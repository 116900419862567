import React from "react";
import ptBR from "~/language/pt-br.json";
import enUS from "~/language/en-us.json";
import esES from "~/language/es-es.json";

import * as GridItems from "../../../shared-modules/dashboard-graphs/components/grid-item/items";
import { DashboardMenuItemCategory } from "~/pages/shared-modules/dashboard-graphs/components/item-list-dialog/menu-items";
import { useLanguageContext } from "~/context/LanguageContext";

const TranslatedLabel = ({ id }: { id: string }) => {
  const { currentLanguage } = useLanguageContext();

  const getTranslation = (id: string) => {
    switch (currentLanguage) {
      case "pt-br":
        return ptBR[id] || id;
      case "es-es":
        return esES[id] || id;
      case "en-us":
      default:
        return enUS[id] || id;
    }
  };

  return <>{getTranslation(id)}</>;
};

export const AndonTripMenuItems = () => {
  return [
    {
      category: "layout",
      productCode: "platform",
      icon: "layout-grid-01",
      label: TranslatedLabel({ id: "dashboard.category.layout" }),
      // label: <TranslatedLabel id="dashboard.category.layout" />,
      items: [
        {
          type: "andon-text",
          label: TranslatedLabel({ id: "dashboard.item.type.text" }),
          icon: "align-left",
          component: GridItems.AndonText,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "andon",
      productCode: "sequence",
      icon: "layout-grid-01",
      label: TranslatedLabel({ id: "dashboard.category.andon" }),
      items: [
        {
          type: "waiting-shipping-orders",
          label: TranslatedLabel({
            id: "dashboard.item.type.andon.waiting-shipping-orders",
          }),
          icon: "align-left",
          component: GridItems.AndonWaitingShippingOrders,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "pending-orders",
          label: TranslatedLabel({
            id: "dashboard.item.type.andon.pending-orders",
          }),
          icon: "align-left",
          component: GridItems.AndonPendingOrders,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "open-trips",
          label: TranslatedLabel({
            id: "dashboard.item.type.andon.open-trips",
          }),
          icon: "align-left",
          component: GridItems.AndonOpenTrips,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "last-integration-order",
          label: TranslatedLabel({
            id: "dashboard.item.type.andon.last-integration-order",
          }),
          icon: "align-left",
          component: GridItems.AndonLastIntegrationOrder,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "last-integration-order-number",
          label: TranslatedLabel({
            id: "dashboard.item.type.andon.last-integration-order-number",
          }),
          icon: "align-left",
          component: GridItems.AndonLastIntegrationOrderNumber,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "errors",
          label: TranslatedLabel({
            id: "dashboard.item.type.andon.errors",
          }),
          icon: "align-left",
          component: GridItems.AndonErrors,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
  ];
};
