import { Dropdown } from "primereact/dropdown";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function WarehouseFinanceCounterSourceConfig({
  config,
  updateValue = function () {},
}) {
  const { currentLanguage } = useLanguageContext();

  const options = [
    {
      code: "current-finance-value",
      label: LanguageProviderWithoutContext({
        id: `dropdown.option.current-finance-value`,
        currentLanguage,
      }),
    },
    {
      code: "entry-finance-value",
      label: LanguageProviderWithoutContext({
        id: "dropdown.option.entry-finance-value",
        currentLanguage,
      }),
    },
    {
      code: "exit-finance-value",
      label: LanguageProviderWithoutContext({
        id: "dropdown.option.exit-finance-value",
        currentLanguage,
      }),
    },
  ];

  return (
    <Dropdown
      value={config.value}
      options={options}
      optionLabel="label"
      optionValue="code"
      onChange={(e) => updateValue(e.value)}
    ></Dropdown>
  );
}
