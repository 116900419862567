import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { defaultChartOptions } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import oeeHistory from "../../fake-data/mes/oee-history.json";
import { ThemeContext } from "~/app";

export default function OEEHistory(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [dataset, setDataset] = useState({});

  useEffect(() => {
    if (useMockData) {
      setDataset(oeeHistory);
    } else {
      // load from api
      setDataset({});
    }
  }, [useMockData]);

  const options = {
    ...defaultChartOptions,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return (
    <Chart
      type="line"
      data={dataset}
      options={options}
      width="100%"
      height="100%"
    />
  );
}
