import "./scss/dashboard-layout.scss";

import { useContext, useEffect, useState } from "react";

import LoadingIndicator from "./components/dcp-loading-indicator";
import NavMenu from "./nav/nav-menu";
import { Outlet } from "react-router-dom";
import { ProductContext } from "./main-layout";
import { UserContext } from "~/app";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

const DashboardLayout = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const [product, setProduct] = useState();
  const dcpAxiosService = useDcpAxiosService();

  async function loadCurrentProduct() {
    try {
      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Admin.ApplicationClient}/list-user-products`,
        "Auth"
      );
      if (data.status) {
        const selectedProduct = data.data.find(
          (x) => x.idApplicationClient == user.applicationIds.platform
        );

        if (selectedProduct) {
          setProduct(selectedProduct);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setLoading(false);
    loadCurrentProduct();
  }, [user]);

  if (!loading) {
    return (
      <ProductContext.Provider value={{ product }}>
        <div className="dashboard-layout">
          <div className="content">
            <NavMenu />
            <Outlet />
          </div>
        </div>
      </ProductContext.Provider>
    );
  }
  return <LoadingIndicator />;
};

export default DashboardLayout;
