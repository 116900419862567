import "./scss/area-map-history.scss";
import React, { useContext, useEffect, useState } from "react";
import settings from "~/services/settings.json";
import {
  getLocationsLastUpdate,
  getRackLocationsInfoModel,
  listAreaGroups,
  listAreaMaps,
} from "~/services/api";
import {
  AreaListModel,
  LocationLastUpdate,
  RackLocationsInfoModel,
} from "~/shared/interfaces";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { Item, WarehouseMap } from "../area-map/components/warehouse-map";
import { listMapItems } from "~/services/api/inventory/area";
import { generateUniqueKey } from "~/shared/helpers/generator";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { AreaHistoryLocationDialog } from "../area-map/components/area-history-location-dialog";
import { Tooltip } from "primereact/tooltip";
import { ThemeContext } from "~/app";
import { Dropdown } from "primereact/dropdown";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";

export function AreaMapHistory() {
  const dcpAxiosService = useDcpAxiosService();
  const { currentTheme } = useContext(ThemeContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [areas, setAreas] = useState<AreaListModel[]>([]);
  const [areaGroups, setAreaGroups] = useState([]);
  const [areaItems, setAreaItems] = useState([]);
  const [rackLocationInfoId, setRackLocationInfoId] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dialogItems, setDialogItems] = useState<LocationLastUpdate[]>([]);
  const dialogItemsList: LocationLastUpdate[] = [];
  const [locationsLastUpdate, setLocationsLastUpdate] = useState<
    RackLocationsInfoModel[]
  >([]);
  const [selectedAreaIndex, setSelectedAreaIndex] = useState<number>();
  const [warningDaysValueInSettings, setWarningDaysValueInSettings] =
    useState<number>(0);
  const [criticalDaysValueInSettings, setCriticalDaysValueInSettings] =
    useState<number>(0);
  const [areasTypeCountValues, setAreasTypeCountValues] = useState({
    total: 0,
    reliable: 0,
    lessReliable: 0,
    unreliable: 0,
    percentage: {
      default: 0,
      warning: 0,
      critical: 0,
    },
  });
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  async function loadItems(id?: number, groups?: any[]) {
    try {
      const data = await listMapItems(id ?? areas[selectedAreaIndex].id);
      if (!data) return;

      const items: Item[] = [];
      setAreaItems(items);

      for (const item of data) {
        items.push({
          ...item,
          i: "",
          group: {},
        });
      }

      let _groups = groups && groups.length > 0 ? groups : areaGroups;

      for (const item of items) {
        item.i = generateUniqueKey(items, "i");
        const group = _groups.find(
          (g) => g.areaGroup.id === item.area_group_id
        );
        if (group) item.group = group;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const loadSettings = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Settings}/list-settings`,
        "Platform",
        {
          params: { applicationClientId },
        }
      );
      if (status === 200) {
        const settingsResponse = data.data[0];
        setWarningDaysValueInSettings(
          settingsResponse.subgroups[0].settings.find(
            (setting) =>
              setting.propertyName === "location_old_reading_warning_days"
          ).value
        );
        setCriticalDaysValueInSettings(
          settingsResponse.subgroups[0].settings.find(
            (setting) =>
              setting.propertyName === "location_old_reading_critical_days"
          ).value
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function load() {
      let areas = await listAreaMaps();
      if (areas) {
        setAreas(areas);
        setSelectedAreaIndex(0);
      }
    }
    load();
    loadSettings();
  }, []);

  useEffect(() => {
    async function load() {
      setLoading(true);
      let areaId = areas[selectedAreaIndex]?.id;
      let groups = await listAreaGroups(areaId);

      setAreaGroups(groups);
      const areaHistory = await getRackLocationsInfoModel(areaId);
      // Count types of area based on ColorHex
      const total = areaHistory.length;
      const reliable = areaHistory.filter(
        (item) => Number(item.color) === 0
      ).length;
      const lessReliable = areaHistory.filter(
        (item) => Number(item.color) === 1
      ).length;
      const unreliable = areaHistory.filter(
        (item) => Number(item.color) === 2
      ).length;

      setAreasTypeCountValues({
        total,
        reliable,
        lessReliable,
        unreliable,
        percentage: {
          default: (reliable / total) * 100 || 0,
          warning: (lessReliable / total) * 100 || 0,
          critical: (unreliable / total) * 100 || 0,
        },
      });
      setLocationsLastUpdate(areaHistory);

      await loadItems(areaId, groups);
      setLoading(false);
    }
    load();
  }, [areas, selectedAreaIndex]);

  async function onLocationClick(rackLocationsInfo: RackLocationsInfoModel) {
    setLoadingLocations(true);
    setModalVisible(true);
    var locations = await getLocationsLastUpdate(rackLocationsInfo.id);

    setRackLocationInfoId(rackLocationsInfo.id);
    dialogItemsList.push(...locations);
    setDialogItems(locations.sort(sortLocations));
    setLoadingLocations(false);
  }

  function sortLocations(a, b) {
    const regex = /(\D+)(\d*)/;
    const matchA = a.locationName.match(regex);
    const matchB = b.locationName.match(regex);

    if (!matchA || !matchB) {
      throw new Error("Invalid locationName format");
    }

    const [, lettersA, numbersA] = matchA;
    const [, lettersB, numbersB] = matchB;

    const compareLetters = lettersA.localeCompare(lettersB);
    const compareNumbers = parseInt(numbersA) - parseInt(numbersB);

    if (compareLetters !== 0) {
      return compareLetters;
    } else {
      return compareNumbers;
    }
  }

  return (
    <div className="area-map-history">
      <div className="area-map-history-wrapper">
        <div className="header">
          <div className="area-selection">
            <div className="selector">
              <div
                className="icon"
                onClick={() => {
                  if (
                    selectedAreaIndex != null &&
                    selectedAreaIndex > 0 &&
                    !loading
                  ) {
                    setSelectedAreaIndex(selectedAreaIndex - 1);
                  }
                }}
              >
                <Icon
                  icon="chevron-left"
                  size={24}
                  color={
                    selectedAreaIndex != null && selectedAreaIndex > 0
                      ? currentTheme.tableIconColorCountExists
                      : currentTheme.tableIconColorCountDoesNotExists
                  }
                  className={`right-setting${
                    selectedAreaIndex != null &&
                    selectedAreaIndex > 0 &&
                    !loading
                      ? "-exists"
                      : "-does-not-exists"
                  }`}
                />
              </div>
              <Dropdown
                value={selectedAreaIndex}
                options={areas.map((area, index) => ({
                  label: area.name,
                  value: index,
                }))}
                loading={loading}
                onChange={(e) => setSelectedAreaIndex(e.value)}
                placeholder={LanguageProvider({
                  id: "inventory.map.area.dropdown.placeholder.select",
                })}
              />
              <div
                className="icon"
                onClick={() => {
                  if (
                    selectedAreaIndex != null &&
                    selectedAreaIndex < areas.length - 1 &&
                    !loading
                  ) {
                    setSelectedAreaIndex(selectedAreaIndex + 1);
                  }
                }}
              >
                <Icon
                  icon="chevron-right"
                  size={24}
                  color={
                    selectedAreaIndex != null &&
                    selectedAreaIndex < areas.length - 1
                      ? currentTheme.tableIconColorCountExists
                      : currentTheme.tableIconColorCountDoesNotExists
                  }
                  className={`right-setting${
                    selectedAreaIndex != null &&
                    selectedAreaIndex < areas.length - 1 &&
                    !loading
                      ? "-exists"
                      : "-does-not-exists"
                  }`}
                />
              </div>
            </div>
          </div>
          <div className="area-map-sub-header">
            <div className="square-container">
              <div className="rack-reliability">
                <div className="rack-status-wrapper">
                  <div
                    className="rack-status-icon"
                    style={{ backgroundColor: "#8CD8C0" }}
                  />
                  <span className="rack-status-value">
                    {areasTypeCountValues.reliable}{" "}
                    <span className="rack-status-percentage">
                      /{areasTypeCountValues.percentage.default}%
                    </span>
                  </span>
                </div>
                <div className="rack-status-wrapper">
                  <div
                    className="rack-status-icon"
                    style={{ backgroundColor: "#FFE78A" }}
                  />
                  <span className="rack-status-value">
                    {areasTypeCountValues.lessReliable}/
                    <span className="rack-status-percentage">
                      {areasTypeCountValues.percentage.warning}%
                    </span>
                  </span>
                </div>
                <div className="rack-status-wrapper">
                  <div
                    className="rack-status-icon"
                    style={{ backgroundColor: "#FBA6BA" }}
                  />
                  <span className="rack-status-value">
                    {areasTypeCountValues.unreliable}/
                    <span className="rack-status-percentage">
                      {areasTypeCountValues.percentage.critical}%
                    </span>
                  </span>
                </div>
              </div>
              <Tooltip
                className="rack-reliability-tooltip-wrapper"
                target=".rack-tooltip"
                position="bottom"
              >
                <div className="rack-tooltip-content">
                  <span className="rack-tooltip-title">
                    <LanguageProvider id={"area.map.history.tooltip.title"} />
                  </span>
                  <span className="rack-tooltip-subtitle-reliability-reliable">
                    <LanguageProvider
                      id={"area.map.history.tooltip.title.reliable"}
                    />
                  </span>
                  <span className="rack-tooltip-description">
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.reliable.description.one"
                      }
                    />
                  </span>
                  <span className="rack-tooltip-description">
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.reliable.description.two"
                      }
                    />
                    {warningDaysValueInSettings}
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.reliable.description.three"
                      }
                    />
                  </span>
                  <span className="rack-tooltip-subtitle-reliability-lessReliable">
                    <LanguageProvider
                      id={"area.map.history.tooltip.title.less.reliable"}
                    />
                  </span>
                  <span className="rack-tooltip-description">
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.less.reliable.description.one"
                      }
                    />
                  </span>
                  <span className="rack-tooltip-description">
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.less.reliable.description.two"
                      }
                    />
                    {warningDaysValueInSettings}
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.less.reliable.description.three"
                      }
                    />
                  </span>
                  <span className="rack-tooltip-subtitle-reliability-unreliable">
                    <LanguageProvider
                      id={"area.map.history.tooltip.title.unreliable"}
                    />
                  </span>
                  <span className="rack-tooltip-description">
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.unreliable.description.one"
                      }
                    />
                  </span>
                  <span className="rack-tooltip-description">
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.unreliable.description.two"
                      }
                    />
                    {criticalDaysValueInSettings}
                    <LanguageProvider
                      id={
                        "area.map.history.tooltip.title.unreliable.description.three"
                      }
                    />
                  </span>
                </div>
              </Tooltip>
              <div className="rack-tooltip">
                <Icon
                  className="rack-icon-reliability-help"
                  color={"gray"}
                  icon={"annotation-question"}
                  size={20}
                />{" "}
                <span className="rack-reliability-content">
                  <LanguageProvider id={"area.map.history.tooltip.title"} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="map-container">
          {loading ? (
            <div className="map-container-loading">
              <LoadingIndicator />
            </div>
          ) : (
            <WarehouseMap
              isStatic={true}
              items={areaItems}
              exceptions={[]}
              rackGroupLocationHistory={locationsLastUpdate}
              onLocationHistoryClick={onLocationClick}
              onExceptionClick={() => {}}
              onUpdate={() => {}}
              onFlip={() => {}}
              onDelete={() => {}}
              onEdit={() => {}}
            />
          )}
          <AreaHistoryLocationDialog
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
            locations={dialogItems}
          />
          {/* <Dialog
            visible={modalVisible}
            onHide={() => setModalVisible(false)}
            appendTo="self"
            className="location-history-dialog"
            header={<LanguageProvider id="location.history.header" />}
          >
            <div className="history-dialog-content">
              {!loadingLocations ? (
                dialogItems.map((item) => {
                  return (
                    <div className="history-location">
                      <span
                        style={{
                          color: getContrastFontColorFromHex(item.colorHex),
                          background: item.colorHex,
                        }}
                        className="name"
                      >
                        {item.locationName}
                      </span>
                      <span>
                        {item.lastUpdate
                          ? item.lastUpdate.toLocaleDateString()
                          : LanguageProvider({
                              id: "location.history.no.record",
                            })}
                      </span>
                    </div>
                  );
                })
              ) : (
                <LoadingIndicator></LoadingIndicator>
              )}
            </div>
          </Dialog> */}
        </div>
      </div>
    </div>
  );
}
