import { GenericCounter } from "../../custom-charts-components/generic-counter";
import LanguageProvider from "~/shared/components/language-provider.jsx";
import "./AndonPendingOrder.scss";

export default function AndonPendingOrders() {
  return (
    <div className="andon-pending-orders">
      <GenericCounter
        title={<LanguageProvider id="dashboard.andon.pending.orders.counter" />}
        value="3"
      />
    </div>
  );
}
