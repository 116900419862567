import axios from "axios";
import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import {
  CHART_COLORS,
  defaultChartOptions,
} from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakedata from "../../fake-data/mes/ensaque.json";
import { ThemeContext } from "~/app";

export default function LibracomEnsaqueLineChart(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const date = configs.find(
          (config) => config.defaultConfig.name === "date"
        );
        if (!date) return;

        const { data, status } = await axios.get(
          "https://api.duettsoftware.com/dashboard/api/open-dashboard/get-ensaque-chart",
          {
            params: {
              dataReferencia: new Date(date.value),
            },
          }
        );
        if (status !== 200 || !data.data) return;
        const parsedData = parseChartData(data.data);
        setChartData(parsedData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (!useMockData) loadData();
    else {
      setChartData(fakedata);
    }
  }, [useMockData, configs]);

  const options = {
    ...defaultChartOptions,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return !loading ? (
    <Chart
      type="line"
      data={chartData}
      options={options}
      width="100%"
      height="100%"
    />
  ) : (
    <div className="chart-load">
      <Skeleton height="33%"></Skeleton>
      <Skeleton height="100%"></Skeleton>
      <Skeleton height="66%"></Skeleton>
    </div>
  );
}

function parseChartData(data) {
  try {
    const labels = data.data.map((item) => item.hora);
    const datasets = [];

    for (const equipment of data.equipaments) {
      let values = [];
      data.data.forEach((item) => {
        values.push(
          item.data.find((x) => x.tagEquipamento === equipment).qtdSacos
        );
      });

      datasets.push({
        type: "line",
        label: equipment,
        borderColor: CHART_COLORS[data.equipaments.indexOf(equipment)],
        backgroundColor: CHART_COLORS[data.equipaments.indexOf(equipment)],
        data: values,
      });
    }

    if (datasets.length === 0) {
      datasets.push({
        type: "line",
        label: "Sem dados",
        borderColor: CHART_COLORS[0],
        backgroundColor: CHART_COLORS[0],
        data: [],
      });
    }

    return { labels, datasets };
  } catch (error) {
    console.error(error);
  }
}
