import "../../databases/scss/database-new-entry-edit-dialog.scss";
import { useEffect, useState } from "react";
import { Button } from "~/shared/components/dcp-button";
import { Dialog } from "primereact/dialog";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~shared/components/language-provider";
import { Skeleton } from "primereact/skeleton";
import settings from "~/services/settings.json";
import * as EditFields from "./edit-fields/index";
import { useFormik } from "formik";
import { getFormattedItems } from "~/services/api";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

const renderField = (field, formik, databaseOptions) => {
  if (!field) return;

  // Helptext
  const tooltip = field.hasTextHelp ? { tooltip: field.textHelp } : {};

  // Field Value
  let value = formik ? formik.values[field.keyName] : "";

  const onChangeText = (value) => {
    if (formik) {
      formik.setFieldValue(field.keyName, value ?? "");
      formik.handleChange(value);
    }
  };

  const onChangeNumber = (value) => {
    if (formik && value) {
      formik.setFieldValue(field.keyName, value ?? 0);
    }
  };

  const onChangeUniqueSelection = (e) => {
    // if (!formik) return;

    console.log("e.value: ", e.value);
    formik.setFieldValue(field.keyName, e.value ?? "");
  };

  const onChangeDropdown = (e) => {
    if (formik) {
      const selectedValue = e.value ?? "";
      formik.setFieldValue(field.keyName, selectedValue);
    }
  };

  const onChangeDateTime = (e) => {
    if (formik) {
      if (e.target.value && e.target.value.toString().length > 0) {
        formik.setFieldValue(field.keyName, e.value.toISOString() ?? "");
      }
    }
  };

  const type = field.typeValue.name;
  const commonProps = {
    tooltip: field.textHelp ?? "",
    error:
      formik && formik.errors[field.keyName] && formik.touched[field.keyName]
        ? formik.errors[field.keyName]
        : null,
    value: value,
    fieldKey: field.keyName,
    onChange: onChangeText,
    onBlur: (e) => {
      if (formik) formik.handleBlur(e);
    },
    placeholder: LanguageProvider({
      id: `database.field.placeholder.${field.typeValue.name}`,
    }),
  };

  switch (type) {
    case "short-text":
      return EditFields.SimpleText({
        ...commonProps,
        type: "text",
        hasInputMask: field.hasInputMask,
        inputMaskName: field.inputMaskName,
      });

    case "email":
      return EditFields.SimpleText({
        ...commonProps,
        type: "email",
      });

    case "telephone":
      return EditFields.SimpleText({
        ...commonProps,
        type: "tel",
      });

    case "long-text":
      return EditFields.LongText({
        ...commonProps,
        onChange: onChangeText,
      });

    case "fixed-text":
      return EditFields.FixedText({ ...commonProps });

    case "date": {
      return EditFields.DateTime({
        ...commonProps,
        onChange: onChangeDateTime,
      });
    }

    case "database":
      function formatOptions() {
        const options = [];
        if (!databaseOptions) return options;
        var item = databaseOptions[field.typeDatabaseId];
        for (const key in databaseOptions[field.typeDatabaseId]) {
          options.push({ value: item[key], itemId: key });
        }
        return options;
      }

      return EditFields.DropdownField({
        ...commonProps,
        onChange: onChangeDropdown,
        optionLabel: "value",
        optionValue: "itemId",
        options: formatOptions(),
      });

    case "datetime": {
      return EditFields.DateTime({
        ...commonProps,
        onChange: onChangeDateTime,
        showTime: true,
      });
    }

    case "due-date": {
      return EditFields.DateTime({
        ...commonProps,
        onChange: onChangeDateTime,
        showTime: false,
      });
    }

    case "time": {
      return EditFields.DateTime({
        ...commonProps,
        onChange: onChangeDateTime,
        timeOnly: true,
      });
    }

    case "number":
      return EditFields.Number({
        ...commonProps,
        onChange: onChangeNumber,
        type: "number",
        hasInputMask: field.hasInputMask,
        inputMaskName: field.inputMaskName,
      });

    case "receiving-storage":
      return EditFields.SimpleText({
        ...commonProps,
        type: "text",
      });

    case "receiving-dock":
      return EditFields.SimpleText({
        ...commonProps,
        type: "text",
      });

    case "type-separation":
      return EditFields.SimpleText({
        ...commonProps,
        type: "text",
      });

    case "dispatch-status":
      return EditFields.SimpleText({
        ...commonProps,
        type: "text",
      });

    case "unique-selection":
      return EditFields.UniqueSelection({
        ...commonProps,
        options: field.options,
        onChange: onChangeUniqueSelection,
      });

    case "dropdown":
      function formatOptionsDropdown() {
        const options = [];
        if (!field.options) return options;
        for (const key in field.options) {
          options.push({
            title: field.options[key].title,
            idField: field.options[key].id,
          });
        }
        return options;
      }

      return EditFields.DropdownField({
        ...commonProps,
        onChange: onChangeDropdown,
        optionLabel: "title",
        optionValue: "idField",
        options: formatOptionsDropdown(),
      });

    case "type-receiving":
      return EditFields.DropdownField({
        ...commonProps,
        onChange: onChangeDropdown,
        optionLabel: "receivingTypeName",
        optionValue: "receivingTypeCode",
        options: [
          { receivingTypeName: "Sacaria", receivingTypeCode: "01" },
          { receivingTypeName: "Granel", receivingTypeCode: "02" },
          { receivingTypeName: "Bag", receivingTypeCode: "03" },
          { receivingTypeName: "Peças", receivingTypeCode: "04" },
        ],
      });

    case "receiving-status":
      return EditFields.DropdownField({
        ...commonProps,
        onChange: onChangeDropdown,
        optionLabel: "receivingStatusName",
        optionValue: "receivingStatuscode",
        options: [
          { receivingStatusName: "Aberto", receivingStatuscode: "01" },
          {
            receivingStatusName: "Aguardando pesagem",
            receivingStatuscode: "02",
          },
          {
            receivingStatusName: "Aguardando descarga",
            receivingStatuscode: "03",
          },
          {
            receivingStatusName: "Aguardando recebimento",
            receivingStatuscode: "04",
          },
          {
            receivingStatusName: "Aguardando conferência",
            receivingStatuscode: "05",
          },
          { receivingStatusName: "Conferido", receivingStatuscode: "06" },
          { receivingStatusName: "Recebido", receivingStatuscode: "07" },
          { receivingStatusName: "Armazenado", receivingStatuscode: "08" },
        ],
      });

    default:
      return null;
  }
};

export const NewEntryEditDialog = ({
  visible,
  onHide,
  onFinish,
  database,
  loading = false,
  selectedItem,
}) => {
  const dcpAxiosService = useDcpAxiosService();
  const [fields, setFields] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);
  const [databasesOptions, setDatabasesOptions] = useState({});

  const validadeForm = (values, props) => {
    if (!fields) return;

    let errors = {};
    fields.forEach((field) => {
      if (field.required) {
        const _value = values[field.keyName];
        if (_value.length < 1) {
          errors[`${field.keyName}`] = (
            <LanguageProvider id="gen.message.field.required" />
          );
        }
      }
    });

    return errors;
  };

  const formik = useFormik({
    initialValues: [],
    onSubmit: async (values) => {
      console.log("values: ", values);
      if (onFinish) await onFinish(values);
      formik.resetForm();
    },
    validate: validadeForm,
  });

  const loadFields = async () => {
    try {
      setLoadingItems(true);
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Field + "/list-by-database",
        "Platform",
        {
          params: {
            idDatabase: database.id,
            databaseName: database.name,
          },
        }
      );
      if (Array.isArray(data.data)) {
        setFields(data.data);
        let fields = {};

        data.data.forEach((field) => {
          const value = selectedItem[field.keyName] || "";
          fields[field.keyName] = value;
        });

        const _databaseOptions = {};
        for (const field of data.data) {
          if (field.typeValue.name === "database" && field.typeDatabaseId) {
            const data = await getFormattedItems(field.typeDatabaseId);

            _databaseOptions[field.typeDatabaseId] = data.values;
          }
        }
        setDatabasesOptions(_databaseOptions);

        formik.setValues(fields);
      } else {
        setFields([]);
      }
    } catch (error) {
      console.error(error);
      setLoadingItems(false);
    }
    setLoadingItems(false);
  };

  const hide = () => {
    if (onHide) onHide();
  };

  const dialogHeader = () => {
    return <span className="database-name">{database.name}</span>;
  };

  const dialogFooter = () => {
    return (
      <div className="buttons-container">
        <Button
          type="button"
          appearance="subtle"
          className="p-button p-button-text p-button-plain"
          onClick={() => {
            hide();
            formik.resetForm();
          }}
          style={{ width: "unset" }}
          label={<LanguageProvider id="gen.cancel.button" />}
        />
        <Button
          label={<LanguageProvider id="gen.save.button" />}
          onClick={formik.submitForm}
          disabled={!formik.isValid}
          className="finish-btn"
          loading={loading}
        />
      </div>
    );
  };

  useEffect(() => {
    if (visible) {
      formik.resetForm();
      loadFields();
    }
  }, [selectedItem, visible]);

  return (
    <>
      <Dialog
        visible={visible}
        onHide={() => {
          hide();
          formik.resetForm();
        }}
        className="database-new-entry-edit"
        style={{ width: "600px" }}
        contentStyle={{ padding: "0" }}
        header={dialogHeader}
        footer={dialogFooter}
        headerStyle={{
          padding: "30px 40px",
          borderBottom: "1px solid var(--systemBackground)",
        }}
      >
        <div className="fields-fixed-container">
          <form onSubmit={formik.handleSubmit}>
            {fields &&
              !loadingItems &&
              fields.map((field, index) => {
                return (
                  <div key={"field-" + index} className="input-wrapper">
                    <div className="label">
                      <span className="name">
                        {field.required ? "*" : null}
                        {field.viewName}
                        {field.hasTextHelp && (
                          <>
                            <Icon
                              icon={"alert-circle"}
                              size={15}
                              color={"#717BBC"}
                              className={`${field.id}-tooltip help-icon`}
                            />
                          </>
                        )}
                      </span>
                      {field.hasDescription && (
                        <span className="description">{field.description}</span>
                      )}
                    </div>
                    <div>{renderField(field, formik, databasesOptions)}</div>
                  </div>
                );
              })}
          </form>
          {loadingItems && (
            <>
              <div className="input-wrapper skeleton">
                <Skeleton width="10rem" className="label" />
                <Skeleton width="6rem" className="label" />
                <Skeleton height="3rem" className="" />
              </div>
              <div className="input-wrapper skeleton">
                <Skeleton width="10rem" className="label" />
                <Skeleton width="6rem" className="label" />
                <Skeleton height="3rem" className="" />
              </div>
              <div className="input-wrapper skeleton">
                <Skeleton width="10rem" className="label" />
                <Skeleton width="6rem" className="label" />
                <Skeleton height="3rem" className="" />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export const Preview = ({ fields, name }) => {
  return (
    <div className="database-new-entry-edit">
      <div className="p-dialog-header">
        <span className="database-name">
          {name ? name : "Titulo do formulário"}
        </span>
      </div>
      <div className="fields-fixed-container">
        {fields &&
          fields.map((field, index) => {
            return (
              <div key={"field-" + index} className="input-wrapper">
                <div className="label">
                  <span className="name">
                    {field.required ? "*" : null}
                    {field.viewName}
                    {field.hasTextHelp && (
                      <>
                        <Icon
                          icon={"alert-circle"}
                          size={15}
                          color={"#717BBC"}
                          className={`${field.id}-tooltip help-icon`}
                        />
                      </>
                    )}
                  </span>
                  {field.hasDescription && (
                    <span className="description">{field.description}</span>
                  )}
                </div>
                <div>
                  {renderField({
                    ...field,
                    typeValue: { name: field.type },
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
