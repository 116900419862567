import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs.jsx";
import { GenericCounter } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/custom-charts-components/generic-counter";

export default function FinanceCounter(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [title, setTitle] = useState("");

  const locale = "pt-BR";

  useEffect(() => {
    if (useMockData) {
      setCurrentValue(87.43);
      setTitle("Valor Financeiro Atual");
    } else {
      // load from api
      setCurrentValue(0);
      setTitle("");
      // setTitle(getConfigValue(configs, "count-title") ?? "");
    }
  }, [useMockData, configs]);

  function template(value) {
    return `R$ ${value.toLocaleString(locale)}`;
  }

  return (
    <GenericCounter
      title={title}
      value={currentValue}
      valueTemplate={template}
    />
  );
}
