import { DropDownProps } from ".";
import React from "react";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";

export function DropdownField(props: DropDownProps): JSX.Element {
  return (
    <>
      <Dropdown
        tooltip={props.tooltip}
        style={{ width: "100%" }}
        className={classNames({
          "p-invalid": props.error !== null || props.error !== undefined,
        })}
        value={props.value}
        //id={props.fieldKey}
        options={props.options}
        filter
        optionLabel={props.optionLabel}
        optionValue={props.optionLabel}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      {props.error ? (
        <small className="p-error">{props.error}</small>
      ) : (
        <small className="p-error">&nbsp;</small>
      )}
    </>
  );
}
