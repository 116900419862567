import { Dialog } from "primereact/dialog";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import "../scss/rack-example.scss";
import { RackExampleImage } from "~/theme/media/assets/RackExample";

const RackExample = ({ visible, onClose }) => {
  return (
    <Dialog
      className="rack-example-dialog"
      visible={visible}
      draggable="true"
      position="right"
      closable={false}
      modal={false}
      resizable={false}
      onHide={onClose}
      header={
        <div className="rack-example-dialog-header">
          <LanguageProvider id={"gen.rack.example"} />
          <Icon icon={"move"} size={20} color={"#98A2B3"} />
        </div>
      }
      footer={
        <div className="rack-example-dialog-footer">
          <Button label="Ok" onClick={onClose} autoFocus />
        </div>
      }
    >
      <div className="rack-example-dialog-content" alt="Product image">
        <RackExampleImage />
      </div>
    </Dialog>
  );
};

export default RackExample;
