import "./scss/reports.scss";

import { useContext, useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import PageHeader from "~/shared/components/page-header/page-header";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "~/context/ToastContext";
import { ThemeContext, UserContext } from "~/app";
import DcpDeniedAccess from "~/shared/components/dcp-denied-access";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export default function DynamicReports() {
  const { currentLanguage } = useLanguageContext();
  const { userRoles } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const applicationId = propertyFrommStorage("authentication", "applicationId");
  const dcpAxiosService = useDcpAxiosService();
  const Navigation = useNavigate();

  // Utils
  const headerSize = useHeaderHeight();
  const [editScreenOpen, setEditScreenOpen] = useState(false);
  const { showToast } = useToastContext();

  // Data
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [users, setUsers] = useState([]);

  // Loading indicators
  const [loadingReports, setLoadingReports] = useState(null);

  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);

  const onCreateNewReport = () => {
    try {
      Navigation("edit/0");
    } catch (error) {
      console.error(error);
    }
  };

  const actions = () => {
    return (
      <>
        {/* <div className="button-actions">
         <Button onClick={onCreateNewReport}>
           <Icon icon="plus" color="white" />{" "}
           <LanguageProvider id="reports.create" />
         </Button>
       </div> */}
      </>
    );
  };

  const actionsTableColumn = (rowData) => {
    return (
      <div className="table-actions-column">
        <div
          className="action-icon"
          onClick={() => Navigation("edit/" + rowData.id)}
        >
          <Icon icon="edit-02" color={currentTheme.tableIconColor} size={20} />
        </div>
        <div
          className="action-icon"
          onClick={() => {
            setSelectedReport(rowData);
            setDeleteConfirmVisible(true);
          }}
        >
          <Icon icon="trash-02" color={currentTheme.tableIconColor} size={20} />
        </div>
      </div>
    );
  };

  const loadReports = async () => {
    try {
      setLoadingReports(true);
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Report,
        "Platform",
        {
          params: {
            applicationId: applicationId,
          },
        }
      );

      if (status == 200) setReports(data.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingReports(false);
  };

  const loadUsers = async () => {
    try {
      const { data } = await dcpAxiosService.get("user/get-users");
      if (data.status) {
        setUsers(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteReport = async () => {
    try {
      setDeleteConfirmVisible(false);

      const { data, status } = await dcpAxiosService.delete(
        settings.Urls.Rest.Report,
        "Platform",
        {
          params: {
            reportId: selectedReport.id,
          },
        }
      );

      if (status === 200) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "reports.delete.confirm",
            currentLanguage,
          }),
        });
      }

      loadReports();
    } catch (error) {
      console.error(error);
    }
  };

  const RenderUsers = (reportUsers) => {
    const matchingUsers = [];

    for (const reportUser of reportUsers.users) {
      const matchingUser = users.find(
        (user) => user.userId === reportUser.userId
      );

      if (matchingUser) {
        matchingUsers.push(matchingUser);
      }
    }

    const firstFourUsers = matchingUsers.slice(0, 3);
    const remainingUsers = matchingUsers.length - 3;

    return (
      <div className="users">
        {firstFourUsers.map((firstFourUser, index) => {
          return (
            <span key={index}>{firstFourUsers ? firstFourUser.name : ""}</span>
          );
        })}

        {remainingUsers > 0 && (
          <div className="remaining-users">{`+${remainingUsers}`}</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    loadReports();
    loadUsers();
  }, []);

  return (
    <>
      {userRoles.administrator ? (
        <div
          className="dynamic-reports"
          style={{ height: `calc(100vh - ${headerSize}px - 68px)` }}
        >
          <PageHeader
            title={<LanguageProvider id="reports" />}
            recordsCount={reports?.length}
            actions={actions}
          />
          <DataTable
            value={reports}
            paginator
            rows={20}
            scrollable
            scrollHeight="flex"
            loading={loadingReports}
            className="reports-datatable"
          >
            <Column
              header={<LanguageProvider id="reports.title" />}
              field="title"
            />
            <Column
              header={<LanguageProvider id="reports.access" />}
              body={(rowData) => {
                return <RenderUsers users={rowData.reportAccess} />;
              }}
            />
            <Column
              header={<LanguageProvider id="reports.permissions" />}
              body={(rowData) => {
                return (
                  <div
                    className={`access-pill ${
                      rowData.isPublic ? "public" : "private"
                    }`}
                  >
                    {rowData.isPublic ? (
                      <span>
                        <LanguageProvider id="reports.permissions.public" />
                      </span>
                    ) : (
                      <span>
                        <LanguageProvider id="reports.permissions.private" />
                      </span>
                    )}
                  </div>
                );
              }}
            />
            <Column
              header={<LanguageProvider id="gen.create.at" />}
              body={(rowData) => {
                return (
                  <span>{new Date(rowData.createdAt).toLocaleString()}</span>
                );
              }}
            />
            <Column body={actionsTableColumn} style={{ width: "80px" }} />
          </DataTable>

          <ModalConfirmation
            isDelete={true}
            isOpen={deleteConfirmVisible}
            modalTitle={<LanguageProvider id="reports.delete" />}
            bodyMessage={<LanguageProvider id="reports.delete.msg" />}
            onCancel={() => setDeleteConfirmVisible(false)}
            onConfirm={deleteReport}
          ></ModalConfirmation>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: currentTheme.systemBackground,
            color: currentTheme.textPrimary,
            fontSize: "24px",
          }}
        >
          <DcpDeniedAccess />
        </div>
      )}
    </>
  );
}
