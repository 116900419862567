import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { GenericCounter } from "../../custom-charts-components/generic-counter";
import { getConfigValue } from "../../grid-item-helpers";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

export default function DocumentsPrintGenericCounter(configs) {
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const handleCountPendingItems = async () => {
      try {
        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.Report + "/document/count-pending-items",
          "PrintDocument"
        );
        if (status === 200) {
          setCurrentValue(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (useMockData) {
      setCurrentValue(387);
      setTitle(getConfigValue(configs, "count-title") ?? "");
    } else {
      handleCountPendingItems();
      setTitle(getConfigValue(configs, "count-title") ?? "");
    }
  }, [useMockData, configs]);

  return <GenericCounter title={title} value={currentValue} />;
}
