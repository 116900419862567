import React, { useContext } from "react";
import { useEffect } from "react";
import { ThemeContext, UserContext } from "~/app";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";
import DcpDeniedAccess from "~/shared/components/dcp-denied-access";

const IdentificacaoImpressaoInterfaceInventory = () => {
  const { user, userRoles } = useContext(UserContext);

  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();
  const { currentTheme } = useContext(ThemeContext);

  useEffect(() => {
    loadDatabaseProductProcess(
      settings.DatabaseProcessType.Inventory_IdentificationInterface
    );
  }, [loadDatabaseProductProcess]);

  return (
    <>
      {!userRoles.administrator &&
      !userRoles.inventoryManager &&
      !userRoles.receiver ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: currentTheme.systemBackground,
            color: currentTheme.textPrimary,
            fontSize: "24px",
          }}
        >
          <DcpDeniedAccess />
        </div>
      ) : (
        databaseProductProcessData && (
          <DatabaseList
            id={databaseProductProcessData.idDatabase}
            canEdit={false}
            hasDetails={
              databaseProductProcessData.uriDetails.length > 0 ? true : false
            }
            uriDetails={databaseProductProcessData.uriDetails}
            showIcon={false}
          />
        )
      )}
    </>
  );
};

export default IdentificacaoImpressaoInterfaceInventory;
