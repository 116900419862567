import "./item-list-dialog.scss";
import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { GetMenuItems } from "./menu-items";
import { InputText } from "primereact/inputtext";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import Icon from "~/shared/components/icons";
import { ThemeContext } from "~/app";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import * as GridItems from "../grid-item/items";

export function ItemListDialog({ open, onClose, onAddItem, menuItems }) {
  const axiosService = useDcpAxiosService();
  const { currentLanguage } = useLanguageContext();
  const { theme, currentTheme } = useContext(ThemeContext);
  const [itemsList, setItemsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [reports, setReports] = useState([]);
  const [userProducts, setUserProducts] = useState(null);

  useEffect(() => {
    async function load() {
      setReports(await fetchReports(axiosService));
      setUserProducts(await fetchUserProducts(axiosService));
    }
    load();
  }, [axiosService]);

  useEffect(() => {
    if (menuItems) {
      setItemsList(menuItems);
    } else {
      setItemsList(GetMenuItems(reports));
    }
  }, [reports, menuItems]);

  return (
    <>
      <Dialog
        className="item-list-dialog"
        visible={open}
        onClose={onClose}
        onHide={onClose}
        blockScroll={true}
        closeOnEscape={true}
        dismissableMask={true}
        header={LanguageProviderWithoutContext({
          id: "dashboard.menu.title",
          currentLanguage,
        })}
      >
        <div className="search-bar-container">
          <span className="p-input-icon-left">
            <div className="search-icon">
              <Icon
                color={currentTheme.textPrimary}
                icon={"search-md"}
                size={20}
              />
            </div>
            <InputText
              showClear={true}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={LanguageProviderWithoutContext({
                id: "search.field.placeholder",
                currentLanguage,
              })}
            />
          </span>
        </div>
        {Array.isArray(reports) &&
          Array.isArray(userProducts) &&
          itemsList.map((category) => {
            if (
              !userProducts.includes(category.productCode) &&
              category.productCode !== "platform"
            ) {
              return null;
            }

            if (category.items.length === 0) return null;

            let items = category.items;

            const compareNormalized = (target, fragment) => {
              const normalizedTarget = target
                .trim()
                .normalize("NFC")
                .toUpperCase();
              const normalizedSearchFragment = fragment
                .trim()
                .normalize("NFC")
                .toUpperCase();
              return normalizedTarget.includes(normalizedSearchFragment);
            };

            if (searchQuery.length > 0) {
              items = items.filter((item) =>
                compareNormalized(item.label, searchQuery)
              );
            }

            if (items.length === 0) return null;

            return (
              <div className="categories-container" key={category.category}>
                <p className="category-name">{category.label}</p>
                <div className="items-container">
                  {items.map((item, index) => {
                    const thumbnail = require(`./item-thumbnails/${theme}/${category.productCode}/${item.type}.png`);

                    return (
                      <div
                        className="item"
                        key={"item-" + index}
                        onClick={() => onAddItem(item.type)}
                      >
                        <div
                          className="image-container"
                          style={{
                            backgroundImage: `url(${thumbnail})`,
                          }}
                        ></div>
                        <div className="text-container">
                          <span className="item-title">{item.label}</span>
                          <p className="item-description">{item.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </Dialog>
    </>
  );
}

async function fetchReports(axiosService) {
  try {
    const { data, status } = await axiosService.get(
      settings.Urls.Rest.Report,
      "Platform"
    );

    if (status === 200 && Array.isArray(data.data)) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}

async function fetchUserProducts(axiosService) {
  try {
    const { data, status } = await axiosService.get(
      `${settings.Urls.Admin.ApplicationClient}/list-user-products`,
      "Auth"
    );
    if (status === 200 && Array.isArray(data.data))
      return data.data.map((product) => product.code);
    else return [];
  } catch (error) {
    console.error(error);
  }
}
