import { Calendar } from "primereact/calendar";

export function DateConfig({ config, updateValue }) {
  return (
    <Calendar
      value={new Date(config.value)}
      onChange={(e) => updateValue(new Date(e.value).toISOString())}
    />
  );
}
