import "../../scss/entry-dialog.scss";

import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";

import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { useState } from "react";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import LanguageProvider from "~/shared/components/language-provider";

const SetUpEntryRegistrationDialog = ({ visible, onHide, entrySettings }) => {
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const [submitting, setSubmitting] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nf: entrySettings.nf,
      danfe: entrySettings.danfe,
      supplier: entrySettings.supplier,
      batchCode: entrySettings.batchCode,
    },
    onSubmit: (values) => onSaveEntrySettings(values),
  });

  const onSaveEntrySettings = async () => {
    setSubmitting(true);

    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Settings + "/update-entry-settings",
        {
          nf: formik.values.nf,
          danfe: formik.values.danfe,
          supplier: formik.values.supplier,
          batchCode: formik.values.batchCode,
        },
        "Warehouse"
      );

      if (status === 200) {
        showToast({
          severity: "success",
          message: LanguageProvider({
            id: "dcp.platform.warehouse.input.entry.data.saved",
          }),
        });
        onHide();
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      className="entry-dialog"
      visible={visible}
      onHide={onHide}
      header={() => {
        return (
          <div className="entry-dialog-header">
            <span className="entry-dialog-header-title">
              <LanguageProvider
                id={"dcp.platform.warehouse.entry.dialog.entry.configure"}
              />
            </span>
          </div>
        );
      }}
      footer={() => {
        return (
          <div className="entry-dialog-footer">
            <div className="actions">
              <Button
                onClick={onHide}
                label={<LanguageProvider id={"gen.cancel"} />}
                className="p-button p-button-text p-button-plain"
              />
              <Button
                loading={submitting}
                type="submit"
                className="p-button"
                onClick={formik.submitForm}
                label={<LanguageProvider id={"gen.save.button"} />}
              />
            </div>
          </div>
        );
      }}
    >
      <Form className="entry-dialog-content">
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider
                id={"dcp.platform.warehouse.entry.dialog.entry.batch"}
              />
            }
          >
            <InputSwitch
              checked={formik.values.batchCode}
              onChange={(e) => {
                formik.setFieldValue("batchCode", e.value);
              }}
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider id={"dcp.platform.warehouse.entry.dialog.nf"} />
            }
          >
            <InputSwitch
              checked={formik.values.nf}
              onChange={(e) => {
                formik.setFieldValue("nf", e.value);
              }}
              autoFocus
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider
                id={"dcp.platform.warehouse.entry.dialog.danfe"}
              />
            }
          >
            <InputSwitch
              checked={formik.values.danfe}
              onChange={(e) => {
                formik.setFieldValue("danfe", e.value);
              }}
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider
                id={"dcp.platform.warehouse.entry.dialog.provider"}
              />
            }
          >
            <InputSwitch
              checked={formik.values.supplier}
              onChange={(e) => {
                formik.setFieldValue("supplier", e.value);
              }}
            />
          </InputContainer>
        </FormRow>
      </Form>
    </Dialog>
  );
};

export default SetUpEntryRegistrationDialog;
