import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import LanguageProvider from "~/shared/components/language-provider";
import SettingsGeneric from "./components/settings-generic";
import "./scss/settings-data-management.scss";

const SettingsDataManagement = () => {
  // Utils
  const headerHeight = useHeaderHeight();

  return (
    <div className="page-body-data-management">
      <div
        className="settings-data-management"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="container">
          <div className="header">
            <h3>
              <LanguageProvider id="settings.data.management" />
            </h3>
          </div>
          <div className="settings-content">
            <SettingsGeneric specificAppId="1" groupId="16" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsDataManagement;
