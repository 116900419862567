import "./scss/location.scss";

import { useCallback, useContext, useRef, useState } from "react";

import { Galleria } from "primereact/galleria";
import { Panel } from "primereact/panel";
import { useToastContext } from "~/context/ToastContext";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { ReactComponent as AccuracyIcon } from "~/theme/custom-icons/accuracy-icon.svg";
import { ReactComponent as DifferenceIcon } from "~/theme/custom-icons/difference-icon.svg";
import { LocationPicturesView } from "./location-pictures-view";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { InputNumber } from "primereact/inputnumber";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { GetLocationCount } from "~/services/api";
import { InventoryContext } from "~/pages/inventory/inventory/inventory-details/inventory-details";
import { InventoryStatus } from "~/shared/interfaces";
import inventory from "~/pages/inventory/location/inventory";

export function Location({ location = {}, oppened = false }) {
  const panelRef = useRef(null);
  const { showToast } = useToastContext();
  const { inventory } = useContext(InventoryContext);

  // Loading indicators
  const [loading, setLoading] = useState(true);

  // Element visibility
  const [locationPicturesView, setLocationPicturesView] = useState(false);
  const [countPicturesView, setCountPicturesView] = useState(false);
  const [finishCountConfirmation, setFinishCountConfirmation] = useState(false);

  // Data
  const [counts, setCounts] = useState([]);
  const [selectedCount, setSelectedCount] = useState(null);
  const [selectedInventoryLocation, setSelectedInventoryLocation] = useState(
    {}
  );

  const dcpAxiosService = useDcpAxiosService();

  const loadCounts = useCallback(async () => {
    try {
      setLoading(true);

      const counts = await GetLocationCount(location.id);
      setCounts(counts);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [location.id]);

  async function onResultEdit(result) {
    try {
      updateLocalResultValue(result);

      var { data } = await dcpAxiosService.post(
        settings.Urls.Rest.Inventory + "/result-edit",
        result,
        "Inventory"
      );
    } catch (error) {
      console.error(error);
    }
  }

  function updateLocalResultValue(result) {
    var currentValues = [...counts];
    currentValues.forEach((count) => {
      if (count.sku === result.sku) {
        count.result = result;
        count.updatedAt = new Date();
      }
    });
    setCounts(currentValues);
  }

  async function onFinishCount() {
    try {
      const { data } = await dcpAxiosService.post(
        `${settings.Urls.Rest.Inventory}/finish-count`,
        {},
        "Inventory",
        { params: { countId: selectedCount } }
      );

      if (data.data === true) {
        showToast({
          severity: "success",
        });
        loadCounts();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function onExpand() {
    await loadCounts();
  }

  return (
    <Panel
      headerTemplate={(e) => PanelHeaderTemplate(e, location)}
      toggleable
      collapsed={!oppened}
      onExpand={onExpand}
      ref={panelRef}
      className="location-panel"
    >
      <div className="panel">
        <div className="stats">
          <div className="stat">
            <DifferenceIcon />
            <div className="info">
              <p className="value">{location.disagreements}</p>
              <p className="label">
                <LanguageProvider id="inventory.differences" />
              </p>
            </div>
          </div>
          <div className="stat">
            <AccuracyIcon />
            <div className="info">
              <p className="value">{location.accuracyPercent}%</p>
              <p className="label">
                <LanguageProvider id="inventory.accuracy" />
              </p>
            </div>
          </div>
          <Button
            className={"p-button-secondary galery"}
            disabled={false}
            onClick={() => {
              setSelectedInventoryLocation(location);
              setLocationPicturesView(true);
            }}
          >
            <Icon icon="image-01" color="#4146FF" size={20} />
            <p className="button-text">
              <LanguageProvider id="gen.galery" />
            </p>
          </Button>
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <div className="location-table">
            <div className="main-column column">
              <div className="column-header">
                <p className="text">WMS</p>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>
                      <LanguageProvider id="gen.status" />
                    </th>
                    <th>
                      <LanguageProvider id="inventory.read.type.sku" />
                    </th>
                    <th>
                      <LanguageProvider id="inventory.system.qtd" />
                    </th>
                    <th>
                      <LanguageProvider id="inventory.result" />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                {counts.map((row) => {
                  const statusIcon = [
                    <Icon
                      icon="check-circle"
                      color="var(--green-500)"
                      size={20}
                    />,
                    <Icon icon="x-circle" color="var(--red-500)" size={20} />,
                    <Icon icon="edit-05" color="var(--blue-600)" size={20} />,
                  ];

                  const allowUpdate = row.result.allowManualUpdate;

                  return (
                    <tbody key={`sku-table-row-${row.sku}`}>
                      <tr>
                        <td>{statusIcon[row.status]}</td>
                        <td>
                          {row.sku === ""
                            ? LanguageProvider({ id: "gen.no.sku" })
                            : row.sku}
                        </td>
                        <td>{row.systemQty}</td>
                        <td>
                          <EditResultButton
                            result={row.result}
                            onClick={onResultEdit}
                          ></EditResultButton>
                        </td>
                        <td>
                          <span
                            style={{ display: allowUpdate ? "block" : "none" }}
                          >
                            <Icon icon="edit-02" color={"var(--gray-500)"} />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
            <div className="users">
              <div className="users-container">
                {counts &&
                  counts.length > 0 &&
                  new Array(counts[0].counts.length).fill(0).map((_, index) => {
                    const isLast = index === counts.length - 1;

                    var userCounts = counts.map((x) => x.counts[index]);

                    if (!userCounts) return null;

                    var userName = userCounts[0].userName;
                    var userId = userCounts[0].userId;
                    var finishDate = userCounts[0].finishDate;
                    var inventoryCountId = userCounts[0].inventoryCountId;

                    var finishCountTooltipMsg = finishDate
                      ? `${LanguageProvider({
                          id: "inventory.finished.count",
                        })} ${new Date(finishDate).toLocaleDateString()}`
                      : LanguageProvider({
                          id: "inventory.finish.count",
                        });

                    return (
                      <div
                        key={`user-${userId}`}
                        className="user-column column"
                      >
                        <div
                          className={`column-header ${isLast ? "last" : ""}`}
                        >
                          <p className="text">{userName ?? "User"}</p>
                          <div className="icons">
                            <Button
                              tooltip={LanguageProvider({
                                id: "inventory.see.pictures",
                              })}
                              tooltipOptions={{
                                position: "bottom",
                              }}
                              className={"p-button-secondary galery"}
                              disabled={true}
                              onClick={() => {
                                setSelectedCount(inventoryCountId);
                                setCountPicturesView(true);
                              }}
                            >
                              <Icon icon="image-01" color="#4146FF" size={20} />
                            </Button>
                            <Button
                              tooltip={finishCountTooltipMsg}
                              tooltipOptions={{
                                position: "bottom",
                                showOnDisabled: true,
                              }}
                              className={"finish"}
                              disabled={finishDate !== null}
                              onClick={() => {
                                setSelectedCount(inventoryCountId);
                                setFinishCountConfirmation(true);
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.3334 8.33398V4.53398C13.3334 3.41388 13.3334 2.85383 13.1154 2.426C12.9237 2.04968 12.6177 1.74372 12.2414 1.55197C11.8136 1.33398 11.2535 1.33398 10.1334 1.33398H5.86675C4.74664 1.33398 4.18659 1.33398 3.75877 1.55197C3.38244 1.74372 3.07648 2.04968 2.88473 2.426C2.66675 2.85383 2.66675 3.41388 2.66675 4.53398V11.4673C2.66675 12.5874 2.66675 13.1475 2.88473 13.5753C3.07648 13.9516 3.38244 14.2576 3.75877 14.4493C4.18659 14.6673 4.74664 14.6673 5.86675 14.6673H8.00008M5.66675 7.33398H5.33341M5.66675 10.0007H5.33341M5.66675 4.66732H5.33341M9.66675 12.6673L11.0001 14.0007L14.0001 11.0007M10.6667 7.33398H8.00008M10.6667 10.0007H8.00008M10.6667 4.66732H8.00008"
                                  stroke={
                                    finishDate !== null ? "#98A2B3" : "white"
                                  }
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </div>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <LanguageProvider id="inventory.count" />
                              </th>
                              <th>
                                <LanguageProvider id="inventory.difference" />
                              </th>
                            </tr>
                          </thead>
                          {userCounts.map((row, index) => {
                            let count = "-";
                            if (row.hasCount && row.count > 0)
                              count = row.count;
                            else if (row.hasCount && row.count === 0)
                              count = LanguageProvider({ id: "gen.empty" });

                            return (
                              <tbody key={`count-row-${row.sku}`}>
                                <tr>
                                  <td>{count}</td>
                                  <td>{row.hasCount ? row.difference : "-"}</td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>

      <LocationPicturesView
        isVisible={locationPicturesView}
        onHide={() => setLocationPicturesView(false)}
        inventoryLocation={selectedInventoryLocation}
      />

      <div
        className="gallery-modal"
        style={{ display: countPicturesView ? "flex" : "none" }}
      >
        <div className="close-icon" onClick={() => setCountPicturesView(false)}>
          <Icon icon="x" color="white" size={38} />
        </div>
        <div className="gallery">
          <Galleria
            showItemNavigators
            circular
            responsiveOptions={responsiveOptions}
            style={{ maxWidth: "1000px" }}
            value={
              // selectedCount && selectedCount.images ? selectedCount.images : []
              []
            }
            numVisible={5}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          ></Galleria>
        </div>
      </div>

      <ModalConfirmation
        modalTitle={<LanguageProvider id="inventory.finish.count.title" />}
        bodyMessage={
          <>
            <p style={{ margin: 0, color: "#667085" }}>
              <LanguageProvider id="inventory.finish.count.body" />
            </p>
            <br />
            <p style={{ margin: 0, color: "#667085" }}>
              <LanguageProvider id="inventory.finish.count.body.warning" />
            </p>
          </>
        }
        isOpen={finishCountConfirmation}
        onCancel={() => setFinishCountConfirmation(false)}
        onConfirm={onFinishCount}
      />
    </Panel>
  );
}

function EditResultButton({ result, onClick }) {
  const [value, setValue] = useState(result.result);
  const [active, setActive] = useState(false);

  const blocked =
    !result.allowManualUpdate ||
    inventory.status === InventoryStatus.Canceled ||
    inventory.status === InventoryStatus.Finished;

  return (
    <div className="edit-result-button">
      <Inplace
        disabled={blocked}
        active={active}
        onToggle={(e) => setActive(e.value)}
      >
        <InplaceDisplay>{result.result}</InplaceDisplay>
        <InplaceContent>
          <InputNumber
            value={value}
            onChange={(e) => setValue(e.value)}
            autofocus
          />
          <Button disabled={value === result.result}>
            <Icon
              icon={"check"}
              color={"white"}
              onClick={() => {
                result.result = value;
                result.updatedAt = new Date();
                if (onClick) {
                  onClick(result);
                  setActive(false);
                }
              }}
            />
          </Button>
          <Button>
            <Icon
              icon={"x"}
              color={"white"}
              onClick={() => {
                setActive(false);
              }}
            />
          </Button>
        </InplaceContent>
      </Inplace>
    </div>
  );
}

function PanelHeaderTemplate(options, location) {
  const toggleIcon = options.collapsed ? "chevron-right" : "chevron-down";

  return (
    <div className={`location-panel-header`}>
      <div
        className={`${options.togglerClassName} icon`}
        onClick={options.onTogglerClick}
        onKeyDown={(e) => {}}
      >
        <Icon icon={toggleIcon} />
      </div>
      <p className="label">{location.location.name}</p>
      <LocationStatus location={location} />
    </div>
  );
}

function LocationStatus({ location }) {
  const status = {
    1: {
      code: "audited",
      label: <LanguageProvider id="inventory.audited" />,
      icon: <Icon icon="edit-05" color="var(--blue-600)" size={20} />,
    },
    2: {
      code: "matches",
      label: <LanguageProvider id="inventory.matches" />,
      icon: <Icon icon="check-circle" color="var(--green-500)" size={20} />,
    },
    3: {
      code: "divergent",
      label: <LanguageProvider id="inventory.divergent" />,
      icon: <Icon icon="x-circle" color="var(--red-500)" size={20} />,
    },
  };

  const currentStatus = status[location.locationStatus];
  return (
    <div className={`status ${currentStatus.code}`}>
      {currentStatus ? currentStatus.icon : null}
      <p className="label">{currentStatus ? currentStatus.label : ""}</p>
    </div>
  );
}

const itemTemplate = (item) => {
  return <img src={item} alt={item} style={{ width: "100%" }} />;
};

const thumbnailTemplate = (item) => {
  return <img src={item} alt={item} style={{ width: "20%" }} />;
};

const responsiveOptions = [
  {
    breakpoint: "991px",
    numVisible: 4,
  },
  {
    breakpoint: "767px",
    numVisible: 3,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
  },
];
