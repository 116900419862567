import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { getConfigValue } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/mes/productivity-hour-bar.json";
import "./ProductivityHourBar.scss";
import { ThemeContext } from "~/app";

export default function ProductivityHourBar(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({});
  const [totalProduced, setTotalProduced] = useState(0);
  const [totalBatches, setTotalBatches] = useState(0);

  useEffect(() => {
    if (useMockData) {
      setTotalBatches(580);
      setTotalProduced(352.5);
      setData(fakeData);
    } else {
      const tonThreshold = getConfigValue(configs, "ton-threshold") ?? [0, 0];
      setTotalBatches(0);
      setTotalProduced(0);
      // get data from api
      const apiData = {};
      setData(apiData);
    }
  }, [useMockData, configs]);

  const options = {
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardDataColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
  };

  return (
    <div className="productivity-hour-bar">
      <Chart
        className="chart"
        type="bar"
        data={data}
        options={options}
        height="100%"
        width="100%"
      />
      <div className="legend">
        <p>
          <span>Total Produzido: </span>
          {totalProduced.toFixed(1)}
        </p>
        <p>
          <span>Total Bateladas: </span>
          {totalBatches}
        </p>
      </div>
    </div>
  );
}
