export const defaultChartOptions = {
  maintainAspectRatio: false,
  animation: false,
  plugins: {
    legend: {
      labels: {
        usePointStyle: false,
        borderRadius: 6,
        boxHeight: 12,
        boxWidth: 12,
        useBorderRadius: true,
      },
    },
  },
  datasets: {
    bar: {
      borderRadius: 3,
    },
  },
  elements: {
    point: {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
};

export const CHART_COLORS = [
  "rgb(255, 159, 64)",
  "rgb(3, 206, 183)",
  "rgb(54, 162, 235)",
  "rgb(153, 102, 255)",
  "rgb(255, 99, 132)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
  "rgb(201, 203, 207)",
  "rgb(255, 159, 64)",
  "rgb(3, 206, 183)",
  "rgb(54, 162, 235)",
  "rgb(153, 102, 255)",
  "rgb(255, 99, 132)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
];

export function getConfigValue(configs, configName) {
  const config = configs.find(
    (config) => config.defaultConfig.name === configName
  );
  if (config) {
    switch (config.defaultConfig.valueType) {
      case "array":
        return JSON.parse(config.value);
      case "timestamp":
        return new Date(config.value);
      default:
        return config.value;
    }
  }
  return null;
}

export function getRandomData(length, min, max) {
  const data = [];
  for (let i = 0; i < length; i++) {
    const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
    data.push(randomInt);
  }
  return data;
}

export function generateHeatMapFakeDataset() {
  const data = [];

  for (let i = 0; i < 4; i++) {
    let yLabel = "A" + (i + 1);
    const rowData = [];

    for (let j = 0; j < 24; j++) {
      const xLabel = j.toString().padStart(2, "0") + ":00";
      rowData.push({
        x: xLabel,
        y: getRandomData(1, 0, 100)[0],
      });
    }

    data.push({
      name: yLabel,
      data: rowData,
    });
  }

  return data;
}
