import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import LanguageProvider from "~/shared/components/language-provider";
import SettingsGeneric from "./components/settings-generic";
import "./scss/settings-grafana.scss";

const SettingsGrafana = () => {
  // Utils
  const headerHeight = useHeaderHeight();

  return (
    <div className="page-body-settings-grafana">
      <div
        className="settings-grafana"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="container">
          <div className="header">
            <h3>
              <LanguageProvider id="settings.grafana" />
            </h3>
          </div>
          <div className="settings-content">
            <SettingsGeneric specificAppId="1" groupId="11" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsGrafana;
