import "./scss/area-edit.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AreaSideMenu } from "./components/area-side-menu";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import { Button } from "~/shared/components/dcp-button";
import { CreateGroup } from "./components/create-group";
import LanguageProvider from "~/shared/components/language-provider";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import settings from "~/services/settings.json";
import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import { useToastContext } from "~/context/ToastContext";
import { Item, WarehouseMap } from "./components/warehouse-map";
import { listMapItems } from "~/services/api/inventory/area";
import { listFloorAreas } from "~/services/api/platform/floor-area";
import { FloorAreaModel, MapItemType } from "~/shared/interfaces";
import { generateUniqueKey } from "~/shared/helpers/generator";
import PageHeader from "~/shared/components/page-header/page-header";

export function AreaEdit() {
  // Other
  const { idAreaMap } = useParams();
  const headerSize = useHeaderHeight();
  const navigate = useNavigate();

  // Data
  const [selectedAreaMap, setSelectedAreaMap] = useState<any>({});
  const [areaItems, setAreaItems] = useState<Item[]>();
  const [selectedRackGroup, setSelectedRackGroup] = useState<any>();
  const [groups, setGroups] = useState([]);
  const [floorAreas, setFloorAreas] = useState<FloorAreaModel[]>([]);

  // Eelement visibility
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const { showToast } = useToastContext();
  const [deleteConfirmaitionOpen, setDeleteConfirmaitionOpen] = useState(false);

  const dcpAxiosService = useDcpAxiosService();

  const onSaveArea = async () => {
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.AreaMap + "/map-items",
        areaItems,
        "Platform"
      );

      if (status === 200) {
        showToast({
          severity: "success",
          message: <LanguageProvider id="area.map.save.msg" />,
        });
        await loadMapItems();
      } else {
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="" />,
      });
    }
  };

  const loadMapItems = async () => {
    try {
      let mapItems = await listMapItems(idAreaMap);
      if (!mapItems) return;

      let items: Item[] = [];

      for (const item of mapItems) {
        items.push({
          ...item,
          i: "",
        });
      }

      items = addReandomKeyToItems(mapItems);
      setAreaItems(items);
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id={"unhandled.error"} />,
      });
    }
    setLoading(false);
  };

  const loadAreaMap = async () => {
    setLoading(true);
    try {
      if (!idAreaMap) return;

      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Rest.AreaMap}`,
        "Platform",
        { params: { areaId: idAreaMap } }
      );

      if (data.data) {
        setSelectedAreaMap(data.data);
        return data.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadGroups = async () => {
    try {
      setLoadingGroups(true);
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.AreaGroup}/list`,
        "Platform",
        { params: { areaId: idAreaMap } }
      );

      if (Array.isArray(data.data)) {
        setGroups(data.data);
        setLoadingGroups(false);
        return data.data;
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingGroups(false);
  };

  const loadFloorAreas = async () => {
    try {
      const data = await listFloorAreas();
      if (floorAreas) setFloorAreas(data);
    } catch (error) {}
  };

  const addGroupToArea = (group) => {
    // check if group is already on area
    const currentItem = areaItems.find(
      (ai) => ai.area_group_id === group.areaGroup.id && ai.id >= 0
    );
    if (currentItem) return;

    const newItem = {
      i: generateUniqueKey(areaItems, "i"),
      name: group.areaGroup.name,
      id: 0,
      x: 0,
      y: 0,
      h: 1,
      w: getGroupLength(group.groupRacks),
      orientation: "horizontal",
      area_id: selectedAreaMap.id,
      area_group_id: group.areaGroup.id,
      floor_area_id: 0,
      type: MapItemType.Rack,
      color: "",
    };

    //const updatedItems = getUpdatedItemsPos(areaItems, layoutPos);
    setAreaItems([...areaItems, newItem]);
  };

  const checkFloorAreaIsAdded = (floorArea: FloorAreaModel): boolean => {
    return !!areaItems.find(
      (ai) => ai.floor_area_id === floorArea.id && ai.id > 0
    );
  };

  const addFloorArea = (floorArea: FloorAreaModel) => {
    try {
      if (checkFloorAreaIsAdded(floorArea)) return;

      const width = Math.round(floorArea.totalWidthInCm / 100);
      const height = Math.round(floorArea.totalLengthInCm / 100);

      const newItem = {
        i: generateUniqueKey(areaItems, "i"),
        name: floorArea.name,
        id: 0,
        x: 0,
        y: 0,
        h: height,
        w: width,
        orientation: "horizontal",
        area_id: selectedAreaMap.id,
        area_group_id: 0,
        floor_area_id: floorArea.id,
        type: MapItemType.Floor,
        color: floorArea.color,
      };
      setAreaItems([...areaItems, newItem]);
    } catch (error) {
      console.error(error);
    }
  };

  const onLayoutChange = (items) => {
    let _areaItems = [...areaItems];

    for (let i = 0; i < _areaItems.length; i++) {
      const item = items.find((x) => x.i === _areaItems[i].i);
      if (item) {
        _areaItems[i].x = item.x;
        _areaItems[i].y = item.y;
      }
    }

    setAreaItems(_areaItems);
  };

  const onRemoveItemFromArea = (key) => {
    try {
      const _areaItems = [...areaItems];
      for (let i = 0; i < _areaItems.length; i++) {
        const item = _areaItems[i];
        if (item.i === key) {
          if (item.id === 0) _areaItems.splice(i, 1);
          else if (item.id > 0) _areaItems[i].id *= -1;
        }
      }
      setAreaItems(_areaItems);
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteRackGroup = async () => {
    try {
      setDeleting(true);
      onRemoveItemFromArea(selectedRackGroup);

      if (!selectedRackGroup || !selectedRackGroup.areaGroup) return;

      const { data, status } = await dcpAxiosService.delete(
        settings.Urls.Rest.AreaGroup + "/delete",
        "Platform",
        {
          params: {
            id: selectedRackGroup.areaGroup.id,
          },
        }
      );
      if (status === 200) {
        showToast({
          severity: "success",
          message: (
            <LanguageProvider id="area.map.delete.group.delete.success" />
          ),
        });
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
      setDeleteConfirmaitionOpen(false);
      setDeleting(false);
      loadGroups();
    } catch (error) {
      console.error(error);
    }
  };

  const onEdit = (item: Item) => {
    if (item.type === MapItemType.Rack) {
      const group = groups.find((g) => g.areaGroup.id === item.area_group_id);
      if (group) {
        onRemoveItemFromArea(item.i);
        onEditRackGroup(group);
      }
    } else if (item.type === MapItemType.Floor) {
      // edit floorArea
    }
  };

  const onDeleteGroup = async (group) => {
    const item = areaItems.find((ai) => ai.area_group_id === group.id);
    if (item) onRemoveItemFromArea(item.i);
    setSelectedRackGroup(null);
    setIsCreatingGroup(false);
    await loadGroups();
    await loadMapItems();
  };

  const onDuplicateGroup = async () => {
    setIsCreatingGroup(false);
    await loadGroups();
    await loadMapItems();
  };

  const onEditRackGroup = (group) => {
    try {
      if (!group) return;
      setSelectedRackGroup(group);
      setIsCreatingGroup(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onFlip = (key) => {
    try {
      let _areaItems = [...areaItems];

      for (let i = 0; i < _areaItems.length; i++) {
        if (_areaItems[i].i === key) {
          _areaItems.push({
            ..._areaItems[i],
            h: _areaItems[i].w,
            w: _areaItems[i].h,
            orientation:
              _areaItems[i].orientation === "horizontal"
                ? "vertical"
                : "horizontal",
          });

          _areaItems.splice(i, 1);
        }
      }

      setAreaItems(_areaItems);
    } catch (error) {
      console.error(error);
    }
  };

  const onGoBack = async () => {
    try {
      if (isCreatingGroup) {
        setIsCreatingGroup(false);
        await loadGroups();
        await loadMapItems();
      } else {
        navigate("../");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function load() {
      await loadAreaMap();
      await loadGroups();
      await loadFloorAreas();
      await loadMapItems();
    }
    load();
  }, []);

  return (
    <div
      className="area-edit"
      style={{ height: `calc(100vh - ${headerSize}px)` }}
    >
      {!isCreatingGroup && (
        <AreaSideMenu
          height={() => {
            const size = document.querySelector<HTMLElement>(".area-edit");
            if (size) return size.offsetHeight;
          }}
          groups={groups}
          floorAreas={floorAreas}
          onCreateGroup={async () => {
            setSelectedRackGroup(null);
            setIsCreatingGroup(true);
          }}
          loading={loadingGroups}
          areaItems={areaItems}
          onAddGroupToArea={addGroupToArea}
          onAddFloorArea={addFloorArea}
          onGroupEdit={(group) => onEditRackGroup(group)}
        ></AreaSideMenu>
      )}

      <div
        className="area-edit-container"
        style={{ marginRight: isCreatingGroup ? "0" : "65px" }}
      >
        <div className="area-edit-screen">
          <div className="top-bar">
            <PageHeader
              title={`${selectedAreaMap.name}`}
              titleTemplate={undefined}
              recordsCount={undefined}
              onReturn={onGoBack}
              actions={undefined}
            ></PageHeader>
          </div>
          <div className="bottom-container">
            {isCreatingGroup ? (
              <CreateGroup
                area={selectedAreaMap}
                group={selectedRackGroup}
                onCancel={async () => {
                  setIsCreatingGroup(false);
                  await loadMapItems();
                }}
                onCreate={async () => {
                  await loadGroups();
                  await loadMapItems();
                }}
                onDelete={onDeleteGroup}
                onDuplicate={onDuplicateGroup}
              />
            ) : (
              <>
                <div className="map-edit-container">
                  <div className="right-column">
                    <div className="map-container">
                      <WarehouseMap
                        items={areaItems}
                        isStatic={false}
                        onUpdate={onLayoutChange}
                        onFlip={onFlip}
                        onDelete={onRemoveItemFromArea}
                        onEdit={onEdit}
                      />
                    </div>
                    <div className="buttons-bar">
                      <div className="button-container">
                        <Button onClick={onSaveArea}>
                          <LanguageProvider id="gen.save.button" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <ModalConfirmation
            isOpen={deleteConfirmaitionOpen}
            modalTitle={<LanguageProvider id="area.map.delete.group" />}
            bodyMessage={<LanguageProvider id="area.map.delete.group.msg" />}
            onCancel={() => setDeleteConfirmaitionOpen(false)}
            isDelete={true}
            onConfirm={onDeleteRackGroup}
            loading={deleting}
          />
        </div>
      </div>
    </div>
  );
}

// function getUpdatedItemsPos(items, layoutPos) {
//   try {
//     let updatedItems = [];
//     items.forEach((item) => {
//       const pos = layoutPos.find(
//         (lp) => lp.areaGroupId === item.areaGroupId && lp.id === item.id
//       );
//       if (pos) {
//         updatedItems.push({
//           ...item,
//           posX: pos.posX,
//           posY: pos.posY,
//         });
//       } else {
//         updatedItems.push(item);
//       }
//     });

//     return updatedItems;
//   } catch (error) {
//     console.error(error);
//   }
// }

function addReandomKeyToItems(items) {
  for (const item of items) {
    item["i"] = "";
  }

  for (const item of items) {
    item.i = generateUniqueKey(items, "i");
  }
  return items;
}

function getGroupLength(groupRacks: any[]) {
  try {
    if (!Array.isArray(groupRacks) || groupRacks.length < 1) return 0;

    let groupLength = 0;
    groupRacks.forEach((gr) => {
      groupLength += gr.rackType.width;
    });
    return Math.round(groupLength / 100);
  } catch (error) {
    console.error(error);
  }
}
