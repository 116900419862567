import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import {
  Database,
  DatabaseItem,
  DatabaseUploadedFile,
  FormatedDatabaseItems,
  ListFieldModel,
} from "~/shared/interfaces";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

/**
 * Retrieves a database by its ID.
 * @param databaseId - The ID of the database to retrieve.
 * @returns A Promise that resolves to the retrieved Database object.
 */
export async function getDatabase(databaseId: number): Promise<Database> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Databases}/get`,
      "Platform",
      {
        params: {
          idDatabase: databaseId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves all databases.
 * @returns A Promise that resolves to an array of Database objects.
 */
export async function getDatabases(): Promise<Database> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Databases}/list-databases`,
      "Platform"
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves all databases including mains.
 * @returns A Promise that resolves to an array of Database objects.
 */
export async function getDatabasesMain(): Promise<Database> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Databases}/list-databases`,
      "Platform",
      {
        params: {
          showMainDatabases: true,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves database items based on the provided parameters.
 * @param databaseId - The ID of the database.
 * @param parentItemId - The ID of the parent item.
 * @param filter - The filter object containing additional parameters.
 * @returns A Promise that resolves to the retrieved database items.
 */
export async function getDatabaseItems(
  databaseId: number,
  parentItemId: number,
  filter: any
): Promise<any> {
  try {
    if (!Array.isArray(filter.filters)) filter.filters = [];

    const { data } = await axiosService().post(
      `${settings.Urls.Rest.DatabaseItem}/list-database-items`,
      filter,
      "Platform",
      {
        params: {
          idDatabase: databaseId,
          idParentDatabaseItemId: parentItemId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function getFormattedItems(
  databaseId: number
): Promise<FormatedDatabaseItems> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.DatabaseItem}/get-formated-items`,
      "Platform",
      {
        params: {
          databaseId: databaseId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function getDatabaseFields(
  databaseId: number
): Promise<ListFieldModel> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Field}/list-by-database`,
      "Platform",
      {
        params: {
          idDatabase: databaseId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function getHistoricUploadedFilesByDatabaseId(
  databaseId: number
): Promise<DatabaseUploadedFile> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.DatabaseItem}/list-database-uploaded-files`,
      "Platform",
      {
        params: {
          idDatabase: databaseId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

export async function checkDatabaseName(
  databaseId: number,
  databaseName: string
): Promise<Database> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Databases}/check-name`,
      "Platform",
      {
        params: { idDatabase: databaseId, databaseName: databaseName },
      }
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function saveDatabaseItem(payload: DatabaseItem): Promise<any> {
  try {
    const { status } = await axiosService().post(
      `${settings.Urls.Rest.DatabaseItem}/create-database-item`,
      payload,
      "Platform"
    );

    return status === 200;
  } catch (error) {
    console.error(error);
  }
}

export async function saveDatabaseUploadedFile(
  idDatabase,
  fileName,
  rowsCount
): Promise<any> {
  try {
    const { status, data } = await axiosService().post(
      `${settings.Urls.Rest.DatabaseItem}/create-database-uploaded-file?idDatabase=` +
        idDatabase +
        `&fileName=` +
        fileName +
        `&rowsCount=` +
        rowsCount,
      null,
      "Platform"
    );

    if (data.data) return data.data;
  } catch (error) {
    console.error(error);
  }
}

export async function saveDatabaseItemsFromUploadedFile(
  payload: DatabaseItem,
  signal?: AbortSignal | null
): Promise<any> {
  try {
    const { status } = await axiosService().post(
      `${settings.Urls.Rest.DatabaseItem}/create-database-items-from-uploaded-file`,
      payload,
      "Platform",
      {
        signal,
      }
    );

    return status === 200;
  } catch (error) {
    if (error.name === "CanceledError") {
      console.warn("Request was canceled");
      return null;
    }
    console.error(error);
  }
}

export async function deleteDatabaseItem(
  itemId: number,
  databaseId: number,
  databaseName: string
): Promise<any> {
  try {
    const { status } = await axiosService().post(
      `${settings.Urls.Rest.DatabaseItem}/delete-database-item`,
      [itemId],
      "Platform",
      {
        params: {
          idDatabase: databaseId,
          databaseName: databaseName,
        },
      }
    );

    return status === 200;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteDatabaseItemsFromUploadedFile(
  idDatabase: number,
  idDatabaseFileUploaded: number
): Promise<any> {
  try {
    const { status } = await axiosService().post(
      `${settings.Urls.Rest.DatabaseItem}/delete-database-uploaded-files-items`,
      [idDatabase],
      "Platform",
      {
        params: {
          idDatabase: idDatabase,
          idDatabaseFileUploaded: idDatabaseFileUploaded,
        },
      }
    );

    return status === 200;
  } catch (error) {
    console.error(error);
  }
}

export async function createDatabase(model: any): Promise<any> {
  try {
    const { data } = await axiosService().post(
      `${settings.Urls.Rest.Databases}/create`,
      model,
      "Platform"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function createDatabseItemsByList(model: any): Promise<boolean> {
  try {
    const { data } = await axiosService().post(
      `${settings.Urls.Rest.Field}/create-by-list`,
      model,
      "Platform"
    );

    return data.status;
  } catch (error) {
    console.error(error);
  }
}
