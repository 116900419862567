import { useEffect, useState } from "react";
import "./scss/map.scss";
import { Item, WarehouseMap } from "../../area-map/components/warehouse-map";
import React from "react";
import { listMapItems } from "~/services/api/inventory/area";
import { generateUniqueKey } from "~/shared/helpers/generator";

export function Map({ area, groups = [], exceptions = [], onExceptionClick }) {
  const [areaItems, setAreaItems] = useState<Item[]>();

  async function loadItems() {
    try {
      const data = await listMapItems(area.id);
      if (!data) return;

      const items: Item[] = [];

      for (const item of data) {
        items.push({
          ...item,
          i: "",
          group: {},
        });
      }

      for (const item of items) {
        item.i = generateUniqueKey(items, "i");
        const group = groups.find((g) => g.areaGroup.id === item.areaGroupId);
        if (group) item.group = group;
      }

      setAreaItems(items);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (area) loadItems();
  }, [area]);

  return (
    <div className="location-map">
      <WarehouseMap
        isStatic={true}
        items={areaItems}
        exceptions={exceptions}
        onExceptionClick={onExceptionClick}
        onUpdate={() => {}}
        onFlip={() => {}}
        onDelete={() => {}}
        onEdit={() => {}}
      />
    </div>
  );
}
