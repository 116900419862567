import "./grid-item.scss";
import settings from "~/services/settings.json";

import { useEffect, useRef, useState } from "react";
import { Button } from "~/shared/components/dcp-button";
import { Checkbox } from "primereact/checkbox";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { Menu } from "primereact/menu";
import { ThemeContext, UserContext } from "~/app";
import { classNames } from "primereact/utils";
import { useContext } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

export function GridItem({
  item,
  type,
  onStatic,
  onConfig,
  onDelete,
  onRefresh,
  onDuplicate,
  onAiAnalysis,
  disabledMenu = false,
  inEditMode = false,
  gridItemComponent = () => {},
}) {
  const dcpAxiosService = useDcpAxiosService();
  const { userRoles } = useContext(UserContext);
  const menuRef = useRef(null);
  const [key, setKey] = useState(Math.floor(Math.random() * 1000000));
  const { currentTheme } = useContext(ThemeContext);
  const [iaService, setIaService] = useState(false);

  const renderCardContent = () => {
    if (gridItemComponent) {
      if (type.name === "title")
        return gridItemComponent(item.title, item.configValues);
      else return gridItemComponent(item.configValues);
    }
  };

  const handleCheckIAService = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Admin.Client + "/check-ia-service",
        "Auth"
      );
      if (status === 200) {
        setIaService(data.data);
      } else {
        console.error("Error checking IA service");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionTemplate = (
    options,
    color,
    label,
    icon,
    onClick = () => {},
    toggle
  ) => {
    return (
      <div
        className={classNames(options.className) + " menu-option"}
        style={{
          color,
          fontSize: "16px",
          display: "flex",
          gap: "12px",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
          menuRef.current.hide(e);
        }}
      >
        <span
          className="icon"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Icon icon={icon} color={color} size="16px" />
        </span>
        <span className="label">{LanguageProvider({ id: label })}</span>
        {toggle && (
          <span className="checkbox-wrapper">
            <Checkbox checked={item.isStatic} />
          </span>
        )}
      </div>
    );
  };

  const menuItems = [
    {
      template: (_item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "dashboard.update",
          "refresh-cw-01",
          () => {
            setKey(key + 1);
            onRefresh(item);
          }
        ),
    },
    {
      template: (_item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "dashboard.filter",
          "filter-funnel-02",
          () => onConfig(item)
        ),
    },
    {
      template: (_item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          item.isStatic ? "dashboard.unfix" : "dashboard.fix",
          item.isStatic ? "pin-01" : "pin-02",
          () => onStatic(item),
          true
        ),
    },
    {
      template: (_item, options) =>
        optionTemplate(
          options,
          "#4146FF",
          "gen.message.duplicate",
          "copy-03",
          () => onDuplicate(item)
        ),
    },
    { separator: true },
    {
      template: (_item, options) =>
        optionTemplate(
          options,
          "#F9485B",
          "gen.exclude.button",
          "trash-02",
          () => onDelete(item)
        ),
    },
  ];

  const previewMenuItems = [
    {
      template: (_item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "dashboard.update",
          "refresh-cw-01",
          () => {
            setKey(key + 1);
            onRefresh(item);
          }
        ),
    },
    {
      template: (_item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "dashboard.filter",
          "filter-funnel-02",
          () => onConfig(item)
        ),
    },
  ];

  // Change the cardBackground if is text
  const isText = type.name === "text";
  const isTitle = type.name === "title";

  let backgroundColor = "#FFFFFF";
  let border = "1px solid var(--painelBorderColor)";

  if (isText) {
    const bgConfigColor = item.configValues.find(
      (config) => config.defaultConfig.name === "background-color"
    );
    if (bgConfigColor) backgroundColor = `#${bgConfigColor.value}`;
  }

  useEffect(() => {
    handleCheckIAService();
  }, []);

  return (
    <div
      className={`dashboard-grid-item ${
        type.name === "title" ? "title" : null
      }`}
      style={{
        background: isText ? backgroundColor : null,
        border: isTitle && !inEditMode ? "none" : border,
      }}
    >
      {item && (
        <>
          <div className="header">
            <p>{item.title}</p>
            <div className="dashboard-grid-item-header-actions">
              {type.ai &&
              (userRoles.administratorOrInventoryManager ||
                userRoles.administrator ||
                userRoles.inventoryManager)
                ? iaService && (
                    <Button
                      className="btn-ia-analytic"
                      appearance="terciary"
                      onClick={() => {
                        onAiAnalysis(item);
                      }}
                    >
                      <Icon
                        icon={"lightbulb-02"}
                        color={currentTheme.iconDefaultColor}
                      />
                    </Button>
                  )
                : null}
              <Button
                className="menu-btn p-button-plain"
                link
                appearance="secondary"
                onClick={(e) => menuRef.current.toggle(e)}
              >
                <Icon
                  icon="dots-vertical"
                  size="20px"
                  color={currentTheme.iconDefaultColor}
                />
              </Button>
              <Menu
                style={{ width: "250px" }}
                popup
                model={disabledMenu ? previewMenuItems : menuItems}
                ref={menuRef}
              />
            </div>
          </div>
          <div key={key} className="item-content">
            {renderCardContent()}
          </div>
        </>
      )}
    </div>
  );
}
