import React, { createContext, useEffect, useState } from "react";
import DcpAxiosService, {
  useDcpAxiosService,
} from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { AndonSettings } from "~/shared/interfaces/sequence.ts";

export const AndonSettingsContext = createContext<AndonSettings>(
  {} as AndonSettings
);

export function AndonSettingsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const axiosService = useDcpAxiosService();
  const [andonSettings, setAndonSettings] = useState<AndonSettings>(
    {} as AndonSettings
  );

  useEffect(() => {
    fetchDashboardSettings(axiosService).then((settings) =>
      setAndonSettings(settings)
    );
  }, [axiosService]);

  return (
    <AndonSettingsContext.Provider value={andonSettings}>
      {children}
    </AndonSettingsContext.Provider>
  );
}

async function fetchDashboardSettings(
  axiosService: DcpAxiosService
): Promise<AndonSettings> {
  try {
    const { data } = await axiosService.get(
      settings.Urls.Rest.Sequence + "/andon/dashboard/settings",
      "Sequence"
    );

    if (data.data) return data.data;
  } catch (error) {
    console.error(error);
  }
}
