import { createContext, useContext, useEffect, useState } from "react";

import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import { useToastContext } from "./ToastContext";
import { get } from "@maxgraph/core";
import { getDatabaseItems } from "~/services/api";
import { useLanguageContext } from "./LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export const MenuItemsContext = createContext(null);

export function MenuItemsContextProvider({ children }) {
  const dcpAxiosService = useDcpAxiosService();

  const { currentLanguage } = useLanguageContext();
  const [databases, setDatabases] = useState([]);
  const [datalakes, setDatalakes] = useState([]);
  const [dashboards, setDashoards] = useState([]);
  // Print product template tags
  const [printDataTags, setPrintDataTags] = useState([]);

  const [loading, setLoading] = useState(false);

  const { showToast } = useToastContext();

  const loadMenuItems = async () => {
    try {
      setLoading(true);

      // Load Dashboards
      const dashboardResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Dashboard + "/list",
        "Platform"
      );
      if (dashboardResponse.status === 200) {
        setDashoards(dashboardResponse.data.data);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={dashboardResponse.data.message} />,
        });
      }

      // Load databases
      const databaseResponse = await dcpAxiosService.get(
        `${settings.Urls.Rest.Databases}/list-databases`,
        "Platform",
        {
          params: {
            showMainDatabases: false,
          },
        }
      );
      if (databaseResponse.data.status) {
        setDatabases(databaseResponse.data.data);
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: "database.get.list.error",
            currentLanguage,
          }),
        });
      }

      // Load Datalakes
      const DatalakesResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Datalake + "/list",
        "Platform"
      );
      if (DatalakesResponse.status === 200) {
        setDatalakes(DatalakesResponse.data.data);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={DatalakesResponse.data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const loadTemplateTags = async () => {
    try {
      setLoading(true);

      const { data: templateTagsProccessTypeResponse } =
        await dcpAxiosService.get(
          `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
          "Platform",
          {
            params: {
              databaseProductProcessType:
                settings.DatabaseProcessType.Print_DadosImpressao,
            },
          }
        );

      const templateTagsResponse = await dcpAxiosService.get(
        `${settings.Urls.Rest.DatabaseItem}/list-database-items`,
        "Platform",
        {
          params: {
            idDatabase: templateTagsProccessTypeResponse.data.idDatabase,
          },
        }
      );

      if (templateTagsResponse.status === 200) {
        const { headers } = templateTagsResponse.data.data;
        const columnsValues = [];

        headers.forEach((header) => {
          columnsValues.push(header.columnName);
        });
        setPrintDataTags(columnsValues);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={templateTagsResponse.data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <MenuItemsContext.Provider
      value={{
        datalakes,
        databases,
        dashboards,
        printDataTags,
        loading,
        loadMenuItems,
        loadTemplateTags,
      }}
    >
      {children}
    </MenuItemsContext.Provider>
  );
}

export function useMenuItemsContext() {
  const context = useContext(MenuItemsContext);

  if (!context) {
    throw new Error(
      "useMenuItemsContext have to be used within MenuItemsContextProvider"
    );
  }

  return context;
}
