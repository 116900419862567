import "../scss/massive-print-dialog.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import settings from "~/services/settings.json";
import qs from "qs";
import { propertyFrommStorage } from "~/services/storage/storage-access";

export const MassivePrintDialog = ({
  isOpen,
  onClose,
  massivePrintDataSelected,
}) => {
  const axiosService = useDcpAxiosService();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );
  // Loading
  const [loading, setLoading] = useState(false);
  const [disablePrintButton, setDisablePrintButton] = useState(true);
  const [interfacePrintData, setInterfacePrintData] = useState([]);
  const [printDriverValueInSettings, setPrintDriverValueInSettings] =
    useState("");
  const [printerValues, setPrinterValues] = useState([]);
  const [printerOptions, setPrinterOptions] = useState([]);
  const [printerSelected, setPrinterSelected] = useState();

  const handleMassivePrint = async () => {
    try {
      const selectedPrinter = printerOptions.find(
        (printer) => printer.id === printerSelected
      ).name;
      const printerValuesSelected = printerValues.find(
        (printer) => printer.code === selectedPrinter.split(" - ")[0]
      );

      const returnDataToMassivePrint = {
        printer: selectedPrinter,
        printerValues: printerValuesSelected,
        interfacePrintData: interfacePrintData,
        printDriverValueInSettings: printDriverValueInSettings,
      };

      massivePrintDataSelected(returnDataToMassivePrint);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  function findSettingsValueByGroupName(
    settingsResponse,
    groupName,
    subgroupName,
    propertyName
  ) {
    return settingsResponse.data.data
      .find((group) => group.name === groupName)
      ?.subgroups.find((subgroup) => subgroup.name === subgroupName)
      ?.settings.find((setting) => setting.propertyName === propertyName)
      ?.value;
  }

  const getInterfacePrintData = async () => {
    try {
      setLoading(true);
      const { data: databaseProductProcess } = await axiosService.get(
        `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
        "Platform",
        {
          params: {
            databaseProductProcessType:
              settings.DatabaseProcessType.Inventory_IdentificationInterface,
          },
        }
      );
      const { data, status } = await axiosService.get(
        settings.Urls.Rest.DatabaseItem + "/list-database-items",
        "Platform",
        {
          params: {
            idDatabase: databaseProductProcess.data.idDatabase,
            databaseName: databaseProductProcess.data.nameDatabase,
          },
        }
      );
      const settingsResponse = await axiosService.get(
        settings.Urls.Rest.Settings + "/list-settings",
        "Platform",
        {
          params: {
            applicationClientId: applicationClientId,
          },
        }
      );

      // Printer interface data
      const columnNameValueInSettings = findSettingsValueByGroupName(
        settingsResponse,
        "interface-impressao",
        "inventory-interface-impressao-fields",
        "fields-print-interface-sku"
      );
      const columnCodeValueInSettings = findSettingsValueByGroupName(
        settingsResponse,
        "interface-impressao",
        "inventory-interface-impressao-fields",
        "fields-print-interface-pallet"
      );

      // Printer driver url
      const printerDriverValueInSettings = findSettingsValueByGroupName(
        settingsResponse,
        "interface-impressao",
        "inventory-interface-impressao",
        "fields-print-driver"
      );

      setPrintDriverValueInSettings(printerDriverValueInSettings);

      if (status === 200) {
        const codeColumnName = data.data?.headers?.find(
          (column) => column.columnName === columnCodeValueInSettings
        ).columnKey;
        const labelColumnName = data.data?.headers?.find(
          (column) => column.columnName === columnNameValueInSettings
        ).columnKey;

        const formattedValues = data.data.items.map((item) => ({
          name: item.columns[labelColumnName],
          value: item.columns[codeColumnName],
        }));
        setInterfacePrintData(formattedValues);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeSkuDropdown = async (e) => {
    const valueSelected = e.target.value;
    if (valueSelected) {
      setPrinterSelected(valueSelected);
      setDisablePrintButton(false);
    }
  };

  function findColumnKeyByName(data, targetName) {
    const columnKey = data.find(
      (column) => column.columnName === targetName
    ).columnKey;
    if (columnKey) {
      return columnKey;
    }
    return null;
  }

  useEffect(() => {
    const getPrinterValues = async () => {
      try {
        const { data: databaseProductProcess } = await axiosService.get(
          `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
          "Platform",
          {
            params: {
              databaseProductProcessType:
                settings.DatabaseProcessType.Inventory_Printers,
            },
          }
        );
        const {
          data: databaseItemsResponse,
          status: databaseItemsResponseStatus,
        } = await axiosService.get(
          settings.Urls.Rest.DatabaseItem + "/list-database-items",
          "Platform",
          {
            params: {
              idDatabase: databaseProductProcess.data.idDatabase,
              databaseName: databaseProductProcess.data.nameDatabase,
            },
          }
        );
        if (databaseItemsResponseStatus) {
          const codeColumnKey = findColumnKeyByName(
            databaseItemsResponse.data.headers,
            "Código"
          );
          const nameColumnKey = findColumnKeyByName(
            databaseItemsResponse.data.headers,
            "Nome"
          );
          const portColumnKey = findColumnKeyByName(
            databaseItemsResponse.data.headers,
            "Número Porta"
          );
          const ipColumnKey = findColumnKeyByName(
            databaseItemsResponse.data.headers,
            "IP"
          );

          const printersDatabaseRegisters =
            databaseItemsResponse.data.items.map((item) => ({
              code: item.columns[codeColumnKey],
              name: item.columns[nameColumnKey],
              port: item.columns[portColumnKey],
              ip: item.columns[ipColumnKey],
            }));

          setPrinterValues(printersDatabaseRegisters);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getPrintOptionValues = async () => {
      try {
        setLoading(true);
        const { data, status } = await axiosService.get(
          `${settings.Urls.Rest.Connection}/list-data`,
          "Platform",
          {
            params: {
              connectionCode: ["printers-inventory"],
            },
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: "repeat" }),
          }
        );

        let printersFormattedOptions = [];
        if (status === 200) {
          for (const item of data.data) {
            if (item.applicationConnectionCode === "printers-inventory") {
              printersFormattedOptions = Object.entries(item.values).map(
                ([id, name]) => ({
                  id: parseInt(id),
                  name: name,
                })
              );
            }
          }
          setPrinterOptions(printersFormattedOptions);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getPrintOptionValues();
    getPrinterValues();
    getInterfacePrintData();
  }, []);

  return (
    <Dialog
      visible={isOpen}
      onHide={onClose}
      className="massive-print-dialog"
      headerStyle={{ padding: "20px" }}
      contentStyle={{
        paddingTop: "20px",
        borderBottom: "1px solid var(--systemBackground)",
        borderTop: "1px solid var(--systemBackground)",
        padding: "30px 0px 0px 0px",
      }}
      header={
        <>
          <span className="dashboards-header">
            <LanguageProvider id={"gen.massive.identification.print"} />
          </span>
        </>
      }
      onClick={(e) => e.stopPropagation()}
    >
      <div className="massive-print-selected-option">
        <div className="massive-print-selected-form-wrapper">
          <Form>
            <FormRow>
              <InputContainer
                label={
                  <LanguageProvider
                    id={"inventory.print.identification.print.massive.printer"}
                  />
                }
              >
                <Dropdown
                  id="printer"
                  placeholder={LanguageProvider({
                    id: "gen.placeholder.select",
                  })}
                  options={printerOptions}
                  onChange={(e) => handleChangeSkuDropdown(e)}
                  value={printerSelected}
                  optionLabel="name"
                  optionValue="id"
                  loading={loading}
                />
              </InputContainer>
            </FormRow>
          </Form>
        </div>
        <div className="modal-footer-wrapper">
          <div className="modal-footer">
            <div className="dcp-btn-wrapper-manage-modal">
              <Button
                label={LanguageProvider({ id: "gen.cancel" })}
                className="p-button p-button-text p-button-plain"
                onClick={() => {
                  onClose();
                }}
              />
              <Button
                label={LanguageProvider({ id: "gen.print.button" })}
                onClick={handleMassivePrint}
                type="submit"
                className="p-button"
                disabled={disablePrintButton}
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
