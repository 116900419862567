import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import {
  CHART_COLORS,
  defaultChartOptions,
} from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import { ThemeContext } from "~/app";

export default function UserShippedItems(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (useMockData) {
      const fakeData = [
        {
          user: "Usuário 1",
          value: 80,
        },
        {
          user: "Usuário 2",
          value: 10,
        },
        {
          user: "Usuário 3",
          value: 40,
        },
        {
          user: "Usuário 4",
          value: 50,
        },
      ];
      setData(fakeData);
    } else {
      // load from api
      setData([]);
    }
  }, [useMockData]);

  const datasets = {
    labels: data.map((item) => item.user),
    datasets: [
      {
        label: "Itens expedidos",
        data: data.map((item) => item.value),
        backgroundColor: CHART_COLORS[0],
      },
    ],
  };

  const options = {
    ...defaultChartOptions,
    indexAxis: "y",
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={datasets}
      options={options}
      width="100%"
      height="100%"
    />
  );
}
