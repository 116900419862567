import DcpAxiosService from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import {
  ComponentPositionInfo,
  OperationAlert,
} from "~/shared/interfaces/sequence.ts";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function getComponentPositions(): Promise<
  ComponentPositionInfo[]
> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Sequence + "/andon/component",
      "Sequence"
    );

    if (data.data && status === 200) return data.data;
    else return [];
  } catch (e) {
    console.error(e);
  }
}

export async function getOperationAlerts(): Promise<OperationAlert[]> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Sequence + "/andon/alerts",
      "Sequence"
    );

    if (data.data && status === 200) return data.data;
    else return [];
  } catch (e) {
    console.error(e);
  }
}
