import * as Dcp from "~/shared/components/dcp-button";

import { memo, useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";

import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import { ThemeContext, UserContext } from "~/app";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import Icon from "~/shared/components/icons";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import LanguageProvider from "../../../../shared/components/language-provider";

const DashboardCard = ({ model, onUpdateModel, roles }) => {
  const { id, name, isFavorite } = model;
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { user, userRoles } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);

  const dcpAxiosService = useDcpAxiosService();

  // Dialog controll
  const [favoriteConfirmationOpen, setFavoriteConfirmationOpen] =
    useState(false);
  const [unfavoriteConfirmationOpen, setUnfavoriteConfirmationOpen] =
    useState(false);
  const [duplicateConfirmationOpen, setDuplicateConfirmationOpen] =
    useState(false);
  const [accessEditModalOpen, setAccessEditModalOpen] = useState(false);

  const [submiting, setSubmiting] = useState(false);

  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { showToast } = useToastContext();
  const formik = useFormik({
    initialValues: {
      name: model.name,
      dashboardAccess: model.dashboardAccess,
    },
    validate: (values) => {
      let errors = {};

      if (!values.name || values.name.length < 1) {
        errors.name = <LanguageProvider id={"gen.message.field.required"} />;
      }
      return errors;
    },
    onSubmit: ({ name }) => onUpdate(name),
  });

  const onUpdate = async () => {
    setSubmiting(true);
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Dashboard,
        {
          id: model.id,
          isPublic: model.isPublic,
          name: formik.values.name,
          dashboardAccess: formik.values.dashboardAccess,
          dashboardApplication: model.dashboardApplication,
        },
        "Platform"
      );

      onUpdateModel();
      if (status == 200) {
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setEditModalOpen(false);
    setSubmiting(false);
  };

  const onDuplicate = async () => {
    setSubmiting(true);
    try {
      const { response } = await dcpAxiosService.post(
        settings.Urls.Rest.Dashboard + "/duplicate",
        { params: { id: model.id } },
        "Platform"
      );
      setDuplicateConfirmationOpen(false);
      onUpdateModel();
    } catch (error) {
      console.error(error);
    }
    setSubmiting(false);
  };

  const onClose = (e) => {
    setEditModalOpen(false);
    formik.resetForm();
  };

  const onFavorite = async () => {
    setSubmiting(true);
    try {
      const { response } = await dcpAxiosService.post(
        `${settings.Urls.Rest.DashboardFavorite}/create`,
        {
          id: 0,
          clientId: "00000000-0000-0000-0000-000000000000",
          dashboardId: model.id,
          createdAt: undefined,
        },
        "Platform"
      );
      setFavoriteConfirmationOpen(false);
      onUpdateModel();
      if (response.status) {
        showToast({
          severity: "success",
          message: <LanguageProvider id={"dashboard.favorite.success"} />,
        });
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={response.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setSubmiting(false);
  };

  const onUnfavorite = async () => {
    setSubmiting(true);
    try {
      const { response } = await dcpAxiosService.delete(
        settings.Urls.Rest.DashboardFavorite + "/remove",
        "Platform",
        { params: { id: model.id } }
      );
      setUnfavoriteConfirmationOpen(false);
      onUpdateModel();
      if (response.status) {
        showToast({
          severity: "success",
          message: <LanguageProvider id={"dashboard.unfavorite.success"} />,
        });
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={response.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setSubmiting(false);
  };

  const optionTemplate = (options, color, label, onClick = () => {}) => {
    return (
      <div
        className={classNames(options.className)}
        style={{ color, fontSize: "14px" }}
        onClick={onClick}
      >
        {LanguageProvider({ id: label })}
      </div>
    );
  };

  const menuItems = [
    ...(userRoles.administratorOrInventoryManager ||
    userRoles.administrator ||
    userRoles.inventoryManager ||
    userRoles.receiver
      ? [
          {
            template: (item, options) =>
              optionTemplate(
                options,
                currentTheme.dashboardDataColor,
                "gen.edit.button",
                () => setEditModalOpen(true)
              ),
          },
        ]
      : []),
    {
      template: (item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "dashboard.configure.access",
          () => {
            setAccessEditModalOpen(true);
          }
        ),
    },
    {
      template: (item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "gen.message.duplicate",
          () => setDuplicateConfirmationOpen(true)
        ),
    },
    {
      template: (item, options) =>
        optionTemplate(options, "#F9485B", "dashboard.delete", () =>
          setDeleteConfirmationOpen(true)
        ),
    },
  ];

  const onDelete = async () => {
    setSubmiting(true);
    try {
      const { response } = await dcpAxiosService.delete(
        settings.Urls.Rest.Dashboard,
        "Platform",
        { params: { id: model.id } }
      );
      setDeleteConfirmationOpen(false);
      onUpdateModel();
    } catch (error) {
      console.error(error);
    }
    setSubmiting(false);
  };

  return (
    <div
      className="card dashboard-card fade-in"
      onClick={() => {
        if (
          !editModalOpen &&
          !deleteConfirmationOpen &&
          !duplicateConfirmationOpen &&
          !favoriteConfirmationOpen &&
          !unfavoriteConfirmationOpen &&
          !accessEditModalOpen
        ) {
          navigate("dashboard-graphs/" + id);
        }
      }}
    >
      <div className="dashboard-card-header">
        <div className="dashboard-info">
          <div className="dashboard-card-actions">
            <Button
              title={
                isFavorite ? (
                  <LanguageProvider id={"dashboard.unfavorite"} />
                ) : (
                  <LanguageProvider id={"dashboard.favorite"} />
                )
              }
              className={`btn btn-favorite ${isFavorite && "checked"}`}
              onClick={(e) => {
                e.stopPropagation();
                isFavorite
                  ? setUnfavoriteConfirmationOpen(true)
                  : setFavoriteConfirmationOpen(true);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_5905_190397)">
                  <path
                    d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                    fill="#000"
                  />
                  <g clipPath="url(#clip1_5905_190397)">
                    <path
                      d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                      fill="#000"
                    />
                  </g>
                </g>
              </svg>
            </Button>
            <div
              className="popup_menu_right"
              aria-controls="popup_menu_right"
              aria-haspopup
              onClick={(e) => {
                menuRef.current.toggle(e);
                e.stopPropagation();
              }}
            >
              <Icon icon={"dots-vertical"} size={20} color={"#8189A3"} />
              <Menu popup ref={menuRef} model={menuItems} />
            </div>
          </div>
          <div className="dashboard-name">{name}</div>
        </div>
      </div>
      <div className="dashboard-card-icon">
        <svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.036 26.25H6.58195C5.88345 26.25 5.5342 26.25 5.26741 26.1138C5.03273 25.9939 4.84194 25.8027 4.72236 25.5675C4.58643 25.3001 4.58643 24.9501 4.58643 24.25V3.75M27.036 8.75L20.2584 15.5429C20.0114 15.7904 19.8879 15.9142 19.7455 15.9605C19.6203 16.0013 19.4854 16.0013 19.3601 15.9605C19.2177 15.9142 19.0943 15.7904 18.8473 15.5429L16.5168 13.2071C16.2698 12.9596 16.1463 12.8358 16.0039 12.7895C15.8787 12.7487 15.7438 12.7487 15.6185 12.7895C15.4761 12.8358 15.3527 12.9596 15.1057 13.2071L9.57523 18.75M27.036 8.75H22.0472M27.036 8.75V13.75"
            stroke="#9B8AFB"
            stroke-width="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="dashboard-card-background">
        <svg
          width="109"
          height="88"
          viewBox="0 0 109 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.20099e-05 80.4025C8.22099e-05 84.6172 3.02996 88 6.79506 88C30.572 88 98.8405 88 109 88C109 59.7712 79.431 76.2977 59.6517 39.4718C39.8725 2.64588 19.9862 0 9.17945e-05 0C-7.34868e-05 24.511 2.57639e-05 61.2936 7.20099e-05 80.4025Z"
            fill={currentTheme.dashboardProductBackgroundColor}
          />
        </svg>
      </div>
      <Dialog
        visible={editModalOpen}
        onHide={onClose}
        headerStyle={{ padding: "20px" }}
        contentStyle={{
          paddingTop: "20px",
          borderBottom: "1px solid var(--systemBackground)",
          borderTop: "1px solid var(--systemBackground)",
        }}
        header={
          <span className="dashboards-header">
            <LanguageProvider id="dashboard.edit" />
          </span>
        }
        footer={
          <span>
            <Button
              text
              style={{ marginTop: "20px" }}
              className="p-button-plain"
              label={<LanguageProvider id="gen.cancel" />}
              onClick={onClose}
            ></Button>
            <Button
              label={<LanguageProvider id="gen.save.button" />}
              onClick={(e) => {
                formik.submitForm();
              }}
              loading={submiting}
            ></Button>
          </span>
        }
      >
        <Form style={{ padding: 0 }} onClick={(e) => e.stopPropagation()}>
          <FormRow>
            <InputContainer label={<LanguageProvider id="gen.name" />}>
              <InputText
                width="700px"
                value={formik.values.name}
                style={{ minWidth: "540px" }}
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
              />
              {formik.touched.name && formik.errors.name ? (
                <small className="p-error">{formik.errors.name}</small>
              ) : (
                <small className="p-error">&nbsp;</small>
              )}
            </InputContainer>
          </FormRow>
        </Form>
      </Dialog>
      <DashboardAccessDialog
        visible={accessEditModalOpen}
        onCancel={() => setAccessEditModalOpen(false)}
        onSave={(e) => {
          formik.setFieldValue("dashboardAccess", e);
          formik.submitForm();
        }}
        roles={roles}
        model={model}
      />
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        isDelete={true}
        modalTitle={<LanguageProvider id="dashboard.delete" />}
        bodyMessage={<LanguageProvider id="dashboard.delete.msg" />}
        onCancel={(e) => {
          setDeleteConfirmationOpen(false);
        }}
        onConfirm={onDelete}
        loading={submiting}
      ></ModalConfirmation>
      <ModalConfirmation
        isOpen={duplicateConfirmationOpen}
        isDelete={false}
        modalTitle={<LanguageProvider id="dashboard.duplicate" />}
        bodyMessage={<LanguageProvider id="dashboard.duplicate.msg" />}
        onCancel={(e) => {
          setDuplicateConfirmationOpen(false);
        }}
        onConfirm={onDuplicate}
        loading={submiting}
      ></ModalConfirmation>
      <ModalConfirmation
        isOpen={favoriteConfirmationOpen}
        isDelete={false}
        modalTitle={<LanguageProvider id="dashboard.favorite" />}
        bodyMessage={<LanguageProvider id="dashboard.favorite.msg" />}
        onCancel={(e) => {
          setFavoriteConfirmationOpen(false);
        }}
        onConfirm={onFavorite}
        loading={submiting}
      ></ModalConfirmation>
      <ModalConfirmation
        isOpen={unfavoriteConfirmationOpen}
        isDelete={false}
        modalTitle={<LanguageProvider id="dashboard.unfavorite" />}
        bodyMessage={<LanguageProvider id="dashboard.unfavorite.msg" />}
        onCancel={(e) => {
          setUnfavoriteConfirmationOpen(false);
        }}
        onConfirm={onUnfavorite}
        loading={submiting}
      ></ModalConfirmation>
    </div>
  );
};

export function DashboardAccessDialog({
  visible = false,
  onSave = () => {},
  onCancel = () => {},
  roles = [],
  model = {},
  loading = false,
}) {
  const [selectedRole, setSelectedRole] = useState();
  const { user } = useContext(UserContext);

  const save = () => {
    try {
      let dashboardAccess = [];

      if (selectedRole) {
        dashboardAccess = selectedRole.map((role) => {
          return {
            id: 0,
            isRole: true,
            identifier: String(role.id),
          };
        });
      } else {
        dashboardAccess.push({
          id: 0,
          isRole: false,
          identifier: "",
        });
      }
      onSave(dashboardAccess);
    } catch (error) {
      console.error(error);
    }
  };

  const dialogHeader = () => {
    return (
      <span>{<LanguageProvider id="dashboard.access.config.header" />}</span>
    );
  };

  const dialogFooter = () => {
    return (
      <div
        className="buttons"
        style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
      >
        <Dcp.Button
          className="p-button-plain p-button-text"
          label={<LanguageProvider id="gen.cancel" />}
          onClick={onCancel}
        />
        <Dcp.Button
          label={<LanguageProvider id="gen.confirm" />}
          onClick={save}
          loading={loading}
        />
      </div>
    );
  };

  const setupCurrentRoles = () => {
    try {
      const currentRoles = [];
      for (const role of roles) {
        const findRole = model.dashboardAccess.find(
          (access) => access.isRole && parseInt(access.identifier) == role.id
        );
        if (findRole) currentRoles.push(role);
      }
      setSelectedRole(currentRoles);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setupCurrentRoles();
  }, [model]);

  return (
    <Dialog
      className="dashboard-access-dialog"
      visible={visible}
      onHide={onCancel}
      headerStyle={{ padding: "20px" }}
      style={{ width: "30vw" }}
      header={dialogHeader}
      footer={dialogFooter}
      contentStyle={{
        paddingTop: "20px",
        borderBottom: "1px solid var(--systemBackground)",
        borderTop: "1px solid var(--systemBackground)",
      }}
    >
      <Form>
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider id="dashboard.access.config.select.role" />
            }
          >
            <MultiSelect
              style={{
                border: "none",
                background: "var(--systemBackground)",
                width: "100%",
                maxWidth: "592px",
              }}
              display="chip"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.value)}
              options={roles}
              optionLabel={"code"}
              showClear
            ></MultiSelect>
          </InputContainer>
        </FormRow>
      </Form>
    </Dialog>
  );
}

export default memo(DashboardCard);
