export const colors = {
  blue100: "#BEDDFD",
  blue400: "#58A6FB",

  blueGray50: "#edeff6",
  blueGray100: "#c8cce3",
  blueGray200: "#aeb4d6",
  blueGray300: "#8891c3",
  blueGray400: "#717cb8",
  blueGray500: "#4e5ba6",
  blueGray600: "#475397",
  blueGray700: "#374176",
  blueGray800: "#2b325b",
  blueGray900: "#212646",

  dark50: "#EBECEE",
  dark100: "#C0C4CA",
  dark200: "#A2A7B0",
  dark300: "#777F8C",
  dark400: "#5D6676",
  dark500: "#344054",
  dark600: "#2F3A4C",
  dark700: "#252D3C",
  dark800: "#1D232E",
  dark900: "#161B23",

  indigo25: "#F5F8FF",
  indigo50: "#eff1fe",
  indigo100: "#ced3fb",
  indigo200: "#b6bef9",
  indigo300: "#95a1f7",
  indigo400: "#818ef5",
  indigo500: "#6172f3",
  indigo600: "#5868dd",
  indigo700: "#4551ad",
  indigo800: "#353f86",
  indigo900: "#293066",

  primary25: "#F3F5FF",
  primary50: "#ecedff",
  primary100: "#c4c6ff",
  primary200: "#a8aaff",
  primary300: "#8083ff",
  primary400: "#676bff",
  primary500: "#4146ff",
  primary600: "#3b40e8",
  primary700: "#2e32b5",
  primary800: "#24278c",
  primary900: "#1b1d6b",

  white: "#FFFFFF",
  gray05: "#ECEEF9",
  gray25: "#FCFCFD",
  gray50: "#F9FAFB",
  gray60: "#e5e6ff",
  gray100: "#F2F4F7",
  gray200: "#EAECF0",
  gray300: "#D0D5DD",
  gray350: "#dee0e3",
  gray400: "#98A2B3",
  gray500: "#667085",
  gray600: "#010102",
  gray700: "#344054",
  gray800: "#1D2939",
  gray900: "#101828",

  error25: "#FFF9FA",
  error50: "#FDE9EB",
  error100: "#F8BAC0",
  error200: "#F598A2",
  error300: "#F06978",
  error400: "#ED4C5D",
  error500: "#E91F35",
  error600: "#D41C30",
  error700: "#A51626",
  error800: "#80111D",
  error900: "#620D16",

  warning25: "#FFFCF5",
  warning50: "#FEF7E9",
  warning100: "#FAE5BA",
  warning200: "#F8D899",
  warning300: "#F5C66B",
  warning400: "#F3BB4E",
  warning500: "#F0AA22",
  warning600: "#DA9B1F",
  warning700: "#AA7918",
  warning800: "#845E13",
  warning900: "#65470E",

  success25: "#EEFFFD",
  success50: "#E6FAF8",
  success100: "#B1F0E9",
  success200: "#8BE8DE",
  success300: "#56DECF",
  success400: "#35D8C5",
  success500: "#03CEB7",
  success600: "#03BBA7",
  success700: "#029282",
  success800: "#027165",
  success900: "#01574D",

  blueberry25: "#F4F7FF",
  blueberry50: "#EDF2FF",
  blueberry100: "#C7D7FF",
  blueberry200: "#ACC3FF",
  blueberry300: "#86A8FF",
  blueberry400: "#6E97FF",
  blueberry500: "#4A7DFF",
  blueberry600: "#4372E8",
  blueberry700: "#3559B5",
  blueberry800: "#29458C",
  blueberry900: "#1F356B",

  purple25: "#FAFAFF",
  purple50: "#F2EFFE",
  purple100: "#D6CCFD",
  purple200: "#C2B3FC",
  purple300: "#A690FA",
  purple400: "#957BF9",
  purple500: "#7A5AF8",
  purple600: "#6F52E2",
  purple700: "#5740B0",
  purple800: "#4A1FB8",
  purple900: "#332668",

  brightGreen25: "#FBFFF4",
  brightGreen50: "#F5FBE9",
  brightGreen100: "#E0F1BC",
  brightGreen200: "#D1EB9B",
  brightGreen300: "#BDE26E",
  brightGreen400: "#B0DC51",
  brightGreen500: "#9CD326",
  brightGreen600: "#8EC023",
  brightGreen700: "#6F961B",
  brightGreen800: "#567415",
  brightGreen900: "#425910",

  green25: "#F5FEFB",
  green50: "#E6F7F1",
  green100: "#B1E5D5",
  green200: "#8CD8C0",
  green300: "#57C6A4",
  green400: "#36BB92",
  green500: "#04AA77",
  green600: "#049B6C",
  green700: "#037954",
  green800: "#025E41",
  green900: "#024732",

  aquamarine25: "#F3FFFE",
  aquamarine50: "#EDFAF9",
  aquamarine100: "#C8EFED",
  aquamarine200: "#AEE8E5",
  aquamarine300: "#88DDD9",
  aquamarine400: "#71D6D1",
  aquamarine500: "#4ECCC6",
  aquamarine600: "#47BAB4",
  aquamarine700: "#37918D",
  aquamarine800: "#2B706D",
  aquamarine900: "#215653",

  saladish25: "#FEFCF3",
  saladish50: "#FAF8EC",
  saladish100: "#EFE8C4",
  saladish200: "#E7DDA8",
  saladish300: "#DBCE80",
  saladish400: "#D5C567",
  saladish500: "#CAB641",
  saladish600: "#B8A63B",
  saladish700: "#8F812E",
  saladish800: "#6F6424",
  saladish900: "#554C1B",

  yellow25: "#FFFDF5",
  yellow50: "#FFFAE6",
  yellow100: "#FFEFB0",
  yellow200: "#FFE78A",
  yellow300: "#FFDC54",
  yellow400: "#FFD533",
  yellow500: "#FFCF0F",
  yellow600: "#E8B900",
  yellow700: "#B59000",
  yellow800: "#8C7000",
  yellow900: "#6B5500",

  lightTaupe25: "#FEF9F7",
  lightTaupe50: "#F8F3F0",
  lightTaupe100: "#E9D9D2",
  lightTaupe200: "#DFC6BC",
  lightTaupe300: "#D0AC9D",
  lightTaupe400: "#C79C8A",
  lightTaupe500: "#B9836D",
  lightTaupe600: "#A87763",
  lightTaupe700: "#835D4D",
  lightTaupe800: "#66483C",
  lightTaupe900: "#4E372E",

  orange25: "#FFF7F4",
  orange50: "#FFF0EA",
  orange100: "#FFCFBE",
  orange200: "#FFB89F",
  orange300: "#FF9773",
  orange400: "#FF8358",
  orange500: "#FF642E",
  orange600: "#E85B2A",
  orange700: "#B54721",
  orange800: "#8C3719",
  orange900: "#6B2A13",

  sunset25: "#FFF6F6",
  sunset50: "#FFF1F1",
  sunset100: "#FFD4D4",
  sunset200: "#FFC0C0",
  sunset300: "#FFA3A3",
  sunset400: "#FF9191",
  sunset500: "#FF7575",
  sunset600: "#E86A6A",
  sunset700: "#B55353",
  sunset800: "#8C4040",
  sunset900: "#6B3131",

  ros25: "#FFF5F6",
  ros50: "#FEECF0",
  ros100: "#FCC3D0",
  ros200: "#FBA6BA",
  ros300: "#F97D9A",
  ros400: "#F86486",
  ros500: "#F63D68",
  ros600: "#E0385F",
  ros700: "#AF2B4A",
  ros800: "#872239",
  ros900: "#671A2C",

  pink25: "#FEF6FB",
  pink50: "#FDEDF8",
  pink100: "#FAC6EA",
  pink200: "#F7AAE0",
  pink300: "#F483D2",
  pink400: "#F16BC9",
  pink500: "#EE46BC",
  pink600: "#D940AB",
  pink700: "#A93285",
  pink800: "#832767",
  pink900: "#641D4F",

  pinkBlue25: "#F5FAFF",
  pinkBlue50: "#EFF8FF",
  pinkBlue100: "#E0EFFD",
  pinkBlue200: "#B2DDFF",
  pinkBlue300: "#84CAFF",
  pinkBlue400: "#6BBDFF",
  pinkBlue500: "#2E90FA",
  pinkBlue600: "#1570EF",
  pinkBlue700: "#175CD3",
  pinkBlue800: "#1849A9",
  pinkBlue900: "#194185",

  berry25: "#F3F1F3",
  berry50: "#F2EBF3",
  berry100: "#D7C2DB",
  berry200: "#C4A5C9",
  berry300: "#A97CB1",
  berry400: "#9862A1",
  berry500: "#7E3B8A",
  berry600: "#73367E",
  berry700: "#592A62",
  berry800: "#45204C",
  berry900: "#35193A",
};
