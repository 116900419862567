import { Button } from "primereact/button";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import Icon from "../icons";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "primereact/tooltip";

export function SettingsFieldsPrintSubgroup({ fields, onChange = () => {} }) {
  const [updatedFields, setUpdatedFields] = useState(fields);
  const nameInputRefs = useRef([]);

  useEffect(() => {
    console.log(updatedFields);
  }, [updatedFields]);

  const handleFieldChange = (index, key, value) => {
    const _defaultFields = [...updatedFields];
    _defaultFields[index][key] = value;
    setUpdatedFields(_defaultFields);
    onChange(_defaultFields);

    if (key === "name" && nameInputRefs.current[index]) {
      nameInputRefs.current[index].focus();
    }
  };

  return (
    <div className="subgroup-container">
      <h3>Campos de impressão documento</h3>
      <p>
        Atribua os campos disponíveis para exibição em Impressão de Documentos.
      </p>
      <Form className="settings">
        {updatedFields.map((field, index) => {
          return (
            <FormRow key={field.id + "-" + index}>
              <InputContainer
                className="custom-input-container"
                label={LanguageProvider({ id: "gen.name" })}
              >
                <InputText
                  ref={(el) => (nameInputRefs.current[index] = el)}
                  value={field.name}
                  onChange={(e) => {
                    handleFieldChange(index, "name", e.target.value);
                  }}
                />
              </InputContainer>

              <InputContainer
                label={LanguageProvider({ id: "gen.code" })}
                className="color-picker-container"
              >
                <InputText
                  value={field.code}
                  onChange={(e) => {
                    handleFieldChange(index, "code", e.target.value);
                  }}
                />
              </InputContainer>
              <Tooltip target=".icon-delete-field-svg" position="top">
                Excluir campo{" "}
              </Tooltip>
              <InputContainer className="icon-delete-field-input" label={" "}>
                <Icon
                  className="icon-delete-field-svg"
                  icon="trash-02"
                  size={23}
                  onClick={() => {
                    const _defaultFields = [...updatedFields];
                    _defaultFields.splice(index, 1);
                    setUpdatedFields(_defaultFields);
                    onChange(_defaultFields);
                  }}
                />
              </InputContainer>
            </FormRow>
          );
        })}
        <Button
          className="new-color-btn"
          severity="secondary"
          onClick={(e) => {
            e.preventDefault();
            const newField = {
              id: 0,
              applicationClientId: "00000000-0000-0000-0000-000000000000",
              code: "",
              name: "",
              enable: false,
            };
            setUpdatedFields([...updatedFields, newField]);
            onChange([...updatedFields, newField]);
          }}
        >
          <LanguageProvider id="settings.print.document.fields.add" />
        </Button>
      </Form>
    </div>
  );
}
