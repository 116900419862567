import "./scss/report-edit.scss";

import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";

import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { Skeleton } from "primereact/skeleton";
import { useToastContext } from "~/context/ToastContext";
import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import PageHeader from "~/shared/components/page-header/page-header";
import { getDataSources } from "~/services/api/platform/report";
import { getUsers } from "~/services/api";
import { ThemeContext } from "~/app";

export default function ReportEdit() {
  const { currentTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const headerSize = useHeaderHeight();
  const { showToast } = useToastContext();

  const dcpAxiosService = useDcpAxiosService();

  // Loading indicators
  const [loadingDataSource, setLoadingDataSource] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  // Data
  const [users, setUsers] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [productOriginId, setProductOriginId] = useState("");
  const [selectedDataSource, setSelectedDataSource] = useState();
  const [confirmDataSource, setConfirmDataSource] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Dialog controll
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [
    chagenDataSourceConfimationVisible,
    setChagenDataSourceConfimationVisible,
  ] = useState(false);

  const { idReport, projectId } = useParams();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  const formik = useFormik({
    initialValues: {
      applications: [],
      dataSourceId: 0,
      productOriginId: 0,
      report: {
        id: 0,
        title: "Novo relatório",
        code: "",
        dataSourceId: 0,
        productOriginId: "",
        isPublic: true,
        createdAt: new Date(),
      },
      reportAccess: [],
      reportColumns: [],
    },
  });

  async function loadUsers() {
    try {
      const users = await getUsers();
      setUsers(users.data);
      updateDropdown();
    } catch (error) {
      console.error(error);
    }
  }
  async function loadReport() {
    try {
      setLoadingReport(true);

      if (idReport === 0) {
        setLoadingReport(false);
        return;
      }

      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Report + "/" + idReport,
        "Platform"
      );

      if (status === 200) {
        formik.setValues(data.data);
        setProductOriginId(data.data.report.productOriginId);
      }

      setLoadingReport(false);
    } catch (error) {
      console.error(error);
    }
  }

  function updateDropdown() {
    try {
      // update access
      let access = [];
      formik?.values?.reportAccess.forEach((reportAccess) => {
        const user = users.find((u) => u.userId === reportAccess?.userId);
        if (user) access.push(user);
      });
      setSelectedUsers(access);

      // update selected data source
      const dataSourceId = formik.values.report.dataSourceId;
      const dataSource = dataSources.find(
        (ds) => ds.dataSource.id === dataSourceId
      );
      setSelectedDataSource(dataSource);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    try {
      async function load() {
        if (productOriginId !== "") {
          const sources = await getDataSources(productOriginId);
          setDataSources(sources);

          const dataSourceId = formik.values.report.dataSourceId;
          if (dataSourceId === 0) return;
          const dataSource = sources.find(
            (ds) => ds.dataSource.id === dataSourceId
          );
          setSelectedDataSource(dataSource);
        } else {
          setDataSources([]);
        }
      }
      load();
    } catch (error) {
      console.error(error);
    }
  }, [formik?.values?.report?.dataSourceId, productOriginId]);

  async function onSave() {
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Datalake + "/create",
        {
          itemId: idReport,
          isPublic: formik.values.report.isPublic,
          applications: [applicationClientId],
          dataSourceId: formik.values.report.dataSourceId,
          productOriginId: applicationClientId,
          datalake: {
            name: formik.values.report.title,
            idDataSource: formik.values.report.dataSourceId,
            type: settings.DatalakeType.Report,
          },
          reportAccesses: formik?.values?.reportAccess,
          reportColumns: formik.values.reportColumns,
        },
        "Platform"
      );

      if (status === 200) {
        showToast({
          severity: "success",
          message: <LanguageProvider id="reports.save.success" />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onAddColumn(e) {
    try {
      const dataSourceColumn = e;
      const reportColumnRules = [
        {
          id: 0,
          type: 0,
          value: "",
          reportColumnId: 0,
        },
      ];

      const reportColumn = {
        id: 0,
        match: 0,
        order: formik.values.reportColumns.length + 1,
        reportId: idReport,
        dataSourceColumnId: e.id,
      };

      formik.setFieldValue("reportColumns", [
        ...formik.values.reportColumns,
        { dataSourceColumn, reportColumn, reportColumnRules },
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  function onRemoveColumn(index) {
    try {
      let columns = formik.values.reportColumns;
      columns.splice(index, 1);

      for (let i = 0; i < columns.length; i++) {
        columns[i].order = i + 1;
      }

      formik.setFieldValue("reportColumns", columns);
    } catch (error) {
      console.error(error);
    }
  }

  function onChangeMatch(e, index) {
    try {
      let columns = formik.values.reportColumns;
      columns[index].reportColumn.match = Number(e.value);
      formik.setFieldValue("reportColumns", columns);
    } catch (error) {
      console.error(error);
    }
  }

  function onDeleteRule(columnIndex, ruleIndex) {
    try {
      let columns = formik.values.reportColumns;
      let rules = columns[columnIndex].reportColumnRules;
      rules.splice(ruleIndex, 1);
      columns[columnIndex].reportColumnRules = rules;
      formik.setFieldValue("reportColumns", columns);
    } catch (error) {
      console.error(error);
    }
  }

  function onAddRule(columnIndex) {
    try {
      let columns = formik.values.reportColumns;
      let rules = columns[columnIndex].reportColumnRules;
      rules.push({
        id: 0,
        type: "startsWith",
        value: "",
        reportColumnId: columns[columnIndex].reportColumn.id,
      });

      columns[columnIndex].reportColumnRules = rules;
      formik.setFieldValue("reportColumns", columns);
    } catch (error) {
      console.error(error);
    }
  }

  function onChangeRule(type, value, ruleIndex, columnIndex) {
    try {
      let columns = formik.values.reportColumns;
      let rules = columns[columnIndex].reportColumnRules;
      rules[ruleIndex].type = Number(type);
      rules[ruleIndex].value = value;
      columns[columnIndex].reportColumnRules = rules;
      formik.setFieldValue("reportColumns", columns);
    } catch (error) {
      console.error(error);
    }
  }

  function onChangeUserAccess(e) {
    try {
      let _users = users.filter((_user) => {
        return e.value.find((su) => su.userId === _user.userId) ? true : false;
      });

      const reportAccess = _users.map((user) => {
        return {
          id: 0,
          userId: user.userId,
          reportId: idReport,
          name: user.name,
        };
      });

      formik.setFieldValue("reportAccess", reportAccess);
    } catch (error) {
      console.error(error);
    }
  }

  const matchOptions = [
    {
      label: <LanguageProvider id="locale.matchAny" />,
      match: 1,
    },
    {
      label: <LanguageProvider id="locale.matchAll" />,
      match: 0,
    },
  ];

  const ruleOptions = [
    {
      label: <LanguageProvider id="locale.startsWith" />,
      type: 0,
    },
    {
      label: <LanguageProvider id="locale.endsWith" />,
      type: 1,
    },
    {
      label: <LanguageProvider id="locale.contains" />,
      type: 2,
    },
    {
      label: <LanguageProvider id="locale.notContains" />,
      type: 3,
    },
    {
      label: <LanguageProvider id="locale.equals" />,
      type: 4,
    },
    {
      label: <LanguageProvider id="locale.notEquals" />,
      type: 5,
    },
  ];

  function actions() {
    return (
      <>
        {formik?.values?.report?.id > 0 && (
          <Button
            className="p-button-text p-button-plain"
            onClick={() => {
              const path = projectId
                ? `/${projectId}/reports/${formik.values.report.id}`
                : `/reports/${formik.values.report.id}`;

              navigate(path);
            }}
            tooltip="Visualizar relatório"
            tooltipOptions={{ position: "bottom" }}
          >
            <Icon icon={"table"}></Icon>
          </Button>
        )}
      </>
    );
  }

  useEffect(() => {
    updateDropdown();
  }, [formik?.values?.reportAccess, formik?.values?.report?.dataSourceId]);

  useEffect(() => {
    async function loadData() {
      await loadUsers();
      await loadReport();
    }
    loadData();
  }, [idReport]);

  return (
    <div className="report-wrapper">
      <div className="report-edit">
        <div className="report-edit-container">
          <div className="report-edit-content">
            <PageHeader
              title={formik?.values?.report?.title}
              actions={actions}
              onReturn={() => {
                navigate("../");
              }}
            />
            <div className="content-container">
              <div className="report-config">
                <div className="internal">
                  <div className="report-config-header">
                    <span className="section-title">
                      <LanguageProvider id="reports.config" />
                    </span>

                    <Form>
                      <FormRow>
                        <InputContainer
                          label={<LanguageProvider id="reports.title" />}
                        >
                          <InputText
                            value={formik?.values?.report?.title}
                            onChange={(e) =>
                              formik.setFieldValue("report", {
                                ...formik.values.report,
                                title: e.target.value,
                              })
                            }
                          ></InputText>
                        </InputContainer>
                        <InputContainer
                          label={<LanguageProvider id="reports.access" />}
                        >
                          <MultiSelect
                            display="chip"
                            options={users}
                            value={selectedUsers}
                            optionLabel="name"
                            onChange={onChangeUserAccess}
                            maxSelectedLabels={3}
                          ></MultiSelect>
                        </InputContainer>
                      </FormRow>
                      <FormRow>
                        <InputContainer
                          label={<LanguageProvider id="reports.permissions" />}
                        >
                          <div className="option-container">
                            <div className="option">
                              <RadioButton
                                value={true}
                                checked={formik?.values?.report?.isPublic}
                                onChange={(e) => {
                                  formik.setFieldValue("report", {
                                    ...formik.values.report,
                                    isPublic: e.value,
                                  });
                                }}
                              />
                              <div className="option-text">
                                <p className="option-name description">
                                  <LanguageProvider id="reports.permissions.public" />
                                </p>
                                <p className="description">
                                  <LanguageProvider id="reports.permissions.public.desc" />
                                </p>
                              </div>
                            </div>
                            <div className="option">
                              <RadioButton
                                value={false}
                                checked={!formik?.values?.report?.isPublic}
                                onChange={(e) =>
                                  formik.setFieldValue("report", {
                                    ...formik.values.report,
                                    isPublic: e.value,
                                  })
                                }
                              />
                              <div className="option-text">
                                <p className="option-name description">
                                  <LanguageProvider id="reports.permissions.private" />
                                </p>
                                <p className="description">
                                  <LanguageProvider id="reports.permissions.private.desc" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </InputContainer>
                      </FormRow>
                    </Form>
                  </div>
                  <div className="report-config-body-container">
                    <span className="section-title">
                      <LanguageProvider id="reports.config.column" />
                    </span>
                    <div className="report-field-config-area">
                      {loadingReport ? (
                        <Skeleton height="250px" />
                      ) : (
                        <div className="report-config-body">
                          {formik.values.reportColumns.map(
                            (model, colIndex) => {
                              return (
                                <Panel
                                  key={"column-" + colIndex}
                                  headerTemplate={(options) => {
                                    const toggleIcon = options.collapsed
                                      ? "chevron-right"
                                      : "chevron-down";

                                    return (
                                      <div
                                        className={`panel-header ${
                                          options.collapsed ? "collapsed" : null
                                        }`}
                                        onClick={options.onTogglerClick}
                                      >
                                        <Icon
                                          icon={model.dataSourceColumn.icon}
                                          color={
                                            currentTheme.reportColumnIconColor
                                          }
                                        ></Icon>
                                        <p className="header-title">
                                          <LanguageProvider
                                            id={
                                              model.dataSourceColumn.labelCode
                                            }
                                          />
                                        </p>
                                        <Icon
                                          icon={toggleIcon}
                                          color={
                                            currentTheme.reportToggleIconColor
                                          }
                                        />
                                      </div>
                                    );
                                  }}
                                  toggleable
                                  className="report-column"
                                >
                                  <div className="report-column-content">
                                    <div
                                      className={`left-side ${
                                        model.reportColumnRules.length < 1
                                          ? "rule-empty"
                                          : null
                                      }`}
                                    >
                                      {model.reportColumnRules.length > 0 && (
                                        <Dropdown
                                          className="match-selection"
                                          options={matchOptions}
                                          optionValue="match"
                                          optionLabel="label"
                                          value={model.reportColumn.match}
                                          onChange={(e) =>
                                            onChangeMatch(e, colIndex)
                                          }
                                        ></Dropdown>
                                      )}
                                      <Button
                                        className="p-button-text p-button-plain new-rule-btn"
                                        onClick={() => onAddRule(colIndex)}
                                      >
                                        <Icon
                                          icon="plus"
                                          color={
                                            currentTheme.reportBackIconColor
                                          }
                                          size="18px"
                                        />
                                        <LanguageProvider id="reports.rule.new" />
                                      </Button>
                                    </div>
                                    {model.reportColumnRules.length > 0 && (
                                      <div className="column-rules">
                                        {model &&
                                          model.reportColumnRules &&
                                          model.reportColumnRules.map(
                                            (rule, ruleIndex) => {
                                              return (
                                                <div
                                                  className="column-row"
                                                  key={"rule-row-" + ruleIndex}
                                                >
                                                  <Dropdown
                                                    options={ruleOptions}
                                                    optionValue="type"
                                                    optionLabel="label"
                                                    value={rule.type}
                                                    onChange={(e) =>
                                                      onChangeRule(
                                                        e.value,
                                                        rule.value,
                                                        ruleIndex,
                                                        colIndex
                                                      )
                                                    }
                                                  ></Dropdown>
                                                  <InputText
                                                    value={rule.value}
                                                    onChange={(e) =>
                                                      onChangeRule(
                                                        rule.type,
                                                        e.target.value,
                                                        ruleIndex,
                                                        colIndex
                                                      )
                                                    }
                                                  ></InputText>
                                                  <div
                                                    className="trash-icon"
                                                    onClick={() =>
                                                      onDeleteRule(
                                                        colIndex,
                                                        ruleIndex
                                                      )
                                                    }
                                                  >
                                                    <Icon
                                                      icon="trash-02"
                                                      color={
                                                        currentTheme.reportToggleIconColor
                                                      }
                                                    ></Icon>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="footer">
                                    <Button
                                      className="p-button-text p-button-plain"
                                      onClick={() => onRemoveColumn(colIndex)}
                                    >
                                      <LanguageProvider id="gen.remove" />
                                    </Button>
                                  </div>
                                </Panel>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="side-bar">
                <div className="header">
                  <p className="section-title">
                    <LanguageProvider id="reports.data.source" />
                  </p>
                  <p className="description">
                    <LanguageProvider id="reports.data.source.description" />
                  </p>
                  <Dropdown
                    options={dataSources}
                    itemTemplate={(option) => {
                      return (
                        <LanguageProvider id={option.dataSource.labelCode} />
                      );
                    }}
                    valueTemplate={(option, props) => {
                      if (option) {
                        return (
                          <span>
                            <LanguageProvider
                              id={option.dataSource.labelCode}
                            />
                          </span>
                        );
                      } else {
                        return (
                          <span>
                            <LanguageProvider id={props.placeholder} />
                          </span>
                        );
                      }
                    }}
                    placeholder="datasource.select.placeholder"
                    optionLabel="dataSource.labelCode"
                    value={selectedDataSource}
                    onChange={(e) => {
                      if (selectedDataSource) {
                        setChagenDataSourceConfimationVisible(true);
                        setConfirmDataSource(e.value);
                      } else {
                        setSelectedDataSource(e.value);
                        formik.setFieldValue("report", {
                          ...formik.values.report,
                          dataSourceId: e.value.dataSource.id,
                          dataSource: null,
                        });
                      }
                    }}
                  />
                  <p className="description field-list">
                    <LanguageProvider id="reports.data.source.field.list.description" />
                  </p>
                </div>
                <div className="table-columns-list">
                  <div className="scrollable-area">
                    {selectedDataSource && loadingDataSource && (
                      <div className="field-skeleton">
                        <Skeleton height="50px" />
                        <Skeleton height="50px" />
                        <Skeleton height="50px" />
                      </div>
                    )}
                    {selectedDataSource && !loadingDataSource && (
                      <div className="fields-list">
                        {selectedDataSource.dataSourceColumns &&
                          selectedDataSource.dataSourceColumns.map(
                            (ds, index) => {
                              // filter columns
                              const columns = formik.values.reportColumns;
                              const findColumn = columns.find(
                                (c) => c.dataSourceColumn.id === ds.id
                              );
                              if (findColumn) return;

                              return (
                                <div
                                  className="field-wrapper"
                                  onClick={() => onAddColumn(ds)}
                                  key={"datasource-column-" + index}
                                >
                                  <div className="field-icon">
                                    <Icon
                                      icon={ds.icon}
                                      color={
                                        currentTheme.reportDataSourceIconColor
                                      }
                                    />
                                  </div>
                                  <p className="field-title">
                                    <LanguageProvider id={ds.labelCode} />
                                  </p>
                                </div>
                              );
                            }
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="report-edit-content-footer">
              <Button
                className="p-button-text p-button-plain"
                onClick={() => setCancelDialogVisible(true)}
              >
                <LanguageProvider id="gen.cancel.button" />
              </Button>
              <Button onClick={onSave}>
                <LanguageProvider id="gen.save.button" />
              </Button>
            </div>
          </div>
        </div>

        <ModalConfirmation
          isOpen={cancelDialogVisible}
          onHide={() => setCancelDialogVisible(false)}
          isDelete={false}
          modalTitle={<LanguageProvider id="gen.cancel" />}
          bodyMessage={<LanguageProvider id="gen.cancel.msg" />}
          onConfirm={() => navigate("../")}
          onCancel={() => setCancelDialogVisible(false)}
        ></ModalConfirmation>

        <ModalConfirmation
          isOpen={chagenDataSourceConfimationVisible}
          onHide={() => setChagenDataSourceConfimationVisible(false)}
          onCancel={() => setChagenDataSourceConfimationVisible(false)}
          modalTitle={<LanguageProvider id="datasource.change" />}
          bodyMessage={<LanguageProvider id="datasource.change.msg" />}
          isDelete={false}
          onConfirm={() => {
            formik.setFieldValue("reportColumns", []);
            formik.setFieldValue("report", {
              ...formik.values.report,
              dataSourceId: confirmDataSource.dataSource.id,
              dataSource: null,
            });
            setChagenDataSourceConfimationVisible(false);
          }}
        />
      </div>
    </div>
  );
}
