/**
 * Converts a color hex to rbg values
 * @param hex Color Hex
 * @returns An object with the corresponding red, green and blue values
 */
export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  let hexString = hex.replace("#", "");

  if (hexString.length !== 6) {
    throw new Error("Invalid HEX color value");
  }

  const r = parseInt(hexString.substring(0, 2), 16);
  const g = parseInt(hexString.substring(2, 2), 16);
  const b = parseInt(hexString.substring(4, 2), 16);

  return { r, g, b };
}

/**
 * Get the color black or white, depending what is more contrastant from the color
 * Hex provided
 * @param hex Color Hex
 * @returns A black or white color hex
 */
export function getContrastFontColorFromHex(hex: string): string {
  var { r, g, b } = hexToRgb(hex);

  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) return "#000000";
  else return "#ffffff";
}

/**
 * Compare a fragment against a target without special characters
 * @param target
 * @param fragment
 */
export function compareNormalized(target: string, fragment: string): boolean {
  const normalizedTarget = target.trim().normalize("NFC").toUpperCase();
  const normalizedSearchFragment = fragment
    .trim()
    .normalize("NFC")
    .toUpperCase();
  return normalizedTarget.includes(normalizedSearchFragment);
}

export function formatDate(dateStr: string | Date) {
  const date = new Date(dateStr);
  if (!dateStr) return "-";

  const result = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;

  return result ?? "-";
}

export function formatElapsedTime(dateStr: string | Date) {
  const date = new Date(dateStr);
  if (!dateStr) return "-";

  const result = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

  return result ?? "-";
}