import "./scss/nav-top-search.scss";

import { useRef, useState } from "react";

import Icon from "../components/icons";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "../components/language-provider";
import { Menu } from "primereact/menu";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import { useDebounce } from "primereact/hooks";
import { useEffect } from "react";

const NavTopSearch = () => {
  const dcpAxiosService = useDcpAxiosService();
  const [resultItems, setResultItems] = useState([]);
  const [searchValue, searchDebounceValue, setSearchValue] = useDebounce(
    "",
    400
  );

  const searchInputRef = useRef();

  function resultSectionLabel(type) {
    let resultType = {
      typeTitle: "",
      icon: "",
      color: "",
    };

    switch (type) {
      case settings.GlobalSearchType.Product:
        resultType = {
          typeTitle: "Produtos",
          icon: "product",
          color: "#4FD9AE",
        };
        return resultType;
      case settings.GlobalSearchType.Dashboard:
        resultType = {
          typeTitle: "Dashboards",
          icon: "pie-chart-05",
          color: "#FF8B62",
        };
        return resultType;
      case settings.GlobalSearchType.Database:
        resultType = {
          typeTitle: "Databases",
          icon: "database-03",
          color: "#7893FF",
        };
        return resultType;
      case settings.GlobalSearchType.Menu:
        resultType = {
          typeTitle: "Menu",
          icon: "",
          color: "#4E5BA6",
        };
        return resultType;
      default:
        break;
    }
  }

  const handleSearchInputChange = async (searchValue, event) => {
    try {
      if (searchValue != undefined && searchValue.trim().length > 0) {
        const { data } = await dcpAxiosService.get(
          `${settings.Urls.Rest.Search}/search`,
          "Platform",
          {
            params: { value: searchValue },
          }
        );
        console.log(data);

        if (data.status) {
          let results = Array.from(data.data);
          let accumulatedResults = [];
          let sectionTitles = {};

          results.forEach((result) => {
            let sectionTitleIcon = resultSectionLabel(result.type);

            if (!sectionTitles[result.type]) {
              accumulatedResults.push({
                label: (
                  <div className="result-section-wrapper">
                    <span className="result-section-title">
                      {sectionTitleIcon.typeTitle}
                    </span>
                  </div>
                ),
                items: [],
              });
              sectionTitles[result.type] = true;
            }

            // Adiciona o item à seção atual
            accumulatedResults.push({
              icon: (
                <div className="result-icon-wrapper">
                  <Icon
                    icon={sectionTitleIcon.icon}
                    color={sectionTitleIcon.color}
                    className={`resultIcon-${sectionTitleIcon.typeTitle}`}
                    size={24}
                  />
                </div>
              ),
              label: (
                <div className="result-title-wrapper">
                  <a href={result.url} target="_blank" className="result-link">
                    <span
                      className="result-body-title"
                      onClick={() => window.open(result.url, "_blank")}
                    >
                      {result.title}
                    </span>
                  </a>
                </div>
              ),
            });
          });

          setResultItems(accumulatedResults);
        }
      } else {
        setResultItems([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  useEffect(() => {
    handleSearchInputChange(searchDebounceValue);
  }, [searchDebounceValue]);

  return (
    <>
      <div className="nav-top-menu-search">
        <span className="search-input p-input-icon-left">
          <InputText
            ref={searchInputRef}
            value={searchValue}
            className="p-inputtext-md"
            placeholder={LanguageProvider({
              id: "search.field.placeholder",
            })}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </span>
        <div className="content">
          {Array.isArray(resultItems) && resultItems.length > 0 ? (
            <Menu
              id="popup_menu_left"
              className="popup-results"
              model={resultItems}
            />
          ) : (
            <div className="empty-search">
              <LanguageProvider id={"dcp.global.search.empty.result"} />
            </div>
          )}
        </div>
        <div className="footer">
          <div className="items">
            <div className="item">
              <span className="tag">Esc</span>{" "}
              <LanguageProvider id={"dpc.gen.leave.key.desc"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavTopSearch;
