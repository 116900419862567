import "./scss/dashboard.scss";
import "../../../shared/components/scss/dcp-popup-menu.scss";

import { useContext, useEffect, useState } from "react";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { ThemeContext, UserContext } from "../../../app";

import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import welcome from "~/services/welcome.json";
import Icon from "~/shared/components/icons";
import LoadingIndicator from "../../../shared/components/dcp-loading-indicator";
import LanguageProvider from "../../../shared/components/language-provider";
import DashboardCard from "./cards/dashboard-card";
import DatabaseCard from "./cards/database-card";
import ProductCard from "./cards/product-card";
import ReportCard from "./cards/report-card";
import DatalakeDialogCreate from "./components/datalake-dialog-create";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

const Dashboard = () => {
  const { currentLanguage } = useLanguageContext();
  const { user, userRoles } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const dcpAxiosService = useDcpAxiosService();
  const [searchValue, setSearchValue] = useState("");
  // Product region
  const [products, setProducts] = useState([]);

  // Dashboard region
  const [newDashboardDialogVisible, setNewDashboardDialogVisible] =
    useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [roles, setRoles] = useState([]);

  // Datalake region
  const [newDatalakeDialogVisible, setNewDatalakeDialogVisible] =
    useState(false);
  const { datalakes, dashboards, loading, loadMenuItems } =
    useMenuItemsContext();

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const [filteredDatalakes, setFilteredDatalakes] = useState([]);
  const [welcomeMessage, setWelcomeMessage] = useState("");

  const LoadMessages = () => {
    const welcomeLanguage = welcome[currentLanguage];
    var arrayPossition = randomNumberInRange(0, welcome.size - 1);
    setWelcomeMessage(welcomeLanguage[arrayPossition]);
  };

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const handleSearchInputChange = (searchValue) => {
    setSearchValue(searchValue);
  };
  const LoadProducts = async () => {
    try {
      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Admin.ApplicationClient}/list-user-products`,
        "Auth"
      );

      if (data.status) {
        setProducts(data.data.filter((x) => x.code != "platform"));
        setProducts(data.data.filter((x) => x.code != "mes"));
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: data.message,
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const LoadRoles = async () => {
    const { data, status } = await dcpAxiosService.get(
      `${settings.Urls.Admin.Roles}/list`,
      "Auth"
    );

    if (status == 200) {
      setRoles(data.data);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validate: (values) => {
      let errors = {};

      if (!values.name || values.name.length < 1) {
        errors.name = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }

      if (dashboards.find((dashboard) => dashboard.name === values.name)) {
        errors.name = LanguageProvider({
          id: "dashboard.duplicated.name.error",
        });
      }

      return errors;
    },
    onSubmit: ({ name }) => onDashboardCreate(name),
  });
  const onDashboardCreate = async () => {
    setSubmiting(true);
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Dashboard,
        {
          name: formik.values.name,
          dashboardAccess: [
            {
              isRole: false,
            },
          ],
        },
        "Platform"
      );

      if (status === 200) {
        navigate("dashboard-graphs/" + data.data.id);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setSubmiting(false);
  };
  const onClose = () => {
    setNewDashboardDialogVisible(false);
    formik.resetForm();
  };

  useEffect(() => {
    LoadMessages();
    LoadProducts();
    LoadRoles();
    loadMenuItems();
  }, [user, currentLanguage]);

  useEffect(() => {
    try {
      if (Array.isArray(products)) {
        const filteredProducts = products.filter((product) =>
          product.productName.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredProducts(filteredProducts);
      }

      if (Array.isArray(dashboards)) {
        const filteredDashboards = dashboards.filter((dashboard) =>
          dashboard.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        const sorted = [...filteredDashboards].sort((a, b) => {
          if (a.isFavorite && !b.isFavorite) {
            return -1;
          } else if (!a.isFavorite && b.isFavorite) {
            return 1;
          } else {
            return 0;
          }
        });
        setFilteredDashboards(sorted);
      }
      if (Array.isArray(datalakes)) {
        const filteredDatalakes = datalakes.filter((datalake) =>
          datalake.name
            ? datalake.name.toLowerCase().includes(searchValue.toLowerCase())
            : false
        );
        setFilteredDatalakes(filteredDatalakes);
      }
    } catch (error) {
      console.error("Dashboard useEffect error => ", error);
    }
  }, [products, datalakes, dashboards, searchValue]);

  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <div className="user">
          <div className="user-avatar-container">
            <div className="user-avatar">
              {user.avatar ? (
                <img />
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                    stroke="#101828"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
          <span className="user-name">
            {`${user.userName}, `}
            <span className="welcome-message">
              <LanguageProvider id={welcomeMessage} />
            </span>
          </span>
          <div className="dashboard-search">
            <span className="search-input p-input-icon-left">
              <div className="search-icon-wrapper">
                <Icon icon={"search-md"} size={20} />
              </div>
              <InputText
                className="p-inputtext-md"
                placeholder={LanguageProvider({
                  id: "search.dashboard.field.placeholder",
                })}
                onChange={(e) => handleSearchInputChange(e.target.value)}
              />
            </span>
          </div>
        </div>

        {/* Products rendering */}
        <>
          <div className="products">
            <div className="products-header">
              <span className="header-tittle">
                <LanguageProvider id={"gen.products"} />
              </span>
            </div>
            <div className="content-divider" />
            <div className="products-content">
              {!loading ? (
                products &&
                filteredProducts.map((product, productIndex) => {
                  return (
                    <ProductCard
                      key={`product-card-${productIndex}`}
                      model={product}
                    />
                  );
                })
              ) : (
                <LoadingIndicator color={currentTheme.primary} />
              )}
            </div>
          </div>
        </>

        {/* Dashboards rendering */}
        <div className="dashboards">
          <div className="dashboards-header">
            <span className="header-tittle">
              <LanguageProvider id={"gen.dashboards"} />
            </span>
          </div>
          <div className="content-divider" />
          <div className="dashboards-content">
            {!loading &&
              (userRoles.administratorOrInventoryManager ||
                userRoles.inventoryManager ||
                userRoles.administrator ||
                userRoles.receiver ||
                userRoles.print) && (
                <div
                  className="btn add-item"
                  onClick={() => setNewDashboardDialogVisible(true)}
                >
                  <div className="icon-wrapper">
                    <Icon icon={"plus"} size={20} color="#6172F3" />
                  </div>
                </div>
              )}
            {!loading ? (
              dashboards &&
              filteredDashboards.map((dashboard, dashboardIndex) => {
                return (
                  <DashboardCard
                    key={`dashboard-card-${dashboardIndex}`}
                    model={dashboard}
                    onUpdateModel={loadMenuItems}
                    roles={roles}
                  />
                );
              })
            ) : (
              <LoadingIndicator color={currentTheme.primary} />
            )}
          </div>
          <Dialog
            visible={newDashboardDialogVisible}
            onHide={onClose}
            headerStyle={{ padding: "20px" }}
            contentStyle={{
              paddingTop: "20px",
              borderBottom: "1px solid var(--systemBackground)",
              borderTop: "1px solid var(--systemBackground)",
              backgroundColor: "var(--systemForeground)",
            }}
            header={
              <span className="dashboards-header-dialog">
                <LanguageProvider id="dashboard.add.new" />
              </span>
            }
            footer={
              <span>
                <Button
                  text
                  style={{ marginTop: "20px" }}
                  className="p-button p-button-text p-button-plain"
                  label={<LanguageProvider id="gen.cancel" />}
                  onClick={onClose}
                ></Button>
                <Button
                  label={<LanguageProvider id="dashboard.add.new" />}
                  onClick={formik.submitForm}
                  loading={submiting}
                  type="submit"
                  autoFocus
                ></Button>
              </span>
            }
          >
            <Form style={{ padding: 0 }}>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.name" />}>
                  <InputText
                    width="700px"
                    value={formik.values.name}
                    style={{ minWidth: "540px" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        formik.submitForm();
                      }
                    }}
                    onChange={(e) =>
                      formik.setFieldValue("name", e.target.value)
                    }
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <small className="p-error">{formik.errors.name}</small>
                  ) : (
                    <small className="p-error">&nbsp;</small>
                  )}
                </InputContainer>
              </FormRow>
            </Form>
          </Dialog>
        </div>

        {/* Datalakes rendering */}
        {userRoles.administrator ||
        userRoles.inventoryManager ||
        userRoles.receiver ||
        userRoles.print ? (
          <div className="databases">
            <div className="databases-header">
              <span className="header-tittle">
                <LanguageProvider id={"gen.datalakes"} />
              </span>
            </div>
            <div className="content-divider" />
            <div className="databases-content">
              {!loading &&
                (userRoles.administratorOrInventoryManager ||
                  userRoles.inventoryManager ||
                  userRoles.administrator ||
                  userRoles.receiver) && (
                  <div
                    className="btn add-item"
                    onClick={() => setNewDatalakeDialogVisible(true)}
                  >
                    <div className="icon-wrapper">
                      <Icon
                        icon={"plus"}
                        size={20}
                        color="#6172F3"
                        className="search-filter"
                      />
                    </div>
                  </div>
                )}
              {!loading ? (
                datalakes &&
                filteredDatalakes.map((datalake, datalakeIndex) => {
                  if (datalake.type === settings.DatalakeType.Database) {
                    return (
                      <DatabaseCard
                        key={`database-card-${datalakeIndex}`}
                        model={datalake}
                      />
                    );
                  } else if (datalake.type === settings.DatalakeType.Report) {
                    return (
                      <ReportCard
                        key={`database-card-${datalakeIndex}`}
                        model={datalake}
                      />
                    );
                  }
                })
              ) : (
                <LoadingIndicator color={currentTheme.primary} />
              )}
            </div>
          </div>
        ) : null}
      </div>
      {newDatalakeDialogVisible && (
        <DatalakeDialogCreate
          onClose={() => {
            setNewDatalakeDialogVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;
