import { createContext, useEffect, useRef, useState } from "react";
import { Button } from "~/shared/components/dcp-button";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import ConfigField from "./config-fields/index";
import "./config-side-menu.scss";

export const SaveButtonRefContext = createContext(null);

export function ConfigSideMenu({ item, onSave, onCancel, isOpen }) {
  const [newItem, setNewItem] = useState({});
  const saveButtonRef = useRef(null);

  const getConfigField = (config) => {
    try {
      const updateValue = (value) => {
        const newConfigValues = newItem.configValues.map((_item) => {
          if (_item.defaultConfig.id === config.defaultConfig.id) {
            return {
              ..._item,
              value,
            };
          } else {
            return _item;
          }
        });

        setNewItem({ ...newItem, configValues: newConfigValues });
      };

      switch (config.defaultConfig.fieldType) {
        case 0:
          return ConfigField.Text({ config, updateValue, onKeyDown });
        case 2:
          return ConfigField.DecimalNumber({ config, updateValue, onKeyDown });
        case 5:
          return ConfigField.Date({ config, updateValue, onKeyDown });
        case 6:
          return ConfigField.FontSize({ config, updateValue, onKeyDown });
        case 7:
          return ConfigField.ColorPicker({ config, updateValue, onKeyDown });
        case 8:
          return ConfigField.TextArea({ config, updateValue, onKeyDown });
        case 9:
          return ConfigField.TonThreshold({ config, updateValue, onKeyDown });
        case 10:
          return ConfigField.PercentageThreshold({
            config,
            updateValue,
            onKeyDown,
          });
        case 11:
          return ConfigField.WMSCounterSource({
            config,
            updateValue,
            onKeyDown,
          });
        case 12:
          return ConfigField.InventorySelection({
            config,
            updateValue,
            onKeyDown,
          });
        case 13:
          return ConfigField.SequenceCounterSource({
            config,
            updateValue,
            onKeyDown,
          });
        case 14:
          return ConfigField.WarehouseCounterSource({
            config,
            updateValue,
            onKeyDown,
          });
        case 15:
          return ConfigField.WarehouseFinanceCounterSource({
            config,
            updateValue,
          });
        case 16:
          return ConfigField.AmcGenericCounterSource({
            config,
            updateValue,
          });
        case 17:
          return ConfigField.InventoryLocationStatus({
            config,
            updateValue,            
            onKeyDown,
          });
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // hack to stop the config menu from closing on input return click
  function onKeyDown(e) {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        onSave(newItem);
        setNewItem({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setNewItem(item);
  }, [item, isOpen]);

  return (
    <nav
      className={`config-side-menu ${isOpen ? "open" : null}`}
      onClick={(e) => e.preventDefault()}
    >
      <div className="menu-container">
        <div className="menu-header">
          <div className="title">
            <p className="config-label">
              {<LanguageProvider id="dashboard.configure.item" />}
            </p>
            <Icon
              className="icon"
              icon="x-close"
              color={"#717BBC"}
              onClick={() => onCancel()}
              type="button"
            />
          </div>
        </div>
        {/* map options */}
        {newItem && (
          <div className="configs">
            <Form className="config-form">
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="dashboard.item.type.title" />}
                >
                  <InputText
                    value={newItem.title}
                    onChange={(e) => {
                      setNewItem({ ...newItem, title: e.target.value });
                    }}
                    onKeyDown={onKeyDown}
                  ></InputText>
                </InputContainer>
              </FormRow>
              {newItem.configValues &&
                newItem.configValues.map((config, index) => {
                  return (
                    <FormRow key={"config-" + index}>
                      <InputContainer
                        label={
                          <LanguageProvider
                            id={`dashboard.config.${config.defaultConfig.label}`}
                          />
                        }
                      >
                        <SaveButtonRefContext.Provider value={saveButtonRef}>
                          {getConfigField(config)}
                        </SaveButtonRefContext.Provider>
                      </InputContainer>
                    </FormRow>
                  );
                })}
              <FormRow className="actions">
                <Button
                  text
                  className="p-button-plain"
                  label={<LanguageProvider id="gen.cancel.button" />}
                  onClick={() => {
                    setNewItem({});
                    onCancel();
                  }}
                  type="buttom"
                ></Button>
                <Button
                  type="submit"
                  label={<LanguageProvider id="gen.save.button" />}
                  onClick={(e) => {
                    onSave(newItem);
                    setNewItem({});
                  }}
                ></Button>
              </FormRow>
            </Form>
          </div>
        )}
      </div>
    </nav>
  );
}
