import { useContext, useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
import settings from "~/services/settings.json";
import { writeStorage } from "~/services/storage/storage-access";
import { UserContext } from "./app";
import { useDcpAxiosService } from "./services/axios/dcp-axios-service";

export const PreLoggedInRoutes = () => {
  const dcpAxiosService = useDcpAxiosService();
  const { setUser } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);

  async function load() {
    const { data } = await dcpAxiosService.post(
      `${settings.BaseApiPath}/${settings.Urls.Admin.Authorization}/login`,
      {
        userName: `duettsoftware/desenvolvimento`,
        password: "123456",
        clientType: "web",
        grantType: "password",
        refreshToken: null,
        application: settings.ProductId,
        externalAppId: null,
      },
      "Auth"
    );

    setUser(data);
    writeStorage("authentication", data);

    // Inventory
    dcpAxiosService.setHeaders("Inventory");

    // Platform
    dcpAxiosService.setHeaders("Platform");

    setLoaded(true);
  }

  useEffect(() => {
    load();
  }, []);

  return loaded ? <Outlet /> : null;
};
