import { Dropdown } from "primereact/dropdown";
import { Mention } from "primereact/mention";
import { useEffect, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import "../scss/connections-subgroup.scss";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { getDatabasesMain, listDatalakeDatabases } from "~/services/api";
import LoadingIndicator from "../dcp-loading-indicator";

export function ConnectionsSubgroup({
  onChange = () => {},
  applicationCode = "drone-inventory",
}) {
  const dcpAxiosService = useDcpAxiosService();
  const [databases, setDatabases] = useState([]);
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  // Loading indicators
  const [loadingDatabases, setloadingDatabases] = useState(true);

  const loadConnections = async () => {
    try {
      setLoading(true);
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Connection,
        "Platform",
        { params: { applicationCode, applicationClientId } }
      );

      if (status === 200) {
        const formattedConnections = data.data.map((con) => {
          return { ...con, isModified: false };
        });

        setConnections(formattedConnections);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const loadDatabases = async () => {
    try {
      setloadingDatabases(true);
      const data = await getDatabasesMain();

      if (Array.isArray(data)) setDatabases(data);
    } catch (error) {
      console.error(error);
    }
    setloadingDatabases(true);
  };

  const onConnectionChange = (e, index) => {
    try {
      const _connections = [...connections];
      _connections.splice(index, 1, e);
      if (onChange) onChange(_connections);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadConnections();
    loadDatabases();
  }, []);

  return (
    <>
      {connections && !loading ? (
        connections.map((connection, index) => {
          return (
            <Connection
              key={"connection-" + index}
              connection={connection}
              databases={databases}
              onChange={(e) => onConnectionChange(e, index)}
            />
          );
        })
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
}

function Connection({ connection, databases, onChange = () => {} }) {
  const dcpAxiosService = useDcpAxiosService();
  const [fields, setFields] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState();

  // Loading indicators
  const [loadingFields, setLoadingFields] = useState(false);
  const [localConnection, setLocalConnection] = useState({});

  const onDatabaseChange = (e) => {
    try {
      setLocalConnection({
        ...localConnection,
        databaseId: e.value.id,
        isModified: true,
      });
      loadFields(e.value);
    } catch (error) {
      console.error(error);
    }
  };

  const loadFields = async (database) => {
    try {
      setLoadingFields(true);
      const { name, id } = database;
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Field + "/list-by-database",
        "Platform",
        {
          params: {
            idDatabase: id,
            databaseName: name,
          },
        }
      );

      if (status === 200) setFields(data.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingFields(false);
  };

  const onFieldChange = (e, field) => {
    try {
      const selectedFieldId = e.value.id;
      const connectionValues = localConnection.connectionValues;

      connectionValues.forEach((value) => {
        if (
          value.applicationConnectionValue.id ==
          field.applicationConnectionValue.id
        ) {
          value.fieldId = selectedFieldId;
        }
      });

      setLocalConnection({
        ...localConnection,
        connectionValues,
        isModified: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sugestionItemTemplate = (suggestion) => {
    return (
      <div className="flex align-items-center">
        <span className="flex flex-column ml-2">{suggestion.viewName}</span>
      </div>
    );
  };

  const onSearch = (event) => {
    const query = event.query;
    let suggestions;

    if (!query.trim().length) {
      suggestions = fields.map((field) => {
        return {
          ...field,
          patterField: `{${field.keyName}}`,
        };
      });
    } else {
      suggestions = fields.filter((field) => {
        return field.viewName.toLowerCase().startsWith(query.toLowerCase());
      });
    }

    setSuggestions(suggestions);
  };

  useEffect(() => {
    setLocalConnection(connection);
    if (connection.databaseId) {
      const _database = databases.find((db) => db.id === connection.databaseId);
      loadFields(_database);
    }
  }, [connection, databases]);

  useEffect(() => {
    onChange(localConnection);
  }, [localConnection]);

  return (
    <>
      <div className="connections-subgroup">
        <h3>
          <LanguageProvider
            id={<LanguageProvider id={connection.applicationConnection.name} />}
          />
        </h3>
        <Form>
          <FormRow>
            <InputContainer
              label={
                <LanguageProvider id={connection.applicationConnection.name} />
              }
            >
              <Dropdown
                options={databases}
                optionLabel="name"
                onChange={onDatabaseChange}
                value={databases.find(
                  (db) => db.id === localConnection.databaseId
                )}
              ></Dropdown>
            </InputContainer>
          </FormRow>
          {localConnection.connectionValues &&
            splitConnectionValues(localConnection.connectionValues).map(
              (rowValues, rowIndex) => (
                <FormRow key={`form-row-${rowIndex}`}>
                  {rowValues.map((value, index) => (
                    <InputContainer
                      key={`connection-value-${index}`}
                      label={
                        <LanguageProvider
                          id={value.applicationConnectionValue.name}
                        />
                      }
                    >
                      <Dropdown
                        options={fields}
                        optionLabel="viewName"
                        onChange={(e) => onFieldChange(e, value)}
                        value={fields.find(
                          (field) => field.id === value.fieldId
                        )}
                      ></Dropdown>
                    </InputContainer>
                  ))}
                </FormRow>
              )
            )}
          <FormRow>
            <InputContainer
              label={<LanguageProvider id="connection.pattern.description" />}
            >
              <InputContainer
                label={<LanguageProvider id="connection.display.pattern" />}
              >
                <p className="pattern-description"></p>
                <Mention
                  value={localConnection.displayPattern}
                  // onSelect={(e) => {
                  //   console.log(e);
                  //   setLocalConnection({
                  //     ...localConnection,
                  //     displayPattern: `@${e.suggestion.patterField}`,
                  //     isModified: true,
                  //   });
                  // }}
                  onChange={(e) =>
                    setLocalConnection({
                      ...localConnection,
                      displayPattern: e.target.value,
                      isModified: true,
                    })
                  }
                  suggestions={suggestions}
                  field="patterField"
                  itemTemplate={sugestionItemTemplate}
                  trigger={["@"]}
                  onSearch={onSearch}
                  className="pattern-field"
                />
              </InputContainer>
            </InputContainer>
          </FormRow>
        </Form>
      </div>
    </>
  );
}

function splitConnectionValues(connectionValues) {
  const maxValuesPerRow = 2;
  const rows = [];
  for (let i = 0; i < connectionValues.length; i += maxValuesPerRow) {
    const rowValues = connectionValues.slice(i, i + maxValuesPerRow);
    rows.push(rowValues);
  }
  return rows;
}
