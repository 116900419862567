import { Dropdown } from "primereact/dropdown";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function WMSCounterSourceConfig({
  config,
  updateValue = function () {},
}) {
  const { currentLanguage } = useLanguageContext();

  const options = [
    {
      code: "total-locations",
      label: LanguageProviderWithoutContext({
        id: `dropdown.option.total-locations`,
        currentLanguage,
      }),
    },
    {
      code: "occupied-locations",
      label: LanguageProvider({
        id: "dropdown.option.occupied-locations",
        currentLanguage,
      }),
    },
    {
      code: "free-locations",
      label: LanguageProvider({
        id: "dropdown.option.free-locations",
        currentLanguage,
      }),
    },
    {
      code: "open-shipping-orders",
      label: LanguageProvider({
        id: "dropdown.option.open-shipping-orders",
        currentLanguage,
      }),
    },
    {
      code: "open-receipt-orders",
      label: LanguageProvider({
        id: "dropdown.option.open-receipt-orders",
        currentLanguage,
      }),
    },
  ];

  return (
    <Dropdown
      value={config.value}
      options={options}
      optionLabel="label"
      optionValue="code"
      onChange={(e) => updateValue(e.value)}
    ></Dropdown>
  );
}
