import React from "react";
import "./scss/template.scss";
import { ThemeContext, UserContext } from "../../../app";
import { useContext, useEffect, useState } from "react";
import ModalTemplate from "./components/modal-create-template";
import LanguageProvider from "~/shared/components/language-provider";
import { Tag } from "primereact/tag";
import Icon from "~/shared/components/icons";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import settings from "~/services/settings.json";
import axios from "axios";
import { useToastContext } from "~/context/ToastContext";
import {
  createTemplate,
  getTemplate,
  updateTemplate,
} from "~/services/api/platform/template";
import { Dropdown } from "primereact/dropdown";
import { Buffer } from "buffer";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { Tooltip } from "primereact/tooltip";
import PageHeader from "~/shared/components/page-header/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

const TemplateMain = () => {
  const user = useContext(UserContext);
  const { currentLanguage } = useLanguageContext();
  const { showToast } = useToastContext();
  const { currentTheme } = useContext(ThemeContext);
  const { printDataTags, loadTemplateTags, loading } = useMenuItemsContext();
  const navigate = useNavigate();
  const { templateId } = useParams();

  const [isZplPreviewSubmitted, setIsZplPreviewSubmitted] = useState(false);
  const [enableGeneratePreview, setEnableGeneratePreview] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const ValidateTemplateZplForm = (data) => {
    let erros = {};
    try {
      if (data) {
        if (data.name === "" || data.name === null)
          erros.name = (
            <LanguageProvider id="template.message.name.field.required" />
          );
        if (data.code === "" || data.code === null)
          erros.code = (
            <LanguageProvider id="template.message.code.field.required" />
          );
        if (data.type === 0 || data.type === null)
          erros.type = (
            <LanguageProvider id="template.message.type.field.required" />
          );
        if (data.qrCodeContent === "" || data.qrCodeContent === null)
          erros.qrCodeContent = (
            <LanguageProvider id="template.message.zplCode.field.required" />
          );
        if (data.dpiDensity <= 0 || data.dpiDensity === null)
          erros.dpiDensity = (
            <LanguageProvider id="template.message.dpiDensity.field.required" />
          );
        if (data.qrCodeWidth <= 0 || data.qrCodeWidth === null)
          erros.qrCodeWidth = (
            <LanguageProvider id="template.message.width.field.required" />
          );
        if (data.qrCodeHeight <= 0 || data.qrCodeHeight === null)
          erros.qrCodeHeight = (
            <LanguageProvider id="template.message.height.field.required" />
          );
      }
    } catch (error) {
      console.error(error);
    }
    return erros;
  };

  const templateZplFormik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      code: "",
      type: 2,
      isActive: false,
      qrCodeContent: "",
      qrCodeWidth: 0,
      qrCodeHeight: 0,
      dpiDensity: 0,
    },
    validate: ValidateTemplateZplForm,
    onSubmit: (values) => {
      handleTemplateSave(values);
    },
  });
  const IsFormValid = (name) => {
    return !!(
      templateZplFormik.errors[name] && templateZplFormik.touched[name]
    );
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">{templateZplFormik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmitRequestZplPreview = async (values) => {
    console.log("values", values);
    const zpl = values.qrCodeContent;
    console.log(zpl);
    if (zpl) {
      const imageUrl = await convertZplToImage(zpl);
      setImageUrl(imageUrl);
    } else {
      setImageUrl("");
    }
  };

  const convertZplToImage = async (zpl) => {
    const values = templateZplFormik.values;
    const width = (values.qrCodeWidth / 2.54).toFixed(6);
    const height = (values.qrCodeHeight / 2.54).toFixed(6);

    if (
      values.dpiDensity &&
      values.qrCodeWidth !== 0 &&
      values.qrCodeHeight !== 0
    ) {
      try {
        const response = await axios.post(
          `https://api.labelary.com/v1/printers/${values.dpiDensity}dpmm/labels/${width}x${height}/0/`,
          zpl,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            responseType: "arraybuffer",
          }
        );
        const base64Image = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        return `data:image/png;base64,${base64Image}`;
      } catch (error) {
        console.error("Error converting ZPL to image:", error);
        return null;
      }
    }
  };

  const handleTemplateSave = async (values) => {
    try {
      setIsLoading(true);
      if (templateId !== "0") {
        let templateFormatted = {
          ...values,
          id: templateId,
        };
        const responseUpdate = await updateTemplate(templateFormatted);
        if (responseUpdate.status) {
          showToast({
            severity: "success",
            message: <LanguageProvider id={"gen.message.template.saved"} />,
          });
          await loadTemplate(responseUpdate.data.id);
        }
      } else {
        const responseCreate = await createTemplate(values);
        if (responseCreate.status) {
          showToast({
            severity: "success",
            message: <LanguageProvider id={"gen.message.template.created"} />,
          });
          await loadTemplate(responseCreate.data.id);
        }
      }
    } catch (error) {
      console.error("Error saving template:", error);
      templateZplFormik.resetForm();
    }
    setIsLoading(false);
  };

  const loadTemplate = async (templateId) => {
    try {
      if (templateId === "0") {
        templateZplFormik.resetForm();
        return;
      }
      const { data } = await getTemplate(templateId);

      if (data.status) {
        templateZplFormik.setValues({
          id: data.data.id,
          name: data.data.name,
          code: data.data.code,
          type: data.data.type,
          isActive: data.data.isActive,
          qrCodeContent: data.data.qrCodeContent,
          qrCodeWidth: data.data.qrCodeWidth,
          qrCodeHeight: data.data.qrCodeHeight,
          dpiDensity: data.data.dpiDensity,
        });
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: data.message,
            currentLanguage,
          }),
        });
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const verifyPreviewRequiredValues = async () => {
      const previewRequiredValues = templateZplFormik.values;
      if (
        previewRequiredValues.qrCodeContent !== "" &&
        previewRequiredValues.qrCodeWidth !== null &&
        previewRequiredValues.qrCodeHeight !== null &&
        previewRequiredValues.dpiDensity !== null
      ) {
        setEnableGeneratePreview(false);
      } else {
        setEnableGeneratePreview(true);
      }
    };
    verifyPreviewRequiredValues();
  }, [templateZplFormik.values]);

  useEffect(() => {
    loadTemplate(templateId);
  }, [templateId]);

  useEffect(() => {
    loadTemplateTags();
  }, [user]);

  useEffect(() => {
    const { qrCodeContent, qrCodeWidth, qrCodeHeight, dpiDensity } =
      templateZplFormik.values;

    if (
      templateId !== "0" &&
      qrCodeContent !== "" &&
      qrCodeWidth > 0 &&
      qrCodeHeight > 0 &&
      dpiDensity > 0 &&
      !isZplPreviewSubmitted
    ) {
      onSubmitRequestZplPreview(templateZplFormik.values);
      setIsZplPreviewSubmitted(true);
    }
  }, [templateId, templateZplFormik.values, isZplPreviewSubmitted]);

  return (
    <div className="template-management">
      <div className="page-container">
        <div className="template-container">
          <PageHeader
            title={
              templateId === "0" ? (
                <LanguageProvider id="gen.add.new.template" />
              ) : (
                templateZplFormik?.values.name
              )
            }
            onReturn={() => navigate("/print/template")}
            actions={null}
          />
          <div className="template-body">
            <div className="qr-code-wrapper">
              <div className="qr-code-wrapper-container">
                <div className="qr-code-input-area-container">
                  <div className="template-code-wrapper">
                    <div className="template-code-content">
                      <div className="template-form-content">
                        <Form>
                          <FormRow>
                            <InputContainer
                              label="Nome"
                              className="template-name-input-wrapper"
                            >
                              <InputText
                                name="name"
                                id="name"
                                value={templateZplFormik.values.name}
                                onChange={(e) =>
                                  templateZplFormik.setFieldValue(
                                    "name",
                                    e.target.value
                                  )
                                }
                                required
                                className={[
                                  classNames({
                                    "p-invalid": IsFormValid("name"),
                                  }),
                                ]}
                                style={{ width: "345px" }}
                              />
                              <GetFormError name={"name"} />
                            </InputContainer>
                            <InputContainer label="Código">
                              <InputText
                                name="code"
                                id="code"
                                value={templateZplFormik.values.code}
                                onChange={(e) =>
                                  templateZplFormik.setFieldValue(
                                    "code",
                                    e.target.value
                                  )
                                }
                                className={[
                                  classNames({
                                    "p-invalid": IsFormValid("code"),
                                  }),
                                ]}
                              />
                              <GetFormError name={"code"} />
                            </InputContainer>
                            <InputContainer label="Tipo">
                              <Dropdown
                                placeholder={LanguageProviderWithoutContext({
                                  id: "template.dropdown.placeholder.title",
                                  currentLanguage,
                                })}
                                name="type"
                                id="type"
                                options={[
                                  // {
                                  //   label: "HTML",
                                  //   value: 1,
                                  // },
                                  {
                                    label: "ZPL",
                                    value: 2,
                                  },
                                ]}
                                value={templateZplFormik.values?.type}
                                className={[
                                  classNames({
                                    "p-invalid": IsFormValid("type"),
                                  }),
                                ]}
                                disabled
                              />
                              <GetFormError name={"type"} />
                            </InputContainer>
                            <InputContainer
                              label="Ativo"
                              className="template-isActive-button-wrapper"
                            >
                              <InputSwitch
                                name="isActive"
                                id="isActive"
                                checked={templateZplFormik.values.isActive}
                                onChange={(e) => {
                                  templateZplFormik.setFieldValue(
                                    "isActive",
                                    e.value
                                  );
                                }}
                              />
                            </InputContainer>
                          </FormRow>
                        </Form>
                      </div>
                      <div className="template-tags-wrapper">
                        <Tooltip target=".tag-icon-alert" position="top">
                          <span>
                            <LanguageProvider id="template.message.tags.tooltip" />
                          </span>
                        </Tooltip>
                        <span className="dynamic-tags-title">
                          <LanguageProvider id={"gen.template.dynamic.tags"} />
                          <Icon
                            className="tag-icon-alert"
                            icon={"help-circle"}
                            size={20}
                            color={currentTheme.textTertiary}
                          />
                        </span>
                      </div>
                      <div className="card-additional-info">
                        {!loading ? (
                          printDataTags.length > 0 &&
                          printDataTags.map((tag, index) => {
                            return (
                              <Tag
                                className="template-tags"
                                key={index}
                                severity="info"
                                value={tag}
                              />
                            );
                          })
                        ) : (
                          <Tag
                            rounded
                            severity="info"
                            value={<LanguageProvider id={"gen.loading"} />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <InputTextarea
                    autoResize
                    className={[
                      "qr-code-textarea",
                      classNames({
                        "p-invalid": IsFormValid("qrCodeContent"),
                      }),
                    ]}
                    placeholder={LanguageProvider({ id: "gen.qr.code" })}
                    value={templateZplFormik.values.qrCodeContent ?? ""}
                    onChange={(e) => {
                      templateZplFormik.setFieldValue(
                        "qrCodeContent",
                        e.target.value
                      );
                    }}
                    required
                  />
                  <GetFormError name={"qrCodeContent"} />
                </div>
                <div className="zpl-image-preview-container">
                  <div className="zpl-image-content">
                    <div className="header">
                      <span className="title">
                        <LanguageProvider id={"gen.preview.label"} />
                      </span>
                      <span className="title">
                        <LanguageProvider id={"gen.dimensions.label"} /> X:{" "}
                        {templateZplFormik.values.qrCodeWidth}cm Y:{" "}
                        {templateZplFormik.values.qrCodeHeight}cm
                      </span>
                    </div>
                    <div className="zpl-preview">
                      {imageUrl ? (
                        <img
                          className="qr-code-image"
                          src={imageUrl}
                          alt="ZPL Preview"
                        />
                      ) : (
                        <div className="no-preview">
                          <LanguageProvider id="gen.no.preview" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="template-zpl-image-dimensions">
                    <FormRow className="template-size-setting-wrapper">
                      <InputContainer
                        label={LanguageProvider({ id: "gen.width" })}
                        className="template-width-input"
                      >
                        <InputNumber
                          placeholder={LanguageProvider({
                            id: "gen.width",
                          })}
                          name="qrCodeWidth"
                          id="qrCodeWidth"
                          min={0}
                          value={templateZplFormik.values?.qrCodeWidth}
                          onValueChange={(e) => {
                            templateZplFormik.setFieldValue(
                              "qrCodeWidth",
                              e.target.value
                            );
                          }}
                          suffix=" cm"
                          className={[
                            classNames({
                              "p-invalid": IsFormValid("qrCodeWidth"),
                            }),
                          ]}
                        />
                        <GetFormError name={"qrCodeWidth"} />
                      </InputContainer>
                      <InputContainer
                        label={LanguageProvider({ id: "gen.height" })}
                        className="template-height-input"
                      >
                        <InputNumber
                          placeholder={LanguageProvider({
                            id: "gen.height",
                          })}
                          name="qrCodeHeight"
                          id="qrCodeHeight"
                          min={0}
                          value={templateZplFormik.values?.qrCodeHeight}
                          onValueChange={(e) => {
                            templateZplFormik.setFieldValue(
                              "qrCodeHeight",
                              e.target.value
                            );
                          }}
                          suffix=" cm"
                          className={[
                            classNames({
                              "p-invalid": IsFormValid("qrCodeHeight"),
                            }),
                          ]}
                        />
                        <GetFormError name={"qrCodeHeight"} />
                      </InputContainer>
                      <InputContainer
                        label={LanguageProvider({
                          id: "template.label.input.title",
                        })}
                        className="template-dpi-input"
                      >
                        <Dropdown
                          placeholder={LanguageProvider({
                            id: "template.dropdown.placeholder.title",
                          })}
                          name="dpiDensity"
                          id="dpiDensity"
                          options={[
                            {
                              label: "6 dpmm (152 dpi)",
                              value:
                                settings.TemplateDpiDensity["6 dpmm (152 dpi)"],
                            },
                            {
                              label: "8 dpmm (203 dpi)",
                              value:
                                settings.TemplateDpiDensity["8 dpmm (203 dpi)"],
                            },
                            {
                              label: "12 dpmm (300 dpi)",
                              value:
                                settings.TemplateDpiDensity[
                                  "12 dpmm (300 dpi)"
                                ],
                            },
                            {
                              label: "24 dpmm (600 dpi)",
                              value:
                                settings.TemplateDpiDensity[
                                  "24 dpmm (600 dpi)"
                                ],
                            },
                          ]}
                          value={templateZplFormik.values?.dpiDensity}
                          onChange={(e) => {
                            templateZplFormik.setFieldValue(
                              "dpiDensity",
                              e.value
                            );
                          }}
                          className={[
                            classNames({
                              "p-invalid": IsFormValid("dpiDensity"),
                            }),
                          ]}
                        />
                        <GetFormError name={"dpiDensity"} />
                      </InputContainer>
                    </FormRow>
                    <div className="image-generate-preview-button">
                      <Button
                        className="p-button p-button-outlined generate-preview-button"
                        appearance="primary"
                        onClick={() =>
                          onSubmitRequestZplPreview(templateZplFormik.values)
                        }
                        icon={
                          <Icon
                            icon={"eye"}
                            color={currentTheme.tableActionButtonHoverIconColor}
                          />
                        }
                        disabled={enableGeneratePreview}
                      >
                        <LanguageProvider id="gen.generate.preview" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="card-actions">
                  <div className="template-manage-button-wrapper">
                    <Button
                      className="p-button p-button-text-plain btn-cancel-template"
                      onClick={() => {
                        navigate("print/template");
                      }}
                    >
                      <LanguageProvider id="gen.cancel" />
                    </Button>
                    <Button
                      className="p-button p-button-primary btn-add-template"
                      onClick={() => {
                        templateZplFormik.handleSubmit();
                      }}
                      loading={isLoading}
                      type="submit"
                    >
                      <LanguageProvider id="gen.save.button" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateMain;
