import React from "react";
import { RadioButton } from "primereact/radiobutton";
import { UniqueSelectionProps } from ".";

export function UniqueSelection(props: UniqueSelectionProps): JSX.Element {
  return (
    <div className="radio-options-wrapper">
      {props.options.map((option, index) => {
        return (
          <div key={index} className="radio-option-preview">
            <RadioButton
              value={option.title}
              onChange={props.onChange}
              checked={props.value === option.title}
            />
            <span className="option-label">{option.title}</span>
          </div>
        );
      })}
    </div>
  );
}
