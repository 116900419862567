import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useHeaderHeight } from "~/hooks/useHeaderHeight";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import "./scss/settings-integrations.scss";

const AuthSettingsIntegrations = () => {
  // Utils
  const headerHeight = useHeaderHeight();
  const dcpAxiosService = useDcpAxiosService();

  // Loading indicators
  const [loading, setLoading] = useState(true);
  const [clientId, setClientId] = useState("");
  const [clientDomain, setClientDomain] = useState("");

  const loadIntegrationData = () => {
    try {
      setLoading(true);
      setClientId(propertyFrommStorage("authentication", "applicationId"));
      setClientDomain(propertyFrommStorage("authentication", "domain"));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadIntegrationData();
  }, []);

  return (
    <div className="page-body-settings-integrations">
      <div
        className="integrations-settings"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="container">
          <div className="header">
            <h3>
              <LanguageProvider id="settings.integrations" />
            </h3>
          </div>
          <div className="settings-content">
            <div className="settings-container">
              <div className="container">
                <div className="group-container">
                  <h3 className="page-header">
                    {<LanguageProvider id={`setting.group.parameters`} />}
                  </h3>
                  <div className="subgroup-container-integrations">
                    <h3>
                      <LanguageProvider
                        id={`setting.subgroup.integration.text.fields.description`}
                      />
                    </h3>
                    <div className="settings">
                      {!loading && (
                        <Form>
                          <FormRow className="setting">
                            <InputContainer
                              label={
                                <LanguageProvider
                                  id={`setting.subgroup.integration.client.id`}
                                />
                              }
                            >
                              <InputText value={clientId} disabled />
                            </InputContainer>
                          </FormRow>
                          <FormRow className="setting">
                            <InputContainer
                              label={
                                <LanguageProvider
                                  id={`setting.subgroup.integration.domain`}
                                />
                              }
                            >
                              <InputText value={clientDomain} disabled />
                            </InputContainer>
                          </FormRow>
                        </Form>
                      )}
                      {loading && (
                        <>
                          <Skeleton
                            width="240px"
                            height="40px"
                            className="skeleton"
                          />
                          <Skeleton
                            width="240px"
                            height="40px"
                            className="skeleton"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthSettingsIntegrations;
